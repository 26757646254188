import React, {Fragment, memo, useCallback, useContext, useEffect, useRef, useState} from "react";
import {hasPermission} from "@utils/utils";
import AuthContext from "@context/AuthContext";
import Content from "@components/Content";
import Card from "@components/Card";
import aclServiceFactory from "@factories/aclServiceFactory";
import {getRoleCollection} from "@utils/utils";
import Icon from "@components/Icon";
import roleEnum from "@enum/roleEnum";
import Button from "@components/Button";
import Row from "@components/Row";
import Col from "@components/Col";
import Modal from "@components/Modal";
import routeServiceFactory from "../factories/routeServiceFactory";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component
import {rand} from "../utils/utils";
import axios from "axios";


const ACL = ({...args}) => {
    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * Service
     */
    const aclService = aclServiceFactory({auth});
    const routeService = routeServiceFactory();

    /**
     * States
     */
    const [rules, setRules] = useState([]);
    const [acl, setAcl] = useState({});
    const [tab, setTab] = useState(null);

    /**
     * References
     */
    const aclRef = useRef();
    aclRef.current = acl;

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const getImage = (name) => {
        return <Image name={name}/>
    }
    const fetch = async () => {
        const response = await aclService.get();

        setRules(response.rules.sort((a, b) => {
            return a.label.localeCompare(b.label, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        }));
        setAcl(response.acl);
    }

    const getRoleName = (role) => {
        return role.replace('ROLE_', '').toLowerCase();
    }

    const checked = (role, permission, manager) => {
        const name = getRoleName(role);
        const field = manager ? 'manager' : 'permission'

        return aclRef.current && aclRef.current[name] && aclRef.current[name][field] && aclRef.current[name][field].includes(permission);
    }

    const change = useCallback((role, permission, manager) => {
        let update = {...aclRef.current};
        const name = getRoleName(role);
        let field = manager ? 'manager' : 'permission';
        let index = -1;
        let matches = [];
        let normalPermission = permission.replace('_own', '');

        field = update[name].manager && (update[name].manager.indexOf(`${permission}`) !== -1 || update[name].manager.indexOf(`${normalPermission}`) !== -1) ? 'manager' : field;

        if(permission.indexOf('_own') === -1) {
            if(update[name].permission.indexOf(`${permission}_own`) !== -1 || (update[name].manager && update[name].manager.indexOf(`${permission}_own`) !== -1)) {
                permission = `${permission}_own`;
            }
        }

        for(let perm of [permission, normalPermission]) {
            index = update[name].permission.indexOf(perm);
            if (index !== -1) {
                matches.push(`permission_${perm}`);
                update[name].permission.splice(index, 1);
            }

            if(update[name].manager) {
                index = update[name].manager.indexOf(perm);
                if (index !== -1) {
                    matches.push(`manager_${perm}`);
                    update[name].manager.splice(index, 1);
                }
            }
        }

        if(matches.indexOf(`${field}_${permission}`) === -1) {
            update[name][field].push(permission);
        } else if(manager && matches.indexOf(`manager_${permission}`) !== -1) {
            update[name].permission.push(permission);
        } else if(matches.indexOf(`${field}_${normalPermission}_own`) !== -1) {
            update[name][field].push(normalPermission);
        }

        setAcl(update);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const submit = async () => {
        await aclService.patch(acl);
    }

    const getRoute = (route) => {

        if(route) {
            if(route.indexOf('/') === 0) {
                return route
            }

            return routeService[route] ? routeService[route]() : route
        }

        return (<span className={'no-documentation'}>keine Route dokumentiert</span>);
    }

    const getRequired = (require) => {
        if(require) {
            return require.map((option) => <li key={rand()}>{option.text}</li>);
        }
        return <li className={'nothing'}><span className={'no-documentation'}>benötigt keine zusätzlichen kostenpflichtigen Optionen</span></li>
    }

    const addRequire = (item) => {
        if(item.require) {
            return <Icon tooltip={'Dieses Recht benötigt kostenpflichtige Optionen im aktuellen Vertrag.'} icon={'money'} className={'pay-contract-option'}/>
        }

        return <Fragment />;
    }

    const getModalContent = (content) => {
        return <table>
            <tbody>
            <tr><td><strong>Name</strong>:</td><td>{content.item.label}</td></tr>
            <tr><td><strong>Gruppe</strong>:</td><td>{content.group.label}</td></tr>
            <tr><td><strong>Route</strong>:</td><td>{getRoute(content.item.url)}</td></tr>
            <tr><td><strong>Recht Frontend</strong>:</td><td>{content.item.name}</td></tr>
            <tr><td><strong>API Recht</strong>:</td><td>{content.item.api ? content.item.name.replace('_view', '') : (<span className={'no-documentation'}>Besitzt kein API Recht</span>)}</td></tr>
            <tr><td><strong>Vertrag</strong>:</td><td><ul className={'require-options'}>{getRequired(content.item.require)}</ul></td></tr>
            <tr><td><strong>Wird verwendet bei</strong>:</td><td>{getImage(content.item.image)}</td></tr>
            </tbody>
        </table>;
    }

    if (!hasPermission(auth, 'acl_list_view')) {
        return <Fragment/>;
    }

    return <Fragment>
        <Content>
            <Card title={'Rechte'}>
                <Row>
                    <Col sm={2} md={1}>
                        <div className={'acl-tab-navigation'}>
                            <strong>Bereiche/Seiten</strong>
                            {rules.map((group, groupIndex) => <div key={`acl-tab-${groupIndex}`}  onClick={() => setTab(group.group)} className={`navigation ${group.group === tab ? 'active' : ''}`.trim()}><span>{group.label}</span></div>)}
                            {tab && (<Button onClick={submit} color={'success'}>Speichern</Button>)}
                        </div>
                    </Col>

                    {tab && (
                    <Col sm={10} md={11}>
                        {rules.filter(item => item.group === tab).map((group) => <Fragment key={rand()}>
                            {group.sections.map((section) => <Fragment key={rand()}>
                                <div className={'acl-container'}>
                                    <div className={'acl-content'}>
                                        <div className={'permissions'}>
                                            <div className={'header'}>
                                                <div key={`id-${rand()}`} className={'column th'}>
                                                    <div className={'cell section-name'}>
                                                        {section.name}
                                                    </div>
                                                </div>
                                            {getRoleCollection().filter(item => item.key !== roleEnum.ROLE_MANAGER).map((role, roleIndex) =>
                                                <div key={`id-${roleIndex}`} className={'column th'}>
                                                    <div className={'cell'}>
                                                        {role.label}
                                                    </div>

                                                </div>)}
                                            </div>
                                            <div className={'column th acl-row'}>
                                                {section.items.map((item, itemIndex) => <div key={`id-header-${itemIndex}`}
                                                                                             className={'cell sub label'}>
                                                    <Modal size={[1024, 650]} title={'Rechte Details'} contentData={{item, group}} content={getModalContent} button={<span><Icon tooltip={`Details`} className={'details-button'} icon={'info'} /></span>}>
                                                    </Modal>
                                                    {addRequire(item)}
                                                    <span>{item.label}</span>
                                                </div>)}
                                            </div>
                                            {getRoleCollection().filter(item => item.key !== roleEnum.ROLE_MANAGER).map((role, roleIndex) => <Fragment key={rand()}>
                                                <div key={`id-${roleIndex}`} className={'column'}>
                                                    {section.items.map((item, itemIndex) => <div
                                                        key={`id-${roleIndex}-${itemIndex}`} className={`cell sub ${checked(role.key, item.name) || checked(role.key, item.name, true) || checked(role.key, `${item.name}_own`) || checked(role.key, `${item.name}_own`, true) ? 'perm-enabled' : ''}`}>
                                                        <PermissionItem
                                                            name={item.name}
                                                            roleId={role.key}
                                                            onChange={change}
                                                            managed={checked(role.key, item.name, true) || checked(role.key, `${item.name}_own`, true)}
                                                            checked={checked(role.key, item.name) || checked(role.key, item.name, true) || checked(role.key, `${item.name}_own`) || checked(role.key, `${item.name}_own`, true)}
                                                        />
                                                        <PermissionItemOwn
                                                            disabled={!item.hasOnlyOwn}
                                                            name={`${item.name}_own`}
                                                            roleId={role.key}
                                                            onChange={change}
                                                            checked={checked(role.key, `${item.name}_own`) || checked(role.key, `${item.name}_own`, true)}
                                                        />
                                                        {getRoleName(role.key) !== 'admin' && (
                                                            <PermissionItemManaged
                                                                name={item.name}
                                                                roleId={role.key}
                                                                onChange={change}
                                                                checked={checked(role.key, item.name, true) || checked(role.key, `${item.name}_own`, true)}
                                                            />)}
                                                        {(getRoleName(role.key) === 'home'  || getRoleName(role.key) === 'doctor') && (
                                                            <PermissionItemContractRequired
                                                                name={`${item.name}_required_contract`}
                                                                roleId={role.key}
                                                                onChange={change}
                                                                checked={checked(role.key, `${item.name}_required_contract`) || checked(role.key, `${item.name}_required_contract`, true)}
                                                            />)}
                                                    </div>)}
                                                </div>
                                                </Fragment>)}
                                        </div>
                                    </div>
                                </div>
                            </Fragment>)}
                        </Fragment>)}
                    </Col>)}
                    {!tab && (
                        <Col osm={1} sm={8} md={8} omd={1} className={'mt-5 mb-5'}>
                            <div className={'medicharge-acl-introduction'}>
                                <h1>Willkommen im ACL von Medicharge</h1>
                                <p>
                                    Auf dieser Seite können Sie jeder Rolle individuelle Zugriffsrechte auf die einzelnen Medicharge Bereiche geben.<br/>
                                    Sie können bestimmen welche Seiten und Tabellenspalte jede Rolle sehen kann.<br />
                                    Sie bestimmen welche Rolle welche Aktion ausführen dar.
                                </p>
                                <br />
                                <br />
                                <p>
                                    <h3>1. Schritt</h3>
                                    Wählen Sie auf der linken Seite eine Bereich/Seite aus für den Sie neue Rechte festlegen möchten.
                                    <br /><br />
                                    <h3>2. Schritt</h3>
                                    Auf jeder einstellungsseite haben Sie die Möglichkeit zu bestimmen ob eine Rolle Vollzugriff bekommt <br />
                                    oder nur eigene Inhalte sehen darf die mit seinen Benutzer verknüpft sind. <br/>
                                    Oder Sie geben nur Manager einer Rolle entsprechenden Zugriff.
                                    <br /><br />
                                    <h3>3. Schritt</h3>
                                    Nachdem Sie alle Rechte ausgewählt haben klicken Sie auf speichern. Den Button dafür finden Sie auf der Linken Seite direkt unter der "Bereich/Seite" Auswahl.<br />
                                    <strong>Achtung:</strong> Speichern Sie bevor Sie in einen anderen Bereich/Seite wechseln
                                    <br />
                                    <br />
                                    <h3>4. Schritt</h3>
                                    Starten Sie ihren Medicharge Server neu. Die Rechte werden nur beim beim starten des Serves erneut eingelesen.
                                    <br />
                                    <br />
                                    <strong>Info:</strong>
                                    <ul>
                                        <li>- Jedes Recht besitzt eine kleine Hilfe (<Icon tooltip={`Details`} className={'details-button'} icon={'info'} />) damit Sie schnell die stelle finden wo diese Berechtigung Anwendung findet.<br />
                                            &nbsp;&nbsp;zum öffnen der Hilfe klicken Sie auf das Icon <Icon tooltip={`Details`} className={'details-button'} icon={'info'} /> in der Spalte wo der Name des Rechtes steht.
                                        </li>
                                        <li>- Sollten Sie wissen wollen was ein Icon bedeutet, halten Sie einfach den Mauszeiger darüber</li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                    )}
                </Row>
            </Card>
        </Content>
    </Fragment>
}

const PermissionItem = memo(({name, roleId, onChange, checked, managed}) => {
    return (<Fragment>
            <label onClick={() => onChange(roleId, name, false)}>
                {checked && (
                    <Icon
                        tooltip={managed ? 'Nur Manager hat Zugriff' : 'Alle Nutzer haben Zugriff'}
                        icon={'check'} className={'check'}/>)}
                {!checked && (
                    <Icon tooltip={'Kein Zugriff'} icon={'forbidden'}
                          className={'forbidden'}/>)}
            </label>
</Fragment>)
});

const PermissionItemManaged = memo(({name, roleId, onChange, checked}) => {
    return <label onClick={() => onChange(roleId, name, true)}>
        <Icon
            tooltip={checked ? 'Nur Manager (aktiviert)' : 'Nur Manager (deaktiviert)'}
            icon={'manager'}
            className={`check manager ${checked ? '' : 'disabled'}`}/>
    </label>;
});

const PermissionItemOwn = memo(({name, roleId, onChange, checked, disabled}) => {

    /**
     * Functions
     */
    const getTooltip = () => {
        if(!disabled) {
            return checked ? 'Nur eigene Inhalte (aktiviert)' : 'Nur eigene Inhalte (deaktiviert)';
        }

        return '';
    }

    return <label onClick={() => { if(!disabled) { onChange(roleId, name, false) } }}>
        <Icon
            tooltip={getTooltip()}
            icon={'protected_access'}
            className={`check own ${checked ? '' : 'disabled'} ${disabled ? 'hidden' : ''}`}/>
    </label>;
});

const PermissionItemContractRequired = memo(({name, roleId, onChange, checked, disabled}) => {

    /**
     * Functions
     */
    const getTooltip = () => {
        if(!disabled) {
            return checked ? 'Nur mit Vertrag verfügbar (aktiviert)' : 'Nur mit Vertrag verfügbar (deaktiviert)';
        }

        return '';
    }

    return <label onClick={() => { if(!disabled) { onChange(roleId, name, false) } }}>
        <Icon
            tooltip={getTooltip()}
            icon={'money'}
            className={`check own ${checked ? '' : 'disabled'} ${disabled ? 'hidden' : ''}`}/>
    </label>;
});

const Image = ({name}) => {
    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [state, setState] = useState(false);

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const fetch = async () => {
        if(name) {
            const response = await axios.get(`/api/acl/doku/${name}`, {headers: {'x-auth-token': auth.token}});

            if(response.data.image) {
                setState(response.data.image);
            }

            return;
        }

        setState(-1);
    }

    return <Fragment>
        {state === null && (<span className={'no-documentation'}>Lade Bild ...</span>)}
        {state === -1 && (<span className={'no-documentation'}>keine Bild vorhanden</span>)}
        {state && (<img className={'acl_permission_img'} src={state} alt={''}/>) }
    </Fragment>
}

ACL.propTypes = {};

ACL.defaultProps = {};

export default ACL;
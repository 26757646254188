/* jshint esversion: 8 */

const createUriSortParameters = (sort) => {
    let first = true;
    let result = '';
    for (let field in sort) {
        if (!first) {
            result += '&';
        }
        result += `sort[${field}]=${sort[field]}`;
        first = false;
    }

    return result;
};

const initSorting = (sort, setSort, filter, setFilter) => {
    for (let element of document.getElementsByClassName('filterable')) {
        element.onclick = (e) => {
            let target = e.target.tagName === 'TH' ? e.target : e.target.parentNode;
            let sorting = {};
            if (!target.classList.contains('asc') && !target.classList.contains('desc')) {
                target.classList.add('asc');
                sorting = { ...sort, [target.dataset.field]: 'asc' };
            } else if (target.classList.contains('asc')) {
                target.classList.remove('asc');
                target.classList.add('desc');
                sorting = { ...sort, [target.dataset.field]: 'desc' };
            } else {
                target.classList.remove('desc');
                let tmp = { ...sort };
                delete tmp[target.dataset.field];
                sorting = tmp;
            }

            setSort(sorting);
            if(filter && setFilter) {
                setFilter({...filter, sort: sorting});
            }
        };
    }
};

export { createUriSortParameters, initSorting };
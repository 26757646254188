export default class Observer {
    collection = [];
    callback = null;
    lastCollect = null;

    constructor(callback) {
        this.callback = callback;
    }

    register(handler) {
        if(handler.unique) {
            const index = this.collection.findIndex((item) => { return item.key === handler.key });
            if(index > -1) {
                this.collection.splice(index, 1);
            }
        }
        this.collection.push(handler);
    }

    pushUpdate(args, elementKey, mounted) {
        if(elementKey) {
            const index = this.collection.findIndex((item) => item.key === elementKey);

            if(index > -1) {
                if(mounted === undefined || mounted) {
                    this.collection[index].update(args);
                } else {
                    this.collection[index]._state = args;
                }
            }

            return;
        }

        for(let ii = 0; ii < this.collection.length; ii++) {
            if(typeof this.collection[ii] === 'function') {
                this.collection[ii](args);
            } else if(this.collection[ii].update) {
                this.collection[ii].update(args);
            }
        }
    }

    notify(args) {
        if(this.callback) {
            this.lastCollect = args;
            args = args ? args : {};
            const data = {...this.collect(true), ...args};

            this.callback(this.normalize(data), this);
            this.lastCollect = null;
        }
    }

    normalize(data) {
        let result = {};

        for(let name in data) {
            const value = data[name];
            if((typeof value === 'string' || value instanceof Array)) {
                result[name] = value;
            } else if (value !== ''){
                result[name] = value;
            }
        }

        return result;
    }

    get(key, deleteUpdate) {
        const index = this.collection.findIndex((item) => { return item.key === key });
        if(index > -1) {
            if('_state' in this.collection[index]) {
                const state = this.collection[index]._state;
                if(deleteUpdate) {
                    delete this.collection[index]._state;
                }
                return state;
            }
            return this.collection[index].get();
        }

        return undefined;
    }

    collect(useAssoziativesArray) {
        let data = useAssoziativesArray ? {} : [];

        for(let ii = 0; ii < this.collection.length; ii++) {
            if(this.collection[ii].get) {
                let item = this.get(this.collection[ii].key, false); this.collection[ii].get();
                if(item) {
                    if(useAssoziativesArray) {
                        const key = this.collection[ii].name ? this.collection[ii].name : this.collection[ii].key;
                        item = item instanceof Object ? item : {[key]: item}
                        data = {...data, ...item};
                    } else {
                        data.push(item);
                    }
                }
            } else if(this.collection[ii].collect) {
                let items = null;
                if(this.collection[ii].lastCollect) {
                    items = this.collection[ii].lastCollect;
                }

                if(useAssoziativesArray) {
                    items = items ? items : this.collection[ii].collect(useAssoziativesArray);
                    data = {...data, ...items}
                } else {
                    items = items ? items : Object.values(this.collection[ii].collect(useAssoziativesArray));
                    data = data.concat(items);
                }
            }
        }

        return data;
    }
}
import React, {Fragment, useContext} from "react";
import {Link as ReactLink} from "react-router-dom";
import PropTypes from "prop-types";
import AuthContext from "@context/AuthContext";
import {hasPermission} from "@utils/utils";
import ContractRequiredModal from "./ContractRequiredModal"; //comment in if you add Proptypes to your Component


const Link = ({target, permission, size, to, type, children, className, onClick, fullWidth, message, ...args}) => {

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * Functions
     */
    const isButton = (type) => {
        return ['button', 'button-outlined', 'button-success'].indexOf(type) !== -1
    }

    if(permission && !hasPermission(auth, permission)) {
        if(hasPermission(auth, `${permission}_required_contract`)) {
            return <ContractRequiredModal label={children} className={`${className} missing-contract`} message={message} type={type} fullWidth={fullWidth}/>
        } else {
            return <Fragment />;
        }
    }

    return (
        <ReactLink target={target} onClick={onClick} to={to} className={['link', size, type, isButton(type) ? 'btn' : '', fullWidth ? 'fullwidth': '', className].join(' ').trim()}>
            <span className='link-title'>{children}</span>
        </ReactLink>
    );
}

Link.propTypes = {
    size: PropTypes.oneOf(['small', 'normal', 'large']),
    type: PropTypes.oneOf(['normal', 'button', 'button-outlined', 'uncolored', 'button-success'])
};

Link.defaultProps = {
    size: 'normal',
    type: 'normal',
    fullWidth: false,
    message:'',
    target: ''
};

export default Link;
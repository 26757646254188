import React, {Fragment, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component
import {Circles} from "react-loading-icons";
import ToolTip from "./ToolTip";
import AuthContext from "@context/AuthContext";
import {hasPermission} from "@utils/utils";
import {rand} from "@utils/utils";


const Button = ({isLoading, id, tableButton, permission, outlined, disabled, tooltip, icon, onClick, children, fullwidth, size, color, className, ...args}) => {

    id = id ? id : `button-${rand()}`;

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * State
     */
    const [loading, setLoading] = useState(false);

    /**
     * Hooks
     */
    useEffect(() => {
        if(loading) {
            doClick();
        }

        return () => {
            //console.log("www1111111");
            //setLoading(false);
        }
    }, [loading]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const doClick = async () => {
        if(onClick) {
            await onClick();
        }

        setLoading(false);
    }

    const click = (e) => {
        e.preventDefault();

        setLoading(true)

        return false;
    }

    const buildButton = () => {
        return <button id={id} disabled={!!loading || isLoading || disabled} onClick={click} className={[className ? className : '', tableButton ? 'table-button' : '', 'btn', `btn-${color}`, fullwidth ? `fullwidth` : '', size, outlined ? 'outlined' : '', outlined ? 'btn-primary' : ''].join(' ')} {...args}>
            {(loading || isLoading) && (<Circles stroke={color} fill={color} className={'action-loader'} />)}
            {!(loading || isLoading) && icon}
            {children}
        </button>;
    }

    if(permission && !hasPermission(auth, permission)) {
        return <Fragment />;
    }

    if(tooltip) {
        /**
         * Validate Tooltip
         */
        if(!tooltip.content) {
            tooltip = {content: tooltip};
        }

        return <ToolTip className={fullwidth ? `fullwidth` : ''} arrow={tooltip.arrow} place={tooltip.place} type={tooltip.type} effect={tooltip.effect} trigger={buildButton()}>{tooltip.content}</ToolTip>
    }

    return buildButton();
}

Button.propTypes = {
    size: PropTypes.oneOf(['small', 'normal', 'large', 'xxl']),
    outlined: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'success', 'danger', 'warning']),
    disabled: PropTypes.bool,
    fullwidth: PropTypes.bool,
    tableButton: PropTypes.bool,
    isLoading: PropTypes.bool
};

Button.defaultProps = {
    color: 'primary',
    size: 'normal',
    outlined: false,
    disabled: false,
    fullwidth: false,
    tableButton: false,
    isLoading: false
};

export default React.memo(Button);
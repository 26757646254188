/* jshint esversion: 8 */
import factory from "@factories/factory";
import request from "@utils/request";
import {toast} from "react-toastify";

const contractServiceFactory = (config) => {
    return {
        ...config,
        ...factory,
        path: 'contracts',
        buildContractId: function (item) {
            let debtorId = item.debtorId;

            if(item.home) {
                debtorId = item.home.debtorId;
            } else if(item.doctor){
                debtorId = item.doctor.debtorId;
            }

            return `${item._id}_${debtorId}`;
        },
        cancel: async function(contractId, option) {
            const response = await request.put(`/api/contracts/${contractId}`, {options: [option]}, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                if(option.revoke) {
                    toast.success('Ihre Kündigung der Option wurden erfolgreich zurückgenommen.');
                } else {
                    toast.success('Ihre Option wurden erfolgreich gekündigt.');
                }
            } else {
                toast.error('Ihre Optionen konnten nicht gekündigt werden.');
            }
        },
        bookOptions: async function(contractId, optionList) {
            const response = await request.put(`/api/contracts/${contractId}`, {options: optionList}, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                toast.success('Ihre Optionen wurden erfolgreich gebucht, vielen Dank.');
            } else {
                toast.error('Ihre Optionen konnten nicht gebucht werden.');
            }
        },
        adoption: async function(contractId, message) {
            const response = await request.post(`/api/contracts/${contractId}/adoption`, {}, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                toast.success(message ? message : 'Sobald ihr Vertragspartner bestätigt hat übernimmt dieser alle zukünftigen Rechnungen.');
                return response.data;
            } else {
                if(response.data.reason) {
                    switch(response.data.reason) {
                        case 'missing_manager':
                        case 'missing_email':
                            toast.error('Die Kostenübernahme konnte nicht angefragt werden, da die andere Partei nicht informiert werden kann.');
                            break;
                        default:
                    }
                } else {
                    toast.error('Die Kostenübernahme konnte nicht angefragt werden.');
                }
            }
        },
        reject: async function(contractId) {
            const response = await request.put(`/api/contracts/${contractId}/adoption/reject`, {}, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                toast.success('Die Vertragsübernahme wurde abgelehnt. Der Vertrag ist zurück an den Vertragspartner gegangen.');
            }
        },
        accept: async function(contractId) {
            const response = await request.put(`/api/contracts/${contractId}/adoption/accept`, {}, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                toast.success('Der Vertrag wurde erfolgreich bestätigt und aktiviert.');
            } else {
                toast.error('Der Vertrag konnte nicht bestätigt werden.');
            }
        },
        withdraw: async function(contractId) {
            const response = await request.post(`/api/contracts/${contractId}/adoption/withdraw`, {}, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                toast.success('Die Kostenübernahme wurde zurückgezogen.');
            } else {
                toast.error('Die Kostenübernahme konnte nicht zurückgezogen werden.');
            }
        }
    }
}

export default contractServiceFactory;
/* jshint esversion: 8 */
import {Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, TextField} from '@material-ui/core';
import React, {Fragment, useState} from 'react';
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import {RiLockPasswordLine} from "react-icons/ri";
import {Password} from "../utils/password";
import {checkRequiredFields, inputChange, isEmptyObject} from "../utils/tools";

const PasswordForm = (params) => {

    const simplifiedPasswordComplexity = {
        "minLength": 5,
        "minLowercaseCount": 0,
        "minUppercaseCount": 0,
        "minNumbersCount": 0,
        "minSpecialcharsCount": 0,
        "maxLength": 4096,
        "forbiddenchars": true
    };

    const [state, setState] = useState({
        password: '',
        password_confirm: '',
        password_required: params.hasOwnProperty('password_required') ? params.password_required : false,
        once_password: params.once_password ? false : null,
        current_password: params.current_password ? '' : null,
        minLength: params.min_length >= 0 ? params.min_length : 12,
        minLowercaseCount: params.min_lowercase_count >= 0 ? params.min_lowercase_count : 2,
        minUppercaseCount: params.min_uppercase_count >= 0 ? params.min_uppercase_count : 2,
        minNumbersCount: params.min_numbers_count >= 0 ? params.min_numbers_count : 2,
        minSpecialcharsCount: params.min_specialchars_count >= 0 ? params.min_specialchars_count : 2,
        maxLength: params.max_length >= 0 ? params.max_length : 4096,
        forbiddenchars: params.hasOwnProperty('forbiddenchars') ? params.forbiddenchars : true,
        disabled: params.hasOwnProperty('disabled') ? params.disabled : false,
    });

    const [validation, setValidation] = useState({
        error: false,
        errorMessage: {},
    });

    const [value, setValue] = useState({
        showPassword: false
    });

    const handleClickShowPassword = () => {
        setValue({...value, showPassword: !value.showPassword});
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const stateValue = (aState, name) => {
        if (!(name in aState)) {
            return '';
        }
        return aState[name];
    };

    const validate = (e, aState) => {
        let newState = aState === undefined ? Object.assign({}, state) : aState;
        let requiredFields = {};
        if (params.current_password && 'password' in newState && newState.password !== '') {
            requiredFields.current_password = 'Aktuelles Passwort';
        }
        let newValidation = checkRequiredFields(validation, requiredFields, state);
        if ('password' in newState && newState.password !== '') {
            let complexityErrors = newState.once_password
                ? Password.checkPasswordComplexity(
                    newState.password,
                    simplifiedPasswordComplexity.minLength,
                    simplifiedPasswordComplexity.minLowercaseCount,
                    simplifiedPasswordComplexity.minLowercaseCount,
                    simplifiedPasswordComplexity.minNumbersCount,
                    simplifiedPasswordComplexity.minSpecialcharsCount,
                    simplifiedPasswordComplexity.maxLength,
                    simplifiedPasswordComplexity.forbiddenchars
                )
                : Password.checkPasswordComplexity(
                    newState.password,
                    newState.minLength,
                    newState.minLowercaseCount,
                    newState.minUppercaseCount,
                    newState.minNumbersCount,
                    newState.minSpecialcharsCount,
                    newState.maxLength,
                    newState.forbiddenchars
                );
            if (complexityErrors.length > 0) {
                newValidation['error'] = true;
                newValidation['errorMessage']['password'] = complexityErrors.join("\n");
            }
            else {
                let errorMessage = newValidation.errorMessage;
                delete (errorMessage['password']);
                newValidation['errorMessage'] = {
                    ...errorMessage,
                };
                newValidation['error'] = !isEmptyObject(errorMessage);
            }
        }
        else {
            let errorMessage = newValidation.errorMessage;
            delete (errorMessage['password']);
            newValidation['errorMessage'] = {
                ...errorMessage,
            };
            newValidation['error'] = !isEmptyObject(errorMessage);
        }
        if (stateValue(newState, 'password') !== stateValue(newState, 'password_confirm')) {
            newValidation['error'] = true;
            newValidation['errorMessage']['password_confirm'] = "Passwort und Passwortwiederholung müssen übereinstimmen. "
                + Password.checkPasswordCompare(stateValue(newState, 'password'), stateValue(newState, 'password_confirm'));
        }
        else {
            let errorMessage = newValidation.errorMessage;
            delete (errorMessage['password_confirm']);
            newValidation['errorMessage'] = {
                ...errorMessage,
            };
            newValidation['error'] = !isEmptyObject(errorMessage);
        }
        if (aState === undefined) {
            setState(newState);
        }
        setValidation(newValidation);

        if (e !== undefined) {
            // call validate function on parent form
            if ('onValidate' in params) {
                params.onValidate(undefined, newValidation);
            }
            // save data into parent form state
            if (!newValidation['error'] && 'onSave' in params) {
                params.onSave(e, 'bearbeiten', newState);
            }
        }

        return !newValidation.error;
    };

    const generatePasswordClick = (event) => {
        event.preventDefault();
        let password = state.once_password
            ? Password.generatePassword(
                simplifiedPasswordComplexity.minLength,
                simplifiedPasswordComplexity.minLowercaseCount,
                simplifiedPasswordComplexity.minUppercaseCount,
                simplifiedPasswordComplexity.minNumbersCount,
                simplifiedPasswordComplexity.minSpecialcharsCount)
            : Password.generatePassword(12,2,2,2,2);

        let newState = Object.assign({}, state);
        newState.password = password;
        newState.password_confirm = password;
        validate(event, newState);
        setState(newState);

        setValue({...value, 'showPassword': true});

        return false;
    };

    const passwordInput = (e) => {
        let errors = checkPasswortComplexityDependingOnOncePassword(e.target.value);
        if (errors.length > 0) {
            let newValidation = Object.assign({}, validation);
            newValidation.error = true;
            newValidation.errorMessage['password'] = errors.join(" ");
            setValidation(newValidation);
        }
        else {
            let newValidation = Object.assign({}, validation);
            newValidation.error = false;
            delete newValidation.errorMessage['password'];
            setValidation(newValidation);
        }
    };

    const passwordIdentical = (e) => {
        let newValidation = Object.assign({}, validation);
        let newState = Object.assign({}, state);

        if (stateValue(newState, 'password') !== e.target.value.trim()) {
            newValidation['error'] = true;
            newValidation['errorMessage']['password_confirm'] = "Passwort und Passwortwiederholung müssen übereinstimmen. "
                + Password.checkPasswordCompare(stateValue(newState, 'password'), e.target.value.trim());
        }
        else {
            let errorMessage = newValidation.errorMessage;
            delete (errorMessage['password_confirm']);
            newValidation['errorMessage'] = {
                ...errorMessage,
            };
            newValidation['error'] = !isEmptyObject(errorMessage);
        }

        setValidation(newValidation);
    };

    const checkPasswortComplexityDependingOnOncePassword = (value) => {
        let once_password_checked = state.once_password;
        return once_password_checked === true
            ? Password.checkPasswordComplexity(value,
                simplifiedPasswordComplexity.minLength,
                simplifiedPasswordComplexity.minLowercaseCount,
                simplifiedPasswordComplexity.minUppercaseCount,
                simplifiedPasswordComplexity.minNumbersCount,
                simplifiedPasswordComplexity.minSpecialcharsCount,
                simplifiedPasswordComplexity.maxLength,
                simplifiedPasswordComplexity.forbiddenchars)
            : Password.checkPasswordComplexity(value,
                state.minLength,
                state.minLowercaseCount,
                state.minUppercaseCount,
                state.minNumbersCount,
                state.minSpecialcharsCount,
                state.maxLength,
                state.forbiddenchars);
    };

    params.parentRef.current.onValidate = validate;
    params.parentRef.current.getState = () => { return state; };

    return (
        <Fragment>
            <Grid container spacing={1}>
                {params.current_password && (
                    <Grid item xs={12}>
                        <TextField
                            autoComplete='off'
                            disabled={state.disabled}
                            error={!!validation.errorMessage.current_password}
                            // formHelperTextProps={{focused: true}}
                            fullWidth
                            helperText={validation.errorMessage.current_password || ''} //'Das aktuelle Passwort.'}
                            id='current_password'
                            InputProps={{
                                autoComplete: 'off',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={state.disabled}
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            tabIndex={-104}
                                            title="Passwortsichtbarkeit umschalten"
                                        >
                                            {value.showPassword ? <MdVisibility/> : <MdVisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            label='Aktuelles Password'
                            name='current_password'
                            onBlur={validate}
                            onChange={(e) => setState(inputChange(e, state))}
                            required={state.password !== ''}
                            tabIndex={-110}
                            type={value.showPassword ? 'text' : 'password'}
                            value={state.current_password}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <TextField
                        autoComplete='new-password'
                        disabled={state.disabled}
                        error={!!validation.errorMessage.password}
                        // formHelperTextProps={{focused: true}}
                        fullWidth
                        helperText={validation.errorMessage.password || ''}
                        // 'Mindestens 12 Zeichen. ' +
                        // 'Davon 2 kleine Buchstaben "a-zäöüß", ' +
                        // '2 große Buchstaben "A-ZÄÖÜ", ' +
                        // '2 Zahlen "0-9" und ' +
                        // '2 Sonderzeichen " <>|,.-;:_#*+~^°!§$£%&/()=?²³[]{}\\"' +
                        // ' (Ohne o,i,l,O,°, ,|,²,³,£ um Verwechselungen zu vermeiden)'}
                        id='password'
                        InputProps={{
                            autoComplete: 'new-password',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={state.disabled}
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        tabIndex={-106}
                                        title="Passwortsichtbarkeit umschalten"
                                    >
                                        {value.showPassword ? <MdVisibility/> : <MdVisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label='Passwort'
                        name='password'
                        onBlur={validate}
                        onChange={(e) => setState(inputChange(e, state))}
                        onInput={passwordInput}
                        placeholder='Passwort'
                        required={state.password_required}
                        tabIndex={-109}
                        type={value.showPassword ? 'text' : 'password'}
                        value={state.password || ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoComplete='new-password'
                        disabled={state.disabled}
                        error={!!validation.errorMessage.password_confirm}
                        // formHelperTextProps={{focused: true}}
                        fullWidth
                        helperText={validation.errorMessage.password_confirm || ''} //'Passwort muss das selbe wie oben sein.'}
                        id='password_confirm'
                        InputProps={{
                            autoComplete: 'new-password',
                            endAdornment: (
                                <InputAdornment position="end">
                                    {params.generate_password && ((
                                        <IconButton
                                            disabled={state.disabled}
                                            onClick={generatePasswordClick}
                                            onMouseDown={handleMouseDownPassword}
                                            tabIndex={-105}
                                            title="Passwort generieren"
                                        >
                                            <RiLockPasswordLine/>
                                        </IconButton>
                                    ) || (
                                        <IconButton
                                            disabled={state.disabled}
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            title="Passwortsichtbarkeit umschalten"
                                        >
                                            {value.showPassword ? <MdVisibility/> : <MdVisibilityOff/>}
                                        </IconButton>
                                    ))}
                                </InputAdornment>
                            ),
                        }}
                        label='Password wiederholen'
                        name='password_confirm'
                        onBlur={validate}
                        onChange={(e) => setState(inputChange(e, state))}
                        onInput={passwordIdentical}
                        placeholder='Password wiederholen'
                        required={state.password !== ''}
                        tabIndex={-108}
                        type={value.showPassword ? 'text' : 'password'}
                        value={state.password_confirm}
                    />
                </Grid>
                {params.once_password && (
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.once_password}
                                        color='default'
                                        disabled={state.disabled}
                                        id='once_password'
                                        name='once_password'
                                        onBlur={validate}
                                        onChange={(e) => setState(inputChange(e, state))}
                                        tabIndex={-107}
                                    />
                                }
                                label='Nur einmal gültig'
                                style={{'marginTop': '18px', 'marginLeft': '10px'}}
                            />
                            <FormHelperText>Nutzer muss nach dem Login das Passwort ändern.</FormHelperText>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </Fragment>
    );
};

export default PasswordForm;

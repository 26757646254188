import PropTypes from "prop-types";
import {
    AiOutlineLock,
    AiOutlineUser,
    BiMessageDetail,
    BiImport,
    BsInfoCircle,
    BsSortAlphaDown,
    BsSortAlphaUpAlt,
    FaBed,
    FaFax,
    FaTasks,
    FaTimes,
    FaTruckLoading,
    FiClipboard,
    FiEdit,
    FiEye,
    FiEyeOff,
    FiHelpCircle,
    FiTrash2,
    GiApothecary,
    GiWallet,
    GrRevert,
    IoDocumentTextOutline,
    IoSettingsSharp,
    MdAdminPanelSettings,
    MdArrowForwardIos,
    MdManageAccounts,
    MdAlternateEmail,
    RiMedicineBottleFill,
    AiOutlineDownload,
    VscFilePdf,
    AiOutlineFileJpg,
    GrDocument,
    BsCart4,
    BsShieldLockFill,
    RiForbid2Line,
    BsShieldCheck,
    FaUsersCog,
    BsCashCoin,
    GoPackage,
    CgDetailsMore,
    MdLogin,
    RiUserShared2Line,
    RiUserReceived2Line,
    AiFillSound, MdOutlinePaid, BsCalendar3, BiVolumeMute, FaBriefcaseMedical,
} from "react-icons/all";
import {FaClinicMedical, FaNotesMedical} from "react-icons/fa";
import {HiOutlineDocumentText} from "react-icons/hi";
import {BsCardList, BsPersonCircle, BsQuestionCircle} from "react-icons/bs";
import {FiCheck, FiHome, FiList, FiPlusSquare, FiUsers} from "react-icons/fi";
import {AiOutlineMinusSquare} from "react-icons/ai";
import React from "react";
import {addClass} from "@utils/utils";
import {Circles} from "react-loading-icons";
import ToolTip from "@components/ToolTip";

const Icon = ({icon, tooltip, className, ...args}) =>{

    /**
     * Functions
     */
    const getIcon = (icon, className) => {
        switch (icon) {
            case 'edit':
                return (<FiEdit className={addClass(className)} {...args} />);
            case 'medical_note':
                return (<FaNotesMedical className={addClass(className)} {...args} />);
            case 'contract':
                return (<HiOutlineDocumentText className={addClass(className)} {...args} />);
            case 'medical_home':
                return (<FaClinicMedical className={addClass(className)} {...args} />);
            case 'home':
                return (<FiHome className={addClass(className)} {...args} />);
            case 'person':
                return (<BsPersonCircle className={addClass(className)} {...args} />);
            case 'users':
                return (<FiUsers className={addClass(className)} {...args} />);
            case 'summary':
                return (<BsCardList className={addClass(className)} {...args} />);
            case 'check':
                return (<FiCheck className={addClass(className)} {...args} />);
            case 'list':
                return (<FiList className={addClass(className)} {...args} />);
            case 'question':
                return (<BsQuestionCircle className={addClass(className)} {...args} />);
            case 'plus':
                return (<FiPlusSquare className={addClass(className)} {...args} />);
            case 'apothecary':
                return (<GiApothecary className={addClass(className)} {...args} />);
            case 'truck':
                return (<FaTruckLoading className={addClass(className)} {...args} />);
            case 'protection':
                return (<MdAdminPanelSettings className={addClass(className)} {...args} />);
            case 'manage':
                return (<MdManageAccounts className={addClass(className)} {...args} />);
            case 'delete':
                return (<FaTimes className={addClass(className)} {...args} />);
            case 'close':
                return (<FaTimes className={addClass(className)} {...args} />);
            case 'clipboard':
                return <FiClipboard className={addClass(className)} {...args} />
            case 'sort_desc':
                return <BsSortAlphaUpAlt className={addClass(className)} {...args} />
            case 'sort_asc':
                return <BsSortAlphaDown className={addClass(className)} {...args} />
            case 'eye':
                return <FiEye className={addClass(className)} {...args} />
            case 'eye_off':
                return <FiEyeOff className={addClass(className)} {...args} />
            case 'lock':
                return <AiOutlineLock className={addClass(className)} {...args} />
            case 'arrow_right':
                return <MdArrowForwardIos className={addClass(className)} {...args} />
            case 'wallet':
                return <GiWallet className={addClass(className)} {...args} />;
            case 'help':
                return <FiHelpCircle className={addClass(className)} {...args} />
            case 'info':
                return <BsInfoCircle className={addClass(className)} {...args} />
            case 'tasks':
                return <FaTasks className={addClass(className)} {...args} />
            case 'patient':
                return <FaBed className={addClass(className)} {...args} />
            case 'settings':
                return <IoSettingsSharp className={addClass(className)} {...args} />
            case 'import':
                return <BiImport className={addClass(className)} {...args} />;
            case 'user':
                return <AiOutlineUser className={addClass(className)} {...args}/>;
            case 'fax':
                return <FaFax className={addClass(className)} {...args} />;
            case 'email':
                return <MdAlternateEmail className={addClass(className)} {...args} />;
            case 'document':
                return <IoDocumentTextOutline className={addClass(className)} {...args} />;
            case 'medicine':
                return <RiMedicineBottleFill className={addClass(className)} {...args} />
            case 'trash':
                return <FiTrash2 className={addClass(className, 'icon-color-danger')} {...args}/>
            case 'revert':
                return <GrRevert className={addClass(className, 'icon-color-primary')} {...args} />
            case 'download':
                return <AiOutlineDownload className={addClass(className)} {...args} />;
            case 'pdf':
                return <VscFilePdf className={addClass(className)} {...args} />;
            case 'jpg':
                return <AiOutlineFileJpg className={addClass(className)} {...args} />;
            case 'unknown_document':
                return <GrDocument className={addClass(className)} {...args} />
            case 'message':
                return <BiMessageDetail className={addClass(className)} {...args} />
            case 'cart':
                return <BsCart4  className={addClass(className)} {...args}  />;
            case 'protected_lock':
                return <BsShieldLockFill className={addClass(className)} {...args} />;
            case 'protected_access':
                return <BsShieldCheck className={addClass(className)} {...args} />
            case 'manager':
                return <FaUsersCog className={addClass(className)} {...args} />
            case 'forbidden':
                return <RiForbid2Line className={addClass(className)} {...args}/>
            case 'money':
                return <BsCashCoin className={addClass(className)} {...args} />
            case 'login':
                return <MdLogin className={addClass(className)} {...args} />
            case 'user-switch-out':
                return <RiUserShared2Line className={addClass(className)} {...args} />
            case 'user-switch-in':
                return <RiUserReceived2Line className={addClass(className)} {...args} />
            case 'package':
                return <GoPackage  className={addClass(className)} {...args} />
            case 'details':
                return <CgDetailsMore  className={addClass(className)} {...args} />
            case 'sound':
                return <AiFillSound  className={addClass(className)} {...args} />
            case 'paid':
                return <MdOutlinePaid  className={addClass(className)} {...args} />
            case 'sound_off':
                return <BiVolumeMute  className={addClass(className)} {...args} />
            case 'meeting':
                return <BsCalendar3  className={addClass(className)} {...args} />
            case 'spinner':
                return <Circles  className={addClass(className, 'icon-spinning')} {...args} />
            case 'doctor':
                return <FaBriefcaseMedical className={addClass(className)} {...args} />
            case 'minus':
                return <AiOutlineMinusSquare className={addClass(className)} {...args} />
            default:
                return (<BsQuestionCircle className={addClass(className)} {...args} />);

        }
    }

    if(tooltip) {
        /**
         * Validate Tooltip
         */
        if(!tooltip.content) {
            tooltip = {content: tooltip};
        }

        return <ToolTip className={`no-min-width ${tooltip.classes ? tooltip.classes : ''}`} arrow={tooltip.arrow} place={tooltip.place} type={tooltip.type} effect={tooltip.effect} trigger={getIcon(icon, className)}>{tooltip.content}</ToolTip>
    }

    return getIcon(icon, className);
}

/**
 *
 * @type {{icon: string}}
 */
Icon.propTypes = {
    icon: PropTypes.oneOf(['minus', 'close', 'doctor', 'spinner', 'meeting', 'sound_off', 'paid', 'sound', 'details', 'package', 'user-switch-out', 'user-switch-in', 'login', 'cart', 'message', 'unknown_document','money', 'manager', 'protected_access', 'forbidden', 'protected_lock', 'jpg', 'pdf', 'download', 'document', 'email', 'fax', 'user', 'revert', 'trash', 'medicine', 'import', 'settings', 'patient', 'tasks', 'info', 'help', 'wallet', 'arrow_right', 'lock', 'eye_off', 'eye', 'sort_desc', 'sort_asc', 'clipboard', 'delete', 'contract', 'check', 'edit', 'home', 'list', 'medical_home', 'medical_note', 'summary', 'users', 'person', 'plus', 'question', 'apothecary', 'manage', 'protection', 'truck'])
};

Icon.defaultProps = {
    tooltip: ''
};

export default Icon;


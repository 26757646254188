import factory from "@factories/factory";
import request from "@utils/request";

const patientServiceFactory = (config) => {
    return {
        ...factory,
        ...config,
        v3: false,
        path: 'patients',
        medicine: async function (id, filter, page, limit) {
            let connectWith = '?';
            let splice = '';

            if(page !== undefined) {
                limit = limit === -1 ? 999999 : limit;
                limit = limit ? limit : this.limit;
                splice = `?offset=${page*limit}&limit=${limit}`;
                connectWith = '&';
            }

            const response = await request.get(`/api/patients/${id}/medicine${splice}${this.toFilterQuery(filter, connectWith)}`, {
                headers: {'x-auth-token': this.auth.token}
            });

            return response.data
        }
    }
}

export default patientServiceFactory;
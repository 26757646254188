import React, {Fragment, useContext, useEffect, useState} from "react";
import Row from "@components/Row";
import Col from "@components/Col";
import Card from "@components/Card";
import Input from "@components/Input";
import Select from "@components/Select";
import Button from "@components/Button";
import AuthContext from "@context/AuthContext";
import routeServiceFactory from "@factories/routeServiceFactory";
import meetingServiceFactory from "@factories/meetingServiceFactory";
import {useHistory, useParams} from 'react-router-dom';
import {toast} from "react-toastify";


const MeetingForm = () => {
    /**
     * Vars
     */
    const { id, option } = useParams();
    const history = useHistory();

    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [state, setState] = useState({url: '', system: 'Skype', time: '', date: '', info: ''});

    /**
     * Services
     */
    const routeService = routeServiceFactory({auth});
    const meetingService = meetingServiceFactory({auth});

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const change = (e) => {
        const data = e.target ? {[e.target.name]: e.target.value} : e;
        setState({...state, ...data});
    }

    const fetch = async () => {
        if(id) {
            const response = await meetingService.get(`${id}/${option}`);
            setState({...state, ...response});
        }
    }

    const submit = async () => {
        const response = await meetingService.update(`${id}/${option}`, {...state});

        if(response) {
            toast.success('Daten wurden im Termin Aktualisiert');

            history.push(routeService.meetingList());
        } else {
            toast.error('Bitte füllen Sie alle Felder aus.');
        }
    }


    return (<Fragment>
        <Row sm={12}>
            <Col sm={12}>
                <Card title={'Termin bearbeiten'}>
                    <div className='container'>
                        <h5 className={'text-uppercase mt-5 mb-4'}>Stammdaten</h5>
                        <Row>
                            <Col sm={4}>
                                <Input
                                    label={'Unternehmen'}
                                    value={state.company}
                                    readonly={true}
                                 />
                            </Col>
                            <Col sm={4}>
                                <Input
                                    label={'Packet'}
                                    value={state.title}
                                    readonly={true}
                                />
                            </Col>
                            <Col sm={4}>
                                <Input
                                    label={'Typ'}
                                    value={state.type}
                                    readonly={true}
                                />
                            </Col>
                        </Row>
                        <Row className={'mt-3'}>
                            <Col sm={6}>
                                <Input
                                    required
                                    name={'date'}
                                    label={'Datum'}
                                    placeholder='Datum'
                                    value={state.date}
                                    type={'date'}
                                    description={'Tag an dem der Termin Stattfindet.'}
                                    onChange={change}/>
                            </Col>
                            <Col sm={6}>
                                <Input
                                    required
                                    name={'time'}
                                    label={'Uhrzeit'}
                                    placeholder='Uhrzeit'
                                    value={state.time}
                                    type={'time'}
                                    description={'Uhrzeit um die der Termin Stattfindet.'}
                                    onChange={change}/>
                            </Col>
                        </Row>
                        <Row className={'mt-3'}>
                            <Col sm={6}>
                                <Select
                                    required
                                    name={'system'}
                                    label={'System'}
                                    defaultValue={state.system}
                                    options={['Skype', 'Microsoft Teams', 'Discord', 'Zoom']}
                                    description={'Geben Sie hier das Programm an was für den Termin verwendet wird.'}
                                    onChange={change}/>
                            </Col>
                            <Col sm={6}>
                                <Input
                                    name={'url'}
                                    label={'Url'}
                                    placeholder='Url'
                                    value={state.url}
                                    description={'Link zum Webinar.'}
                                    onChange={change}/>
                            </Col>
                        </Row>
                        <Row className={'mt-3'}>
                            <Col sm={12}>
                                <Input
                                    name={'info'}
                                    label={'Information'}
                                    placeholder='Information'
                                    value={state.info}
                                    description={'Möchten Sie den Webinar Teilnehmern noch etwas mitteilen?'}
                                    onChange={change}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col osm={6} sm={6}>
                                <Button
                                    type={'submit'}
                                    color={'success'}
                                    className='mt-4 float-end'
                                    onClick={submit}
                                >
                                    Speichern
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Card>
            </Col>
        </Row>
    </Fragment>);
}

MeetingForm.propTypes = {};

MeetingForm.defaultProps = {};

export default MeetingForm;

import React, {Fragment, useContext} from "react";
import {getScreenResolution} from "@utils/utils";
import {Grid, makeStyles, Paper, Typography} from "@material-ui/core";
import {AiOutlineClockCircle, AiOutlineMessage} from "react-icons/ai";
import moment from "moment";
import AuthContext from "../context/auth";
import Icon from "@components/Icon";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component

const useStyles = makeStyles({
    message: {
        flexGrow: 1,
    },
    container: {
        padding: 12,
    },
    messageContainer: {
        padding: 8,
        border: '1px solid #0c5460',
        background: '#d1ecf1',
    },
    icon: {
        marginRight: 8,
    },
    messageText: {
        paddingLeft: 12,
        paddingTop: 8,
        fontSize: 14
    },
    user: {
        fontSize: 14,
        fontWeight: 'bold',
        position: 'relative'
    },
    date: {
        fontSize: 10,
        color: '#888',
    },
    new: {
        background: '#e6ebe7',
    },
    eventAttachment: {
        fontSize: 24,
        cursor: 'pointer',
        height: 40,
        width: 40,
        borderRadius: '50%',
        display: 'inline-block',
        textAlign: 'center',
        padding: 6,
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
        }
    },
    messageIcon: {
        display: "inline-block",
        margin: "-3px 0 0 5px",
        color: "#2a5d78",
        lineHeight: "12px",
        fontSize: 18,
        position: 'relative',
        top: 2

    }
});

const EventMessageRecipeRequest = ({task, event, ...args}) => {
    const classes = useStyles();
    const { auth } = useContext(AuthContext);

    const data = JSON.parse(event.data);

    const icon = (role) => {
        if(role.includes('ROLE_HOME')) {
            return <Icon icon={'medical_home'} tooltip={{content: 'Mitarbeiter einer Pflegeeinrichtung', classes: classes.messageIcon}}/>
        } else if(role.includes('ROLE_DOCTOR')) {
            return <Icon icon={'doctor'} tooltip={{content: 'Mitarbeiter einer Arztpraxis', classes: classes.messageIcon}}/>
        } else if(role.includes('ROLE_PHARMA')) {
            return <Icon icon={'medicine'} tooltip={{content: 'Mitarbeiter einer Apotheke', classes: classes.messageIcon}}/>
        } else if(role.includes('ROLE_DRIVER')) {
            return <Icon icon={'truck'} tooltip={{content: 'Fahrer', classes: classes.messageIcon}}/>
        }

        return <Fragment />;
    }

    return <Fragment>
        <Grid item xs={12}>
            <Paper className={`${classes.messageContainer}`} elevation={1}>
                <Typography className={classes.user}>
                    <AiOutlineMessage className={classes.icon} />
                    {event.createdFrom && event.createdFrom.username}
                    {event.createdFrom && icon(event.createdFrom.roles)}
                </Typography>
                <Typography className={classes.date}>
                    <AiOutlineClockCircle className={classes.icon} />
                    {moment(event.createdAt).format('DD.MM.YYYY HH:mm')}
                </Typography>
                <Typography className={classes.messageText}>
                    <h4>Rezept Anforderung</h4>
                    <strong>Betreff</strong>: {data.subject}<br />
                    <strong>Message</strong>: <div dangerouslySetInnerHTML={{__html: data.message}} /><br />
                </Typography>
            </Paper>
        </Grid>
    </Fragment>
}

EventMessageRecipeRequest.propTypes = {};

EventMessageRecipeRequest.defaultProps = {};

export default EventMessageRecipeRequest;
import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"; //comment in if you add Proptypes to your Component

const useStyles = makeStyles({
    iconContainer: {
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        textAlign: "center",
        padding: '20px 0',
        borderRadius: 5,
        display: "inline-block",
        width: 200,
        margin: ' 0 10px',
        position: "relative"
    },
    icon: {
        fontSize: 60,
        lineHeight: '60px',
        color: '#cdcdcd',
        textDecoration: "none"
    },
    label: {
        fontSize: 14,
        color: '#444',
        cursor: 'default',
        textDecoration: "none"
    },
    count: {
        fontSize: 20,
        fontWeight: "400",
        textDecoration: "none"
    },
    link: {
        textDecoration: "none",
        '&:hover': {
            textDecoration: "none",

            '& svg': {
                color: '#5588a3'
            }
        },
    }
});

const DashboardIconCard = ({enabled, to, footer, title, count, icon, tooltip, ...args}) => {
    /**
     * CSS
     */
    const classes = useStyles();


    return <div className={classes.iconContainer} title={tooltip}>
        {!enabled && <div className={classes.link}>
            <div className={classes.icon}>{icon}</div>
            <div className={classes.label}>{title}</div>
            <div className={classes.count}>{count}</div>
        </div>}
        {enabled && (<Fragment>
            <Link className={classes.link} to={to}>
                <div className={classes.icon}>{icon}</div>
                <div className={classes.label}>{title}</div>
                <div className={classes.count}>{count}</div>
            </Link>
            {footer}
        </Fragment>)}
    </div>
}

DashboardIconCard.propTypes = {
    title: PropTypes.string.isRequired,
    count: PropTypes.any.isRequired,
    icon: PropTypes.any.isRequired
};

DashboardIconCard.defaultProps = {};

export default DashboardIconCard;
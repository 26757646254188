/* jshint esversion: 8 */
import {Fragment, useEffect, useState} from "react";
import {rand} from "@utils/utils";
import Observer from "@utils/observer";
import Select from "@components/Select";
import Row from "@components/Row";
import Col from "@components/Col";
import Icon from "@components/Icon";
import PropTypes from "prop-types";
import Checkbox from "@components/Checkbox";

const TableExtendedSearch = ({filterElementsExpanded, observer, filterElements}) => {
    /**
     * States
     */
    const [,setState] = useState(0);
    const [filterSettings, setFilterSettings] = useState({});
    const [open, setOpen] = useState(filterElementsExpanded);
    const [searchObserver] = useState(new Observer((data) => {
        observer.notify(data);
    }));

    /**
     * Hooks
     */
    useEffect(() => {
        let settings = {};

        for (let ii = 0; ii < filterElements.length; ii++) {
            const rows = filterElements[ii];
            for (let jj = 0; jj < rows.length; jj++) {
                settings[rows[jj].name] = {single: !rows[jj].multiple, data: rows[jj].data, label: rows[jj].label ? rows[jj].label : rows[jj].placeholder};
            }
        }

        setFilterSettings(settings);
    }, [filterElements]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Hooks
     */
    useEffect(() => {
        if(observer) {
            observer.register({
                collect: (useAssoziativesArray) => { return searchObserver.collect(useAssoziativesArray); }
            });
        }
    }, [observer]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const buildFilterRow = (items) => {
        return items.filter((item) => item.type !== 'hidden').map((fields) => {
            const colWidth = 12 / fields.length;
            return (
                <Row key={rand()} sm={12} className={'mt-2'}>
                    {fields.map((item) => buildFilter(item, colWidth))}
                </Row>);
        });
    };

    const buildFilter = (item, colWidth) => {
        const name = item.name;
        switch (item.type) {
            case 'select':
                return (<Col key={rand()} sm={colWidth}><Select
                    placeholder={item.placeholder}
                    name={name}
                    options={item.data}
                    defaultValue={item.value}
                    observer={searchObserver}
                /></Col>);
            case'checkbox':
                return (<Col key={rand()} sm={colWidth}><Checkbox
                    label={item.label}
                    name={name}
                    options={item.data}
                    value={''}
                    observer={searchObserver}
                /></Col>);
            default:
                return (<Fragment key={rand()}/>);
        }
    };

    const removeFilter = (key) => {
        searchObserver.pushUpdate(0, key, false);
        searchObserver.notify();
        setState(rand());
    };

    const getFilterTags = () => {
        let tags = {};

        const data = searchObserver.collect(true); //state;
        for(let name in data) {
            const item = data[name];
            if(item.length > 0) {
                tags[name] = item;
            }
        }

        return Object.keys(tags).filter((item) => item !== '_all' && item !== 'sort');
    };

    const getLabel = (item) => {
        const value = searchObserver.get(item);
        const index = filterSettings[item].data.findIndex((entry) => entry.value === value);
        return filterSettings[item].data[index].label;
    };

    const buildFilterTags = () => {
        const keys = getFilterTags();
        if (keys.length > 0) {
            return (<Fragment key={rand()}>
                <span className={'current-filter'}>Filter: </span>
                {keys.map((item) => (
                    <span key={rand()} className={'filter-tag'}>
                        <span>{filterSettings[item].label}: {getLabel(item)}</span>
                        <Icon
                            title={'Filter entfernen'}
                            onClick={() => removeFilter(item)}
                            className={'remove-filter'}
                            icon={'delete'}/>
                    </span>))}
            </Fragment>);
        }

        return <Fragment/>
    };

    const close = () => {
        setOpen(false);
    };

    return <Fragment>
        {!open && filterElements.filter((items) => items.filter((item) => item.type !== 'hidden').length > 0).length > 0 && (
            <Fragment>
                {buildFilterTags()}
                <span className={'collapse-filter'}
                      onClick={() => setOpen(!open)}>weitere Filter</span>
            </Fragment>
        )}

        {open && filterElements && filterElements.length > 0 && (
            <div className={'additional-filters'}>
                {buildFilterRow(filterElements)}
                <span className={'collapse-filter'} onClick={close}>schließen</span>
            </div>)}
    </Fragment>
}

TableExtendedSearch.propTypes = {
    filterElements: PropTypes.array,
};

TableExtendedSearch.defaultProps = {
    filterElements: [],
    filterElementsExpanded: false
};

export default TableExtendedSearch;
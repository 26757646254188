import Row from "@components/Row";
import Col from "@components/Col";
import Card from "@components/Card";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {isValid, validate} from "@utils/forms";
import Input from "@components/Input";
import Select from "@components/Select";
import roleEnum from "@enum/roleEnum";
import {rand, getRoleCollection, hasPermission} from "@utils/utils";
import InputPasswordChange from "@components/InputPasswordChange";
import userServiceFactory from "@factories/userServiceFactory";
import doctorServiceFactory from "@factories/doctorServiceFactory";
import homeServiceFactory from "@factories/homeServiceFactory";
import AuthContext from "@context/AuthContext";
import Button from "@components/Button";
import Alert from "@components/Alert";
import CheckboxCard from "@components/CheckboxCard";
import Icon from "@components/Icon";
import homeGroupServiceFactory from "@factories/homeGroupServiceFactory";

const User = ({footer, setValid, onChange, name, edit, form}) => {

    const validationFailureMessages = {
        username: ['Bitte vergeben Sie einen Benutzernamen.'],
    }

    const roles = getRoleCollection();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [errors, setErrors] = useState({});
    const [state, setState] = useState(form);
    const [hasValidPassword, setHasValidPassword] = useState(false);
    const [notAllowedUserNames, setNotAllowedUserNames] = useState([]);
    const [users, setUsers] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [homes, setHomes] = useState([]);
    const [homeGroups, setHomeGroups] = useState([]);


    const [changePassword, setChangePassword] = useState(false);


    /**
     * Services
     */
    const userService = userServiceFactory({auth: auth});
    const doctorService = doctorServiceFactory({auth: auth});
    const homeService = homeServiceFactory({auth: auth});
    const homeGroupService = homeGroupServiceFactory({auth: auth});

    /**
     * Hooks
     */
    useEffect(() => {
        fetchUsers();
        fetchDoctors();
        fetchHomes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        filterNotAllowedUserNames(state.username ? [state.username] : []);
        validateUsername();
        fetchHomeGroups(state.home);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        if (setValid) {
            setValid(hasValidPassword && isValid(getFailureMessages(), errors, state, setErrors));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, hasValidPassword]);

    useEffect(() => {
        let data = form[name] ? form[name] : {};
        data = edit ? form : data;
        data.password = data.password || '';
        data.password_confirm = data.password_confirm || '';

        if (!data.salutation) {
            data.salutation = 'mr'
        }

        setState(data);
        filterNotAllowedUserNames(data.username ? [data.username] : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    /**
     * Functions
     */
    const fetchUsers = async () => {
        let response = await userService.get(null, {status: 'all'});
        setUsers(response.items);
    }

    const fetchDoctors = async () => {
        let response = await doctorService.get(null, {}, 0, 999999);

        setDoctors(response.items.map((item) => {
            return {key: item._id, label: `${item.firstname} ${item.lastname}`, value: item}
        }));
    }

    const fetchHomes = async () => {
        let response = await homeService.get(null, {}, 0, 999999);

        setHomes(response.items.map((item) => {
            return {key: item._id, label: item.name, value: item}
        }));
    }

    const fetchHomeGroups = async (home) => {
        if(!!home) {
            const response = await homeGroupService.get(!!home._id ? home._id : home);

            setHomeGroups(response.items.map((item) => {
                return {key: item._id, label: `${item.name}`, value: item}
            }));
        }
    }

    const changePasswordEnabled = () => {
        setChangePassword(!changePassword);
    }

    const getFailureMessages = () => {
        let failure = {username: ['Bitte vergeben Sie einen Benutzernamen.']};

        if (state.roles && state.roles.includes(roleEnum.ROLE_DOCTOR)) {
            failure.doctor = ['Bitte wählen Sie einen Arzt aus.'];
        }

        if (state.roles && state.roles.includes(roleEnum.ROLE_HOME)) {
            failure.home = ['Bitte wählen Sie ein Pflegeheim aus.'];
        }

        return failure
    }

    const filterNotAllowedUserNames = (allowedUserNames) => {
        let list = [...users];

        if (edit) {
            list = list.filter((item) => allowedUserNames.map(name => name.toLowerCase()).indexOf(item.username.toLowerCase()) === -1);
        }

        setNotAllowedUserNames(list.map((item) => item.username.toLowerCase()));
    }

    const changeHomeGroups = (e) => {
        setState({...state, [e.target.name]: e.target.value.map(item => item._id)});

        if(onChange) {
            onChange({...state, [e.target.name]: e.target.value.map(item => item._id)});
        }
    }

    const changeHome = (e) => {
        validate(e, validationFailureMessages, errors, setErrors);

        if(e.target && e.target.name) {
            setState({...state, [e.target.name]: e.target.value});
        } else {
            setState({...state, ...e});
        }

        if (onChange) {
            onChange(e);
        }
    }

    const change = (e) => {
        validate(e, validationFailureMessages, errors, setErrors);

        if(e.target && e.target.name) {
            setState({...state, [e.target.name]: e.target.value});
        } else {
            setState({...state, ...e});
        }

        if (onChange) {
            onChange(e);
        }
    }

    const validateUsername = () => {
        if (state.username && notAllowedUserNames.indexOf(state.username.toLowerCase()) >= 0) {
            let failure = {...errors};
            failure.username = failure.username || [];
            failure.username = failure.username.filter((item) => item.key !== 'username');
            failure.username.push({
                key: 'username',
                message: 'Benutzername ist nicht erlaubt oder wird bereits verwendet.',
                deletable: false
            });
            setErrors(failure);
        }
    }

    const changeRoles = (e) => {
        let roles = state.roles || [];
        let update = {...state}

        roles = roles.filter((item) => item !== e.target.value);

        if (e.target.checked) {
            roles.push(e.target.value);
        }

        if (!roles.includes(roleEnum.ROLE_DOCTOR)) {
            update.doctor = null
        }

        if (!roles.includes(roleEnum.ROLE_HOME)) {
            update.home = null
        } else if(homes.length === 1) {
            update.home = homes[0].value._id;
        }

        setState({...update, roles: roles});

        if (onChange) {
            onChange({
                doctor: update.doctor,
                home: update.home,
                roles: roles
            });
        }
    }

    const getHomeId = (home) => {
        if(home instanceof Object) {
            return home._id;
        }

        return home;
    }

    const getDefaultDoctor = () => {
        if(state.doctor) {
            return typeof state.doctor === 'object' ? state.doctor._id : state.doctor;
        }
        return '';
    }

    return (
        <Row className='mb-12'>
            <Col sm={12}>
                <Card title='Daten des Nutzers'
                      description={edit ? 'Nutzer bearbeiten' : 'Geben Sie die Daten für den neuen Nutzer ein.'}
                      className={['form-card', footer ? 'small-padding' : ''].join(' ')}
                      center={true}>
                    <div className='container'>
                        <h5 className={'text-uppercase mt-5 mb-4'}>Stammdaten</h5>
                        <Row>
                            <Col sm={12}>
                                <Input
                                    required
                                    errors={errors.username}
                                    valid={state.username && state.username.length > 0 && !errors.username}
                                    name={'username'}
                                    label={'Nutzername'}
                                    placeholder='Nutzername'
                                    value={state.username ? state.username : ''}
                                    description={''}
                                    onChange={change}/>
                            </Col>
                        </Row>
                        <Row className={'roles-container'}>
                            <h5 className={'text-uppercase mt-5 mb-4'}>Nutzergruppe</h5>
                            {roles.map((item) => (hasPermission(auth, item.permission) && (<Col key={rand()} sm={6} md={4}>
                                <CheckboxCard
                                    permission={item.permission}
                                    onClick={changeRoles}
                                    label={item.label}
                                    name={'roles'}
                                    value={item.key}
                                    help={item.help}
                                    icon={<Icon icon={item.icon} />}
                                    checked={state.roles && state.roles.indexOf(item.key) !== -1}
                                    description={item.description}/>
                            </Col>)))}
                        </Row>
                        {state.roles && state.roles.includes(roleEnum.ROLE_DOCTOR) && (
                            <Row className={'mt-3'}>
                                <Col key={rand()} sm={12}>
                                    <Select
                                        required
                                        placeholder={'Arzt'}
                                        label={'Arzt'}
                                        name={'doctor'}
                                        defaultValue={getDefaultDoctor()}
                                        options={doctors}
                                        onChange={change}
                                    />
                                </Col>
                            </Row>
                        )}

                        {state.roles && state.roles.includes(roleEnum.ROLE_HOME) && homes.length > 1 && (
                            <Row className={'mt-3'}>
                                <Col key={rand()} sm={12}>
                                    <Select
                                        required
                                        placeholder={'Pflegeeinrichtung'}
                                        label={'Pflegeeinrichtung'}
                                        name={'home'}
                                        defaultValue={state.home ? getHomeId(state.home) : ''}
                                        options={homes}
                                        onChange={changeHome}
                                    />
                                </Col>
                            </Row>
                        )}

                        {state.roles && state.roles.includes(roleEnum.ROLE_HOME) && (
                            <Row className={'mt-3'}>
                                <Col key={rand()} sm={12}>
                                    <Select
                                        label={'Wohngruppen'}
                                        defaultValue={state.homeGroup ? state.homeGroup.map(item => item) : []}
                                        name={'homeGroup'} isMulti={true} options={homeGroups} onChange={changeHomeGroups}/>
                                </Col>
                            </Row>
                        )}

                        {(state.home || state.doctor) && (
                            <Row className={'mt-3'}>
                                <Col key={rand()} sm={12}>
                                    <Alert type={'danger'}>
                                        Mit dem Anlegen des Benutzers erhält {(state.home && 'die Pflegeeinrichtung') || (state.doctor && 'der Arzt')} Zugriff. Das ist kostenpflichtig.
                                    </Alert>
                                </Col>
                            </Row>)}

                        <h5 className={'text-uppercase mt-5 mb-4'}>
                            <span className={'me-2'}>Passwort</span>
                            {edit && (<Button size={'small'} outlined onClick={changePasswordEnabled}>
                                {changePassword ? 'Abbrechen und aktuelles Passwort behalten' : 'Passwort ändern'}
                            </Button>)}
                        </h5>
                        <InputPasswordChange disabled={edit && !changePassword} onChange={change}
                                             setPasswordValid={setHasValidPassword}
                                             form={state}
                        />
                    </div>
                    {footer && footer}
                </Card>
            </Col>
        </Row>
    );
}

User.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    name: PropTypes.string.isRequired,
};

User.defaultProps = {
    edit: false,
};

export default User;
import React, {Fragment, useContext, useState} from "react";
import {BsPrinter} from "react-icons/all";
import {IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
//comment in if you add Proptypes to your Component
import PropTypes from "prop-types";

import axios from "axios";
import handleErrors from "../utils/handleErrors";
import AuthContext from "../context/auth";
import {Circles} from 'react-loading-icons'
import {useHistory} from "react-router-dom";

const useStyles = makeStyles({
    printer: {
        color: 'orange',
        backgroundColor: 'transparent !important'
    },
    preview: {
        position: "fixed",
        left: -5000
    },
    loader: {
        width: 24,
        height: 24,
        padding: 3,
        fontSize: "1.125rem",
        verticalAlign: 'top',
        cursor: "default"
    }
});

const Printer = ({name, url, ...args}) => {
    const id = `printer-${name}`;

    /**
     * CONTEXT
     */
    const {auth, setAuth} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);

    const {history} = useHistory();

    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * Functions
     */
    const print = async () => {
        setIsLoading(true);
        const content = await load();
        let print = document.getElementById(id).contentWindow;
        print.document.open();
        print.document.write(content);
        print.document.close();
        print.focus();
        print.print();
        setIsLoading(false);
    }

    const load = async () => {
        try {
            const response = await axios.get(url, {
                headers: {'x-auth-token': auth.token},
            });
            console.log(response);

            return response.data
        } catch (err) {
            handleErrors(err, setAuth, history);
        }

        return '';
    }

    /**
     * KANN DRIN BLEIBEN, ALEX DRUECK SO WIESO EINFACH NUR AUF DEN MERGE BUTTON.
     */
    return <Fragment>
        {!isLoading && (<IconButton onClick={print} size='small' className={classes.printer}>
            <BsPrinter />
        </IconButton>)}
        {isLoading && (<Circles stroke={'orange'} fill={'orange'} className={classes.loader}/>)}
        <iframe id={id} className={classes.preview} title={'Print'}/>
    </Fragment>
}

Printer.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

Printer.defaultProps = {};

export default Printer;
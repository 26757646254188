/* jshint esversion: 8 */
const escapeStringRegexp = require('escape-string-regexp');

export class Password {

    static passwordCharacters = {
        specials: '-°^!²§³$%&/{([)]=}?\\`´@€+*~#<>|µ,;.:_ £',
        // important use '-' to the beginning of string, otherwise additionally chars can be found
        lowercase: 'abcdefghijklmnopqrstuvwxyzäöüß',
        uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ',
        numbers: '0123456789',                                  // not 0 because risk of confusion
        forbidden: '"\'',                                       // to avoid problems
    };

    static generatePassword(minLength = 12, minLowercaseCount = 2, minUppercaseCount = 2, minNumbersCount = 2, minSpecialcharsCount = 2) {
        let counts = [];
        counts[0] = minSpecialcharsCount;
        counts[1] = minLowercaseCount;
        counts[2] = minUppercaseCount;
        counts[3] = minNumbersCount;

        function getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        }

        let password = "";
        while (password.length < minLength) {
            let type = getRandomInt(counts.length);
            if (counts[type] > 0 || counts.join("") === "0000") {
                password += this.passwordCharacters[Object.keys(this.passwordCharacters)[type]][Math.floor(
                    Math.random() * (Object.keys(this.passwordCharacters)[type].length))];
                if (counts[type] > 0) {
                    counts[type]--;
                }
            }
        }

        return password;
    };

    /**
     * check @p password meets the criteria given by next parameters.
     *
     * @param password
     * @param minLength if > 0 password must have at least @p minLength characters
     * @param minLowercaseCount if > 0 password must have at least @p minLowercaseCount lowercase characters
     * @param minUppercaseCount if > 0 password must have at least @p minUppercaseCount uppercase characters
     * @param minNumbersCount if > 0 password must have at least @p minNumbersCount numbers
     * @param minSpecialcharsCount if > 0 password must have at least @p minSpecialcharsCount special characters
     * @param maxLength if > 0 password must have maximum @p maxLength characters
     * @param forbiddenchars if not false password can not have @p forbiddenchars or '"\'' if @p forbiddenchars is true
     *
     * @returns {*[]} an array of errors or empty array if check was successfully
     */
    static checkPasswordComplexity(password, minLength = 12, minLowercaseCount = 2, minUppercaseCount = 2, minNumbersCount = 2, minSpecialcharsCount = 2, maxLength = 4096, forbiddenchars = false) {
        let errors = [];

        let allPasswordCharacters = escapeStringRegexp(this.passwordCharacters['specials']);
        for (let c = 1; c < (Object.keys(this.passwordCharacters).length - 1); c++) {
            allPasswordCharacters += this.passwordCharacters[Object.keys(this.passwordCharacters)[c]];
        }
        if (typeof forbiddenchars === 'boolean') {
            if (!forbiddenchars) {
                allPasswordCharacters += escapeStringRegexp(this.passwordCharacters['forbidden']);
                forbiddenchars = '';
            }
            else {
                forbiddenchars = escapeStringRegexp(this.passwordCharacters['forbidden']);
            }
        }
        else {
            this.passwordCharacters['forbidden']= forbiddenchars;
        }

        let regExp = new RegExp('[^' + allPasswordCharacters + ']', 'g');
        let matches = password.match(regExp);
        if (matches != null && matches.length > 0) {
            errors.push(
                "Unerlaubte Zeichen gefunden! '" + matches.join("") + "'."
            );
        }

        if (maxLength > 0 && password.length > maxLength) {
            errors.push(
                "Mehr als " + maxLength + " Zeichen! Gefunden " + password.length + " Zeichen."
            );
        }

        if (minLength > 0 && password.length < minLength) {
            errors.push(
                "Weniger als " + minLength + " Zeichen! Gefunden " + password.length + " Zeichen."
            );
        }

        if (minLowercaseCount > 0) {
            regExp = new RegExp('[' + this.passwordCharacters['lowercase'] + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minLowercaseCount) {
                errors.push(
                    "Weniger als " + minLowercaseCount + " kleine Buchstaben!"
                        + (matches != null && matches.length > 0 ? " Gefunden '" + matches.join(",") + "'." : "")
                );
            }
        }

        if (minUppercaseCount > 0) {
            regExp = new RegExp('[' + this.passwordCharacters['uppercase'] + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minUppercaseCount) {
                errors.push(
                    "Weniger als " + minUppercaseCount + " große Buchstaben!"
                        + (matches != null && matches.length > 0 ? " Gefunden '" + matches.join(",") + "'." : "")
                );
            }
        }

        if (minNumbersCount > 0) {
            regExp = new RegExp('[' + this.passwordCharacters['numbers'] + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minNumbersCount) {
                errors.push(
                    "Weniger als " + minNumbersCount + " Zahlen!"
                        + (matches != null && matches.length > 0 ? " Gefunden '" + matches.join(",") + "'." : "")
                );
            }
        }

        if (minSpecialcharsCount > 0) {
            regExp = new RegExp('[' + escapeStringRegexp(this.passwordCharacters['specials']) + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minSpecialcharsCount) {
                errors.push(
                    "Weniger als " + minSpecialcharsCount + " Sonderzeichen!"
                        + (matches != null && matches.length > 0 ? " Gefunden " + matches.join(",") + "." : "")
                );
            }
        }
        return errors;
    };

    static checkPasswordCompare(password1, password2) {
        if (password2.length === 0) {
            return '';
        }
        for (let c = 0; c < password1.length; c++) {
            if (password1[c] !== password2[c]) {
                return "Password ab dem " + (c + 1) + ". Zeichen nicht identisch!";
            }
        }
        return "";
    }
}

    /* jshint esversion: 8 */
import {Checkbox, Chip, Collapse, IconButton, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {
    AiOutlineMail,
    BsChevronDown,
    BsChevronUp,
    FaFax,
    FiTruck,
    MdAdd,
    RiMedicineBottleLine,
    FiEdit,
    MdCheck,
    GrRevert,
    FiTrash2
} from 'react-icons/all';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import {Td, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import AuthContext from '../context/auth';
import SocketContext from '../context/socket';
import TaskContext from '../context/task';
import handleErrors from '../utils/handleErrors';
import EventList from './EventList';
import axios from 'axios';
import moment from 'moment';
import {toast} from 'react-toastify';
import Printer from "./Printer";
import PropTypes from "prop-types";
import ActionLoader from "./ActionLoader";
import {hasPermission, toHttpQuery} from "@utils/utils";
import TwoOptButton from "./TwoOptButton";
import TCell from "./TCell";
import LinkWithContractRequirements from "./LinkWithContractRequirements";
import routeServiceFactory from "@factories/routeServiceFactory";
    import taskGroupEnum from "@enum/taskGroupEnum";
    import {getScreenResolution} from "@utils/utils";

const useStyles = makeStyles({
    contractRequiredButton: {
        backgroundColor: 'transparent !important'

    },
    edit: {
        backgroundColor: 'transparent !important'
    },
    taskInTrash: {
        backgroundColor: 'transparent !important',
      height: '25px',
        padding: '3px',
        fontSize: '1.125rem',
      verticalAlign: 'bottom',
        '& svg': {
          color: '#b00'
        }
    },
    chipContainer: {
        whiteSpace: "nowrap",
        position: "relative",
    },
    disabled: {
        opacity: 0.3,
        cursor: 'default'
    },
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    infoRow: {
        color: '#9d9d9d',
        fontSize: 11,
        padding: 0,
        paddingBottom: 8,
    },
    hidden: {
        display: 'none !important',
    },
    truck: {
        color: '#ff5722',
        backgroundColor: 'transparent !important'
    },
    chipError: {
        border: '1px solid #5e2028',
        background: '#762832',
        color: '#fff',
    },
    email: {
        color: '#009688',
        backgroundColor: 'transparent !important'
    },
    fax: {
        color: '#00bcd4',
        backgroundColor: 'transparent !important'
    },
    chip: {
        position: 'relative',
        zIndex: 10,
    },
    newChip: {
        position: 'absolute',
        zIndex: 5,
        width: 60,
        left: 4,
        padding: '0 0 0 21px',
    },
    unread: {
        height: 10,
        width: 10,
        background: '#5588a3',
        position: 'absolute',
        top: 11,
        borderRadius: 50,
        left: -20,

        '&:hover': {
            '& span': {
                display: 'block'
            },
        }
    },
    tooltip: {
        display: 'none',
        position: 'absolute',
        top: -5,
        left: -4,
        background: '#fff',
        border: '1px solid #5588a3',
        padding: '2px 5px',
        fontSize: 10,
        borderRadius: 5,
        transform: 'translate(-50%, 0)',
        cursor: 'default'
    },
    tbodyTr: {
        // eslint-disable-next-line no-useless-computed-key
        ['@media (max-width: 601px)']: {
            // eslint-disable-line no-useless-computed-key
            border: '0 !important',
        },
        '& > td': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            padding: '16px 13px',
            // eslint-disable-next-line no-useless-computed-key
            ['@media (max-width:450px)']: {
                // eslint-disable-line no-useless-computed-key
                padding: '0',
            },
            '&:nth-child(3)': {
                minWidth: 90,
            },
        },
        // eslint-disable-next-line no-useless-computed-key
        ['@media screen and (max-width: 40em)']: {
            // eslint-disable-line no-useless-computed-key
            '&:first-child > td div.tdBefore': {
                overflow: 'hidden',
                overflowWrap: 'initial',
            },
            '& > td': {
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                padding: '16px',
                ['@media (max-width:450px)']: { // eslint-disable-line no-useless-computed-key
                    padding: '0'
                },
                '&:nth-child(3)': {
                    minWidth: 100
                }
            },
            '& > td:first-child': {
                paddingLeft: '0 !important',
            },
            '& > td:nth-child(2)': {
                paddingLeft: '4px !important',
            },
            '& > td:nth-child(3)': {
                marginTop: '-36px',
            },
            '&:nth-child(3n-1)': {
                paddingLeft: '1.25em',
            },
            '&:nth-child(3n-1) > td:nth-child(2)': {
                paddingBottom: '.5em',
                paddingTop: '8px !important',
            },
            '&:nth-child(3n-1) > td:nth-child(3)': {
                marginTop: '0',
                paddingBottom: '8px !important',
                paddingLeft: '.25em',
                paddingTop: '.5em',
            },
            '&:nth-child(3n-1) > td:nth-child(4)': {
                display: 'none',
            },
        },
    },
    tbodyTrB: {
        '& > td': {
            // eslint-disable-next-line no-useless-computed-key
            ['@media (max-width: 601px)']: {
                // eslint-disable-line no-useless-computed-key
                borderBottom: '2px solid #000000 !important',
            },
        },
    },
    highPriority: {
        '& > td .MuiChip-root': {
            border: "3px solid orange",
        }
    }
});
const TaskRow = ({eventCount, hidePatientName, task, history, fetch, parentContainer, ignoreHide = false}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONSTANT
     */
    const types = {
        1: 'Auftrag',
        2: 'Selbstkauf',
        3: 'Rezept in Wohnbereich',
        4: 'Rezept in Arztpraxis',
    };

    const query = new URLSearchParams(window.location.search.replace('?', ''));
    const taskId = query.get('display[task]');

    /**
     * CONTEXT
     */
    const {auth, setAuth} = useContext(AuthContext);
    const {socket} = useContext(SocketContext);
    const {selectedTasks, setSelectedTasks} = useContext(TaskContext);

    /**
     * Services
     */
    const routeService = routeServiceFactory();

    /**
     * STATES
     */
    const [open, setOpen] = useState(taskId === task._id);
    const [count, setCount] = useState(eventCount ? eventCount.events - eventCount.seen : 0);
    const [maxCount, setMaxCount] = useState(eventCount ? eventCount.events : 0);
    const [systemFail, setSystemFail] = useState(false);
    const [canReopen, setCanReopen] = useState(true);
    const [taskItem, setTaskItem] = useState(task);

    /**
     * REFS
     */
    const countRef = useRef();
    countRef.current = count;

    const maxCountRef = useRef();
    maxCountRef.current = {data: maxCount, set: setMaxCount};

    if (parentContainer) {
        parentContainer.current.count[task._id] = {set: setCount};
    }

    /**
     * HOOKS
     */
    useEffect(() => {
        if(eventCount) {
            maxCountRef.current.set(eventCount.events);
            setCount(eventCount.events - eventCount.seen);
            setSystemFail(!!eventCount.error);
        }
    }, [eventCount]);// eslint-disable-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //     document.getElementById(taskId).scrollIntoView();
    // }, []);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (socket) {
            socket.on(`tasks/${task._id}/events/unread`, (event) => {
                fetchEvent(event);
            });
        }

        return () => {
            if (socket) {
                socket.off(`tasks/${task._id}/events/unread`);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    useEffect(() => {
        if (!open) {
            setCount(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    /**
     * CONTROLS
     */
    const selectTask = (e) => {
        if (e.target.checked) {
            if (!selectedTasks.includes(e.target.value)) {
                setSelectedTasks([e.target.value, ...selectedTasks]);
                if (parentContainer) {
                    parentContainer.current.parent.hasSelection.set(true);
                }
            }
        } else {
            setSelectedTasks(selectedTasks.filter((item) => item !== e.target.value));
            if (parentContainer) {
                parentContainer.current.parent.hasSelection.set(
                    document.querySelectorAll('.task-chk input:checked').length > 0
                );
            }
        }
    };

    /**
     * QUERIES
     */
    const fetchEvent = async (event) => {
        try {
            const response = await axios.get(`/api/events/meta?${toHttpQuery({tasks: [taskItem._id]})}`, {
                headers: {'x-auth-token': auth.token},
            });

            if (response.data[taskItem._id] && response.data[taskItem._id].lastSender !== auth.user) {
                setCount(response.data[taskItem._id].events - response.data[taskItem._id].seen);
                setMaxCount(response.data[taskItem._id].events);
                setSystemFail(response.data[taskItem._id].error);
                //toast.error('Es konnte keine eMail oder Fax versendet werden.');
            }
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const sendToPickup = async () => {
        try {
            let t = {status: 'pickup'};
            //t.latestDelivery = moment(t.latestDelivery).format('DD.MM.YYYY');
            await axios.put(`/api/tasks/${taskItem._id}`, t, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
            toast.success('Auftrag wurde an die Fahrer übergeben.');
            setTaskItem({...taskItem, status: 'pickup'})
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const cancelTask = async () => {
        try {
            let t = {status: 'canceled'};
            //t.latestDelivery = moment(t.latestDelivery).format('DD.MM.YYYY');
            await axios.put(`/api/tasks/${taskItem._id}`, t, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
            toast.success('Auftrag wurde Storniert.');
            setTaskItem({...taskItem, status: 'canceled'})
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const sendFinished = async () => {
        try {
            let t = {status: 'finished'};
            await axios.put(`/api/tasks/${taskItem._id}`, t, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
            toast.success('Aufrag wurde abgeschlossen');
            setTaskItem({...taskItem, status: 'finished'})
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const sendReopen = async () => {
        try {
            let t = {status: 'new'};
            await axios.put(`/api/tasks/${taskItem._id}`, t, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
            toast.success('Task wurde zurück auf "neu" gestellt.');
            setTaskItem({...taskItem, status: 'new'})
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    }

    const historyRef = useHistory();

    const sendPerMail = () => {
        setSelectedTasks([taskItem._id]);
        historyRef.push('/anforderungen/erzeugen?type=email');
    };

    const sendPerFax = () => {
        setSelectedTasks([taskItem._id]);
        historyRef.push('/anforderungen/erzeugen?type=fax');
    };

    const setTaskAsSeen = async () => {
        setOpen(!open);

        if(!(taskItem.seen && taskItem.seen.includes(auth.user))) {
            try {
                await axios.put(`/api/tasks/${taskItem._id}/seen`, {}, {
                    headers: {
                        'x-auth-token': auth.token,
                    },
                });
                setTaskItem({...taskItem, seen: [...taskItem.seen, auth.user]})
            } catch (err) {
                handleErrors(err, setAuth, history);
            }
        }
    }

    return (
        <Fragment>
            <Tr id={taskItem._id} className={[classes.root, classes.taskRow, open ? 'isOpen' : '', classes.tbodyTr, taskItem.latestDelivery && taskItem.status !== 'finished' ? classes.highPriority : ''].join(' ')}>
                {getScreenResolution() > 768  &&  (<Td>
                    <IconButton aria-label='expand row' size='small' onClick={() => setTaskAsSeen()}>
                        {open ? <BsChevronUp/> : <BsChevronDown/>}
                    </IconButton>
                </Td>)}
                <Td className={'selection'}>
                    {(taskItem.type !== 2 && taskItem.type !== 4) && (
                        <Checkbox
                            className={'task-chk'}
                            checked={selectedTasks.includes(taskItem._id)}
                            value={taskItem._id}
                            onChange={selectTask}
                            color='primary'
                        />
                    )}
                </Td>
                {hasPermission(auth, 'task_column_messages_count_view') && (
                <Td className={'chips'}>
                    <div className={classes.chipContainer}>
                        {!(taskItem.seen && taskItem.seen.includes(auth.user)) && (<div className={classes.unread}><span className={classes.tooltip}>neu</span></div>)}
                        <Tooltip title={`${maxCountRef.current.data} Nachrichten in der Aufgabe.` + (taskItem.latestDelivery && taskItem.status !== 'finished' ? " Dringende Anforderung mit Terminvorgabe." : '')}>
                            <Chip
                                className={[classes.chip, systemFail ? classes.chipError : ''].join(' ').trim()}
                                label={maxCountRef.current.data}
                                color={''}
                            />
                        </Tooltip>
                        {count > 0 && <Tooltip title={`${count} neue Nachrichten.`}><Chip className={classes.newChip} label={count} color={'primary'}/></Tooltip>}
                    </div>
                </Td>)}
                {hasPermission(auth, 'task_column_patient_view') && !hidePatientName && (
                    <TCell header={'Patient'} clipboard={{data: taskItem.patient ? `${taskItem.patient.firstname && taskItem.patient.firstname} ${taskItem.patient.lastname}`.trim() : '', help: `Patient kopieren`}}>
                    {taskItem.patient && (
                        <Link to={`/patient/details/${taskItem.patient._id}`} title='Details'>
                            {taskItem.patient.firstname && taskItem.patient.firstname} {taskItem.patient.lastname} (
                            {moment(taskItem.patient.dateOfBirth).format('DD.MM.YYYY')})
                        </Link>
                    )}
                </TCell>)}
                {getScreenResolution() > 768  && (<Fragment>
                    {(hasPermission(auth, 'task_column_home_view') && taskItem.patient && taskItem.patient.home) ? (
                        <TCell classnames={'hide-768'} clipboard={{data: taskItem.patient.home.name, help: `Heim kopieren`}}>
                            {hasPermission(auth, 'home_edit_view') && taskItem.patient && taskItem.patient.home && (
                                <Link to={`/einrichtung/${taskItem.patient.home._id}`} title='Bearbeiten'>
                                    {taskItem.patient.home.name}
                                    {taskItem.patient.homeGroup && ` / ${taskItem.patient.homeGroup.name}`}
                                </Link>
                            )}
                            {!hasPermission(auth, 'home_edit_view') && taskItem.patient && taskItem.patient.home && (
                                <Fragment>
                                    {taskItem.patient.home.name}
                                    {taskItem.patient.homeGroup && ` / ${taskItem.patient.homeGroup.name}`}
                                </Fragment>
                            )}
                        </TCell>) : (<TCell clipboard={{data: []}}></TCell>)}
                </Fragment>)}
                {hasPermission(auth, 'task_column_medicine_view') && (
                    <TCell header={'Medikament'} clipboard={{data: taskItem.medicine, help: `Medikament kopieren`}}>
                        {taskItem.amount ? `${taskItem.amount}x ` : ''}{taskItem.medicine}
                    </TCell>)}
                {getScreenResolution() > 768 && hasPermission(auth, 'task_column_doctor_view') && (
                    <Fragment>
                        {(taskItem.type === 1 || taskItem.type === 4) && taskItem.doctor && (
                            <TCell clipboard={{data: `${taskItem.doctor.title && taskItem.doctor.title} ${taskItem.doctor.firstname && taskItem.doctor.firstname} ${taskItem.doctor.lastname}`.trim(), help: `Arzt kopieren`}}>
                                <Link to={`/arzt/${taskItem.doctor._id}`} title='Bearbeiten'>{`Arztpraxis ${
                                    taskItem.doctor.title && taskItem.doctor.title
                                } ${taskItem.doctor.firstname && taskItem.doctor.firstname} ${taskItem.doctor.lastname}`}</Link>
                            </TCell>
                        )}
                        {!((taskItem.type === 1 || taskItem.type === 4) && taskItem.doctor) && (<td></td>)}
                    </Fragment>
                    )}
                {hasPermission(auth, 'task_column_actions_view') && (
                <Td align='right' className={'action'} style={{width: getScreenResolution() > 768 ? 230 : 'auto'}}>
                    {hasPermission(auth, 'task_action_pickup_view') &&
                    (taskItem.type === 1 || taskItem.type === 3 || taskItem.type === 4) &&
                    taskItem.status === 'new' && (
                        <Tooltip title='Abholfauftrag erzeugen'>
                            <span>
                                <ActionLoader color={'#ff5722'} onClick={sendToPickup} size={getScreenResolution() > 768 ? 'small' : 'normal'}
                                              className={classes.truck}>
                                    <FiTruck />
                                </ActionLoader>
                            </span>
                        </Tooltip>
                    )}
                    {hasPermission(auth, 'task_action_print_view') && (
                    <Tooltip title='Drucken'>
                        <span className={'action-link'}>
                            <Printer className={`icon action-link`} name={taskItem._id} url={`/api/tasks/${taskItem._id}/print`}/>
                        </span>
                    </Tooltip>)}
                    {hasPermission(auth, 'task_action_mail_view') && taskItem.doctor && taskItem.doctor.email && taskItem.doctor.email !== '' && (
                        <Tooltip title='Per Email anfordern'>
                            <span>
                                <ActionLoader color={'#009688'} onClick={sendPerMail} size={getScreenResolution() > 768 ? 'small' : 'normal'}
                                              className={classes.email}>
                                    <AiOutlineMail/>
                                </ActionLoader>
                            </span>
                        </Tooltip>
                    )}
                    {hasPermission(auth, 'task_action_fax_view') && taskItem.doctor && taskItem.doctor.fax && taskItem.doctor.fax !== '' && (
                        <Tooltip title='Per Fax anfordern'>
                            <span>
                                <ActionLoader color={'#00bcd4'} onClick={sendPerFax} size={getScreenResolution() > 768 ? 'small' : 'normal'} className={classes.email}>
                                        <FaFax/>
                                </ActionLoader>
                            </span>
                        </Tooltip>
                    )}
                    {taskItem.patient && (
                        <Tooltip title='Erneut bestellen'>
                            <span>
                                <LinkWithContractRequirements
                                    message={'required_contract_message'}
                                    permission={'task_action_template_view'}
                                    className={`${classes.contractRequiredButton} action-link`}
                                    to={routeService.taskCopy(taskItem._id)}>
                                <RiMedicineBottleLine/>
                            </LinkWithContractRequirements>
                            </span>
                        </Tooltip>)}
                    {hasPermission(auth, 'task_action_edit_view') && taskItem.patient && (
                        <Tooltip title='Auftrag Bearbeiten'>
                            <span>
                                <IconButton
                                    className={classes.edit}
                                    color='primary'
                                    component={Link}
                                    endIcon={<MdAdd/>}
                                    size={getScreenResolution() > 768 ? 'small' : 'normal'}
                                    to={routeService.taskEdit(taskItem._id)}
                                    variant='contained'
                                >
                                    <FiEdit/>
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    {hasPermission(auth, 'task_action_finish_view') && taskItem.status !== 'finished' && (
                        <Tooltip title='Vorgang abschließen'>
                            <span>
                                <ActionLoader color={'#009688'} onClick={sendFinished} size={getScreenResolution() > 768 ? 'small' : 'normal'}
                                              className={classes.email}>
                                    <MdCheck/>
                                </ActionLoader>
                            </span>
                        </Tooltip>)}
                    {hasPermission(auth, 'task_action_cancel_view') && (taskItem.status !== 'finished' && taskItem.status !== 'canceled') && (
                        <Tooltip title='Vorgang Stornieren'>
                            <span>
                                <TwoOptButton onClick={cancelTask} position={'left'} optInMessage={'Möchten Sie diesen Vorgang Stornieren?'} className={`trash-icon ${classes.taskInTrash}`} icon={<FiTrash2/>}>
                                    Stornieren
                                </TwoOptButton>
                            </span>
                        </Tooltip>
                    )}
                    {hasPermission(auth, 'task_action_reopen_view') && (taskItem.status === 'finished' || taskItem.status === 'canceled') && (
                        <Tooltip title='Vorgang eröffnen'>
                            <IconButton onClick={sendReopen} disabled={!canReopen} size='small'
                                        className={[classes.email, !canReopen ? classes.disabled : ''].join(' ')}>
                                <GrRevert/>
                            </IconButton>
                        </Tooltip>)}
                </Td>)}
                {getScreenResolution() < 426  &&  (<Td>
                    <IconButton className={'expand-details'} aria-label='expand row' size='small' onClick={() => setTaskAsSeen()}>
                        Details
                    </IconButton>
                </Td>)}
            </Tr>
            <Tr className={['row-details', !open && classes.hidden, classes.tbodyTr].join(' ')}>
                {getScreenResolution() > 768 && (<Td colSpan={3}/>)}
                <Td colSpan={getScreenResolution() > 768 ? 3 : 4} className={classes.infoRow}>
                    Am {moment(taskItem.createdAt).format('DD.MM.YYYY HH:mm')} Uhr erstellt von{' '}
                    {taskItem.createdFrom ? taskItem.createdFrom.username : ''} <br/>
                    Am {moment(taskItem.modifiedAt).format('DD.MM.YYYY HH:mm')} Uhr zuletzt bearbeitet von{' '}
                    {taskItem.modifiedFrom ? taskItem.modifiedFrom.username : ''}{' '}
                    {taskItem.latestDelivery && (
                        <>
                            <br/>
                            <strong>
                                Am {moment(taskItem.latestDelivery).format('DD.MM.YYYY')} spätestens zu liefern!
                            </strong>
                        </>
                    )}
                </Td>
                <Td colSpan={2} className={classes.infoRow}>
                    {(hasPermission(auth, 'task_column_home_view') && taskItem.patient && taskItem.patient.home) ? (
                        <Fragment>
                            Heim:&nbsp;
                            {hasPermission(auth, 'home_edit_view') && taskItem.patient && taskItem.patient.home && (
                                <Link to={`/einrichtung/${taskItem.patient.home._id}`} title='Bearbeiten'>
                                    {taskItem.patient.home.name}
                                    {taskItem.patient.homeGroup && ` / ${taskItem.patient.homeGroup.name}`}
                                </Link>
                            )}
                            {!hasPermission(auth, 'home_edit_view') && taskItem.patient && taskItem.patient.home && (
                                <Fragment>
                                    {taskItem.patient.home.name}
                                    {taskItem.patient.homeGroup && ` / ${taskItem.patient.homeGroup.name}`}
                                </Fragment>
                            )}
                        </Fragment>) : (<Fragment />)}
                    <br />
                    {hasPermission(auth, 'task_column_doctor_view') && (taskItem.type === 1 || taskItem.type === 4) && taskItem.doctor && (
                                <Fragment>
                                    Arzt:&nbsp;
                                    <Link to={`/arzt/${taskItem.doctor._id}`} title='Bearbeiten'>{`Arztpraxis ${
                                        taskItem.doctor.title && taskItem.doctor.title
                                    } ${taskItem.doctor.firstname && taskItem.doctor.firstname} ${taskItem.doctor.lastname}`}</Link>
                                </Fragment>
                    )}
                    <br />
                    Dosierung/Darreichungsform: {taskItem.dose}
                    <br/>
                    Typ: {types[taskItem.type]}
                    <br/>
                    Anforderungsart:&nbsp;
                    {taskItem.group === taskGroupEnum.MEDICINE && 'Medikament'}
                    {taskItem.group === taskGroupEnum.DOCUMENT && 'Dokument'}
                    {taskItem.group === taskGroupEnum.OTHER && 'Sonstiges'}
                </Td>
            </Tr>
            <Tr className={['row-communication', classes.tbodyTr, open ? classes.tbodyTrB : ''].join(' ')}>
                <Td style={{paddingBottom: 0, paddingTop: 0}} colSpan={getScreenResolution() > 768 ? 8 : 6}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <EventList task={task} parentContainer={maxCountRef} setCanReopen={setCanReopen}/>
                    </Collapse>
                </Td>
            </Tr>
        </Fragment>
    );
};

TaskRow.propTypes = {
    hidePatientName: PropTypes.bool
};

TaskRow.defaultProps = {
    hidePatientName: false
};

export default TaskRow;

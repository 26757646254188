import React from "react";
import PatientForm from "../forms/PatientForm";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const PatientDetails = ({...args}) => {
    return <PatientForm readonly={true}/>
}

PatientDetails.propTypes = {};

PatientDetails.defaultProps = {};

export default PatientDetails;
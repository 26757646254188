import moment from "moment"

const makeMockxiosId = () => {
    let result           = '';
    let characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let ii = 0; ii < 24; ii++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

const getRandomItemIds = (collection, amount) => {
    const shuffled = collection.sort(function(){return .5 - Math.random()});

    if(amount === undefined) {
        amount = Math.floor(Math.random() * collection.length);
    }

    const selected=shuffled.slice(0,amount);

    return selected.map((item) => { return item._id });
}

const getRandomSingleItem = (collection) => {
    const shuffled = collection.sort(function(){return .5 - Math.random()});

    return {...shuffled[0]};
}

const getRandomSingleItemId = (collection) => {
    return getRandomSingleItem(collection)._id;
}

const  getRandomDate = () => {
    const start = new Date();
    const end = new Date(2025, 12, 31);
    const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

    const mom = moment(date.getTime());

    return `${mom.format('YYYY-MM-DD')}T${mom.format('HH:mm:ss')}Z`;
}

const  getRandomPastDate = () => {
    const start = new Date(1960, 12, 31);
    const end = new Date(1987, 12, 31);
    const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

    const mom = moment(date.getTime());

    return `${mom.format('YYYY-MM-DD')}T${mom.format('HH:mm:ss')}Z`;
}

const mapById = (collection, dataCollection, destinationCollectionField, dataCollectionField, single, onlyId) => {
    for(let index in collection) {
        if(!single) { collection[index][destinationCollectionField] = []; }

        for(let item of dataCollection) {
            if(collection[index]._id === item[dataCollectionField] || collection[index]._id === item[dataCollectionField]._id) {
                if(single) {
                    collection[index][destinationCollectionField] = onlyId ? item._id : item;
                    break;
                } else {
                    collection[index][destinationCollectionField].push(onlyId ? item._id : item);
                }
            }
        }
    }

    return collection;
}

export { makeMockxiosId, getRandomItemIds, getRandomSingleItemId, getRandomSingleItem, getRandomDate, mapById, getRandomPastDate }
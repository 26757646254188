import React, {Fragment, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Col from "@components/Col";
import Card from "@components/Card";
import Row from "@components/Row";
import AuthContext from "@context/AuthContext";
import patientServiceFactory from "@factories/patientServiceFactory";
import Table from "@components/Table/Table";
import Button from "@components/Button";
import taskServiceFactory from "@factories/taskServiceFactory";
import taskGroupEnum from "@enum/taskGroupEnum";
import moment from "moment";
import ToolTip from "@components/ToolTip";
import Link from "@components/Link";
import routeServiceFactory from "@factories/routeServiceFactory";
import doctorServiceFactory from "@factories/doctorServiceFactory";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const MedicineDialogStep = ({form, setValid, onNext, onChange, ...args}) => {

    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * State
     */
    const [state, setState] = useState(form ? form : {});

    /**
     * Services
     */
    const taskService = taskServiceFactory({auth})
    const doctorService = doctorServiceFactory({auth})
    const routeService = routeServiceFactory()

    /**
     * Hooks
     */
    useEffect(() => {
        if(state._id) {
            onNext(loadData, state._id);
        }
    }, [state]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(state.patient && state.patient.patient && !state.patient.patient._id) {
            onNext();
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Services
     */
    const patientService = patientServiceFactory({auth});
    const medicineService = {
        get: async (id, filter, page, limit, subPath) => {
            if(state.patient.patient && state.patient.patient._id) {
                return await patientService.medicine(state.patient.patient._id, filter, page, limit);
            }

            return {items: [], max: 0}
        }
    }

    const loadData = async (data) => {
        try {
            const response = await taskService.get(data);

            if(response.doctor) {
                const doctor = await doctorService.get(response.doctor);

                if(doctor) {
                    response.doctor = doctor;
                }
            }

            return {
                group: response.group ? response.group : taskGroupEnum.MEDICINE,
                amount: response.amount ? response.amount : 1,
                medicine: response.medicine,
                dose: response.dose ? response.dose : '',
                type: response.type,
                doctor: response.doctor
            };
        } catch(error) {
        }

        return {};
    }

    /**
     * Functions
     */
    const change = (e) => {
        if(e.target && e.target.name) {
            setState({...state, [e.target.name]: e.target.value});
        } else {
            setState({...state, ...e});
        }

        if (onChange) {
            onChange(e);
        }
    }

    /**
     * Columns
     */
    const columns = [
        {name: 'name', title: 'Medikament'},
        {name: 'dose', title: 'Dosierung', mobile:{size: 6}},
        {name: 'doctor', title: 'Arzt', mobile:{size: 6}, render: (item) => {
            if(item.doctor) {
                return (<Link to={routeService.doctorEdit(item.doctor._id)}>{item.doctor.title} {item.doctor.lastname}</Link>)
            }

            return '';
            }
            },
        {name: 'date', title: 'Letzte Bestellung', mobile:{size: 6}, render: (item) => {
                const index = item.dates.length - 1;
                const mom = moment(item.dates[index]);

                return (<ToolTip trigger={<span>{mom.format('DD.MM.YYYY')}</span>}>
                    <strong className={'font-color-primary'}>Letzte Bestellungen</strong>
                    <br />
                    <span dangerouslySetInnerHTML={{__html: item.dates.map(date => {
                                const mom = moment(date);

                                return `${mom.format('DD.MM.YYYY')} um ${mom.format('HH:mm')} Uhr`;
                            }).reverse().slice(0, 10).join('<br />')}} />
                    </ToolTip>);
            }
        },
        {name: '', title: '', align: 'right', mobile:{align: 'center'}, render: (item) => {
                return (<Button isLoading={state._id === item.id} tableButton={true} onClick={async () => {await change({target: {name: '_id', value: item.id}}) }}>erneut bestellen</Button>);
            }
        }
    ];

    if(!state.patient || (state.patient.patient && !state.patient.patient._id)) {
        return <Fragment />
    }

    return <Row className='mb-12'>
        <Col sm={12}>
            <Card title='Medikament'
                  description={'Wählen Sie einen Medikament aus oder erzeugen einen Neues.'}
                  className={['form-card'].join(' ')}
                  center={true}>
                <div className='container'>
                    <Row>
                        <Col>
                            <div>
                                <Button tableButton={true} color={'success'} onClick={() => { change({target: {name: '_id', value: 'new'}}) }}>Neues Medikament bestellen</Button>
                            </div>

                            <h6 className='card-title mt-3'>Letzte Bestellungen</h6>

                            <Table
                                columns={columns}
                                service={medicineService}
                                searchable={false}
                            />
                        </Col>
                    </Row>
                </div>
            </Card>
        </Col>
    </Row>
}

MedicineDialogStep.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    name: PropTypes.string.isRequired,

};

MedicineDialogStep.defaultProps = {};

export default MedicineDialogStep;
const db = {
    '/api/products/homes': [{
        _id: '6183e64d327638002c511a69',
        name: 'Pflegeeinrichtung ohne Login',
        price: 65.18,
        interval: 'monthly',
        currency: 'eur',
        description: 'Zusätzliches Pflegeheim zum Verwalten in Ihrer MediCharge Suite',
        iconName: 'medical_home',
        services: [
            {name: 'lorem ipsum doloim'},
            {name: 'lorem ipsum doloim'},
            {name: 'lorem ipsum doloim'},
            {name: 'lorem ipsum doloim'},
            {name: 'lorem ipsum doloim'},
            {name: 'lorem ipsum doloim'}
        ]

    },
        {
            _id: '6183e64d327638002c512de4',
            name: 'Pflegeeinrichtung mit Login',
            price: 105.72,
            interval: 'monthly',
            iconName: 'medical_home',
            currency: 'eur',
            description: 'Erweitern Sie ihr Möglichkeiten und erhöhen Sie Ihre Produktivität noch mehr.',
            services: [
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'Mit Login', key: 'with-login', hidden: true}
            ]

        }
    ]
}

export default db;
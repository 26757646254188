import React, { Fragment } from 'react';

const Content = ({ header, items, title, children }) => {
    return (
        <Fragment>
            {(title || items) && (<div className='content-container d-flex justify-content-between align-items-center flex-wrap grid-margin'>
                {title && (<h4 className='mb-3 mt-1 mb-md-0'><span className={'me-2'}>{title}</span>{header && header}</h4>)}
                {items && (<div className={'action-bar'}>
                    {items}
                </div>)}
            </div>)}
            {children}
        </Fragment>
    );
};

export default Content;

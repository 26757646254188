import {intersection, validateEmail} from "@utils/utils";
import React from "react";

const isValid = (validationFailureMessages, errors, state, setErrors) => {
    if(validationFailureMessages !== null) {
        const fields = Object.keys(validationFailureMessages);

        const validCompareErrors = {};
        for (const key in errors) {
            if(fields.indexOf(key) !== -1) { validCompareErrors[key] = errors[key]; }
            else if(!errors[key].deletable) { validCompareErrors[key] = errors[key]; }
        }

        const compareState = {};
        for(const key in state) {
            if(!!state[key]) { compareState[key] = state[key]; }
        }

        setErrors(validCompareErrors);

        return Object.keys(validCompareErrors).length === 0
            && intersection(fields, Object.keys(compareState)).length === fields.length;
    }

    return false;
};

const registerValidationFailureMessages = (definedValidationFailureMessages, state, setValidationFailureMessages) => {
    const messages = {};

    for(const key in definedValidationFailureMessages) {
        const item = definedValidationFailureMessages[key]
        if(item.messages) {
            if(item.state) {

                if(state[item.state.field] === item.state.value) {
                    messages[key] = item.messages;
                }
            } else {
                messages[key] = item.messages;
            }
        } else {
            messages[key] = item;
        }
    }

    setValidationFailureMessages(messages);
}

const validate = (e, validationFailureMessages, errors, setErrors) => {
    if(!(e.target && e.target.name)) {
        return;
    }

    let failure = {...errors};

    delete failure[e.target.name];
    if(e.target.required && e.target.value.length <= 0) {
        failure[e.target.name] = [{key: e.target.name, message: validationFailureMessages[e.target.name] || 'NO_ERROR_MESSAGE_AVAILABLE', deletable: !!validationFailureMessages[e.target.name]}];
    }
    switch(e.target.name) {
        case 'mail':
            if(e.target.value.length > 0 && !validateEmail(e.target.value)) {
                failure[e.target.name] = failure[e.target.name] || [];
                failure[e.target.name].push({message: 'Bitte geben sie eine gültige E-Mail an. z.B: "info@example.de"', deletable: false});
            }
            break;
        default:
    }

    setErrors(failure);
}

const formData = (data) => {
    return (data && data.trim()) || (<small className={'optional-field'}>Keine angabe.</small>);
}

export { isValid, registerValidationFailureMessages, validate, formData };
import {makeStyles} from "@material-ui/core";

export const useRespTableThemeStyles = makeStyles({
    root: {
        '& tbody': {
            '& tr': {
                '&.row-communication': {
                    ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
                        padding: 0,
                        '& form': {
                            margin: '0 -8px',
                            '& .message-input': {
                                width: 200
                            }
                        }
                    }
                },
                '&.row-details': {
                    ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
                        padding: 0
                    }
                },
                '& .pivoted': {
                    ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
                        padding: '17px 0 15px 0 !important',
                        '& .trash-icon': {
                            verticalAlign: 'top',
                            padding: 12,
                            '& .opt-in-trigger': {
                                fontSize: 25
                            }
                        },
                        '& .action-link': {
                            padding: 12,
                            '& svg': {
                                fontSize: 28
                            }
                        },
                        '&.selection': {
                            padding: '0 0 35px 0 !important',
                            display: 'inline-block',
                            margin: '0 0 0 -17px'
                        },
                        '&.chips': {
                            padding: '5px 0px 0 0 !important',
                            display: 'inline-block',
                            float: 'right',
                            '& .MuiChip-colorPrimary': {
                                padding: '0 21px 0 0',
                                left: -32
                            }
                        },
                        '& .tdBefore': {
                            left: 0,
                            top: 0
                        },
                        '& .expand-details': {
                            margin: '0 0 0 50%',
                            transform: 'translate(-50%, 0)',
                            backgroundColor: '#2a5d78',
                            borderRadius: 5,
                            color: '#fff',
                            padding: '5px 15px'
                        }
                    }
                }
            }
        }
    },
    theadTr: {
        '& th': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            padding: '16px',
            ['@media (max-width:601px)']: { // eslint-disable-line no-useless-computed-key
                padding: '0'
            },
            textAlign: 'left',
        },
    },
    tbodyTr: {
        'td': {
            '& .pivoted': {
                ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
                    padding: 0
                }
            }

        },
        ['@media (max-width:601px)']: { // eslint-disable-line no-useless-computed-key
            border: '0 !important',
            borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
        },
        '& td': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            padding: '16px',
            ['@media (max-width:601px)']: { // eslint-disable-line no-useless-computed-key
                padding: '0 !important',
            }
        },
        '& td div.tdBefore': {
            ['@media screen and (max-width: 40em)']: { // eslint-disable-line no-useless-computed-key
                overflow: 'hidden',
                overflowWrap: 'initial',
            }
        },
    },
    forceSelectable: {
        userSelect: 'all !Important'
    },
    checkAllContainer: {
        position: 'relative',
        ['@media screen and (max-width: 40em)']: { // eslint-disable-line no-useless-computed-key
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
        ['@media screen and (min-width: 40em)']: { // eslint-disable-line no-useless-computed-key
            '& > div > label': {
                position: 'absolute',
                left: '94px',
                top: '0',
            },
            '& > div > label > span.MuiFormControlLabel-label': {
                display: 'none',
            }
        },
        ['@media screen and (min-width: 1001px)']: { // eslint-disable-line no-useless-computed-key
            '& > div > label': {
                left: '96px',
            },
        },
        ['@media screen and (min-width: 1601px)']: { // eslint-disable-line no-useless-computed-key
            '& > div > label': {
                left: '100px',
            },
        },
        ['@media screen and (min-width: 2001px)']: { // eslint-disable-line no-useless-computed-key
            '& > div > label': {
                left: '125px',
            },
        },
    }
});

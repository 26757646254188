import Row from "@components/Row";
import Col from "@components/Col";
import Input from "@components/Input";
import {Fragment, useEffect, useState} from "react";
import {validate} from "@utils/forms";
import PropTypes from "prop-types";
import Checkbox from "@components/Checkbox";
import Icon from "@components/Icon";
import {Password} from '@utils/password';

const InputPasswordChange = ({disabled, onChange, setPasswordValid, minLength, form}) => {

    const validationFailureMessages = {
        password: ['Bitte vergeben Sie ein Passwort.'],
        password_confirm: ['Bitte wiederholen Sie das Passwort']
    }

    /**
     * States
     */
    const [errors, setErrors] = useState({});
    const [state, setState] = useState({password: '', password_confirm: '', password_once: false});
    const [displayPassword, setDisplayPassword] = useState(false);

    /**
     * Hooks
     */
    useEffect(() => {
        changeEditMode(disabled);
    }, [disabled]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(!disabled && setPasswordValid) {
            setPasswordValid(validatePassword(state));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        setState({...state, password_once: !!form.password_once});
    }, [form]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const change = (e) => {
        validate(e, validationFailureMessages, errors, setErrors);
        const update = {...state, [e.target.name]: e.target.value}
        setState(update);
        const valid = validatePassword(update);
        if(onChange && valid) {
            onChange(update);
        }
    }

    const check = (e) => {
        const update = {...state, [e.target.name]: e.target.checked};
        setState(update);
        const valid = validatePassword(update);
        if(onChange && valid) {
            onChange(update);
        }
    }

    const changeEditMode = (disabled) => {
        if(disabled) {
            setState({password: '', password_confirm: ''});
            setErrors({});
            setPasswordValid(true);
        } else {
            setPasswordValid(false);
        }
    };

    const validatePassword = (data) => {
        let failure = {...errors};
        failure.password = failure.password || [];
        failure.password_confirm = failure.password_confirm || [];

        if(data.password && data.password_confirm && data.password.length > 0 && data.password_confirm.length > 0) {
            failure.password = failure.password.filter((item) => item.key !== 'pas' && item.key !== 'pas_length');
            failure.password_confirm = failure.password_confirm.filter((item) => item.key !== 'pas_con');

            if(data.password !== data.password_confirm) {
                failure.password.push({key: 'pas', message: 'Das Passwort ist nicht identisch mit den bestätigungs Passwort', deletable: false});
                failure.password_confirm.push({key: 'pas_con', message: 'Das bestätigungs Passwort ist nicht identisch mit den Passwort', deletable: false});
            }

            if(data.password.length < minLength) {
                failure.password.push({key: 'pas_length', message: `Das Password benötigt eine mindest länge von ${minLength} Zeichen`, deletable: false});
            }
        }

        if(data.password && data.password.length > 0) {
            failure.password = failure.password.filter((item) => ['password_confirm', 'password'].indexOf(item) > -1).concat(Password.checkPasswordComplexity(data.password));

            // //unique
            // let pass = {};
            // for(let ii = 0; ii < failure.password.length; ii++) {
            //     pass[failure.password[ii].keys] = failure.password[ii];
            // }
            // failure.password = Object.values(pass);
            setErrors(failure);

            return data.password && data.password.length > 0 && data.password === data.password_confirm;
        }

        return false;
    }

    const showPassword = () => {
        setDisplayPassword(!displayPassword);
    }

    const generatePassword = () => {
        const password = Password.generatePassword();
        const update = {...state, password: password, password_confirm: password};

        setState(update);
        setErrors({});
        setPasswordValid(true);

        if(onChange) {
            onChange(update);
        }
    }

    return (
        <Fragment>
            <Row className={[disabled ? 'disabled' : '', 'password-container'].join(' ').trim()}>
                <Col sm={12}>
                    {disabled && (<div className={'un-clickable'} />)}
                    <Input
                        id={'input-password'}
                        required={!disabled}
                        errors={errors.password}
                        valid={state.password && state.password.length > 0 && (!errors.password || !errors.password.length)}
                        name={'password'}
                        type={displayPassword ? 'text' : 'password'}
                        label={'Passwort'}
                        placeholder={'Passwort'}
                        autoComplete={'new-password'}
                        value={state.password ? state.password : ''}
                        onChange={change} />
                    {displayPassword ? <Icon onClick={showPassword} icon={'eye_off'} className={'password-icon'} title={'Passwort zeigen'}/> : <Icon onClick={showPassword} icon={'eye'} className={'password-icon'} title={'Passwort verstecken'}/>}
                </Col>
            </Row>
            <Row className={['mt-3', disabled ? 'disabled' : '', 'password-container'].join(' ').trim()}>
                <Col sm={12}>
                    {disabled && (<div className={'un-clickable'} />)}
                    <Input
                        id={'input-password_confirm'}
                        required={!disabled}
                        errors={errors.password_confirm}
                        valid={state.password_confirm && state.password_confirm.length > 0 && (!errors.password_confirm || !errors.password_confirm.length)}
                        name={'password_confirm'}
                        type={displayPassword ? 'text' : 'password'}
                        label={'Passwort bestätigen'}
                        placeholder={'Passwort bestätigen'}
                        autoComplete={'new-password'}
                        value={state.password_confirm ? state.password_confirm : ''}
                        onChange={change} />
                    <Icon onClick={generatePassword} icon={'lock'} className={'password-icon'} title={'Passwort erzeugen'}/>
                </Col>
            </Row>
            <Row className={['mt-3', disabled ? 'disabled' : ''].join(' ')}>
                <Col sm={12}>
                    {disabled && (<div className={'un-clickable'} />)}
                    <Checkbox id={'chk-one-time-password'} onChange={check} label={'Einmalig verwendbar'} name={'password_once'} value={'1'}
                              checked={state.password_once}/>
                </Col>
            </Row>
        </Fragment>
    );
}
InputPasswordChange.propTypes = {
    disabled: PropTypes.bool,
};

InputPasswordChange.defaultProps = {
    minLength: 8,
    description: '',
    disabled: false
};

export default InputPasswordChange;
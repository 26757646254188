/* jshint esversion: 8 */
import React from 'react';
import '../assets/scss/navbar.scss';
import { Link } from 'react-router-dom';

// attention!!!
// this 2 lines will be replaced by build script, single quotes are necessary
const version='2.9.0';
const commit='e9c4839c817de530589d71e305017db9fa6ce6fe';

const Footer = () => {
    return (
        <div className='footer'>
            <span className='footer-item' title={commit}>v{version}</span>
            <span className='footer-item'><Link to='/impressum'>Impressum</Link></span>
            <span className='footer-item'><Link to='/datenschutz'>Datenschutz</Link></span>
        </div>
    );
};

export default Footer;

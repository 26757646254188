import {makeStyles} from "@material-ui/core";
import React, {useState} from "react";
import {Button} from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        display: 'inline-block',
        position: 'relative'
    },
    optIn: {
        width: 340,
        position: 'absolute',
        top:0,
        background: '#fff',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        padding: 5,
        borderRadius: 4,
        zIndex: 10,
        border: '1px solid #5588a3',
        //transform: 'translateY(-25%)',
        display: 'none'
    },
    optInVisible: {
        display: 'block'
    },
    buttonAccept: {
        width: 64,
        padding: 0,
        position: 'absolute',
        top: 0,
        left: 0

    },
    buttonReject: {
        margin: '5px 0 0 0',
        width: 64,
        padding: 0,
        fontSize: '0.675rem',
        position: 'absolute',
        bottom: 0,
        left: 0
    },
    buttonColumn: {
        display: 'inline-block',
        width: 60,
        minHeight: 50,
        position: 'relative'
    },
    messageColumn: {
        padding: '0 15px',
        display: 'inline-block',
        width: 'calc(100% - 70px)',
        color: '#2a5d78',
        fontSize: 14,
        fontWeight: 'normal',
        verticalAlign: 'top'
    },
    positionLeft: {
        left: -350,
    },
    positionRight: {
        right: -350
    },
    warning: {
        border: '1px solid #762832'
    },
    messageColumnWarning: {
        color: '#762832'
    }
});

const TwoOptSwitch = ({icon, position, children, onClick, checked, optInMessage, optInVariant, className, ...args}) => {

    /**
     * CSS
     */
    const classes = useStyles();

    const [visible, setVisible] = useState(false);

    const optIn = () => {
        setVisible(!visible);
    };

    const change = () => {
        if(onClick) {
            onClick();
        }

        setVisible(!visible);
    };

    const reject = () => {
        setVisible(!visible);
    };

    const addClass = (classes) => {
        classes = classes instanceof Array ? classes : [classes];

        return classes.join(' ').trim();
    };

    const buttons = () => (
        <div className={classes.buttonColumn}>
            <Button color='primary'
                    type='button'
                    variant='contained'
                    onClick={change}
                    className={classes.buttonAccept}>Ja</Button>
            <Button color='secondary'
                    type='button'
                    variant='outlined'
                    onClick={reject}
                    className={classes.buttonReject}>Nein</Button>
        </div>
    );

    return (
        <div className={[classes.container, className].join(' ')}>
            {!!icon && (
                <span
                    className={'opt-in-trigger'}
                    onClick={optIn}
                    {...args}>
                    {icon}
                </span>
            )}
            {!icon && (
            <Button
                className={'opt-in-trigger'}
                onClick={optIn}
                {...args}
            >
                {children}
            </Button>)}
            <div className={addClass([
                classes.optIn, visible ? classes.optInVisible : '',
                position === 'left' ? classes.positionLeft : classes.positionRight,
                optInVariant === 'warning' ? classes.warning : ''
            ])}>
                {position !== 'left' && buttons()}
                <div
                    className={addClass([classes.messageColumn, optInVariant === 'warning' ? classes.messageColumnWarning : ''])}
                    dangerouslySetInnerHTML={{__html: optInMessage}}
                />
                {position === 'left' && buttons()}
            </div>
        </div>
    )
};

export default TwoOptSwitch;
import React, {Fragment, useContext, useEffect, useState} from "react";
import Col from "@components/Col";
import Row from "@components/Row";
import Card from "@components/Card";
import PropTypes from "prop-types";
import Select from "@components/Select";
import patientServiceFactory from "@factories/patientServiceFactory";
import AuthContext from "@context/AuthContext";
import DatePicker from "@components/DatePicker";
import Icon from "@components/Icon";
import {hasPermission} from "@utils/utils";
import homeServiceFactory from "@factories/homeServiceFactory";
import moment from "moment";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const PatientDialogStep = ({form, setValid,onNext, onChange, ...args}) => {

    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * State
     */
    const [state, setState] = useState(form ? form : {});
    const [patients, setPatients] = useState(null);
    const [homes, setHomes] = useState([]);



    /**
     * Services
     */
    const patientService = patientServiceFactory({auth});
    const homeService = homeServiceFactory({auth})

    /**
     * Hooks
     */
    useEffect(() => {
        setValid(false);
        fetch();
        fetchHomes();

        return () => {
            setState({});
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(state.patient) {
            if(state.patient._id) {
                onNext();
            } else if(state.dateOfBirth) {
                if(hasPermission(auth, 'home_list_view', false)) {
                    if(state.home) {
                        setValid(true);
                    }
                } else if(!hasPermission(auth, 'home_list_view', false)) {
                    setValid(true);
                } else {
                    setValid(false);
                }
            }
        }
    }, [state]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const fetch = async () => {
        const response = await patientService.get(null, {}, 0, -1);

        setPatients(response.items.map((item) => {
            const mom = moment(item.dateOfBirth);
            let label = `${item.firstname} ${item.lastname} ${mom.format('DD.MM.YYYY')}`;

            if(item && item.home) {
                label += ` ${item.home.name}`
            }

            if(item && item.homeGroup) {
                label += ` ${item.homeGroup.name}`
            }

            return {key: item._id, label: label, value: item}
        }));

    }

    const fetchHomes = async () => {
        if(hasPermission(auth, 'home_list_view', false)) {
            const response = await homeService.get(null, {}, 0, -1);

            setHomes(response.items.map((item) => {
                return {key: item._id, label: item.name, value: item}
            }));
        }
    }

    const change = (e) => {
        if(e.target && e.target.name) {
            setState({...state, [e.target.name]: e.target.value});
        } else {
            setState({...state, ...e});
        }

        if (onChange) {
            onChange(e);
        }
    }

    const formatOptions = (item) => {
        const mom = moment(item.value.dateOfBirth);

        return (<Fragment>
            {item.value.firstname} {item.value.lastname}
            &nbsp;<small className={'patient-birth-date'}>({mom.format('DD.MM.YYYY')})</small>
            {item.value.home && (<small className={'patient-home'}>&nbsp;{item.value.home.name}</small>)}
            {item.value.homeGroup && (<small className={'patient-home'}>&nbsp;/&nbsp;{item.value.homeGroup.name}</small>)}
        </Fragment>)
    }

    const addItem = (name) => {
        if(typeof name === 'string') {
            const patient = name.split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1));
            const lastName = patient.pop();
            const firstName = patient.join(' ');

            return {
                key: null,
                label: `${firstName} ${lastName}`,
                value: {_id: null, firstname: firstName, lastname: lastName}
            }
        }

        return name
    }

    const onCreateLabel = (name) => {
        const patient = name.split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1));
        const lastName = patient.pop();
        const firstName = patient.join(' ');

        return (<span><Icon icon={'plus'} /> Patient&nbsp;<strong>
            {firstName && (<span className={'create-info-group'}><span>Vorname</span>{firstName}&nbsp;</span>)}
            {lastName && (<span className={'create-info-group'}><span>Nachname</span>{lastName}</span>)}
            </strong>&nbsp;anlegen.</span>)
    }

    return <Row className='mb-12'>
        <Col sm={12}>
            <Card title='Patienten'
                  description={'Wählen Sie einen Patienten aus oder erzeugen einen Neuen.'}
                  className={['form-card'].join(' ')}
                  center={true}>
                <div className='container'>
                    <Row>
                        <Col sm={12}>
                            <Select
                                required
                                placeholder={'Patient'}
                                label={'Patient'}
                                name={'patient'}
                                defaultValue={state.patient}
                                options={patients}
                                onChange={change}
                                onAddItem={hasPermission(auth, 'patient_create_view') ? addItem : null}
                                onCreateLabel={onCreateLabel}
                                formatOptionLabel={formatOptions}
                                loaderLabel={'Patienten werden geladen ...'}
                            />
                        </Col>
                    </Row>
                    {state.patient && !state.patient.patient && !state.patient._id && (
                        <Fragment>
                            <Row className={'mt-3'}>
                                <Col sm={12}>
                                    <DatePicker
                                        required
                                        name={'dateOfBirth'}
                                        label={'Geburtsdatum'}
                                        placeholder='Geburtsdatum'
                                        value={state.dateOfBirth}
                                        maxDate={new Date()}
                                        onChange={change}
                                    />
                                </Col>
                            </Row>
                            {hasPermission(auth, 'home_list_view', false) && (
                                <Row className={'mt-3'}>
                                    <Col sm={12}>
                                        <Select
                                            required
                                            placeholder={'Pflegeheim'}
                                            label={'Pflegeheim'}
                                            name={'home'}
                                            defaultValue={state.home}
                                            options={homes}
                                            onChange={change}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Fragment>
                    )}
                </div>
            </Card>
        </Col>
    </Row>
}

PatientDialogStep.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    name: PropTypes.string.isRequired,

};

PatientDialogStep.defaultProps = {};

export default PatientDialogStep;
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/de';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {
    Avatar,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent, Chip,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {ImCancelCircle, ImCopy} from 'react-icons/im';
import {MdSend} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import AuthContext from '../context/auth';
import TaskContext from '../context/task';
import '@ckeditor/ckeditor5-build-classic/build/translations/de';
import handleErrors from '../utils/handleErrors';
import {Alert} from "@material-ui/lab";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles({
    paper: {
        marginTop: 20,
        padding: 24,
    },
    date: {
        textAlign: 'right',
    },
    subject: {
        marginTop: 24,
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        margin: 0,
    },
    chip: {
        marginRight: '10px',
    },
    snippetToAllBtn: {
        float: "right",
    },
    snippetList: {
        '& li': {
            display: 'inline-block',
        }
    }
});

const CreateDocuments = ({history}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONTEXT
     */
    const {auth, setAuth} = useContext(AuthContext);
    const {selectedTasks} = useContext(TaskContext);
    const location = useLocation();

    /**
     * STATES
     */
    const [documentIds] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [noTask, setNoTask] = useState(false);
    const [snippets, setSnippets] = useState([]);
    const [type, setType] = useState('preferred');
    /**
     * HOOKS
     */
    useEffect(() => {
        getDocuments();
        getSnippets();

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

    }, [documentIds]);

    useEffect(() => {
        const search = location.search;
        const type = new URLSearchParams(search).get('type');

        if(type) {
            setType(type);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * QUERIES
     */

    const getDocuments = async () => {
        try {
            const search = location.search;
            const state = new URLSearchParams(search).get('state');
            const docs = await axios.post(`/api/documents/request${ state ? `?filter[status]=${state}`: '' }`, {
                tasks: selectedTasks,
            }, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });

            let sortedData = [...docs.data];
            sortedData.sort((doc1, doc2) => {
                const tasks1 = doc1.tasks.filter((item) => item.type !== 4).length;
                const tasks2 = doc2.tasks.filter((item) => item.type !== 4).length;

                if(tasks1 === 0 && tasks2 > 0) {
                    return 1;
                } else if(tasks1 > 0 && tasks2 === 0) {
                    return -1;
                }

                return 0;
            })

            setDocuments(sortedData);
            if (docs.data.length === 0) {
                setNoTask(true);
            }
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const getSnippets = async () => {
        try {
            let response = await axios.get(`/api/users/${auth.user}`, {headers: {'x-auth-token': auth.token}});
            setSnippets(response.data.snippets || []);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const snippetAdd = (index) => () => {
        let name = document.getElementById('snippetName' + index).value;
        setSnippets([
            ...snippets,
            {
                name: name,
                subject: documents[index].subject,
                content: documents[index].content,
            }
        ]);
    };

    const snippetDelete = (snippetChip) => () => {
        setSnippets((chips) => chips.filter((chip) => chip.name !== snippetChip.name));
    };

    const snippetClick = (snippet, index) => () => {
        let newDocuments = [...documents];
        newDocuments[index]['subject'] = snippet.subject;
        newDocuments[index]['content'] = snippet.content;
        setDocuments(newDocuments);
    };

    const snippetToAll = (index) => () => {
        let newDocuments = [...documents];
        let snippet = {
            subject: newDocuments[index]['subject'],
            content: newDocuments[index]['content'],
        };
        for (let d=0; d < newDocuments.length; d++) {
            if (d !== index) {
                newDocuments[d]['subject'] = snippet.subject;
                newDocuments[d]['content'] = snippet.content;
            }
        }
        setDocuments(newDocuments);
    };

    const cancel = () => {
        history.push('/anforderungen');
    };

    /**
     * CONTROLS
     */
    const changeDocument = (index, field, value) => {
        let newDocuments = [...documents];
        newDocuments[index][field] = value;
        setDocuments(newDocuments);
    };

    const removeDocument = (index) => {
        let newDocuments = [...documents];
        newDocuments.splice(index, 1);
        setDocuments(newDocuments);
    };

    /**
     * SUBMIT
     */
    const submit = async (e) => {
        e.preventDefault();
        // save snippets
        try {
            await axios.put(`/api/users/${auth.user}`, {snippets: snippets}, {headers: {'x-auth-token': auth.token}});
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
        // save documents
        for (const document of documents) {
            const tasks = document.tasks.filter((item) => item.type !== 4);
            try {
                await axios.post(`/api/documents`, {...document, tasks: tasks, type: type}, {
                    headers: {
                        'x-auth-token': auth.token,
                    },
                });
                toast.success('Dokumente angelegt');
            } catch (err) {
                handleErrors(err, setAuth, history);
                toast.error('Es konnte keine eMail oder Fax versendet werden.');
            }
        }
        history.push('/anforderungen');
        return false;
    };

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Link to='/anforderungen'>Rezeptanforderungen</Link>
                <Typography color='textPrimary'>Erzeugen</Typography>
            </Breadcrumbs>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography component='h1' color='textSecondary' gutterBottom>
                                Rezeptanforderungen erzeugen
                            </Typography>
                            {noTask && (
                                <Typography>
                                    Aktuell gibt es keine Aufträge, zu denen noch kein Dokument angelegt wurde. Sie
                                    können in den Aufträgen wählen, zu welchen erneut ein Dokument hinzugefügt
                                    werden sollen.
                                </Typography>
                            )}
                            {documents.map((doc, index) => (
                                <Paper key={index} className={classes.paper}>
                                    <Grid container spacing={2} justify='space-between'>
                                        <Grid item xs={documents.length > 1 ? 10 : 12}>
                                            An{' '}
                                            <b>{doc && doc.doctor ? `${doc.doctor.title} ${doc.doctor.firstname} ${doc.doctor.lastname}` : ''}</b>
                                        </Grid>
                                        {documents.length > 1 && (
                                            <Grid item xs={1} style={{marginRight: '24px'}}>
                                                <Button
                                                    color='primary'
                                                    onClick={(e) => removeDocument(index)}
                                                    startIcon={<ImCancelCircle />}
                                                    title='Entfernt den Arzt aus der Liste der Anforderungen. An ihn werden dann keine Anforderungen versendet.'
                                                    variant='contained'
                                                >
                                                    Entfernen
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {doc.tasks.filter((item) => item.type !== 4).length === 0 && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} className={'mt-3'}>
                                                <Alert severity="info">Rezept liegt bereits beim Arzt zur Abholung bereit! <br />Diese Anforderung wird nicht an den Arzt gesendet.</Alert>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {doc.tasks.filter((item) => item.type !== 4).length > 0 && (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className={classes.subject}>
                                            <TextField
                                                fullWidth
                                                id={'subject'+index}
                                                label='Betreff'
                                                name='subject'
                                                onChange={(e) => changeDocument(index, 'subject', e.target.value)}
                                                value={doc.subject}
                                            />
                                        </Grid>
                                        <Grid className={classes.subject} item xs={12}>
                                            <CKEditor
                                                config={{
                                                    language: 'de',
                                                    tabSpaces: 4,
                                                }}
                                                data={doc.content ? doc.content : ''}
                                                editor={ClassicEditor}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    changeDocument(index, 'content', data);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={9} xs={12}>
                                            <ul className={classes.snippetList}>
                                                {snippets && snippets.map((snippet, snippetIndex) => (
                                                    //let icon;
                                                    //
                                                    // if (data.label === 'React') {
                                                    //     icon = <TagFacesIcon />;
                                                    // }
                                                    <li>
                                                        <Chip
                                                            // icon={icon}
                                                            color='primary'
                                                            label={(snippet && snippet.name && snippet.name.length > 0) ? snippet.name : (`[${snippetIndex > 0 ? snippetIndex + 1 : `0${snippetIndex + 1}`}] KEIN NAME VERGEBEN.`)}
                                                            onClick={snippetClick(snippet, index)}
                                                            onDelete={snippetDelete(snippet)}
                                                            className={classes.chip}

                                                        />
                                                    </li>
                                                ))}
                                                <li>
                                                    <TextField
                                                        id={'snippetName'+index}
                                                        name='snippetName'
                                                        placeholder='Vorlagename'
                                                    />
                                                    <Chip
                                                        avatar={<Avatar>+</Avatar>}
                                                        label="Als Vorlage hinzufügen"
                                                        onClick={snippetAdd(index)}
                                                        variant='outlined'
                                                    />
                                                </li>
                                            </ul>
                                        </Grid>
                                        {documents.length > 1 && (
                                        <Grid item lg={3} xs={12}>
                                            <Button
                                                className={classes.snippetToAllBtn}
                                                color='primary'
                                                onClick={snippetToAll(index)}
                                                startIcon={<ImCopy />}
                                                variant='outlined'
                                            >
                                                Betreff und Text in alle anderen übernehmen
                                            </Button>
                                        </Grid>
                                        )}
                                        <Grid item xs={12} className={classes.subject}>
                                            <TableContainer>
                                                <Table aria-label='simple table'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Patient</TableCell>
                                                            <TableCell>Medikament</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {doc.tasks.map((task) => (
                                                            <TableRow key={task._id}>
                                                                <TableCell>
                                                                    {`${task.patient.firstname} ${task.patient.lastname}`}
                                                                </TableCell>
                                                                <TableCell>{task.medicine}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>)}
                                </Paper>
                            ))}
                        </CardContent>
                        <CardActions>
                            <Grid container justify='space-between'>
                                <Grid item>
                                    <Button
                                        color='primary'
                                        onClick={cancel}
                                        startIcon={<ImCancelCircle />}
                                        variant='outlined'
                                    >
                                        Abbrechen
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <form onSubmit={submit}>
                                        <Button
                                            color='primary'
                                            disabled={documents.filter(
                                                (doc) => doc.tasks.filter(
                                                    (item) => item.type !== 4
                                                ).length > 0
                                            ).length === 0}
                                            endIcon={<MdSend />}
                                            type='submit'
                                            variant='contained'
                                        >
                                            Senden
                                        </Button>
                                    </form>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default CreateDocuments;

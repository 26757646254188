import request from "@utils/request";
import {toast} from "react-toastify";
import factory from "@factories/factory";
import axios from "axios";

const userServiceFactory = (config) => {
    return {
        ...factory,
        ...config,
        logout: async function (token) {
            await request.post('/api/auth/logout', {}, {
                headers: {'x-auth-token': token}
            });
        },
        create: async function (data) {
            const response = await request.post('/api/users', data, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                toast.success('Benutzer wurde erfolgreich erstellt.');
            }
        },
        masqueradeLogin: async function(id) {
            const response = await request.post(`/api/auth/masquerade/${id}`, {}, {
                headers: {'x-auth-token': this.auth.token}
            });

            return response.data;
        },
        get: async function(id, filter, page, limit) {
            id = id ? `/${id}` : '';

            let connectWith = '?';
            let splice = '';

            if(page !== undefined) {
                limit = limit ? limit : this.limit;
                splice = `?offset=${page*limit}&limit=${limit}`;
                connectWith = '&';
            }

            const response = await request.get(`/api/users${id}${splice}${this.toFilterQuery(filter, connectWith)}`, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(id) {
                return response.data;
            }

            return response.data;
        },
        delete: async function (id) {
            const response = await request.delete(`/api/users/${id}`, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                toast.success('User wurde erfolgreich gelöscht.');
            }
        },
        update: async function (id, data) {
            const response = await request.put(`/api/users/${id}`, this.buildRequestData(data), {
                headers: {'x-auth-token': this.auth.token}
            });

            return response.data;
        },
        seen: async function() {
            const response = await request.put(`/api/seen/users`, {}, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                toast.success('Alle Nutzer wurden als gelesen markiert.');
            }
        },
        hasMandate: async function(id) {
            const response = await axios.get(`/api/users/${id}/paymentData`, {
                headers: {'x-auth-token': this.auth.token}});

            return response.data;
        }
    }
}

export default userServiceFactory;
import axios from "axios";

const migrator = {
    "/api/contracts/adoption": {
        post: async function(data, params, query, config) {
            const response = await axios.post(`/api/contracts/${params[0]}/adoption`, data, config);

            return response.data;
        }
    },
    "/api/contracts/adoption/accept": {
        put: async function(data, params, query, config) {
            const response = await axios.put(`/api/contracts/${params[0]}/adoption/accept`, data, config);

            return response.data;
        }
    },
    "/api/contracts/adoption/reject": {
        put: async function(data, params, query, config) {
            const response = await axios.put(`/api/contracts/${params[0]}/adoption/reject`, data, config);

            return response.data;
        }
    },
    "/api/contracts/adoption/withdraw": {
        post: async function(data, params, query, config) {
            const response = await axios.post(`/api/contracts/${params[0]}/adoption/withdraw`, data, config);

            return response.data;
        }
    },
    "/api/meetings": {
        get: async function(data, params, query, config) {
            const response = await axios.get(`/api/meetings`, config);

            return response.data;
        }
    },
    "/api/contracts": {
        get: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';
            const response = await axios.get(`/api/contracts${id}`, config);

            return response.data;
        },
        put: async function(data, params, query, config) {
            const response = await axios.put(`/api/contracts/${params[0]}`, data, config);

            return response.data;
        }
    },
    '/api/acl': {
        get: async function(data, params, query, config) {
            const response = await axios.get(`/api/acl`, config);

            return response.data;
        },
        put: async function(data, params, query, config) {
                const response = await axios.put(`/api/acl`, data, config);

                return response.data;
        }
    },
    '/api/auth/logout': {
        post: async function(data, params, query, config) {
            await axios.post(
                `/api/auth/logout`,
                data,
                config
            );
        },
    },
    '/api/auth/masquerade': {
        post: async function(data, params, query, config) {
            const response = await axios.post(
                `/api/auth/masquerade/${params[0]}`,
                data,
                config
            );

            return response.data;
        }
    },
    '/api/seen/users': {
        put: async function(data, params, query, config) {
            await axios.put(`/api/statistics/user_read_all`, {}, config);
        }
    },
    '/api/products/office': {
        get: async function(data, params, query, config) {
            const response = await axios.get(`/api/products/office`, config);

            return response.data;
        }
    },
    '/api/products/doctor': {
        get: async function(data, params, query, config) {
            const response = await axios.get(`/api/products/doctor`, config);

            return response.data;
        }
    },
    "/api/tasks/events/attachment": {
        get: async function(data, params, query, config) {
            const response = await axios.get(`/api/tasks/${params[0]}/events/${params[1]}/attachment/${params[2]}`, config);

            return response.data;
        }
    },
    "/api/tasks/seen": {
        put: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : false;
            if(id) {
                await axios.put(`/api/tasks${id}/seen`, {}, config);
            } else if(data.tasks && data.tasks instanceof Array) {
                for(let ii = 0; ii < data.tasks.length; ii++) {
                    const id = data.tasks[ii];
                    await axios.put(`/api/tasks/${id}/seen`, {}, config);
                }
            } else if(data.tasks) {
                await axios.put(`/api/statistics/task_read_all`, data, config);
            }else {
                await axios.put(`/api/statistics/pickup_read_all`, data, config);
            }
        }
    },
    "/api/tasks/dosage": {
        get: async function(data, params, query, config) {

            const collection = await axios.get(`/api/tasks/dosage`, config);

            return {
                max: collection.data.length,
                items: collection.data
            }
        }
    },
    "/api/users/profile/documents": {
        get: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';

            const response = (await axios.get(`/api/users/${id}/profile/documents`, config)).data;

            let collection = response.filter((item) => item.status === 'unread').map((item) => { return {...item, _id: item.original} });

            return {
                max: collection.length,
                items: collection
            }
        }
    },
    "/api/invoices/download": {
        get: async function(data, params, query, config) {
            const response = await axios.get(`/api/invoice/download/${params[0]}${query}`, config);

            return response.data;
        }
    },
    "/api/invoices/proof": {
        get: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';
            query = query ? `${query}&type=N` : '?type=N';

            const response = (await axios.get(`/api/invoice/list${id}${query}`, config)).data;

            let sum = 0;
            for(let ii = 0; ii < response.collection.length; ii++) {
                sum += response.collection[ii].price;
            }

            console.log(sum, response.collection.map((item) => item.price));

            return {
                max: response.collection.length,
                items: response.collection
            }
        }
    },
    "/api/invoices": {
        get: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';

            const response = (await axios.get(`/api/invoice/list${id}${query}`, config)).data;

            return {
                max: response.collection.length,
                items: response.collection
            }
        },
        put: async function(data, params, query, config) {
            return (await axios.put(`/api/invoice`, data, config)).data;
        }
    },
    "/api/events/seen": {
        put: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';
            if(id) {
                await axios.put(`/api/events${id}/seen`, {}, config);
            } else {
                await axios.put(`/api/statistics/event_read_all`, data, config);
            }
        }
    },
    "/api/events": {
        get: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';
            return (await axios.get(`/api/events${id}${query}`, config)).data;
        }
    },
    "/api/tasks": {
        get: async function(data, params, query, config) {
            query = query.replace('[_all]=', '[text]=').replace('filter[sort]', 'sort');
            const id = params[0] ? `/${params[0]}` : '';
            const result = (await axios.get(`/api/tasks${id}${query}`, config)).data;

            if(id) {
                return result;
            } else {
                return {
                    max: result.count,
                    items: result.data
                }
            }
        },
        post: async function(data, params, query, config) {
            await axios.post(`/api/tasks`, data, config);
        },
        put: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';
            await axios.put(`/api/tasks${id}`, data, config);
        }
    },
    "/api/medicine": {
        get: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';
            return (await axios.get(`/api/medicine${id}${query}`, config)).data;
        },
        put: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';
            await axios.put(`/api/medicine${id}${query}`, data, config)
            return true;
        },
        post: async function(data, params, query, config) {
            await axios.post(`/api/medicine`, data, config)
            return true;
        }
    },
    "/api/tasks/pickup": {
        post: async (data, params, query, config) => {
            const response = await axios.post(
                `/api/tasks/pickup`,
                data,
                config
            );

            return response.data;
        }
    },
    "/api/tasks/events": {
        get: async (data, params, query, config) => {
            const id = params[0] ? `/${params[0]}` : '';
            const result = (await axios.get(`/api/tasks${id}/events${query}`, config)).data;

            return {
                max: result.length,
                items: result
            }
        },
        post: async (data, params, query, config) => {
            const id = params[0] ? `/${params[0]}` : '';
            await axios.post(`/api/tasks${id}/events`, data, config);
            return true
        }
    },
    "/api/patients/medicine": {
        get: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';
            const result = (await axios.get(`/api/patients${id}/medicine${query}`, config)).data;

            return result;
        }
    },
    "/api/patients": {
        get: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';
            const result = (await axios.get(`/api/patients${id}${query}`, config)).data;

            if(id) {
                return result;
            } else {
                return {
                    max: result.count,
                    items: result.data
                }
            }
        },
        post: async function(data, params, query, config) {
            const response = await axios.post(`/api/patients`, data, config);
            return response.data;
        }
    },
    "/api/homes": {
        get: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';
            const result = (await axios.get(`/api/homes${id}${query}`, config)).data;

            if(id) {
                return result;
            } else {
                return {
                    max: result.count,
                    items: result.data
                }
            }
        }
    },
    "/api/doctors": {
        get: async function(data, params, query, config) {
            const id = params[0] ? `/${params[0]}` : '';
            const result = (await axios.get(`/api/doctors${id}${query}`, config)).data;

            if(id) {
                return result;
            } else {
                return {
                    max: result.count,
                    items: result.data
                }
            }
        },
        put: async (data, params, query, config) => {
            const id = params[0] ? `/${params[0]}` : '';
            await axios.put(`/api/doctors${id}`, data, config);

            return true
        },
        post: async (data, params, query, config) => {
            const response = await axios.post(`/api/doctors`, data, config);
            return response.data;
        }
    },
    "/api/users": {
        get:async (data, params, query, config) => {
            query = query.replace('[_all]=', '[text]=').replace('filter[sort]', 'sort');
            const id = params[0] ? `/${params[0]}` : '';
            const result = (await axios.get(`/api/users${id}${query}`, config)).data;

            if(id) {
                let item = {...result};
                delete item.password;
                delete item.password_confirm;
                return item;
            } else {
                return {
                    max: result.count,
                    items: result.data
                }
            }
        },
        post: async (data, params, query, config) => {
            let post = {...data, ...data.user};
            post.once_password = post.password_once;
            delete post.user;
            await axios.post(`/api/users`, post, config);
            return true;
        },
        put: async (data, params, query, config) => {
            const id = params[0] ? `/${params[0]}` : '';
            await axios.put(`/api/users${id}`, data, config);
            return true;
        },
        delete: async (data, params, query, config) => {
            const id = params[0] ? `/${params[0]}` : '';
            await axios.delete(`/api/users${id}`, config);
            return true;
        }
    }
}

export default migrator;

import moment from 'moment';
import React, { Fragment } from 'react';
import {  TiDocumentText } from 'react-icons/all';

import {
    Grid,
    makeStyles,
    Typography,
    Paper,
} from '@material-ui/core';
import { AiOutlineClockCircle } from 'react-icons/ai';
import Icon from "@components/Icon";
import {getScreenResolution} from "@utils/utils";

const useStyles = makeStyles({
    orientation: {
        verticalAlign: 'top'
    },
    message: {
        flexGrow: 1,
    },
    container: {
        padding: 12,
    },
    messageContainer: {
        padding: 8,
        border: '1px solid #03a9f4',
        background: '#e1f5fe',
    },
    iconColor: {
        marginRight: 8,
        color: '#03a9f4',
    },
    icon: {
        marginRight: 8,
    },
    messageText: {
        paddingLeft: 12,
    },
    user: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    date: {
        fontSize: 10,
        color: '#888',
    },
    new: {
        background: '#e6ebe7',
    },
    failed: {
        borderColor: 'rgba(196, 77, 86, 1)',
        background: 'rgba(196, 77, 86, 0.3)',
    },
    messageIcon: {
        display: "inline-block",
        margin: "-3px 0 0 5px",
        color: "#2a5d78",
        lineHeight: "12px",
        fontSize: 18,
        position: 'relative',
        top: 2

    }
});

const Email = ({ event }) => {
    /**
     * CSS
     */
    const classes = useStyles();


    const icon = (role) => {
        if(role.includes('ROLE_HOME')) {
            return <Icon icon={'medical_home'} tooltip={{content: 'Mitarbeiter einer Pflegeeinrichtung', classes: classes.messageIcon}}/>
        } else if(role.includes('ROLE_DOCTOR')) {
            return <Icon icon={'doctor'} tooltip={{content: 'Mitarbeiter einer Arztpraxis', classes: classes.messageIcon}}/>
        } else if(role.includes('ROLE_PHARMA')) {
            return <Icon icon={'medicine'} tooltip={{content: 'Mitarbeiter einer Apotheke', classes: classes.messageIcon}}/>
        } else if(role.includes('ROLE_DRIVER')) {
            return <Icon icon={'truck'} tooltip={{content: 'Fahrer', classes: classes.messageIcon}}/>
        }

        return <Fragment />;
    }

    return (
        <Fragment>
            <Grid item xs={getScreenResolution() > 768 ? 8 : 12}>
                <Paper className={`${classes.messageContainer} ${event.state === 'failed' ? classes.failed : ''}`} elevation={1}>
                    <Typography className={classes.user}>
                        <TiDocumentText className={classes.iconColor} />
                        {event.createdFrom && event.createdFrom.username}
                        {event.createdFrom && icon(event.createdFrom.roles)}
                    </Typography>
                    <Typography className={classes.date}>
                        <AiOutlineClockCircle className={classes.icon} />
                        {moment(event.createdAt).format('DD.MM.YYYY HH:mm')}
                    </Typography>
                    <Typography className={`${classes.messageText}`}><div dangerouslySetInnerHTML={{__html: `${typeof event.data === 'string' ? event.data.split('\n').join('<br />') : 'E-Mail wurde versendet'}`}} /> </Typography>
                </Paper>
            </Grid>
        </Fragment>
    );
};

export default Email;

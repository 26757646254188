import React, {Fragment, memo, useEffect, useRef, useState} from 'react';
import ReactSelect from 'react-select'; /** LIB https://react-select.com/home **/
import CreatableSelect from 'react-select/creatable';
import {rand} from "@utils/utils";
import PropTypes from "prop-types";
import {ImSpinner3} from "react-icons/im";
import Flicker from "@components/Flicker";

const Select = memo(({help, isMulti, loaderLabel, formatOptionLabel, onCreateLabel, readonly, observer, placeholder, description, onChange, className, onAddItem, errors, valid, name, label, required, id, options, defaultValue, ...args}) => {

    if(errors) {
        errors = errors instanceof Array ? errors : [errors];
    }

    id = id ? `select-${rand()}` : id;
    //options = options.map((item) => { if(typeof item !== 'object') { return {value: item._id, label: item}; } return item; });

    // if(placeholder) {
    //     elements = [{value: '', label: placeholder}].concat(elements);
    // }

    /**
     * States
     */
    const [state, setState] = useState([]);
    const [elements, setElements] = useState([]);
    const [initialised, setInitialised] = useState(false);


    /**
     * Reference
     */
    const stateReference = useRef();
    stateReference.current = state;

    /**
     * Hooks
     */
    useEffect(() => {
        buildElements();

        return () => {
            setElements([]);
        };
    }, [options]);// eslint-disable-line react-hooks/exhaustive-deps
    //
    useEffect(() => {
        setDefault(defaultValue);

        return () => {};
    }, [defaultValue, elements]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        register();

        return () => {};
    }, [observer]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const buildElements = async () => {
        if(options) {
            let elements = options.map((item, index) => { if(typeof item !== 'object') {
                return {value: item, label: item, _index: index}; } item._index = index;  return item;
            });

            setElements(elements);
            setInitialised(true);
        }
    }

    const change = (option, meta) => {
        if(onChange) {
            let value = option.value ? option.value : option.key;
            if(option instanceof Array) {
                value = option.map(item => item.value ? item.value : item.key);
            }

            onChange({target: {name: meta.name, value: value}});
        }

        //setState(option._index + (placeholder ? 1 : 0));
        setState([option._index]);
        if(observer) {
            observer.notify({[name]: option.value});
        }
    }

    const register = async () => {
        if(observer) {
            const unique = name.indexOf('[]') === -1;
            const key = name.replace('[]', '').trim();
            if(defaultValue === undefined) {
                const index = getValueIndex(observer.get(key, true));
                if(index !== -1) {
                    setState(index);
                }
            } else if(defaultValue  !== undefined) {
                const savedData = observer.get(key, true);
                if(savedData !== undefined && savedData !==  defaultValue) {
                    setState(observer.get(key, true));
                } else {
                    setState(getValueIndex(defaultValue));
                }
            }
            observer.register({
                get: () => { return elements[stateReference.current] ? elements[stateReference.current].value : false; },
                update: (index) => setState(index ? index : 0),
                key: key,
                unique: unique
            });
        }
    }

    const getValueIndex = (value) => {
        return elements.findIndex((item) => {
            if(item.value instanceof Object) {
                return item.value._id === value
            }

            return !!item.value ? item.value === value : item.key === value;
        });
    }

    const setDefault = async(value) => {
        const selected = [];

        if(!(value instanceof Array)) {
            value = [value];
        }

        for (let id in value) {
            const index = getValueIndex(value[id]);

            if(index !== -1) {
                selected.push(value[id]);
            }
        }

        setState(selected);
    }

    if(readonly) {
        return <Fragment>
            {label && (<label htmlFor={id} className={`form-label ${required ? 'form-label-required' : ''}`}>{label}</label>)}
            <div className={'form-control readonly-data'}>
                {elements[state] ? elements[state].label : <span>Keine Daten</span>}
            </div>
        </Fragment>
    }

    const handleCreate = (inputValue) => {
        //setTimeout(() => {
            let option = null;
            if(onAddItem) {
                const responseOption = onAddItem(inputValue);

                if(responseOption) {
                    option = responseOption;
                }
            }

            const newOption = option ? option : {
                key: null,
                label: inputValue,
                value: inputValue,
            };
            newOption._index = elements.length;
            setElements([...elements, newOption]);

            change({value: newOption.value, key: newOption.key, _index: newOption._index}, {name: name});
        //}, 1000);
    };

    let currentDisplay = [];
    let currentFilter = '';

    const filterOptions = (item, search) => {
        if(typeof item.label === 'string') {
            if(currentFilter !== search) {
                currentFilter = search;
                currentDisplay = [];
            }

            //console.log(currentFilter, currentDisplay);


            if(currentDisplay.length >= 100) {
                if(currentDisplay.indexOf(item.label.toLowerCase()) !== -1) {
                    return true;
                }
                return false
            }

            if(item.label.toLowerCase().indexOf(currentFilter.toLowerCase()) === -1) {
                return false;
            }

            currentDisplay.push(item.label.toLowerCase());
        }

        return true
    }

    if(!initialised) {
        return <div className={'form-input-field-container'}>
            {label && (<label htmlFor={id} className={`form-label ${required ? 'form-label-required' : ''}`}>{label}</label>)}
            <div className={'loading-data'}>
                <input
                    readOnly={true}
                    className={['form-control', className ? className : ''].join(' ').trim()}/>
                <ImSpinner3 className={' loader spin'}/>
                <Flicker  text={loaderLabel} className={'loading-text'}/>
            </div>
        </div>
    }

    const Option = props => {
        return (
            <div className={'select__option'}>
                <div className="selection-item">{props.data.label}</div>
                <small>werweerw</small>
            </div>
        );
    };

    return (
        <Fragment>
            {label && (<label htmlFor={id} className={`form-label ${required ? 'form-label-required' : ''}`}>{label}</label>)}
            {!onAddItem && (
                <ReactSelect
                    id={id}
                    isMulti={isMulti}
                    name={name}
                    required={!!required}
                    className={['form-select', className ? className : ''].join(' ').trim()}
                    classNamePrefix={'select'}
                    value={elements.filter(item => {
                        return state.indexOf(item.key) !== -1 || state.indexOf(item.value) !== -1
                    })}
                    onChange={change}
                    placeholder={placeholder}
                    filterOption={filterOptions}
                    formatOptionLabel={(item) => {
                        if(typeof item.label === 'string') {
                            const data = formatOptionLabel ? formatOptionLabel(item) : item.label;
                            return (<div className={'selection-item'}>
                                {data}
                                <div className={'option-help'}><small>{item.help}</small></div>
                            </div>)
                        }

                        return item.label
                    }}
                    options={elements} />
            )}
            {onAddItem && (
                <CreatableSelect
                    id={id}
                    name={name}
                    required={!!required}
                    className={['form-select', className ? className : '', handleCreate ? 'on-create-item' : ''].join(' ').trim()}
                    classNamePrefix={'select'}
                    value={elements.filter(item => {
                        return state.indexOf(item.key) !== -1 || state.indexOf(item.value) !== -1
                    })}
                    onChange={change}
                    filterOption={filterOptions}
                    formatCreateLabel={(item) => {
                        return (<div className={'create-action'}>{onCreateLabel ? onCreateLabel(item) : `Create: ${item}`}</div>)
                    }}
                    placeholder={placeholder}
                    onCreateOption={handleCreate}
                    formatOptionLabel={(item) => {
                        if(typeof item.label === 'string') {
                            const data = formatOptionLabel ? formatOptionLabel(item) : item.label;
                            return (<div className={'selection-item'}>{data}</div>)
                        }

                        return item.label
                    }}
                    options={elements} />
            )}
        </Fragment>
    );
})

Select.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool
};

Select.defaultProps = {
    readonly: false,
    isMulti: false
};

export default Select;
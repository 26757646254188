import { Grid, IconButton, TextField } from '@material-ui/core';
import { makeStyles, darken } from '@material-ui/core/styles';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import {
    AiOutlineSend,
    AiOutlineFilePdf,
    AiOutlineFileImage,
    AiOutlineFileUnknown,
    GiPaperClip,
    FaTimes,
} from 'react-icons/all';
import { toast } from 'react-toastify';
import SocketContext from '../context/socket';
import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';
import Message from './Message';
import Document from './Document';
import Fax from './Fax';
import Email from './Email';
import FaxResponse from './FaxResponse';
import axios from 'axios';
import EventMessageRecipeRequest from "./EventMessageRecipeRequest";

const useStyles = makeStyles({
    message: {
        flexGrow: 1,
        position: 'relative',
    },
    container: {
        padding: 12,
    },
    messageContainer: {
        padding: 8,
    },
    icon: {
        marginRight: 8,
    },
    messageText: {
        paddingLeft: 12,
        paddingTop: 8,
    },
    user: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    date: {
        fontSize: 10,
        color: '#888',
    },
    new: {
        background: '#e6ebe7',
    },
    hidden: {
        display: 'none',
    },
    addAttachment: {
        position: 'absolute',
        top: 6,
        right: 10,
        fontSize: 24,
        cursor: 'pointer',
        textAlign: 'center',
        height: 40,
        width: 40,
        borderRadius: '50%',
        display: 'inline-block',
        padding: 6,
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
        },
    },
    formAttachment: {
        background: '#5588a3',
        color: '#fff',
        padding: '8px 36px 8px 30px',
        textTransform: 'uppercase',
        borderRadius: 4,
        cursor: 'default',
        position: 'relative',
        overflow: 'hidden',
        margin: '8px 8px 0 0',
    },
    deleteAttachment: {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        right: 0,
        bottom: 0,
        width: 35,
        textAlign: 'center',
        padding: '9px 0 0 0',
        '&:hover': {
            background: darken('#5588a3', 0.2),
        },
    },
    formAttachmentIcon: {
        fontSize: 20,
        position: 'absolute',
        left: 0,
        bottom: 0,
        top: 0,
        width: 30,
        padding: '6px 0 0 0',
        textAlign: 'center',
    },
});

const EventList = ({ task, eventTypes, history, parentContainer, setCanReopen, allowAttachments }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONTEXT
     */
    const { socket } = useContext(SocketContext);
    const { auth, setAuth } = useContext(AuthContext);

    /**
     * STATES
     */
    const [message, setMessage] = useState('');
    const [events, setEvents] = useState([]);
    const [uploads, setUploads] = useState({});
    const [loading, setLoading] = useState(true);

    const eventsRef = useRef();
    eventsRef.current = events;

    const uploadsRef = useRef();
    uploadsRef.current = {
        data: uploads,
        set: setUploads,
    };

    /**
     * CONTROLS
     */

    const change = (e) => {
        setMessage(e.target.value);
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        if (socket !== null) {
            socket.on(`tasks/${task._id}/events`, function (event) {
                fetchEvent(event);
            });
        }

        return () => {
            if (socket !== null) {
                socket.off(`tasks/${task._id}/events`);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    useEffect(() => {
        fetchEvents();

        return () => {
            setRead();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * REQUESTS
     */
    const fetchEvents = async () => {
        try {
            let response = await axios.get(`/api/tasks/${task._id}/events`, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].seen && !response.data[i].seen.includes(auth.user)) {
                    response.data[i].new = true;
                }
            }
            setEvents(response.data);
            setLoading(false);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchEvent = async (event) => {
        try {
            let response = await axios.get(`/api/tasks/${task._id}/events?filter[_id]=${event.id}`, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
            if (response.data[0].seen && !response.data[0].seen.includes(auth.user)) {
                response.data[0].new = true;
            }

            setEvents(response.data.concat(eventsRef.current));
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const setRead = async () => {
        try {
            await axios.put(
                `/api/tasks/${task._id}/events`,
                {
                    seen: true,
                },
                {
                    headers: {
                        'x-auth-token': auth.token,
                    },
                }
            );
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const copy = (list) => {
        let collection = {};

        for (let name in list) {
            collection[name] = list[name];
        }

        return collection;
    };

    const addFile = (e) => {
        let file = e.target.files[0];

        if (file) {
            let data = copy(uploadsRef.current.data);
            data[file.name] = file;
            uploadsRef.current.set(data);
            document.getElementById('document-upload').value = '';
        }
    };

    const deleteAttachment = (e) => {
        const name = e.target.hasAttribute('data-name')
            ? e.target.getAttribute('data-name')
            : e.target.parentNode.getAttribute('data-name');

        if (name) {
            let data = copy(uploadsRef.current.data);
            delete data[name];
            uploadsRef.current.set(data);
        }
    };

    /**
     * SUBMIT
     */
    const submit = async (e) => {
        e.preventDefault();

        const files = Object.values(uploads);

        try {
            if (message !== '' || (files instanceof Array && files.length > 0)) {
                let data = new FormData();

                data.append('type', 'message');
                data.append('data', message === '' ? '[{SYSTEM_FILE_UPLOAD}]' : message);

                for (let ii = 0; ii < files.length; ii++) {
                    data.append('files', files[ii]);
                }

                const response = await axios.post(`/api/tasks/${task._id}/events`, data, {
                    headers: {
                        'x-auth-token': auth.token,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setMessage('');
                uploadsRef.current.set({});
                parentContainer.current.set(parentContainer.current.data + 1);

                if(response.data.reload) {
                    toast.success("Auftrag wurde wieder eröffnet und ist in der liste der neuen Auftrag zu finden.");
                    if(setCanReopen) {
                        setCanReopen(false);
                    }
                }
            } else {
                toast.error('Sie können keine leere Nachricht verschicken.');
                console.log(events);
            }
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
        return false;
    };

    return (
        <Grid container spacing={2} className={classes.container}>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item>Lade Daten</Grid>
                </Grid>
            ) : (
                <Fragment>
                    <Grid item xs={12}>
                        <form onSubmit={(e) => submit(e)}>
                            <Grid container>
                                <Grid item className={`message-input ${classes.message}`} spacing={2}>
                                    <input
                                        type={'file'}
                                        id={'document-upload'}
                                        onChange={addFile}
                                        className={classes.hidden}
                                    />
                                    <TextField
                                        value={message}
                                        onChange={change}
                                        label='Nachricht'
                                        fullWidth
                                    ></TextField>
                                    {allowAttachments && (
                                    <label
                                        htmlFor={'document-upload'}
                                        className={classes.addAttachment}
                                        title={'Datei anhängen'}
                                    >
                                        <GiPaperClip />
                                    </label>)}
                                </Grid>
                                <Grid item>
                                    <IconButton type='submit'>
                                        <AiOutlineSend />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid container>
                                {Object.values(uploadsRef.current.data).map((file) => (
                                    <Grid item className={classes.formAttachment} key={file.name.replace(' ', '-')}>
                                        <label className={classes.formAttachmentIcon}>
                                            {file.name.indexOf('.pdf') !== -1 && <AiOutlineFilePdf />}
                                            {(file.name.indexOf('.jpg') !== -1 ||
                                                file.name.indexOf('.jpeg') !== -1 ||
                                                file.name.indexOf('.png') !== -1) && <AiOutlineFileImage />}
                                            {file.name.indexOf('.pdf') === -1 &&
                                                file.name.indexOf('.jpg') === -1 &&
                                                file.name.indexOf('.jpeg') === -1 &&
                                                file.name.indexOf('.png') === -1 && <AiOutlineFileUnknown />}
                                        </label>
                                        {file.name}
                                        <label
                                            className={classes.deleteAttachment}
                                            data-name={file.name}
                                            onClick={deleteAttachment}
                                        >
                                            <FaTimes />
                                        </label>
                                    </Grid>
                                ))}
                            </Grid>
                        </form>
                    </Grid>
                    {events.filter((item) => {
                        return (eventTypes === undefined) || eventTypes.indexOf(item.type) !== -1;
                    }).map((event) => (
                        <Fragment>
                            {event.type === 'message' && <Message key={event._id} event={event} />}
                            {event.type === 'document' && <Document key={event._id} event={event} />}
                            {event.type === 'fax' && <Fax key={event._id} event={event} />}
                            {event.type === 'faxresponse' && <FaxResponse key={event._id} event={event} />}
                            {event.type === 'email' && <Email key={event._id} event={event} />}
                            {event.type === 'status_change' && <Message key={event._id} event={event} />}
                            {event.type === 'recipe_request' && <EventMessageRecipeRequest key={event._id} event={event} />}
                        </Fragment>
                    ))}
                </Fragment>
            )}
        </Grid>
    );
};

EventList.defaultProps = {
    allowAttachments: true
};

export default EventList;

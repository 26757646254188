/* jshint esversion: 8 */
import {CssBaseline} from '@material-ui/core';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import Bowser from 'bowser';
import {useMemo, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/breadcrumb.scss';
import './assets/scss/forms.scss';
import './assets/scss/_sidbarv3Helper.scss';
import AuthContext from './context/auth';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import OAuthPage from './pages/OAuthPage';
import Imprint from "./views/imprint";
import Privacy from "./views/privacy";
import lang_de from "@languages/DE_de"

import i18n from "i18next";
import {initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: "de",
        fallbackLng: "de",
        resources: {
            de: {
                translation: lang_de
            }
        },
        interpolation: {
            escapeValue: false
        }
    });

function App() {
    const [auth, setAuth] = useState(() => {
        try {
            return JSON.parse(localStorage.getItem('auth'));
        } catch (e) {
            localStorage.removeItem('auth');
            return null;
        }
    });

    if (auth && auth.once_password) {
        let routes = [
            '/profil',
            '/logout',
            '/login'
        ];
        let redirect = true;
        for (let route of routes) {
            if (window.location.href.indexOf(route) !== -1) {
                redirect = false;
            }
        }
        if (redirect === true) {
            window.location.href = '/profil';
        }
    }

    const theme = createMuiTheme({
        palette: {
            primary: {
                light: '#5588a3', // $blue3
                main: '#2a5d78',
                dark: '#00334e', // $blue1
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff7961',
                main: '#f44336',
                dark: '#a30000', // $red3
                contrastText: '#000',
            },
        },
        overrides: {
            MuiBreadcrumbs: {
                root: {
                    padding: '0 10px',
                },
            },
            MuiButton: {
                root: {
                    '&:focus': {
                        backgroundColor: '#00334e', // $blue1
                    },
                },
            },
            MuiCardActions: {
                root: {
                    padding: '16px',
                },
            },
            MuiInputAdornment: {
                root: {
                    '& + .MuiInput-input': {
                        paddingLeft: '7px',
                    },
                },
            },
            MuiPaper: {
                root: {
                    margin: '0 10px',
                },
            },
        },
    });

    const authProvider = useMemo(() => ({auth, setAuth}), [auth]);

    const setCookie = (cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    };

    const browser = Bowser.getParser(window.navigator.userAgent);
    const satisfiedBrowser = {
        chrome: ">=90",
        edge: ">=90",
        firefox: ">=90",
        safari: ">=14"
    };
    const isValidBrowser = browser.satisfies(satisfiedBrowser);
    if (!isValidBrowser) {
        toast.error(
            <div>
                Leider wird Ihr aktueller Browser {browser.getBrowserName()} {browser.getBrowserVersion()} nicht unterstützt.<br/>
                <br/>
                Wir empfehlen<br/>
                &nbsp; &nbsp; Google Chrome (Version {satisfiedBrowser.chrome}),<br/>
                &nbsp; &nbsp; Microsoft Edge (Version {satisfiedBrowser.edge}) oder<br/>
                &nbsp; &nbsp; Mozilla Firefox (Version {satisfiedBrowser.firefox})<br/>
                &nbsp; &nbsp; Apple Safari (Version {satisfiedBrowser.safari})<br/>
                zu verwenden.
            </div>, {autoClose: 5000, hideProgressBar: true, progress: 90000});
        setCookie(`not_supported_${browser}`, '1', 1);
    }

    return (
        <AuthContext.Provider value={authProvider}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Router>
                    <Switch>
                        <Route exact path='/impressum' component={Imprint}/>
                        <Route exact path='/datenschutz' component={Privacy}/>
                        <Route exact path='/login' component={Login}/>
                        <Route exact path='/oauth' component={OAuthPage} />
                        <Route path='/' component={Dashboard}/>
                    </Switch>
                </Router>
            </ThemeProvider>
            <ToastContainer position='top-center'/>
        </AuthContext.Provider>
    );
}

export default App;

import React from "react";
import {addClass} from "@utils/utils";
import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const Headline = ({align, size, design, className, children, ...args}) => {
    switch(size) {
        case 'h1':
            return <h1 className={addClass(['content-headline', className, design, `align-${align}`])} {...args}>
                {children}
            </h1>
        case 'h2':
            return <h2 className={addClass(['content-headline', className, design, `align-${align}`])} {...args}>
                {children}
            </h2>
        case 'h3':
            return <h3 className={addClass(['content-headline', className, design, `align-${align}`])} {...args}>
                {children}
            </h3>
        case 'h4':
            return <h4 className={addClass(['content-headline', className, design, `align-${align}`])} {...args}>
                {children}
            </h4>
        case 'h5':
            return <h5 className={addClass(['content-headline', className, design, `align-${align}`])} {...args}>
                {children}
            </h5>
        case 'h6':
            return <h6 className={addClass(['content-headline', className, design, `align-${align}`])} {...args}>
                {children}
            </h6>
        default:
            return '';
    }
}

Headline.propTypes = {
    size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    design: PropTypes.oneOf(['normal', 'bubble', 'line', 'none', 'strong']),
    align: PropTypes.oneOf(['left', 'right', 'center'])
};

Headline.defaultProps = {
    size: 'h1',
    design: 'normal',
    align: 'left'
};

export default Headline;
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { rand } from '@utils/utils';
import PropTypes from 'prop-types';
import TextInput from 'react-autocomplete-input'; //https://www.npmjs.com/package/react-autocomplete-input
import 'react-autocomplete-input/dist/bundle.css';

const Input = ({
    datalist,
    readonly,
    observer,
    type,
    description,
    onChange,
    value,
    className,
    errors,
    valid,
    name,
    label,
    required,
    id,
    ...args
}) => {
    if (errors) {
        errors = errors instanceof Array ? errors : [errors];
    }

    if (!id) {
        id = name ? `input-${name.toLowerCase()}` : `input-${rand()}`;
    }

    /**
     * State
     */
    const [state, setState] = useState('');

    /**
     * Reference
     */
    const stateReference = useRef();
    stateReference.current = state;

    /**
     * Hooks
     */
    useEffect(() => {
        if (typeof value === 'string') {
            setState(value);
        }
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (observer) {
            observer.register({
                get: () => {
                    return stateReference.current;
                },
                update: (args) => setState(args ? value : false),
                key: name,
            });
        }
    }, [observer]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Function
     */
    const change = (e) => {
        //console.log(e);
        if (!e.target) {
            e = { target: { value: e, name: name } };
        }

        if (onChange) {
            onChange(e);
        }

        setState(e.target.value);
        if (observer) {
            observer.notify({ [name]: e.target.value });
        }
    };

    if (readonly) {
        return (
            <Fragment>
                {label && (
                    <label htmlFor={id} className={`form-label ${required ? 'form-label-required' : ''}`}>
                        {label}
                    </label>
                )}
                <div className={'form-control readonly-data'}>
                    {state && state.length > 0 ? state : <span>Keine Daten</span>}
                </div>
            </Fragment>
        );
    }

    return (
        <div className={'form-input-field-container'}>
            {label && (
                <label htmlFor={id} className={`form-label ${required ? 'form-label-required' : ''}`}>
                    {label}
                </label>
            )}
            <TextInput
                options={datalist}
                id={id}
                trigger={''}
                autoComplete={'off'}
                type={type}
                Component={'input'}
                required={!!required}
                name={name}
                maxOptions={0}
                className={`form-control ${errors && errors.length > 0 ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`}
                value={state}
                {...args}
                regex={'^[a-zA-ZäöüÄÖÜß0-9_\\-\\s\\.]+$'}
                onChange={change}
                onBlur={change}
            />
            {description && <small className={'input-description'}>{description}</small>}

            {errors &&
                errors.map((item) => (
                    <div key={rand()} className='invalid-feedback'>
                        {item.message}
                    </div>
                ))}
        </div>
    );
};

Input.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    datalist: PropTypes.array,
};

Input.defaultProps = {
    type: 'text',
    readonly: false,
    datalist: [],
};

export default Input;

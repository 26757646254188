import React from 'react';
import Row from "./Row";
import Col from "./Col";

const Footer = () => {
    // attention!!!
    // this 2 lines will be replaced by build script, single quotes are necessary
    const version='2.9.0';
    const commit='e9c4839c817de530589d71e305017db9fa6ce6fe';

    return (
        <footer className='footer'>
            <div className={'container'}>
                <Row>
                    <Col>
                        <p>Copyright © 2023 MediCharge<span title={commit}>v{version}</span></p>
                    </Col>
                </Row>
            </div>
        </footer>
    );
};

export default Footer;

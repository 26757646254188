
import React, {Fragment, useContext} from "react";
import axios from "axios";
import handleErrors from "../../utils/handleErrors";
import AuthContext from "../../context/auth";
import {useHistory} from "react-router-dom";
import List from "../../components/List";
//import fileDownload from "js-file-download";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const BackupTab = ({...args}) => {

    /**
     * Context
     */
    const { auth, setAuth } = useContext(AuthContext);
    const {history} = useHistory();

    /**
     * State
     */
    const [collection, setCollection] = React.useState([]);

    /**
     * Hooks
     */
    React.useEffect(() => {
        fetch();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const fetch = async () => {
        try {
            const response = await axios.get(`/api/backup`, {
                headers: {'x-auth-token': auth.token},
            });

            setCollection(response.data);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    }

    const download = async (args) => {
         axios.request({
            url: `/api/backup/download/${args['name']}`,
            method: 'GET',
            headers: {'x-auth-token': auth.token},
            responseType: 'blob', //important
        }).then((res) => {
             const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
             const link = document.createElement('a');
             link.href = downloadUrl;
             link.setAttribute('download', args['name'].replace(/{.*?}_/, '')); //any other extension
             document.body.appendChild(link);
             link.click();
             link.remove();
            //fileDownload(res.data, args['name'].replace(/{.*?}_/, ''));
            fetch();
        });

        return false;
    }

    return <Fragment>
        <List collection={collection} title={'Archive'} defaultState={'new'} eventClick={download}/>
    </Fragment>
}

BackupTab.propTypes = {};

BackupTab.defaultProps = {};

export default BackupTab;
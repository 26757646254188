import React from 'react';
import Footer from "@components/Footer";

const SideBarWrapper = ({ children }) => {
    return <nav className='sidebar'>
        {children}
        <Footer />
    </nav>;
};

export default SideBarWrapper;

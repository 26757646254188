import React from "react";
import moment from "moment";
import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const LastChange = ({displayLabels, data, align, className, ...args}) => {


    return <div className={['last-change', align, className].join(' ').trim()}>
        {data && !data.createdFrom && <span className={'no-data'}>{displayLabels ? 'Erstellt: ' : ''}Unbekannt</span>}
        {data && !data.modifiedFrom && <span className={'no-data'}>{displayLabels ? 'Geändert: ' : ''}Unbekannt</span>}
        {data && data.createdFrom && (<span>{displayLabels ? 'Erstellt: ' : ''}{moment(data.createdAt).format('DD.MM.YYYY')} von {data.createdFrom.username}</span>)}
        {data && data.modifiedFrom && (<span>{displayLabels ? 'Geändert: ' : ''}{moment(data.modifiedAt).format('DD.MM.YYYY')} von {data.modifiedFrom.username}</span>)}
    </div>
}

LastChange.propTypes = {
    align: PropTypes.oneOf(['left', 'right', 'center']),
    displayLabels: PropTypes.bool
};

LastChange.defaultProps = {
    align: 'left',
    displayLabels: true
};

export default LastChange;
import React, { Fragment, useState } from 'react';
import { Document, Page } from 'react-pdf';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    overlay: {
        position: 'fixed',
        zIndex: 6000,
        background: 'rgba(0, 0, 0, 0.6)',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
    },
    container: {
        position: 'absolute',
        left: 30,
        top: 30,
        right: 30,
        bottom: 30,
        overflow: 'auto',
        '& .react-pdf__Page': {
            margin: '0 0 25px 0',
            '& canvas': {
                margin: '0 auto'
            }
        }
    },
    buttons: {
        textAlign: 'center',
        '& > * ': {
            margin: '5px 30px'
        }
    }
});

const Pdf = ({pdf, setPdf, buttons, ...props}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATES
     */
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const close = () => {
        if(setPdf) {
            setPdf(false);
        }
    };

    return (<Fragment>
        <div className={classes.overlay} onClick={close}>
            <div className={classes.container}>
                <Document
                    file={`data:application/pdf;base64,${pdf.current.data}`}
                    options={{ workerSrc: "/pdf.worker.js" }}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                </Document>
                <div className={classes.buttons}>{buttons}</div>
            </div>
        </div>
    </Fragment>);
};

export default Pdf;
/* jshint esversion: 8 */
import {
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel, makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/de';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {ImCancelCircle} from 'react-icons/im';
import {MdDelete, MdSend} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import PasswordForm from "../components/PasswordForm";
import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';
import {checkRequiredFields} from "../utils/tools";

moment.locale('de');

const useStyles = makeStyles((theme) => ({
    warning: {
        backgroundColor: '#ff0000',
        margin: '10px 0 0 0',
        width: '100%',

        '& .MuiFormControlLabel-label': {
            color: '#fff',
            margin: '0',
            padding: '4px',
        }
    },
    modified: {
        margin: '0 0 0 5px',
        color: '#888',
        fontSize: 12
    },
    warning2: {
        backgroundColor: '#f44336',
        borderRadius: 4,
        padding: '8px 16px',
        fontSize: 13,
        color: 'white',
        margin: '24px 8px 5.6px'
    }

}));

const UserForm = ({history, match: {params}}) => {

    let initialRoles = [];
    const classes = useStyles();

    /**
     * STATES
     */
    const {auth, setAuth} = useContext(AuthContext);

    if (auth.roles.includes('ROLE_MANAGER')) {
        initialRoles = JSON.parse(JSON.stringify(auth.roles));
        initialRoles.splice(initialRoles.indexOf('ROLE_MANAGER'), 1)
    }

    const [state, setState] = useState({
        username: '',
        password: '',
        password_confirm: '',
        roles: initialRoles,
        once_password: false,
        patient: '',
        doctor: '',
        home: '',
        homeGroup: '',
        lastname: '',
        firstname: '',
        title: '',
        salutation: '',
        chargeable: 0,
        submitAble: true,
        modifiedAt: '',
        modifiedFrom: '',
        createdAt: '',
        createdFrom: ''
    });
    const [homes, setHomes] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [homeGroups, setHomeGroups] = useState([]);
    const [validation, setValidation] = useState({error: false, errorMessage: {},});
    const [value, setValue] = useState({focusElement: '',});

    /**
     * QUERIES
     */
    const fetchData = async () => {
        try {
            let response = await axios.get(`/api/users/${params.id}`, {headers: {'x-auth-token': auth.token}});
            let homeuser = !!response.data.home;
            response.data.password = '';
            response.data.home && fetchHomeGroups(response.data.home);
            response.data.avatar = '';
            // response.data.homeGroup = response.data.homeGroup ? response.data.homeGroup._id : '';
            setState({...state, ...response.data, homeuser, createdAt: moment(response.data.createdAt), modifiedAt: moment(response.data.modifiedAt)});
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchHomes = async () => {
        try {
            let response = await axios.get(`/api/homes`, {headers: {'x-auth-token': auth.token}});
            setHomes(response.data.data);

            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchDoctors = async () => {
        try {
            let response = await axios.get(`/api/doctors`, {headers: {'x-auth-token': auth.token}});
            setDoctors(response.data.data);

            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchHomeGroups = async (homeId) => {
        try {
            let response = await axios.get(`/api/homes/${homeId}/groups`, {headers: {'x-auth-token': auth.token}});
            setHomeGroups(response.data);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const checkContract = async (home, doctor) => {
        try {
            let response = await axios.get(`/api/contracts/has` + (home ? `?home=${home}`: (doctor ? `?doctor=${doctor}` : '')), {headers: {'x-auth-token': auth.token}});
            let newState = {
                ...state,
            };
            newState.chargeable = response.data ? 0 : 1
            newState.home = home ? home : null;
            newState.doctor = doctor ? doctor : null;
            setState(newState);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchHomes();
        fetchDoctors();
        if (params.hasOwnProperty('id')) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const change = (e, data) => {
        let newState = Object.assign({}, data);
        switch (e.target.type) {
            case 'checkbox':
                newState[e.target.name] = e.target.checked;
                break;
            case 'file':
                newState[e.target.name] = e.target.files[0];
                break;
            case 'radio':
            default:
                newState[e.target.name] = e.target.value;
                break;
        }
        setState(newState);
        return newState;
    };

    const homeChange = (e) => {
        checkContract(e.target.value, null);
        if (e.target.value === '') {
            setState({ ...state, [e.target.name]: e.target.value, homeGroup: '', });
            setHomeGroups([]);
        }
        else {
            setState({ ...state, [e.target.name]: e.target.value });
            fetchHomeGroups(e.target.value);
        }
    };

    const doctorChange = (e) => {
        const index = doctors.findIndex((item) => {
            return item._id === e.target.value
        });

        const doctor = doctors[index];

        checkContract(null, e.target.value);
        setState({ ...state, [e.target.name]: e.target.value, submitAble: doctor.email});
    };

    const focusChange = (e) => {
        setValue({
            ...value,
            'focusElement': e.target.id
        });
    };

    const rolesChange = (e) => {
        let newState = Object.assign({}, state);
        if (e.target.checked && !newState.roles.includes(e.target.value)) {
            newState.roles.push(e.target.value);
            setState(newState);
        }
        else if (!e.target.checked && newState.roles.includes(e.target.value)) {
            newState.roles = newState.roles.filter(item => item !== e.target.value);
            setState(newState);
        }
    };

    const validate = (e, subFormValidation) => {
        // saved validation is only required for form output, because we validates the form complete again, so we use an empty validation!!!
        let newValidation = {};
        let requiredFields = {
            'username': 'Nutzername',
        };
        newValidation = checkRequiredFields(newValidation, requiredFields, state);
        if (subFormValidation !== undefined && subFormValidation['error']) {
            newValidation['error'] = true;
            newValidation['errorMessage'] = Object.assign(
                newValidation['errorMessage'],
                subFormValidation['errorMessage']
            );
        }
        if (state.roles.length === 0) {
            newValidation.error = true;
            newValidation.errorMessage['roles'] = "Es muss mindestens eine Rolle ausgewählt werden.";
        }
        // else {
        //     let errorMessage = newValidation.errorMessage;
        //     delete (errorMessage['roles']);
        //     newValidation['errorMessage'] = {
        //         ...errorMessage,
        //     };
        //     newValidation['error'] = !isEmptyObject(errorMessage);
        // }
        setValidation(newValidation);

        // call validate function of subform
        // e is given if called by callback
        if (e !== undefined && 'current' in parentRef && 'onValidate' in parentRef.current) {
            parentRef.current.onValidate();
        }

        return !newValidation.error;
    };

    /**
     * SUBMIT
     */
    const submit = async (e) => {
        e.preventDefault();
        if (validation && validation['error']) {
            return false;
        }
        let data = {...state};

        try {
            if (params.hasOwnProperty('id') && params.type && params.type === 'loeschen') {
                await axios.delete(`/api/users/${params.id}`, {headers: {'x-auth-token': auth.token}});
                toast.success('Nutzer gelöscht.');
            }
            else if (params.hasOwnProperty('id') && params.type && params.type === 'reaktivieren') {
                data.status = '';
                await axios.put(`/api/users/${params.id}`, data, {headers: {'x-auth-token': auth.token}});
                toast.success('Nutzer reaktiviert.');
            }
            else if (params.hasOwnProperty('id')) {
                await axios.put(`/api/users/${params.id}`, data, {headers: {'x-auth-token': auth.token}});
                toast.success('Nutzer geändert.');
            }
            else {
                await axios.post('/api/users', data, {headers: {'x-auth-token': auth.token}});
                toast.success('Nutzer gespeichert.');
            }

            history.push('/nutzer');
        } catch (err) {
            handleErrors(err, setAuth, history);
        }

        return false;
    };

    const subFormEdit = (e, aPage, object) => {
        let newState = Object.assign({}, state);
        switch (aPage) {
            case 'bearbeiten':
                if (object !== undefined) {
                    newState.password = object.password;
                    newState.password_confirm = object.password_confirm;
                    newState.once_password = object.once_password;
                }
                break;
            case 'cancel':
            default:
                break;
        }
        setState(newState);
    };

    let parentRef = React.useRef(state);

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Link to='/nutzer'>Nutzer</Link>
                <Typography color='textPrimary'>
                    {params.hasOwnProperty('id')
                        ? (params.type
                            ? (params.type === 'loeschen' ? 'Nutzer löschen' : 'Nutzer bearbeiten')
                            : (params.type === 'reaktivieren' ? 'Nutzer reaktivieren' : 'Nutzer bearbeiten')
                          )
                        : 'Neuer Nutzer'}
                </Typography>
            </Breadcrumbs>
            <form onSubmit={(e) => submit(e)}>
                <Card>
                    <CardContent>
                        <Typography component='h1' color='textSecondary' gutterBottom>
                            {params.hasOwnProperty('id')
                                ? (params.type
                                        ? (params.type === 'loeschen' ? 'Nutzer löschen' : 'Nutzer bearbeiten')
                                        : (params.type === 'reaktivieren' ? 'Nutzer reaktivieren' : 'Nutzer bearbeiten')
                                  )
                                : 'Neuer Nutzer'}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={5} sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled={params.type && (params.type === 'loeschen' || params.type === 'reaktivieren')}
                                            error={!!validation.errorMessage.username}
                                            // formHelperTextProps={{focused: true}}
                                            fullWidth
                                            helperText={validation.errorMessage.username || ''} //'Name des Nutzers. Meist vorname.nachname.'}
                                            id='username'
                                            label='Nutzername'
                                            name='username'
                                            onBlur={validate}
                                            onChange={(e) => setState(change(e, state))}
                                            placeholder='vorname.nachname'
                                            required
                                            value={state && state.username}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id='salutation-label'>Anrede</InputLabel>
                                            <Select
                                                disabled={params.type && (params.type === 'loeschen' || params.type === 'reaktivieren')}
                                                labelId='salutation-label'
                                                id='salutation'
                                                name='salutation'
                                                value={state ? state.salutation : ''}
                                                onChange={(e) => { change(e, state); }}
                                            >
                                                <MenuItem value={''}>Nicht angegeben</MenuItem>
                                                <MenuItem value={'Herr'}>Herr</MenuItem>
                                                <MenuItem value={'Frau'}>Frau</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && (params.type === 'loeschen' || params.type === 'reaktivieren')}
                                                id='title'
                                                name='title'
                                                value={state && state.title}
                                                onChange={(e) => change(e, state)}
                                                label='Titel'
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && (params.type === 'loeschen' || params.type === 'reaktivieren')}
                                                id='firstname'
                                                value={state && state.firstname}
                                                name='firstname'
                                                onChange={(e) => change(e, state)}
                                                label='Vorname'
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && (params.type === 'loeschen' || params.type === 'reaktivieren')}
                                                id='lastname'
                                                name='lastname'
                                                value={state && state.lastname}
                                                onChange={(e) => change(e, state)}
                                                label='Nachname'
                                            />
                                        </FormControl>
                                    </Grid>
                                    {state.createdFrom && (
                                        <Grid item xs={12}>
                                            <div className={classes.modified}>
                                                <div>Am {state.createdAt.format('DD.MM.YYYY')} erstellt von {state.createdFrom.username}</div>
                                                <div>Am {state.modifiedAt.format('DD.MM.YYYY')} geändert von {state.modifiedFrom.username}</div>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item lg={12}>
                                        <FormControl
                                            error={!!validation.errorMessage.roles}
                                            disabled={params.type === 'loeschen' || params.type === 'reaktivieren'}
                                            fullWidth
                                            required
                                        >
                                            <FormLabel component="legend">Rollen</FormLabel>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state && state.roles && state.roles.includes('ROLE_ADMIN')}
                                                            color='default'
                                                            id='role_admin'
                                                            name='roles[]'
                                                            value='ROLE_ADMIN'
                                                            onChange={(e) => rolesChange(e)}
                                                            onFocus={(e) => focusChange(e)}
                                                            disabled={params.type === 'loeschen' || params.type === 'reaktivieren' || (!auth.roles.includes('ROLE_ADMIN') && 'disabled')}
                                                        />
                                                    }
                                                    label='Administrator'
                                                    style={{'marginLeft': '10px'}}
                                                    title='Darf alles.'
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state && state.roles && state.roles.includes('ROLE_MANAGER')}
                                                            color='default'
                                                            id='role_manager'
                                                            name='roles[]'
                                                            value='ROLE_MANAGER'
                                                            onChange={(e) => rolesChange(e)}
                                                            onFocus={(e) => focusChange(e)}
                                                            disabled={params.type === 'loeschen' || params.type === 'reaktivieren' || !(auth.roles.includes('ROLE_ADMIN') || (auth.roles.includes('ROLE_MANAGER') && auth.roles.includes('ROLE_PHARMA')))}
                                                        />
                                                    }
                                                    label='Manager'
                                                    style={{'marginLeft': '10px'}}
                                                    title='Darf Nutzer verwalten.'
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state && state.roles && state.roles.includes('ROLE_PHARMA')}
                                                            color='default'
                                                            id='role_pharma'
                                                            name='roles[]'
                                                            value='ROLE_PHARMA'
                                                            onChange={(e) => rolesChange(e)}
                                                            onFocus={(e) => focusChange(e)}
                                                            disabled={params.type === 'loeschen' || params.type === 'reaktivieren' || !(auth.roles.includes('ROLE_ADMIN') || (auth.roles.includes('ROLE_MANAGER') && auth.roles.includes('ROLE_PHARMA')))}
                                                        />
                                                    }
                                                    label='Apotheke'
                                                    style={{'marginLeft': '10px'}}
                                                    title='Mitarbeiter in der Apotheke.'
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state && state.roles && state.roles.includes('ROLE_HOME')}
                                                            color='default'
                                                            id='role_home'
                                                            name='roles[]'
                                                            value='ROLE_HOME'
                                                            onChange={(e) => rolesChange(e)}
                                                            onFocus={(e) => focusChange(e)}
                                                            disabled={params.type === 'loeschen' || params.type === 'reaktivieren' || !(auth.roles.includes('ROLE_ADMIN') || (auth.roles.includes('ROLE_MANAGER') && auth.roles.includes('ROLE_PHARMA')))}
                                                        />
                                                    }
                                                    label='Heim'
                                                    style={{'marginLeft': '10px'}}
                                                    title='Mitarbeiter in der Pflegeeinrichtung.'
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state && state.roles && state.roles.includes('ROLE_DOCTOR')}
                                                            color='default'
                                                            id='role_doctor'
                                                            name='roles[]'
                                                            value='ROLE_DOCTOR'
                                                            onChange={(e) => rolesChange(e)}
                                                            onFocus={(e) => focusChange(e)}
                                                            disabled={params.type === 'loeschen' || params.type === 'reaktivieren' || !(auth.roles.includes('ROLE_ADMIN') || (auth.roles.includes('ROLE_MANAGER') && auth.roles.includes('ROLE_PHARMA')))}
                                                        />
                                                    }
                                                    label='Arztpraxis'
                                                    style={{'marginLeft': '10px'}}
                                                    title='Mitarbeiter in der Arztpraxis.'
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state && state.roles && state.roles.includes('ROLE_DRIVER')}
                                                            color='default'
                                                            id='role_driver'
                                                            name='roles[]'
                                                            value='ROLE_DRIVER'
                                                            onChange={(e) => rolesChange(e)}
                                                            onFocus={(e) => focusChange(e)}
                                                            disabled={params.type === 'loeschen' || params.type === 'reaktivieren' || !(auth.roles.includes('ROLE_ADMIN') || (auth.roles.includes('ROLE_MANAGER') && auth.roles.includes('ROLE_PHARMA')))}
                                                        />
                                                    }
                                                    label='Fahrer'
                                                    style={{'marginLeft': '10px'}}
                                                    title='Fahrer oder Mitarbeiter des Kurierdienstes.'
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state && state.roles && state.roles.includes('ROLE_ACCOUNTING')}
                                                            color='default'
                                                            id='role_accounting'
                                                            name='roles[]'
                                                            value='ROLE_ACCOUNTING'
                                                            onChange={(e) => rolesChange(e)}
                                                            onFocus={(e) => focusChange(e)}
                                                            disabled={params.type === 'loeschen' || params.type === 'reaktivieren' || !(auth.roles.includes('ROLE_ADMIN') || (auth.roles.includes('ROLE_MANAGER') && auth.roles.includes('ROLE_PHARMA')))}
                                                        />
                                                    }
                                                    label='Buchaltung'
                                                    style={{'marginLeft': '10px'}}
                                                    title='Mitarbeiter für Steuerangelegenheiten'
                                                />
                                            </FormGroup>
                                            <FormHelperText>{validation.errorMessage.roles}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={4} md={4} sm={8} xs={12}>
                                {
                                    <PasswordForm
                                        disabled={params.type === 'loeschen' || params.type === 'reaktivieren'}
                                        password_required={!params || !params.id}
                                        generate_password
                                        once_password
                                        minLength={12}
                                        minLowercaseCount={2}
                                        minUppercaseCount={2}
                                        minNumbersCount={2}
                                        minSpecialcharsCount={2}
                                        maxLength={4096}
                                        forbiddenchars={true}
                                        parentRef={parentRef}
                                        onValidate={validate}
                                        subState={{
                                            password: '',
                                            password_confirm: '',
                                            once_password: false,
                                            showPassword: false,
                                        }}
                                        onSave={subFormEdit}
                                    />
                                }
                            </Grid>
                        </Grid>
                        {state && state.roles && state.roles.includes('ROLE_DOCTOR') && (
                            <Fragment>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id='doctor-label'>Arzt</InputLabel>
                                        <Select
                                            disabled={params.type && (params.type === 'loeschen' || params.type === 'reaktivieren')}
                                            labelId='doctor-label'
                                            id='doctor'
                                            name='doctor'
                                            value={state && state.doctor}
                                            onChange={(e) => { doctorChange(e); }}
                                        >
                                            <MenuItem value={''}>Nicht angegeben</MenuItem>
                                            {doctors.map((doctor, i) => (
                                                <MenuItem key={i} value={doctor._id}>
                                                    {doctor.salutation} {doctor.title} {doctor.firstname} {doctor.lastname}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {!state.submitAble && (<div className={classes.warning2}>Um einen Nutzer eine Arztpraxis zuzuweisen muss die Arztpraxis eine eMail Adresse besitzen.</div>)}
                                </Grid>
                            </Fragment>
                        )}
                        {state && state.roles && state.roles.includes('ROLE_HOME') && (
                            <Fragment>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id='home-label'>Pflegeeinrichtung</InputLabel>
                                        <Select
                                            disabled={params.type && (params.type === 'loeschen' || params.type === 'reaktivieren')}
                                            labelId='home-label'
                                            id='home'
                                            name='home'
                                            value={state && state.home}
                                            onChange={(e) => { homeChange(e); }}
                                        >
                                            <MenuItem value={''}>Nicht angegeben</MenuItem>
                                            {homes.map((home, i) => (
                                                <MenuItem key={i} value={home._id}>
                                                    {home.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {state && state.home && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id='homeGroup-label'>Wohnbereich</InputLabel>
                                            <Select
                                                disabled={params.type && (params.type === 'loeschen' || params.type === 'reaktivieren')}
                                                labelId='homeGroup-label'
                                                id='homeGroup'
                                                name='homeGroup'
                                                value={state && state.homeGroup}
                                                onChange={(e) => { change(e, state); }}
                                            >
                                                <MenuItem value={''}>Nicht angegeben</MenuItem>
                                                {homeGroups.map((group, i) => (
                                                    <MenuItem key={i} value={group._id}>
                                                        {group.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                            </Fragment>
                        )}
                        {((state && state.home) || (state && state.roles && state.roles.includes('ROLE_DOCTOR'))) && (
                            <Fragment>
                                {state.chargeable !== 0 && (!params.type || (params.type && (params.type !== 'loeschen' && params.type !== 'reaktivieren'))) && (
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state && state.chargeable !== 1}
                                                    color='default'
                                                    id='chargeable'
                                                    name='chargeable'
                                                    onChange={(e) => change(e, state)}
                                                    value={2}
                                                />
                                            }
                                            label={`Mit dem Anlegen des Nutzers erhält ${state.home && state.home.length > 0 ? 'die Pflegeeinrichtung' : ''}${state.doctor && state.doctor.length > 0 ? 'der Arzt' : ''} Zugriff. Das ist kostenpflichtig.`}
                                            className={classes.warning}
                                        />
                                    </Grid>
                                )}
                            </Fragment>
                        )}
                    </CardContent>
                    <Grid container justify='center'>
                        <Typography className={classes.warning2}>
                            Der Benutzer kann nicht gelöscht werden, sondern wird deaktiviert. Zu Dokumentationszwecken bleiben die Daten des Benutzers erhalten, werden aber in der Abrechnung nicht mehr berücksichtigt. Achtung! Ein deaktivierter Nutzer kann das System nicht mehr benutzen, bis er wieder aktiviert wird.
                        </Typography>
                    </Grid>
                    <CardActions>
                        <Grid container justify='space-between'>
                            <Grid item xs={12} sm={3} md={2} xl={1}>
                                <Button
                                    color='primary'
                                    component={Link}
                                    fullWidth
                                    startIcon={<ImCancelCircle/>}
                                    to='/nutzer'
                                    variant='outlined'
                                >
                                    Abbrechen
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} xl={1}>
                                {params.type && params.type === 'loeschen'
                                    ? (
                                        <Button
                                            color='secondary'
                                            endIcon={<MdDelete/>}
                                            fullWidth
                                            type='submit'
                                            variant='contained'
                                        >
                                            Löschen
                                        </Button>
                                      )
                                    : (
                                        params.type === 'reaktivieren'
                                        ? (
                                            <Button
                                                color='secondary'
                                                endIcon={<MdDelete/>}
                                                fullWidth
                                                type='submit'
                                                variant='contained'
                                            >
                                                Reaktivieren
                                            </Button>
                                          )
                                        : (
                                            <Button
                                                color='primary'
                                                disabled={state.chargeable === 1 || !state.submitAble}
                                                endIcon={<MdSend/>}
                                                fullWidth
                                                type='submit'
                                                variant='contained'
                                            >
                                                Speichern
                                            </Button>
                                          )
                                      )
                                }
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </form>
        </Fragment>
    );
};

export default UserForm;

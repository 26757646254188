/* jshint esversion: 8 */
const escapeStringRegexp = require('escape-string-regexp');

export class Password {

    static passwordCharacters = {
        specials: '-°^!²§³$%&/{([)]=}?\\`´@€+*~#<>|µ,;.:_ £',
        // important use '-' to the beginning of string, otherwise additionally chars can be found
        lowercase: 'abcdefghijklmnopqrstuvwxyzäöüß',
        uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ',
        numbers: '0123456789',                                  // not 0 because risk of confusion
        forbidden: '"\'',                                       // to avoid problems
    };

    static generatePassword(minLength = 12, minLowercaseCount = 2, minUppercaseCount = 2, minNumbersCount = 2, minSpecialcharsCount = 2) {
        let counts = [];
        counts[0] = minSpecialcharsCount;
        counts[1] = minLowercaseCount;
        counts[2] = minUppercaseCount;
        counts[3] = minNumbersCount;

        function getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        }

        let password = "";
        while (password.length < minLength) {
            let type = getRandomInt(counts.length);
            if (counts[type] > 0 || counts.join("") === "0000") {
                password += this.passwordCharacters[Object.keys(this.passwordCharacters)[type]][Math.floor(
                    Math.random() * (Object.keys(this.passwordCharacters)[type].length))];
                if (counts[type] > 0) {
                    counts[type]--;
                }
            }
        }

        return password;
    };

    /**
     * check @p password meets the criteria given by next parameters.
     *
     * @param password
     * @param minLength if > 0 password must have at least @p minLength characters
     * @param minLowercaseCount if > 0 password must have at least @p minLowercaseCount lowercase characters
     * @param minUppercaseCount if > 0 password must have at least @p minUppercaseCount uppercase characters
     * @param minNumbersCount if > 0 password must have at least @p minNumbersCount numbers
     * @param minSpecialcharsCount if > 0 password must have at least @p minSpecialcharsCount special characters
     * @param forbiddenchars if not false password can not have @p forbiddenchars or '"\'' if @p forbiddenchars is true
     *
     * @returns {*[]} an array of errors or empty array if check was successfully
     */
    static checkPasswordComplexity(password, minLength = 12, minLowercaseCount = 2, minUppercaseCount = 2, minNumbersCount = 2, minSpecialcharsCount = 2, forbiddenchars = false) {
        let errors = [];

        let allPasswordCharacters = escapeStringRegexp(this.passwordCharacters['specials']);
        for (let c = 1; c < (Object.keys(this.passwordCharacters).length - 1); c++) {
            allPasswordCharacters += this.passwordCharacters[Object.keys(this.passwordCharacters)[c]];
        }
        if (typeof forbiddenchars === 'boolean') {
            if (!forbiddenchars) {
                allPasswordCharacters += escapeStringRegexp(this.passwordCharacters['forbidden']);
                forbiddenchars = '';
            }
            else {
                forbiddenchars = escapeStringRegexp(this.passwordCharacters['forbidden']);
            }
        }
        else {
            this.passwordCharacters['forbidden']= forbiddenchars;
        }

        let regExp = new RegExp('[^' + allPasswordCharacters + ']', 'g');
        let matches = password.match(regExp);
        if (matches != null && matches.length > 0) {
            errors.push({key: 'not_allowed_keys', message: `Unerlaubte Zeichen gefunden! ${matches.join("")} .`, deletable: false});
        }

        if (minLowercaseCount > 0) {
            regExp = new RegExp('[' + this.passwordCharacters['lowercase'] + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minLowercaseCount) {
                const str = matches != null && matches.length > 0 ? matches.join(",") : '';
                errors.push({keys: 'require_lower_char', message: `Weniger als  ${minLowercaseCount} kleine Buchstaben! Gefunden ${str}`, deletable: false});
            }
        }

        if (minUppercaseCount > 0) {
            regExp = new RegExp('[' + this.passwordCharacters['uppercase'] + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minUppercaseCount) {
                const str = matches != null && matches.length > 0 ? matches.join(",") : '';
                errors.push({keys: 'require_upper_char', message: `Weniger als  ${minUppercaseCount} große Buchstaben! Gefunden: ${str}`, deletable: false});
            }
        }

        if (minNumbersCount > 0) {
            regExp = new RegExp('[' + this.passwordCharacters['numbers'] + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minNumbersCount) {
                const str = matches != null && matches.length > 0 ? matches.join(",") : '';
                errors.push({keys: 'require_number', message: `Weniger als  ${minNumbersCount} Zahlen! Gefunden: ${str}`, deletable: false});
            }
        }

        if (minSpecialcharsCount > 0) {
            regExp = new RegExp('[' + escapeStringRegexp(this.passwordCharacters['specials']) + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minSpecialcharsCount) {
                const str = matches != null && matches.length > 0 ? matches.join(",") : '';
                errors.push({keys: 'require_special_chars', message: `Weniger als  ${minSpecialcharsCount} Sonderzeichen! Gefunden: ${str}`, deletable: false});
            }
        }
        return errors;
    };
}

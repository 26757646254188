import moxiosDb from "./db";
import sign from "jwt-encode";

import { makeMockxiosId } from "./maxiosUtils";
import {rand} from "@utils/utils";
import Migrator from "./migrator"
import axios from "axios";

const getResponses = {
    '/api/v2/refresh': (url, params, data, query, config) => {
        return {token: config.headers['x-auth-token'], success: true, mock: true};
    }
};
const postResponses = {
    '/api/v2/auth': (url, params, data, query) => {
        const users = moxiosDb['/api/users'];
        let jwt = null;

        const index = users.findIndex((item) => { return item.username.toLowerCase() === data.username.toLowerCase(); });

        if(index !== -1) {
            const user = users[index];
            const item = {
                roles: user.roles,
                iat: 1516239022,
                exp: 4796668800,
                iss: "medicharge",
                uid: user._id,
                sub: '61f11646f36bd0590e8a15aa'
            };

            jwt = sign(item, '');
        }

        return {token: jwt, success: true, mock: true};
    },
    '/api/doctors/offices': (url, params, data, query) => {
        const item = {
            _id: makeMockxiosId(),
            name: data.organisation.name,
            street: data.organisation.street,
            zip: data.organisation.zip,
            city: data.organisation.city,
            image: '',
            contact_type: data.organisation.contact_type,
            mail: data.organisation.mail ? data.organisation.mail : '',
            phone: data.organisation.phone ? data.organisation.phone : '',
            fax: data.organisation.fax ? data.organisation.fax : ''
        }
        addToStorage('/api/doctors/offices', item);
    },
    '/api/users': (url, params, data, query) => {
        const item = {
            _id: makeMockxiosId(),
            username: data.user.username,
            password: data.user.password,
            salutation: data.user.salutation,
            title: data.user.title ? data.user.title : '',
            once_password: !!data.user.once_password,
            firstname: data.user.firstname ? data.user.firstname : '',
            lastname: data.user.lastname ? data.user.lastname : '',
            roles: data.user.roles ? data.user.roles : [],
        };

        addToStorage('/api/users', item);
    }
};

const deleteResponses = {};
const patchResponses = {};
const putResponses = {};

const routeMap = [
    new RegExp('(/api/patients/([^/]+)/medicine)'),
    new RegExp('(/api/contracts/([^/]+)/adoption/accept)'),
    new RegExp('(/api/contracts/([^/]+)/adoption/reject)'),
    new RegExp('(/api/contracts/([^/]+)/adoption/withdraw)'),
    new RegExp('(/api/contracts/([^/]+)/adoption)'),
    new RegExp('(/api/contracts/([^/]+))'),
    new RegExp('(/api/auth/masquerade/([^/]+)$)'),
    new RegExp('(/api/invoices/download/([^/]+)$)'),
    new RegExp('(/api/tasks)/([^/]{24})/events/([^/]{24})/attachment/([^/]+)$'),
    new RegExp('(/api/doctors/offices)/?(.+)?'),
    new RegExp('(/api/doctors)/?(.+)?'),
    new RegExp('(/api/homes)/?(.+)?'),
    new RegExp('(/api/users)/(.+)/profile/documents'),
    new RegExp('(/api/users)/details/?(.+)?'),
    new RegExp('(/api/users)/bearbeiten/?(.+)?'),
    new RegExp('(/api/users)/?(.+)?'),
    new RegExp('(/api/medicine)/?(.+)?'),
    new RegExp('(/api/users)/([^/]{24})$'),
    new RegExp('(/api/tasks)/(.+)/events'),
    new RegExp('(/api/tasks)/pickup'),
    new RegExp('(/api/tasks)/([^/]{24})$'),
    new RegExp('(/api/tasks)/(.+)/seen'),
    new RegExp('(/api/events)/(.+)/seen'),
];

const getStorage = (key) => {
    try {
        let collection =  JSON.parse(localStorage.getItem('mockxios'));

        if(!collection) {
            collection = moxiosDb;
            setStorage(collection);
        }

        if(key) {
            return collection[key] ? collection[key] : null;
        }

        return collection;
    } catch(err) {
        return {};
    }
}

const addToStorage = (path, item) => {
    let collection = getStorage();

    if(!collection[path]) { collection[path] = []; }

    collection[path].push(item);

    setStorage(collection);
}

const getRouteData = (url) => {
    const explode = url.split('?');
    url = explode[0];
    let route = explode[0];
    let params = [];
    let query = explode[1] ? `?${explode[1]}` : '';

    for(const map of routeMap) {
        const match = url.match(map);

        if(match) {
            route = match[0] ? match[0] : url;

            for(let ii = 2; ii < match.length; ii++) {
                params.push(match[ii]);
                route = route.replace(`/${match[ii]}`, '');
            }

            break;
        }
    }

    return {
        url: route,
        params: params,
        query: query
    }
}

const setStorage = (data) => {
    localStorage.setItem('mockxios', JSON.stringify(data));
}

const getMock = async (type, _url, data, config) => {
    let collection;
    switch (type) {
        case 'get':
            collection = getResponses;
            break;
        case 'delete':
            collection = deleteResponses;
            break;
        case 'put':
            collection = putResponses;
            break;
        case 'post':
            collection = postResponses;
            break;
        case 'patch':
            collection = patchResponses;
            break;
        default:
            collection = {};
    }

    if (_url.indexOf('/api/v3/') !== -1) {
        const url = _url.replace('/api/v3/', '/api/');
        if(['post', 'put'].indexOf(type) !== -1) {
            return await axios[type](url, data, config);
        } else {
            return await axios[type](url, config);
        }

    } else {
        const {url, params, query} = getRouteData(_url);
        let response;

        if (Migrator[url] && Migrator[url][type]) {
            response = await Migrator[url][type](data, params, query, config);
            if (!(response instanceof Blob)) {
                response = {success: true, ...response};
            }
        } else if (!collection[url]) {
            if (type === 'get') {
                response = getStorage(url, query);

                if (!response) {
                    response = [];
                }

                //find by Id
                if (params[0]) {
                    const index = response.findIndex((item) => item._id === params[0]);
                    if (index !== -1) {
                        response = response[index];
                    }
                } else {
                    response = {
                        max: response.length,
                        items: response
                    }
                }
            } else if (type === 'put') {
                const storage = getStorage();

                //update by id
                if (params[0]) {
                    const index = storage[url].findIndex((item) => item._id === params[0]);
                    const item = storage[url][index];
                    storage[url][index] = {...item, ...data};
                    setStorage(storage);
                }

                response = {success: true, _id: params[0], mock: true};
            } else if (type === 'delete') {
                const storage = getStorage();

                //delete by id
                if (params[0]) {
                    const index = storage[url].findIndex((item) => item._id === params[0]);
                    storage[url].splice(index, 1);
                    setStorage(storage);
                }

                response = {success: true, mock: true};
            } else {
                response = {success: true, mock: true}
            }
        } else {
            response = collection[url](url, params, data, query, config);

            if (!response && (type === 'post' || type === 'put' || type === 'delete')) {
                response = {success: true, mock: true};
            }
        }

        return {
            data: response
        };
    }
}

const logRequest = (url, method, duration) => {
    let performance = localStorage.getItem('performance');

    if(!window.browserId) {
        window.browserId = rand();
    }

    if(!performance) {
        performance = {request: [], id: window.browserId, path: window.location.pathname}
    } else {
        performance = JSON.parse(performance);

        if(performance.id !== window.browserId || performance.path !== window.location.pathname) {
            performance = {request: [], id: window.browserId, path: window.location.pathname}
        }
    }

    performance.request.push({
        route: url,
        method: method,
        duration: duration
    });
    localStorage.setItem('performance', JSON.stringify(performance));
}

const requestMock = {
    get: async (url, config) => {
        const start = (new Date()).getTime();
        const response = await getMock('get', url, null, config);
        logRequest(url, 'get', (new Date()).getTime() - start);
        return response;
    },
    delete: async (url, config) => {
        const start = (new Date()).getTime();
        const response = await getMock('delete', url, null, config);
        logRequest(url, 'get', (new Date()).getTime() - start);
        return response;
    },
    post: async (url, data, config) => {
        const start = (new Date()).getTime();
        const response = await getMock('post', url, data, config);
        logRequest(url, 'get', (new Date()).getTime() - start);
        return response;
    },
    patch: async (url, data, config) => {
        const start = (new Date()).getTime();
        const response = await getMock('patch', url, data, config);
        logRequest(url, 'get', (new Date()).getTime() - start);
        return response;
    },
    put: async (url, data, config) => {
        const start = (new Date()).getTime();
        const response = await getMock('put', url, data, config);
        logRequest(url, 'get', (new Date()).getTime() - start);
        return response;
    }
}

export default requestMock;
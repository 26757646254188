/* jshint esversion: 8 */
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TableContainer,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {AiOutlineDelete, AiOutlineUserAdd} from 'react-icons/ai';
import {FiFilter, MdOutlineMarkEmailRead} from "react-icons/all";
import {BiSortAZ, BiSortZA} from 'react-icons/bi';
import {FiEdit2} from 'react-icons/fi';
import {Link, useLocation} from 'react-router-dom';
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import McPagination from "../components/Pagination";
import AuthContext from '../context/auth';
import {filterThemeStyles, serialize} from "../utils/filter";
import handleErrors from '../utils/handleErrors';
import {useRespTableThemeStyles} from "../utils/respTableTheme";
import {createUriSortParameters, initSorting} from "../utils/sorting";
import Loader from "../components/Loader";
import New from "../components/new";
import {hasPermission} from "@utils/utils";
import {makeStyles} from "@material-ui/core/styles";
import TCell from "../components/TCell";

const useStyles = makeStyles((theme) => ({
    table: {
        margin: '20px 0 0 0',
        '& th': {
            textAlign: 'left'
        }
    }
}));

const DoctorList = ({history}) => {
    /**
     * CSS
     */
    const classes = {
        ...useRespTableThemeStyles(),
        ...filterThemeStyles(),
        ...useStyles()
    };

    const {auth, setAuth} = useContext(AuthContext);
    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);

    /**
     * State
     */
    const [state, setState] = useState('loading');
    const [sort, setSort] = useState({});
    const [filter, setFilter] = useState({text: '', expertises: [], notSeen: !!query.get('filter[notSeen]') ? 1 : 0});
    const [filterShow, showFilter] = useState(!!query.get('filter[notSeen]'));
    const [expertises, setExpertises] = useState([]);
    const [loading, setLoading] = useState(true);


    const fetchData = async (page, limit) => {
        try {
            setLoading(true);
            page = page ? page : 1;
            const filterQuery = serialize({filter: filter});
            let response = await axios.get(`/api/doctors?${filterQuery}&${createUriSortParameters(sort)}&limit=${limit}&offset=${(page - 1) * limit}`, {
                headers: {'x-auth-token': auth.token},
            });
            setState(response.data.data);

            setLoading(false);
            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };
    const fetchExpertise = async () => {
        try {
            let response = await axios.get(`/api/doctors/expertises?limit=99999`, {headers: {'x-auth-token': auth.token}});
            setExpertises(response.data);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchExpertise();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * CONTROLS
     */
    const changeFilter = (e) => {
        setFilter({...filter, [e.target.name]: e.target.value});
    };

    /**
     * Functions
     */
    const changeFilterCheckbox = (e) => {
        if (e.target.checked) {
            setFilter({...filter, [e.target.name]: 1});
        } else {
            setFilter({...filter, [e.target.name]: 0});
        }
    }

    const setAllAsRead = async () => {
        try {
            await axios.put(`/api/statistics/doctor_read_all`, {}, {
                headers: {'x-auth-token': auth.token},
            });
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    }

    initSorting(sort, setSort, filter, setFilter);

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Typography color='textPrimary'>Ärzte</Typography>
            </Breadcrumbs>
            <Tooltip title='Filter anzeigen/verbergen' className={classes.filterButton}>
                <IconButton onClick={() => showFilter(!filterShow)} size='small'>
                    <FiFilter/>
                </IconButton>
            </Tooltip>
            <Grid container spacing={2}>
                {filterShow && (
                    <Grid item xs={12} className='filterWidget'>
                        <Card>
                            <CardContent>
                                <Typography component='h1' color='textSecondary' gutterBottom>
                                    Filter
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id='text-filter'
                                                label='Suche'
                                                name='text'
                                                onChange={changeFilter}
                                                value={filter.text}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} className={classes.filter}>
                                        <InputLabel id='expertises-filter'>Fachbereich</InputLabel>
                                        <Select
                                            fullWidth
                                            id='expertises-filter-select'
                                            labelId='expertises-filter'
                                            multiple
                                            name={'expertises'}
                                            onChange={changeFilter}
                                            value={filter.expertises}
                                        >
                                            {expertises.map((option) => (
                                                <MenuItem value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item sm={3} xs={12} className={classes.filter}>
                                        <Grid container spacing={2}>
                                            <Grid item sm={1}>
                                                <FormControl fullWidth>
                                                    <Checkbox
                                                        checked={filter.notSeen}
                                                        value={1}
                                                        name={'notSeen'}
                                                        onChange={changeFilterCheckbox}
                                                        color='primary'
                                                        label={'nur neue Anforderungen anzeigen'}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item sm={11}><span className={classes.filterChkLabel}>nur neue Ärzte anzeigen</span></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography component='h1' color='textSecondary' gutterBottom>
                                Ärzte
                            </Typography>
                            <Grid container justify='flex-end' spacing={2}>
                                {hasPermission(auth, 'doctor_seen_view') && (
                                <Grid xl={4} lg={5} md={6} sm={12} xs={12} item>
                                    <Button
                                        className={classes.markAsRead}
                                        color='primary'
                                        endIcon={<MdOutlineMarkEmailRead/>}
                                        fullWidth
                                        onClick={setAllAsRead}
                                        type='submit'
                                        variant='contained'
                                    >
                                        alle Ärzte als Gelesen markieren
                                    </Button>
                                </Grid>)}
                                {hasPermission(auth, 'doctor_create_view') && (
                                <Grid item xs={12} sm={4} md={3} xl={2}>
                                    <Button
                                        color='primary'
                                        component={Link}
                                        endIcon={<AiOutlineUserAdd/>}
                                        fullWidth
                                        to='/arzt/neu'
                                        variant='contained'
                                    >
                                        Arzt hinzufügen
                                    </Button>
                                </Grid>)}
                            </Grid>

                            {!loading && (
                                <TableContainer>
                                <Table className={classes.table}>
                                    <Thead>
                                        <Tr className={classes.tbodyTh}>
                                            {hasPermission(auth, 'doctor_column_title_view') && (
                                            <Th>Titel</Th>)}
                                            {hasPermission(auth, 'doctor_column_firstname_view') && (
                                            <Th data-field='firstname' className='filterable'>
                                                Vorname
                                                <BiSortAZ className='sort-icon asc'/>
                                                <BiSortZA className='sort-icon desc'/>
                                            </Th>)}
                                            {hasPermission(auth, 'doctor_column_lastname_view') && (
                                            <Th data-field='lastname' className='filterable'>
                                                Nachname
                                                <BiSortAZ className='sort-icon asc'/>
                                                <BiSortZA className='sort-icon desc'/>
                                            </Th>)}
                                            {hasPermission(auth, 'doctor_column_department_view') && (
                                            <Th>Fachbereich</Th>)}
                                            {hasPermission(auth, 'doctor_column_email_view') && (
                                            <Th>E-Mail</Th>)}
                                            {hasPermission(auth, 'doctor_column_fax_view') && (
                                            <Th>Fax</Th>)}
                                            {hasPermission(auth, 'doctor_column_action_view') && (
                                            <Th align='right'>Aktionen</Th>)}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {state === 'loading' ? (
                                            <Tr>
                                                <Td align='center' colSpan={7}>
                                                    Lade Daten...
                                                </Td>
                                            </Tr>
                                        ) : state.length === 0 ? (
                                            <Tr>
                                                <Td align='center' colSpan={7}>
                                                    Kein Daten vorhanden.
                                                </Td>
                                            </Tr>
                                        ) : (
                                            state.map((doctor, i) => (
                                                <Tr key={i} className={classes.tbodyTr}>
                                                    {hasPermission(auth, 'doctor_column_title_view') && (
                                                        <TCell clipboard={{data: doctor.title, help: `Titel kopieren`}}>
                                                        {doctor.title     && (<Link to={`/arzt/${doctor._id}`} title='Bearbeiten'>{doctor.title}</Link>)}
                                                        {!(doctor.seen && doctor.seen.includes(auth.user)) && (<New />)}
                                                    </TCell>)}
                                                    {hasPermission(auth, 'doctor_column_firstname_view') && (
                                                        <TCell clipboard={{data: doctor.firstname, help: `Vorname kopieren`}}>
                                                        {doctor.firstname && (<Link to={`/arzt/${doctor._id}`} title='Bearbeiten'>{doctor.firstname}</Link>)}
                                                        </TCell>)}
                                                    {hasPermission(auth, 'doctor_column_lastname_view') && (
                                                        <TCell clipboard={{data: doctor.lastname, help: `Nachname kopieren`}}>
                                                        {doctor.lastname  && (<Link to={`/arzt/${doctor._id}`} title='Bearbeiten'>{doctor.lastname}</Link>)}
                                                    </TCell>)}
                                                    {hasPermission(auth, 'doctor_column_department_view') && (
                                                        <TCell clipboard={{data: doctor.expertise, help: `Fachgebiet kopieren`}}>
                                                        {doctor.expertise && (<Link to={`/arzt/${doctor._id}`} title='Bearbeiten'>{doctor.expertise}</Link>)}
                                                        </TCell>)}
                                                    {hasPermission(auth, 'doctor_column_email_view') && (
                                                        <TCell clipboard={{data: doctor.email, help: `E-Mail kopieren`}}>
                                                        {doctor.email     && (<Link to={`/arzt/${doctor._id}`} title='Bearbeiten'>{doctor.email}</Link>)} </TCell>)}
                                                    {hasPermission(auth, 'doctor_column_fax_view') && (
                                                        <TCell clipboard={{data: doctor.fax, help: `Fax kopieren`}}>
                                                        {doctor.fax       && (<Link to={`/arzt/${doctor._id}`} title='Bearbeiten'>{doctor.fax}</Link>)} </TCell>)}
                                                    {hasPermission(auth, 'doctor_column_action_view') && (
                                                    <Td align='right'>
                                                        {hasPermission(auth, 'doctor_edit_view') && (
                                                        <Tooltip title='Bearbeiten'>
                                                            <IconButton
                                                                aria-label='bearbeiten'
                                                                color='primary'
                                                                component={Link}
                                                                size='small'
                                                                to={`/arzt/${doctor._id}`}
                                                            >
                                                                <FiEdit2/>
                                                            </IconButton>
                                                        </Tooltip>)}
                                                        {hasPermission(auth, 'doctor_delete_view') && (
                                                        <Tooltip title='Löschen'>
                                                            <IconButton
                                                                test = {`${doctor.firstname}`}
                                                                aria-label='löschen'
                                                                color='secondary'
                                                                component={Link}
                                                                size='small'
                                                                to={`/arzt/${doctor._id}/loeschen`}
                                                            >
                                                                <AiOutlineDelete/>
                                                            </IconButton>
                                                        </Tooltip>)}
                                                    </Td>)}
                                                </Tr>
                                            ))
                                        )}
                                    </Tbody>
                                </Table>
                            </TableContainer>)}
                            <McPagination fetch={fetchData} filter={filter}/>
                            {loading && (<Loader center size={'large'} color={'#5588a3'}/>)}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default DoctorList;

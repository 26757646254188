import {
    Card,
    CardContent, Checkbox,
    FormControl,
    Grid,
    TextField,
    Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {useState} from "react";
import {useRespTableThemeStyles} from "../../utils/respTableTheme";
import {filterThemeStyles} from "../../utils/filter";

const PickUpFilter = ({filter, changeFilter, filterData, ...args}) => {
    /**
     * CSS
     */
    const classes = {
        ...useRespTableThemeStyles(),
        ...filterThemeStyles()
    };

    /**
     * States
     */
    const [doctorAutoCompleteInput, setDoctorAutoCompleteInput] = useState('');
    const [homeAutoCompleteInput, setHomeAutoCompleteInput] = useState('');

    /**
     * Functions
     *
     */
    const buildDoctorName = (option) => {
        return `${option && (option.firstname || '')} ${
            option && (option.lastname || '')
        } ${
            option && ((option.expertise && ' (' + option.expertise + ')') || '')
        }`.trim();
    }

    const changeFilterCheckbox = (e) => {
        if (e.target.checked) {
            changeFilter({target: {name: e.target.name, value: 1}});
        } else {
            changeFilter({target: {name: e.target.name, value: 0}});
        }
    }

    return (
            <Card>
                <CardContent>
                    <Typography component='h1' color='textSecondary' gutterBottom>
                        Filter
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id='text-filter'
                                    label='Suche'
                                    name='text'
                                    onChange={changeFilter}
                                    value={filter.text}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.filter}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    getOptionLabel={(option) => option.name}
                                    id='home-filter-select'
                                    name='home'
                                    noOptionsText={'Lade Daten...'}
                                    inputValue={homeAutoCompleteInput}
                                    onChange={(e, v) => {
                                        changeFilter({
                                            target: {
                                                name: 'home',
                                                value: v ? v._id : ''
                                            }
                                        });

                                        setHomeAutoCompleteInput(v ? v.name : '');
                                    }}
                                    options={filterData.homes}
                                    renderInput={(params) => <TextField onChange={(e) => {
                                        setHomeAutoCompleteInput(e.target.value)
                                    }} {...params}
                                                                        label='Heime'/>}
                                    value={filter.home ? filter.home : null}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.filter}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    getOptionLabel={(option) => buildDoctorName(option)}
                                    id='doctor-filter-select'
                                    name='doctor'
                                    noOptionsText={'Lade Daten...'}
                                    inputValue={doctorAutoCompleteInput}
                                    onChange={(e, v) => {
                                        changeFilter({
                                            target: {
                                                name: 'doctor',
                                                value: v ? v._id : ''
                                            }
                                        });

                                        setDoctorAutoCompleteInput(v ? buildDoctorName(v) : '');
                                    }}
                                    options={[{firstname: '', lastname: ''}].concat(filterData.doctors)}
                                    renderInput={(params) => <TextField onChange={(e) => {
                                        setDoctorAutoCompleteInput(e.target.value)
                                    }} {...params}
                                                                        label='Arzt'/>}
                                    value={filter.doctor ? filter.doctor : null}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item sm={3} xs={12} className={classes.filter}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={1}>
                                            <FormControl fullWidth>
                                                <Checkbox
                                                    checked={filter.notSeen}
                                                    value={1}
                                                    name={'notSeen'}
                                                    onChange={changeFilterCheckbox}
                                                    color='primary'
                                                    label={'nur neue Abhohlaufträge anzeigen'}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={11}><span className={classes.filterChkLabel}>nur neue Abhohlaufträge anzeigen</span></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
    );
}

export default PickUpFilter;
/* jshint esversion: 8 */
import React, {Fragment, useContext, useEffect, useState} from "react";
import {Circles} from "react-loading-icons";
import AuthContext from "@context/AuthContext";
import {hasPermission} from "../../utils/utils";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const TableRowAction = React.memo(({permission, message, item, action, data, callback, onClick, color, children, ...args}) => {

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [state, setState] = useState(false);


    /**
     * Hooks
     */
    useEffect(() => {
        execute();

        // return () => {
        //     setState(state);
        // };
    }, [state]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const execute = async () => {
        if(state) {
            if(onClick) {
                const result = await onClick(data ? data : item, message, action);

                if(result && callback) {
                    callback(result);
                }

                setState(!state);
            }
        }
    };
    const click = async () => {
        setState(!state);
    };

    if(permission && !hasPermission(auth, permission)) {
        return <Fragment />;
    }

    return <div onClick={click} className={'action-loader-container'}>
        {!state && <Fragment>{children}</Fragment>}
        {state && (<Circles stroke={color} fill={color} className={'action-loader'} />)}
    </div>
})

TableRowAction.propTypes = {};

TableRowAction.defaultProps = {
    color: '#5588a3',
    action: 'update'
};

export default TableRowAction;
import axios from 'axios';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';
import { Breadcrumbs, Button, Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TaskTable from '../components/TaskTable';
import McPagination from "../components/Pagination";
import Loader from "../components/Loader";
import TaskFilter from "./filter/TaskFilter";
import {makeStyles} from "@material-ui/core/styles";

moment.locale('de');

const useStyles = makeStyles((theme) => ({
    filter: {
        margin: '0 0 15px 0'
    }
}));

const TaskManagement = ({ history, match: { params } }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    const { auth, setAuth } = useContext(AuthContext);

    const [patient, setPatient] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [limit, setLimit] = useState(50);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState({
        latestDelivery: 0,
        status: '',
        text: '',
        doctor: '',
        medicine: '',
        home: ''
    });

    const [patients, setPatients] = useState([]);

    const fetchPatients = async () => {
        try {
            let response = await axios.get(`/api/patients?limit=99999`, { headers: { 'x-auth-token': auth.token } });
            setPatients(response.data.data);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchPatient = async () => {
        try {
            if (params.hasOwnProperty('patientId')) {
                let response = await axios.get(`/api/patients/${params.patientId}`, {
                    headers: { 'x-auth-token': auth.token },
                });
                setPatient(response.data);
            }
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchTasks = async (page, olimit) => {
        try {
            if (patient !== null) {
                setLoading(true);
                const query = serialize({filter: {...filter, patient: patient._id}});
                const response = await axios.get(`/api/tasks?${query}&limit=${olimit || limit}&offset=${(page - 1) * limit}`, {
                    headers: { 'x-auth-token': auth.token },
                });

                setTasks(response.data.data);

                setLoading(false);
                return response.data.count;
            }

            return 0;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    useEffect(() => {
        fetchTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient, filter]);

    useEffect(() => {
        fetchPatients();
        fetchPatient();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changePatient = (event, value) => {
        setPatient(value);
    };

    const filterUpdate = (update) => {
        setFilter({...filter, ...update});
    }

    const serialize = (obj, prefix) => {
        let str = [],
            p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                let k = prefix ? prefix + "[" + p + "]" : p,
                    v = obj[p];

                if (v.length === 0) {
                    continue;
                }
                str.push((typeof v === "object") ?
                    serialize(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        return str.join("&");
    };

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Link to='/anforderungen'>Aufträge</Link>
                <Typography color='textPrimary'>Neuer Auftrag</Typography>
            </Breadcrumbs>
            <Card>
                <CardContent>
                    <Typography component='h1' color='textSecondary' gutterBottom>
                        {!params.hasOwnProperty('patientId')
                            ? 'Patient wählen'
                            : patient != null &&
                              `${patient.firstname} ${patient.lastname} (${moment(patient.dateOfBirth).format(
                                  'DD.MM.YYYY'
                              )})`}
                    </Typography>
                    {!params.hasOwnProperty('patientId') && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    getOptionLabel={(option) =>
                                        `${option.firstname} ${option.lastname} (${moment(option.dateOfBirth).format(
                                            'DD.MM.YYYY'
                                        )})${(option.home && ' - ' + option.home.name) || ''}${(option.homeGroup && ' / ' + option.homeGroup.name) || ''}`
                                    }
                                    id='patient'
                                    name='patient'
                                    noOptionsText={'Lade Daten...'}
                                    onChange={(e, v) => changePatient(e, v)}
                                    options={patients}
                                    renderInput={(params) => <TextField {...params} label='Patient' />}
                                />
                            </Grid>
                        </Grid>
                    )}

                    {patient && (
                        <Fragment>
                            <Grid className='mg-t-24' container justify='flex-end' spacing={2}>
                                <Grid item xl={2} lg={3} md={4} sm={5} xs={12}>
                                    <Button
                                        color='primary'
                                        component={Link}
                                        endIcon={<MdAdd />}
                                        fullWidth
                                        to={`/anforderung/neu/${patient && patient._id}`}
                                        variant='contained'
                                    >
                                        Bestellung hinzufügen
                                    </Button>
                                </Grid>
                            </Grid>
                            <Typography className='mg-t-24' component='h1' color='textSecondary' gutterBottom>
                                Letzte Aufträge
                            </Typography>

                            <Grid container spacing={2} className='mg-t-24'>
                                <Grid item xs={12}>
                                    <TaskFilter className={classes.filter} onChange={filterUpdate}/>
                                    {!loading && (<TaskTable auth={auth} hidePatientName={true} tasks={tasks} fetch={fetchTasks} />)}
                                    <McPagination fetch={fetchTasks} setLimit={setLimit}/>
                                    {loading && (<Loader center size={'large'} color={'#5588a3'}/>)}
                                </Grid>
                            </Grid>
                        </Fragment>
                    )}
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default TaskManagement;

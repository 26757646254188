/* jshint esversion: 8 */
import { Card, CardContent, CardMedia, Grid } from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useEffect, Fragment, useState } from 'react';
import mediChargelogo from '../assets/img/mediChargeLogo.png';
import Footer from '../components/Footer';
import AuthContext from '../context/auth';
import '../assets/scss/login.scss';
import { useLocation, useHistory } from 'react-router-dom';

const OAuthPage = () => {
    const code = new URLSearchParams(useLocation().search).get('code');
    const {setAuth } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const history = useHistory();

    useEffect(() => {
        async function login() {
            try {
                const response = await axios.post('/api/oauth', {
                    code: code,
                });

                localStorage.setItem('auth', JSON.stringify(response.data));
                setAuth(response.data);
                history.push('/');
            } catch (err) {
                setError(true);
            }
        }
        login();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='login-wrap'>
            <Grid container style={{ flexFlow: 'column wrap' }} alignItems='center'>
                <Grid item lg={4} style={{ marginTop: '5%' }}>
                    <Card style={{ padding: '10px', marginTop: '24px' }}>
                        <CardMedia
                            image={mediChargelogo}
                            title='MediCharge Logo'
                            style={{ minHeight: '100px', backgroundSize: 'contain' }}
                        />
                        <CardContent>
                            <Grid container justifyContent='center'>
                                <Grid item xs={12}>
                                    {!error ? (
                                        <Fragment>
                                            Anmeldung im System über Apomondo.
                                            <br /> <br />
                                            Kleinen Moment Geduld.
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            Fehler bei der Anmeldung.
                                            <br /> <br />
                                            Eventuell sind Sie nicht berechtigt auf diese
                                            <br />
                                            Resource zuzugreifen.
                                            <br />
                                            <br />
                                            Bitte prüfen Sie ihr Apomondo Abo.
                                        </Fragment>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={4} />
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <Footer />
                </Grid>
            </Grid>
        </div>
    );
};

export default OAuthPage;

import {useContext, useEffect, useMemo, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {io} from 'socket.io-client';
import '../assets/scss/dashboard.scss';
import Logout from '../components/Logout';
//import Sidebar from '../components/Sidebar';
import AuthContext from '../context/auth';
import SocketContext from '../context/socket';
import TaskContext from '../context/task';
import DoctorForm from '../forms/DoctorForm';
import HomeForm from '../forms/HomeForm';
import HomeGroupDeleteForm from '../forms/HomeGroupDeleteForm';
import ImportForm from "../forms/ImportForm";
import PatientForm from '../forms/PatientForm';
import ProfileForm from '../forms/ProfileForm';
import SettingsForm from '../forms/SettingsForm';
import TaskForm from '../forms/TaskForm';
import UserForm from "../forms/UserForm";
import CreateDocuments from '../views/CreateDocuments';
import DoctorList from '../views/DoctorList';
import HomeList from '../views/HomeList';
import PatientList from '../views/PatientList';
import PickUpList from '../views/PickUpList';
import TaskList from '../views/TaskList';
import TaskManagement from '../views/taskManagement';
import UserList from "../views/UserList";
import V3Router from "../_v3/Router";
import PatientDetails from "../views/PatientDetails";
import DashboardStatistics from "../views/Dashboard";
import SideBarV3 from "@views/parts/SideBar";
import TopNavV3 from '@views/parts/Navbar';
import Countdown from "@components/Countdown";
import moment from "moment/moment";
import Row from "@components/Row";
import Col from "@components/Col";
import Link from "@components/Link";
import Alert from "@components/Alert";
//import Sidebar from "../components/Sidebar";

const Dashboard = () => {
    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * State
     */
    const [socket, setSocket] = useState(null);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [sidebarOpen] = useState(true);


    /**
     * Memo
     */
    const socketProvider = useMemo(() => ({socket, setSocket}), [socket]);
    const taskProvider = useMemo(() => ({selectedTasks, setSelectedTasks}), [selectedTasks]);

    useEffect(() => {
        if (socket === null) {
            setSocket(io());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SocketContext.Provider value={socketProvider}>
            <TaskContext.Provider value={taskProvider}>
                {auth ? (
                    <div className={['app', sidebarOpen ? '' : 'fullwidth'].join(' ')}>
                        {auth.system && moment().isBefore(moment(auth.system.deadline)) && (
                            <div className={'v3'}>
                                <Alert type={'danger'} className={'system-change-alert'}>
                                    <Row>
                                        <Col lg={6}>
                                            <h4><strong>Neue Web-Adresse</strong></h4>
                                            Medicharge ist bald unter der neuen Adresse <Link to={{pathname: auth.system.url}}>{auth.system.url}</Link> erreichbar.<br />
                                            Medicharge ist ab dem <strong>{moment(auth.system.deadline).format('DD.MM.YYYY')}</strong> nur noch unter der neuen Adresse erreichbar.
                                        </Col>
                                        <Col lg={4} className={'text-end'}>
                                            <br />
                                            <Countdown className={'system-change-timer'} date={moment(auth.system.deadline)} />
                                        </Col>
                                        <Col lg={2} className={'text-end'}>
                                            <br />
                                            <Link to={{pathname: auth.system.url}} size={'large'} type={'button'}>Jetzt wechseln</Link>
                                        </Col>
                                    </Row>
                                </Alert>
                            </div>
                        )}
                        <div className={'v3'} style={{zIndex: 10000, position: 'fixed', top: 0, left: 0}}>
                            <SideBarV3 />
                            <TopNavV3 />
                        </div>
                        {/*<Sidebar sideBarOpen={sidebarOpen} setSideBarOpen={true}/>*/}
                        {/*<Topnav/>*/}
                        <Switch>
                            <Route path='/v3' component={V3Router}/>
                            <Route exact path='/logout' component={Logout}/>

                            <Route exact path='/arzt/neu' component={DoctorForm}/>
                            <Route exact path='/arzt/:id' component={DoctorForm}/>
                            <Route exact path='/aerzte' component={DoctorList}/>
                            <Route exact path='/arzt/:id/:type' component={DoctorForm}/>

                            {(auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_PHARMA') || auth.roles.includes('ROLE_HOME') || auth.roles.includes('ROLE_DOCTOR')) && (
                                <Route exact path='/einrichtung/neu' component={HomeForm}/>
                            )}
                            {(auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_PHARMA') || auth.roles.includes('ROLE_HOME') || auth.roles.includes('ROLE_DOCTOR')) && (
                                <Route exact path='/einrichtung/:id' component={HomeForm}/>
                            )}
                            {(auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_PHARMA') || auth.roles.includes('ROLE_HOME') || auth.roles.includes('ROLE_DOCTOR')) && (
                                <Route exact path='/einrichtungen' component={HomeList}/>
                            )}

                            {(auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_PHARMA') || auth.roles.includes('ROLE_HOME') || auth.roles.includes('ROLE_DOCTOR')) && (
                                <Route exact path='/pflegeeinrichtungen' component={HomeList}/>
                            )}
                            {(auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_PHARMA') || auth.roles.includes('ROLE_HOME') || auth.roles.includes('ROLE_DOCTOR')) && (
                                <Route exact path='/einrichtung/:id/:type' component={HomeForm}/>
                            )}
                            {(auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_PHARMA') || auth.roles.includes('ROLE_HOME') || auth.roles.includes('ROLE_DOCTOR')) && (
                                <Route exact path='/einrichtung/:id/wohnbereich/:groupId/loeschen'
                                       component={HomeGroupDeleteForm}/>
                            )}

                            <Route exact path='/patient/neu' component={PatientForm}/>
                            <Route exact path='/patienten' component={PatientList}/>
                            <Route exact path='/patient/details/:id' component={PatientDetails}/>
                            <Route exact path='/patient/:id' component={PatientForm}/>
                            <Route exact path='/patient/:patientId/auftraege' component={TaskManagement}/>
                            <Route exact path='/patient/:id/:type' component={PatientForm}/>

                            <Route exact path='/nutzer/neu' component={UserForm}/>
                            <Route exact path='/nutzer' component={UserList}/>
                            <Route exact path='/nutzer/:id' component={UserForm}/>
                            <Route exact path='/nutzer/:id/:type' component={UserForm}/>

                            <Route exact path='/anforderungen' component={TaskList}/>
                            <Route exact path='/anforderungen/erzeugen' component={CreateDocuments}/>
                            <Route exact path='/anforderungen/import' component={ImportForm}/>
                            <Route exact path='/anforderung/neu' component={TaskManagement}/>
                            <Route exact path='/anforderung/patient/:patientId' component={TaskManagement}/>
                            <Route exact path='/anforderung/neu/:patientId' component={TaskForm}/>
                            <Route exact path='/anforderung/neu/:patientId/:copyTaskId' component={TaskForm}/>
                            <Route exact path='/anforderung/edit/:patientId/:taskId' component={TaskForm}/>

                            <Route exact path='/abholauftraege' component={PickUpList}/>

                            {(auth.roles.includes('ROLE_ADMIN') || (auth.roles.includes('ROLE_MANAGER') && auth.roles.includes('ROLE_PHARMA'))) && (
                                <Route exact path='/einstellungen' component={SettingsForm}/>
                            )}


                            <Route exact path='/profil' component={ProfileForm}/>

                            <Route path='/' component={DashboardStatistics}/>
                        </Switch>
                    </div>
                ) : (
                    <Redirect to='/login'/>
                )}
            </TaskContext.Provider>
        </SocketContext.Provider>
    );
};

export default Dashboard;

import React from "react";
import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const Flicker = ({text, delay, className, ...args}) => {
    const build = (text) => {
        const parts = text.split('');
        let currentDelay = 0;
        const duration = parts.length * delay

        const result = [];

        for (let ii  = 0; ii < parts.length; ii++) {
            result.push(<span key={`flicker-${ii}`}
                              className="flicker-animation"
                              style={{
                                  animationDelay: `${currentDelay}s`,
                                  animationDuration: `${duration}s`
                              }}>
                {parts[ii] !== ' ' ? parts[ii] : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}}/>}
            </span>)

            if(parts[ii] !== ' ') {
                currentDelay += delay;
            }
        }

        return result;
    }


    return <div className={`flicker-container ${className}`}>
        {build(text)}
    </div>
}

Flicker.propTypes = {
    text: PropTypes.string,
    delay: PropTypes.number,
};

Flicker.defaultProps = {
    delay: 0.2,
    text: 'Lade Daten'
};

export default Flicker;
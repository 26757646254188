/* jshint esversion: 8 */
import {
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import axios from 'axios';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {ImCancelCircle} from 'react-icons/im';
import {MdDelete, MdSend} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';
import {isEmail, isEmpty, isValid} from '../utils/validation';
import moment from "moment";
const useStyles = makeStyles({
    warning: {
        backgroundColor: '#f44336',
        borderRadius: 4,
        padding: '8px 16px',
        fontSize: 13,
        color: 'white',
        margin: '24px 8px 5.6px'
    },
    modified: {
        color: '#888',
        fontSize: 12
    },
});
const DoctorForm = ({history, match: {params}}) => {
    const classes = useStyles();
    /**
     * STATES
     */
    const {auth, setAuth} = useContext(AuthContext);
    const [offices, setOffices] = useState([]);
    const [state, setState] = useState({
        office: '',
        lastname: '',
        firstname: '',
        title: '',
        salutation: '',
        street: '',
        postalCode: '',
        city: '',
        country: '',
        expertise: '',
        fax: '',
        email: '',
        preferred: '',
        debtorId: '',
        withLogin: false
    });
    const [expertises, setExpertises] = useState([]);
    const [officeAutoComplete, setOfficeAutoComplete] = useState('');

    const [error, setError] = useState({
        lastname: false,
        email: false,
        fax: false,
        preferred: false,
    });

    /**
     * REQUESTS
     */
    const fetchData = async () => {
        try {
            let response = await axios.get(`/api/doctors/${params.id}`, {headers: {'x-auth-token': auth.token}});
            response.data.expertise = response.data.expertise ? response.data.expertise : '';
            setState({country: 'Deutschland', ...response.data, createdAt: moment(response.data.createdAt), modifiedAt: moment(response.data.modifiedAt)});
            setInitialOfficeAutoComplete(offices, response.data.office);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchExpertise = async () => {
        try {
            let response = await axios.get(`/api/doctors/expertises`, {headers: {'x-auth-token': auth.token}});
            setExpertises(response.data);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchOffices = async () => {
        try {
            let response = await axios.get(`/api/doctors/offices?limit=99999`, {headers: {'x-auth-token': auth.token}});
            setOffices(response.data.items);
            setInitialOfficeAutoComplete(response.data.items, state.office);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const setInitialOfficeAutoComplete = (collection, id) => {
        for(const item of collection) {
            if(item._id === id) {
                setOfficeAutoComplete(item.name);
            }
        }
    }

    /**
     * HOOKS
     */

    useEffect(() => {
        setInitialOfficeAutoComplete(offices, state.office);
    }, [offices, state]);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (params.hasOwnProperty('id')) {
            fetchData();
        }
        fetchExpertise();
        fetchOffices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * CONTROLS
     */
    const change = (e) => {
        validate(e);
        setState({...state, [e.target.name]: e.target.value});
    };

    const autoChange = (e, value) => {
        setState({...state, expertise: value});
    };

    const changeZip = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setState({...state, [e.target.name]: value.replace(/[^0-9]+/, '').substr(0,5)});
    };

    /**
     * VALIDATION
     */
    const validate = (e) => {
        switch (e.target.name) {
            case 'lastname':
                setError({...error, lastname: isEmpty(e.target.value)});
                break;
            case 'email':
                setError({...error, email: !isEmail(e.target.value)});
                break;
            case 'fax':
                setError({...error, fax: isEmpty(e.target.value)});
                break;
            case 'preferred':
                if (e.target.value === 'E-Mail') {
                    setError({...error, email: isEmpty(state.email), fax: false, preferred: isEmpty(e.target.value)});
                }
                if (e.target.value === 'Fax') {
                    setError({...error, fax: isEmpty(state.fax), email: false, preferred: isEmpty(e.target.value)});
                }
                break;
            default:
        }
    };

    const changeOffice = (e, v) => {
        setState({ ...state, office: v ? v._id : ''});

        if(v) {
            setOfficeAutoComplete(v.name);
        }

        return true;
    };

    /**
     * SUBMIT
     */
    const submit = async (e) => {
        e.preventDefault();
        if (state.preferred === '') {
            setError({...error, preferred: true});
        }

        let update = {...state};
        delete update.createdFrom;
        delete update.modifiedFrom;

        try {
            if (isValid(error)) {
                if (params.hasOwnProperty('id') && params.type && params.type === 'loeschen') {
                    await axios.delete(`/api/doctors/${params.id}`, {headers: {'x-auth-token': auth.token}});
                    toast.success('Arzt wurde gelöscht.');
                }
                else if (params.hasOwnProperty('id')) {
                    await axios.put(`/api/doctors/${params.id}`, update, {headers: {'x-auth-token': auth.token}});
                    toast.success('Arzt geändert.');
                }
                else {
                    await axios.post('/api/doctors', update, {headers: {'x-auth-token': auth.token}});
                    toast.success('Arzt gespeichert.');
                }

                history.push('/aerzte');
            }
        } catch (err) {
            handleErrors(err, setAuth, history);
        }

        return false;
    };

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Link to='/aerzte'>Ärzte</Link>
                <Typography color='textPrimary'>
                    {params.hasOwnProperty('id')
                        ? params.type && params.type === 'loeschen'
                            ? 'Arzt löschen'
                            : 'Arzt bearbeiten'
                        : 'Neuer Arzt'}
                </Typography>
            </Breadcrumbs>
            <Grid container>
                <Grid item xs={12}>
                    <form
                        onSubmit={(e) => {
                            submit(e);
                        }}
                    >
                        <Card>
                            <CardContent>
                                <Typography component='h1' color='textSecondary' gutterBottom>
                                    {params.hasOwnProperty('id')
                                        ? params.type && params.type === 'loeschen'
                                            ? 'Arzt löschen'
                                            : 'Arzt bearbeiten'
                                        : 'Neuer Arzt'}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id='salutation-label'>Anrede</InputLabel>
                                            <Select
                                                disabled={params.type && params.type === 'loeschen'}
                                                labelId='salutation-label'
                                                id='salutation'
                                                name='salutation'
                                                value={state.salutation}
                                                onChange={(e) => {
                                                    change(e);
                                                }}
                                            >
                                                <MenuItem value={''}>Nicht angegeben</MenuItem>
                                                <MenuItem value={'Herr'}>Herr</MenuItem>
                                                <MenuItem value={'Frau'}>Frau</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && params.type === 'loeschen'}
                                                id='title'
                                                name='title'
                                                value={state.title}
                                                onChange={(e) => change(e)}
                                                label='Titel'
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && params.type === 'loeschen'}
                                                id='firstname'
                                                value={state.firstname}
                                                name='firstname'
                                                onChange={(e) => change(e)}
                                                label='Vorname'
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && params.type === 'loeschen'}
                                                id='lastname'
                                                name='lastname'
                                                value={state.lastname}
                                                error={error.lastname}
                                                required
                                                helperText={error.lastname && 'Nachname ist erforderlich'}
                                                onChange={(e) => change(e)}
                                                label='Nachname'
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disabled={params.type && params.type === 'loeschen'}
                                                freeSolo
                                                id='expertise'
                                                inputValue={state.expertise}
                                                name='expertise'
                                                noOptionsText={'Lade Daten...'}
                                                onInputChange={autoChange}
                                                options={expertises.map((expertise) => expertise)}
                                                renderInput={(params) => <TextField {...params} label='Fachbereich'/>}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && params.type === 'loeschen'}
                                                id='street'
                                                name='street'
                                                value={state.street}
                                                onChange={(e) => change(e)}
                                                label='Straße'
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && params.type === 'loeschen'}
                                                id='postalCode'
                                                name='postalCode'
                                                value={state.postalCode}
                                                onChange={(e) => changeZip(e)}
                                                label='Postleitzahl'
                                                pattern="[0-9]{5}"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && params.type === 'loeschen'}
                                                id='city'
                                                name='city'
                                                value={state.city}
                                                onChange={(e) => change(e)}
                                                label='Ort'
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && params.type === 'loeschen'}
                                                id='country'
                                                name='country'
                                                value={state.country}
                                                onChange={(e) => change(e)}
                                                label='Land'
                                            />
                                        </FormControl>
                                    </Grid>
                                    {!auth.roles.includes('ROLE_DOCTOR') && (auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_PHARMA')) && (
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    getOptionLabel={(option) => option ? option.name : ''}
                                                    id='office'
                                                    name='office'
                                                    noOptionsText={'Lade Daten...'}
                                                    onChange={changeOffice}
                                                    options={offices}
                                                    inputValue={officeAutoComplete}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            onChange={(e) => setOfficeAutoComplete(e.target.value)}
                                                            label='Arztpraxis'
                                                        />
                                                    )}
                                                    value={state.offices ? state.offices : ''}
                                                />
                                            </FormControl>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id='preferred-label'>Bevorzugte Kontaktart *</InputLabel>
                                            <Select
                                                disabled={params.type && params.type === 'loeschen'}
                                                id='preferred'
                                                labelId='preferred-label'
                                                name='preferred'
                                                onChange={(e) => {
                                                    change(e);
                                                }}
                                                required
                                                value={state.preferred}
                                            >
                                                <MenuItem value={'E-Mail'}>E-Mail</MenuItem>
                                                {state.withLogin && <MenuItem value={'E-Mail-Notification'}>E-Mail: Nur Benachrichtigen, keine Patientendaten</MenuItem>}
                                                <MenuItem value={'Fax'}>Fax</MenuItem>
                                                {state.withLogin && <MenuItem value={'Fax-Notification'}>Fax: Nur Benachrichtigen, keine Patientendaten</MenuItem>}
                                                {state.withLogin && <MenuItem value={'NONE'}>Nur innerhalb Medicharge benachrichtigen</MenuItem>}
                                            </Select>
                                            <FormHelperText>Je nachdem was hier ausgewählt wurde werden Rezeptanforderungen dann an die Faxnummer oder die E-Mail-Adresse geschickt.</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && params.type === 'loeschen'}
                                                error={error.email}
                                                helperText={error.email && 'Ist keine korrekte E-Mail Adresse'}
                                                id='email'
                                                label='E-Mail'
                                                name='email'
                                                onChange={(e) => change(e)}
                                                placeholder='z.B. test@gmx.de'
                                                required={state.preferred === 'E-Mail'}
                                                value={state.email}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={params.type && params.type === 'loeschen'}
                                                error={error.fax}
                                                helperText={error.fax ? 'Eine Faxnummer wird benötigt!' : 'Die Faxnummer muss zwingend die Ortsvorwahl enthalten.'}
                                                id='fax'
                                                label='Fax'
                                                name='fax'
                                                onChange={(e) => change(e)}
                                                placeholder='z.B. 031123456789'
                                                required={state.preferred === 'Fax'}
                                                value={state.fax}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled={!auth.roles || !auth.roles.includes('ROLE_ADMIN')}
                                            formHelperTextProps={{focused: true}}
                                            fullWidth
                                            helperText='In der Regel dreistellig. z.B. 001'
                                            id='debtorId'
                                            label='Kundennummer'
                                            name='debtorId'
                                            onChange={(e) => change(e)}
                                            value={state.debtorId}
                                        />
                                    </Grid>
                                </Grid>
                                {state.createdFrom && (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div className={classes.modified}>
                                                <div>Am {state.createdAt.format('DD.MM.YYYY')} erstellt von {state.createdFrom.username}</div>
                                                <div>Am {state.modifiedAt.format('DD.MM.YYYY')} geändert von {state.modifiedFrom.username}</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                            <Grid container justify='center'>
                              <Typography className={classes.warning}> Der Artzt kann nicht gelöscht werden, sondern wird deaktiviert. Zu Dokumentationszwecken bleiben die Daten des Artzten erhalten, werden aber in der Abrechnung nicht mehr berücksichtigt. Achtung! Ein deaktivierter Artzt kann das System nicht mehr benutzen, bis er wieder aktiviert wird.
                              </Typography>
                            </Grid>
                            <CardActions>
                                <Grid container justify='space-between'>
                                    <Grid item>
                                        <Button
                                            color='primary'
                                            component={Link}
                                            startIcon={<ImCancelCircle/>}
                                            to='/aerzte'
                                            variant='outlined'
                                        >
                                            Abbrechen
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {params.type && params.type === 'loeschen' ? (
                                            <Button
                                                color='secondary'
                                                endIcon={<MdDelete/>}
                                                type='submit'
                                                variant='contained'
                                            >
                                                Löschen
                                            </Button>
                                        ) : (
                                            <Button
                                                color='primary'
                                                endIcon={<MdSend/>}
                                                type='submit'
                                                variant='contained'
                                            >
                                                Speichern
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default DoctorForm;

import factory from "@factories/factory";
import request from "@utils/request";

const invoiceServiceFactory = (config) => {
    return {
        ...factory,
        ...config,
        path: 'invoices',
        getProof: async function(id, filter, page, limit) {
            return this.get(id, filter, page, limit, '/proof');
        },
        download: async function(id, path) {
            const response = await request.get(`/api/${this.path}/download/${id}?path=${path}`, {
                headers: {'x-auth-token': this.auth.token},
                responseType: 'blob'
            });

            return response.data;
        },
        update: async function (id, path, data) {
            const response = await request.put(`/api/${this.path}`, {id: id, path: path, ...data}, {
                headers: {'x-auth-token': this.auth.token},
            });

            return response.data;
        }
    }
}

export default invoiceServiceFactory;
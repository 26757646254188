import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {ImImage} from "react-icons/im";
import {rand, hasPermission} from "@utils/utils";
import Icon from "@components/Icon";
import AuthContext from "@context/AuthContext";

const CheckBoxCardInput = ({observer, type, value, name, id, onChange, checked}) => {
    /**
     * State
     */
    const [state, setState] = useState('');

    /**
     * Reference
     */
    const stateReference = useRef();
    stateReference.current = state;

    /**
     * Hooks
     */
    useEffect(() => {
        if(observer) {
            observer.register({
                get: () => {
                    return stateReference.current},
                key: `${name}-${id}`,
                name: name,
                unique: true
            });
        }

        return () => {
            setState(false);
        }
    }, [observer]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(observer) {
            observer.notify({[name]: state});
        }
    }, [state, name, observer]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Function
     */
    const click = (e) => {
        if(onChange) {
            onChange(e);
        }

        setState(e.target.value);
    }

    return <Fragment>
        <input className={'card-input'} type={type} name={name} value={value} id={id} onChange={click} checked={checked}/>
    </Fragment>
}

const CheckboxCard = ({permission, observer, type, value, name, label, icon, image, imageTitle, description, onClick, checked, help}) => {
    const id = `${type}-card-${rand()}`

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    if(permission && !hasPermission(auth, permission)) {
        return <Fragment />;
    }

    return (
        <Fragment>
            <CheckBoxCardInput observer={observer} type={type} name={name} value={value} id={id} onChange={onClick} checked={checked} />
            <label htmlFor={id} className='card'>
                <div className='card-body'>
                    <div className={'card-icon-container'}>
                        {image && (<img alt={imageTitle} src={image} />)}
                        {icon && icon}
                        {!image && !icon && (<ImImage className={'icon-no-image mt-1'}/>)}
                    </div>
                    <div className={'card-info-container'}>
                        <h5>{label}</h5>
                        <span className={'card-description'} dangerouslySetInnerHTML={{__html: description}}/>
                    </div>
                    {help && (
                        <div className={'card-help-container'}>
                            <Icon icon={'help'} className={'card-help-icon'}/>
                            <div className={'info-popover card'}>
                                <div className={'card-body'}>
                                    {help.map((item) => (
                                        <div key={rand()} className={'info-row'}>
                                            <div className={'info-headline'}>{item.title}</div>
                                            <div className={'info-content'} dangerouslySetInnerHTML={{__html: item.content}} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </label>
        </Fragment>
    );
}

CheckboxCard.propTypes = {
    type: PropTypes.oneOf(['checkbox', 'radio']),
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};

CheckboxCard.defaultProps = {
    type: 'checkbox'
}

export default CheckboxCard;
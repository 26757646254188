import React from 'react';

const SideBarMenu = ({ children }) => {
    return (
        <div className='sidebar-body ps'>
            <ul className='nav'>{children}</ul>
        </div>
    );
};

export default SideBarMenu;

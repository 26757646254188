/* jshint esversion: 8 */
import {useState} from "react";
import PropTypes from "prop-types";
import Input from "@components/Input";
import Observer from "@utils/observer";
import TableData from "./TableData";
import TableExtendedSearch from "./TableExtendedSearch";
import {addClass} from "@utils/utils";

/**
 * @param service
 *      Required: NO,
 *      Type: Array,
 *      Description: contains Table data
 *
 * @param service
 *      Required: YES
 *      Type: Object
 *          {
 *              tableRefresh:<FUNC()> - update the table and call new Data
 *          }
 *      Description: An object that has the "get" method, which is used to
 *                   load and filter all data in the table asynchronously.
 *      Notice: service required a function called "get" the data or is optional if the param "data" is in component setted.
 *
 *
 * @param columns
 *      Required: YES
 *      Type: Array<Object>
 *      Description: contains the definition of the columns and how they should be rendered.
 *      Object:
 *          name: <STRING> - Required
 *              Is the internal name of the column
 *          render: <FUNCTION(item, helper)> - Optional
 *              Description: The custom render Function
 *              Parameters:
 *                  item: <OBJECT>      - The Data Item of the Row
 *                  helper: <OBJECT>    - Collection of helper functions
 *                      highlight: <Function(<STRING>)> - highlight the search text in matching word
 *          title: <STRING> - Optional
 *              Description: Is the Column Name in Table header
 *          classes: ARRAY<STRING|STRING func(item)> - Optional
 *              Description: list of cell classes
 *          display: <BOOL> - Optional
 *              Default: true
 *              Description: Show the column if true
 *          copyable: <BOOL> - Optional
 *              Default: true
 *              Description: Column Value is can be copied
 *          width: <INT> - Optional
 *              Default: auto
 *              Description: set the column width
 *          mobile: <Object> - Optional
 *              size: <INT> - Optional
     *              Default: 12
     *              Description: the size is given in a range from 1 to 12. this is a grid system with 12 levels.
 *              align: <left|center|right> - Optional
 *                  Default: left
 *                  Description: set the column value align
 *          align: <left|center|right> - Optional
 *              Default: left
 *              Description: set the column value align
 *
 * @param self
 *      Required: NO
 *      Type: object
 *      Default: null
 *      Description: to use functions from the table in higher-level components
 *
 *
 * @param offset
 *      Required: NO
 *      Type: int
 *      Default: 0
 *      Description: the columns offset which the table columns should be shifted to the left. only works if the param "fullTable" is false
 *
 * @param fullTable
 *      Required: NO
 *      Type: bool
 *      Default: true
 *      Description: if this value is true, only the rows are returned and not the entire table pull.
 *
 * @param observer
 * @param onCollapseContent
 *      Required: NO
 *      Type: Function
 *      Default: null
 *      Description: a function that is executed when a line is expanded. This function returns the content of the expanded spade
 *
 * @param rowCollapseAble
 *      Required: NO
 *      Type: bool
 *      Default: false
 *      Description: If the value is True, the line can be expanded
 *
 * @param rowCheckAble
 *      Required: NO
 *      Type: bool
 *      Default: false
 *      Description: If the value is True, there is a checkbox to select the row
 *
 * @param onRowHighlight
 *      Required: NO
 *      Type: Function
 *      Default: null
 *      Description: a function that is executed when a line is highlighted.
 *
 * @param filterElements
 *      Required: NO
 *      Type: Array<Object>
 *      Default: []
 *      Description: a list of objects that serve as additional filters
 *
 * @param searchable
 *      Required: NO
 *      Type: bool
 *      Default: true
 *      Description: if this value is true, the table can be filtered
 *
 * @param collapseLabel
 *      Required: NO
 *      Type: String
 *      Default: 'Details'
 *      Description: the label of the collapse icon
 *
 * @param filterElementsExpanded
 *      Required: NO
 *      Type: bool
 *      Default: false
 *      Description: if this value is set to true, then the filter bar is expanded by default
 *
 * @param pagination
 *      Required: NO
 *      Type: bool
 *      Default: true
 *      Description: if this value is true, the table is paginated
 *
 * @param className
 *      Required: NO
 *      Type: String
 *      Default: Empty
 *      Description: adds more CSS classes to the table
 *
 * @param header
 *      Required: NO
 *      Type: bool
 *      Default: true
 *      Description: if this value is true, the table has a header
 *
 * @returns {JSX.Element|*}
 * @constructor
 */


const Table = ({data, collapseLabel, filterElementsExpanded, self, offset, fullTable, observer, service, onCollapseContent, rowCollapseAble, rowCheckAble, columns, onRowHighlight, filterElements, searchable, pagination, className, header}) => {
    /**
     * States
     */
    const [searchObserver] = useState(new Observer((data) => { update(data); }));

    const update = async (data) => {
            // if(data && '_all' in data && data._all.length === 0) {
            //     delete data._all;
            // }

            searchObserver.pushUpdate(data, 'table');
    };

    const renderTableData = () => {
        return <TableData
            tableRowData={data}
            resolution={window.outerWidth}
            table={self}
            offset={offset}
            fullTable={fullTable}
            header={header}
            pagination={pagination}
            filterElements={filterElements}
            observer={observer}
            searchObserver={searchObserver}
            service={service}
            collapseLabel={collapseLabel}
            onCollapseContent={onCollapseContent}
            rowCheckAble={rowCheckAble}
            rowCollapseAble={rowCollapseAble}
            columns={columns.map((item, index) => { item.order = index * 10; return item; })}
            onRowHighlight={onRowHighlight}
        />
    }

    if(!fullTable) {
        return renderTableData();
    }

    return (
        <div className={addClass(['table-container', className])}>
            {searchable && (
            <div className={'search-container'}>
                <Input
                    placeholder={'Suche ...'}
                    name={'_all'}
                    observer={searchObserver}
                />

                {<TableExtendedSearch filterElementsExpanded={filterElementsExpanded} observer={searchObserver} filterElements={filterElements} />}

            </div>)}
            {renderTableData()}

        </div>
    )
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    searchable: PropTypes.bool,
    pagination: PropTypes.bool,
    header: PropTypes.bool,
    fullTable: PropTypes.bool,
    collapseLable: PropTypes.string
};

Table.defaultProps = {
    rowCheckAble: false,
    rowCollapseAble: false,
    filterElements: [],
    searchable: true,
    pagination: true,
    header: true,
    className: '',
    fullTable:true,
    offset: 0,
    collapseLabel: 'Details'
}

export default Table;
import React, {useEffect, useState, useContext} from 'react';
import Card from '@components/Card';
import Col from '@components/Col';
import Content from '@components/Content';
import Row from '@components/Row';
import Dialog from "@components/Dialog";
import AuthContext from "@context/AuthContext";
import Icon from "@components/Icon";
import routeServiceFactory from "@factories/routeServiceFactory";
import {useHistory} from "react-router-dom";
import User from "@views/parts/forms/dialog/user/User";
//import Summary from "@views/parts/forms/dialog/user/summary/Summary";
import userServiceFactory from "@factories/userServiceFactory";

const UserForm = () => {
    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const history = useHistory();

    /**
     * STATES
     */
    const [state, setState] = useState({password: '', password_confirm: ''});

    /**
     * SERVICES
     */
    const routeService = routeServiceFactory();
    const userService = userServiceFactory({auth: auth});

    /**
     * HOOKS
     */
    useEffect( () => {
        //fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const change = (data) => {
        setState({ ...state, ...data});
    };

    const submit = async () => {
        try {
            const data = {...state.user, type: 'User'};
            await userService.create(data);
            history.push(routeService.userList());
        } catch (err) {
            console.log(err);
        }

        return false;
    };

    return (
        <Content>
            <form noValidate>
                <Row sm={12}>
                    <Col sm={12}>
                        <Card>
                            <Dialog onChange={change} onSubmit={submit}>
                                <User title={'Benutzer'} name={'user'} icon={<Icon icon={'person'} />}/>
                            </Dialog>
                        </Card>
                    </Col>
                </Row>
            </form>
        </Content>
    );
};

export default UserForm;

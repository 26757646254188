/* jshint esversion: 8 */
import React, {Fragment, useContext, useEffect, useState} from "react";
import {Card, CardContent, Grid, makeStyles} from "@material-ui/core";
import DashboardIconCard from "../components/DashboardIconCard";


import {
    AiOutlineMedicineBox,
    BsInfoCircle,
    BsTruck, FaBed,
    FaRegHospital,
    MdOutlineTaskAlt,
    TiMessages
} from "react-icons/all";
import {AiOutlineUserAdd} from "react-icons/ai";
import axios from "axios";
import handleErrors from "../utils/handleErrors";
import AuthContext from "../context/auth";
import ActionLoader from "../components/ActionLoader";
import {hasPermission} from "@utils/utils";
import Countdown from "../_v3/components/Countdown";
import moment from "moment/moment";
import meetingServiceFactory from "../_v3/factories/meetingServiceFactory";
import Alert from "../_v3/components/Alert";

import zoomLogo from "../assets/img/zoom.svg";
import msTeamsLogo from "../assets/img/msteams.webp";
import skypeLogo from "../assets/img/skype.png";
import discordLogo from "../assets/img/discord.png";

//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component
const useStyles = makeStyles({
    meetingDescription: {
        color: '#444'
    },
    meetingInformations: {
        width: '75%',
        marginTop: 15
    },
    system: {
        float: 'right',
        margin: '15px 0 0 0',
        maxHeight: 75,
        '& img': {
            maxHeight: 75,
            maxWidth: 120
        }
    },
    alert: {
        background: 'rgba(42, 93, 120, 0.2)',
        padding: '5px 15px',
        margin: '20px 10px 0 10px',
        borderRadius: 5
    },
    planning: {
        color: '#2a5d78',
        fontWeight: 'bold',
        textAlign: 'right'
    },
    finalDay: {
        textAlign: 'right',
        fontSize: 12,
        color: '#adadad'
    },
    meetingBoxState: {
        position: 'absolute',
        top: 32,
        right: 20,
    },
    participating: {
        backgroundColor: '#2a5d78',
        padding: '5px 10px',
        borderRadius: 5,
        color: '#fff',
        float: 'right',
        margin: '0 0 5px 0',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#00334e'
        }
    },
    meetingBox: {
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        padding: '15px 20px',
        borderRadius: 5,
        display: "inline-block",
        margin: '0 46px',
        width: 'calc(100% - 92px)',
        position: "relative",
        "& h3": {
            position: 'relative'
        },
        "& h3:after": {
            content: " ",
            position: 'absolute',
            bottom: 10,
            left: 10,
            height: 4,
            width: 60,
            backgroundColor: '#000'
        }
    },
    page: {
        margin: '30px 10px 0 10px',
        padding: '30px 0'
    },
    headline: {
        textAlign: "center",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        margin: 0,
        cursor: "default"
    },
    headlineMargin: {
        margin: '0 0 15px 0',
    },
    faqChecker: {
        display: 'none',
        '&:checked + .faq-content': {
            display: 'block'
        }
    },
    faqIcon: {
        color: 'rgb(33, 150, 243)',
        height: 20,
        width: 20,
        margin: '12px 10px 6px 10px'
    },
    faqContent: {
        display: 'none',
        padding: '5px 20px 15px 40px'
    },
    faqLabel: {
        cursor: "pointer",
        color: 'rgb(13, 60, 97)',
        display: 'inline-block',
        width: '100%',

        '& strong': {
            verticalAlign: 'top',
            display: 'inline-block',
            margin: '13px 0 0 0'
        }
    },
    faqContainer: {
        borderRadius: 5,
        backgroundColor: 'rgb(232, 244, 253)',

    },
    subHeadline: {
        textAlign: "center",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        color: '#666',
        margin: '0 0 15px 0',
        cursor: "default"
    },
    statistic: {
        textAlign: "center"
    },
    readAll: {
        position: "absolute",
        bottom: 5,
        left: 0,
        right: 0,

        '& *': {
            padding: 0
        },

        '& span': {
            cursor: "pointer",
            color: '#cdcdcd',
            fontSize: 12,
        },

        '&:hover': {
            '& *': {
                background: 'transparent'
            },

            '& span': {
                color: '#000'
            }
        },
    },
    changelog: {
        '& > ul > li': {
            listStyleType: 'none'
        },
        '& li': {
            position: 'relative',
            listStyleType: 'disc'
        },
        '& li::marker': {
            color: '#5588a3'
        },
        '& .headline': {
            color: '#5588a3',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            margin: 0,
            fontSize: 16
        },
        '& ul': {
            padding: '0 0 0 20px',
            margin: 0,
        },
        '& .changelog-accordion': {
            backgroundColor: 'white',
            maxHeight: '54px',
            overflow: 'hidden',
            transition: 'max-height 0.2s ease-out',
        },
        '& .open': {
            maxHeight: '500px',
        },
        '& .changelog-version': {
            cursor: 'pointer',
        },
        '& .arrow':{
            borderLeft: '2px solid black',
            borderBottom: '2px solid black',
            width: '8px',
            height: '8px',
            textAlign: 'center',
            transition: 'all 0.35s',
            marginLeft: '20px',
            transform: 'rotate(-45deg)',
            display: 'inline-block',
            marginTop: '3px',
            verticalAlign: 'top',
            pointerEvents: 'none'
        },
        '& .open .arrow':{
            transform: 'rotate(135deg)',
            marginTop: '7px',
        }
    }
});

const Dashboard = ({history, ...args}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * Context
     */
    const {auth, setAuth} = useContext(AuthContext);
    //const {history} = useHistory();

    /**
     * Services
     */
    const meetingService = meetingServiceFactory({auth: auth})

    if(auth.roles.includes('ROLE_ACCOUNTING')) {
        history.push('/profil');
    }

    /**
     * States
     */
    const [state, setState] = useState({tasks: 0, pickups: 0, events: 0, users: 0, homes: 0, doctors: 0, patients: 0});
    const [changeLog, setChangeLog] = useState('');
    const [meetings, setMeetings] = useState([]);
    const [faq, setFaq] = useState([]);


    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
        fetchMeetings();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    /**
     * Functions
     */
    const fetchMeetings = async () => {
        const response = await meetingService.get();

        setMeetings(response.items);
    }

    const meetingSystem = (item) => {
        switch(item.system) {
            case 'Zoom':
                return (<a href={'https://zoom.us/download'} rel="noreferrer" target={'_blank'} title={item.system}>
                    <img alt={'Zoom'} src={zoomLogo}/>
                </a>);
            case 'Microsoft Teams':
                return (<a href={'https://www.microsoft.com/de-de/microsoft-teams/download-app'} rel="noreferrer" target={'_blank'} title={item.system}>
                    <img alt={'Microsoft Teams'} src={msTeamsLogo}/>
                </a>);
            case 'Skype':
                return (<a href={'https://www.skype.com/de/get-skype/'} rel="noreferrer" target={'_blank'} title={item.system}>
                    <img alt={'Skype'} src={skypeLogo}/>
                </a>);
            case 'Discord':
                return (<a href={'https://discord.com/download'} rel="noreferrer" target={'_blank'} title={item.system}>
                    <img alt={'Discord'} src={discordLogo}/>
                </a>);
            default:
                return '';
        }
    }

    const fetch = async () => {
        try {
            let result = {events: 0, tasks: 0, pickups: 0, doctors: 0, users: 0, patients: 0};

            if(hasPermission(auth, 'dashboard_new_user_view')) {
                const response = await axios.get(`/api/users?filter[notSeen]=1`, {
                    headers: {'x-auth-token': auth.token},
                });
                result.users = response.data.count;
            }

            if(hasPermission(auth, 'dashboard_new_events_view')) {
                const response = await axios.get(`/api/events?filter[notSeen]=1`, {
                    headers: {'x-auth-token': auth.token},
                });
                result.events = response.data.max;
            }

            if(hasPermission(auth, 'dashboard_new_tasks_view')) {
                const response = await axios.get(`/api/tasks?filter[notSeen]=1&filter[status]=new`, {
                    headers: {'x-auth-token': auth.token},
                });
                result.tasks = response.data.count;
            }

            if(hasPermission(auth, 'dashboard_new_pickup_view')) {
                const response = await axios.get(`/api/tasks?filter[notSeen]=1&filter[status]=pickup`, {
                    headers: {'x-auth-token': auth.token},
                });
                result.pickups = response.data.count;
            }

            if(hasPermission(auth, 'dashboard_new_doctor_view')) {
                const response = await axios.get(`/api/doctors?filter[notSeen]=1`, {
                    headers: {'x-auth-token': auth.token},
                });
                result.doctors = response.data.count;
            }

            if(hasPermission(auth, 'dashboard_new_patient_view')) {
                const response = await axios.get(`/api/patients?filter[notSeen]=1`, {
                    headers: {'x-auth-token': auth.token},
                });
                result.patients = response.data.count;
            }

            if(hasPermission(auth, 'dashboard_new_home_view')) {
                const response = await axios.get(`/api/homes?filter[notSeen]=1`, {
                    headers: {'x-auth-token': auth.token},
                });
                result.homes = response.data.count;
            }

            const response = await axios.get(`/api/statistics`, {
                headers: {'x-auth-token': auth.token},
            });

            setState(result);
            setChangeLog(response.data.log);
            setFaq(response.data.faq);

            let openChangeLogItems = document.getElementsByClassName('open');
            if (openChangeLogItems.length === 0) {
                let changeLogItems = document.getElementsByClassName('changelog-accordion');
                if (changeLogItems.length > 0) {
                    changeLogItems[0].classList.add('open');
                }
            }
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const clearCounter = async (action) => {
        try {
            await axios.put(`/api/statistics/${action}_read_all`, {}, {
                headers: {'x-auth-token': auth.token},
            });
            fetch();
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    // const hasPermission = (permission, required) => {
    //     let valid = false;
    //     for(let perm of required) {
    //         perm = perm instanceof Array ? perm : [perm];
    //         valid = perm.filter(value => permission.includes(value)).length === perm.length;
    //         if(valid) { break; }
    //     }
    //
    //     return valid;
    // }

    const changeAccordion = (e) => {
        if(e.target.tagName === "H4"){
            var accordion = e.target.closest(".changelog-accordion");
            if (accordion.classList.contains('open')) {
                accordion.style.maxHeight = "54px";
            } else {
                accordion.style.maxHeight = accordion.scrollHeight + "px";
            }
            accordion.classList.toggle("open");
        }
    };

    if(auth.permissions.indexOf('dashboard_view') === -1) {
        return <Fragment />;
    }

    return <Fragment>
        <Card className={classes.page}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h1 className={classes.headline}>Willkommen</h1>
                        <h3 className={classes.subHeadline}>Das ist in deiner Abwesenheit passiert</h3>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.statistic}>
                        {hasPermission(auth, 'dashboard_new_tasks_view') && (<DashboardIconCard
                            icon={<MdOutlineTaskAlt />}
                            enabled={hasPermission(auth, 'task_list_view')}
                            to={'/anforderungen?filter[notSeen]=1'}
                            title={'Aufträge'}
                            count={`+ ${state.tasks}`}
                            tooltip={'Neue Aufträge'}
                            footer={state.tasks ? (<div className={classes.readAll}><ActionLoader type={'ThreeDots'} onClick={() => { clearCounter('task')}}><span>als gesehen markieren</span></ActionLoader></div>) : ''}
                        />)}
                        {hasPermission(auth, 'dashboard_new_events_view') && (<DashboardIconCard
                            icon={<TiMessages />}
                            title={'Nachrichten'}
                            enabled={hasPermission(auth, 'task_list_view')}
                            to={'/anforderungen?filter[notSeenEvents]=1'}
                            count={`+ ${state.events}`}
                            tooltip={'Neue Nachrichten'}
                            footer={state.events ? (<div className={classes.readAll}><ActionLoader type={'ThreeDots'} onClick={() => { clearCounter('event')}}><span>als gesehen markieren</span></ActionLoader></div>) : ''}
                        />)}
                        {hasPermission(auth, 'dashboard_new_pickup_view') && (<DashboardIconCard
                            icon={<BsTruck />}
                            title={'Abhohlaufträge'}
                            to={'/abholauftraege?filter[notSeen]=1'}
                            enabled={hasPermission(auth, 'pickup_list_view')}
                            count={`+ ${state.pickups}`}
                            tooltip={'Neue Abhohlaufträge'}
                            footer={state.pickups ? (<div className={classes.readAll}><ActionLoader type={'ThreeDots'} onClick={() => { clearCounter('pickup')}}><span>als gesehen markieren</span></ActionLoader></div>) : ''}
                        />)}
                        {hasPermission(auth, 'dashboard_new_home_view') && (<DashboardIconCard
                            icon={<FaRegHospital />}
                            title={'Pflegeeinrichtungen'}
                            to={'/einrichtungen?filter[notSeen]=1'}
                            enabled={hasPermission(auth, 'home_list_view')}
                            count={`+ ${state.homes}`}
                            tooltip={'Neue Pflegeeinrichtungen'}
                            footer={state.homes ? (<div className={classes.readAll}><ActionLoader type={'ThreeDots'} onClick={() => { clearCounter('home')}}><span>als gesehen markieren</span></ActionLoader></div>) : ''}
                        />)}
                        {hasPermission(auth, 'dashboard_new_doctor_view') && (<DashboardIconCard
                            icon={<AiOutlineMedicineBox />}
                            title={'Ärzte'}
                            to={'/aerzte?filter[notSeen]=1'}
                            enabled={hasPermission(auth, 'doctor_list_view')}
                            count={`+ ${state.doctors}`}
                            tooltip={'Neue Ärzte'}
                            footer={state.doctors ? (<div className={classes.readAll}><ActionLoader type={'ThreeDots'} onClick={() => { clearCounter('doctor')}}><span>als gesehen markieren</span></ActionLoader></div>) : ''}
                        />)}
                        {hasPermission(auth, 'dashboard_new_user_view') && (<DashboardIconCard
                            icon={<AiOutlineUserAdd />}
                            title={'Benutzer'}
                            to={'/nutzer?filter[notSeen]=1'}
                            enabled={hasPermission(auth, 'user_list_view')}
                            count={`+ ${state.users}`}
                            tooltip={'Neue Benutzer'}
                            footer={state.users ? (<div className={classes.readAll}><ActionLoader type={'ThreeDots'} onClick={() => { clearCounter('user')}}><span>als gesehen markieren</span></ActionLoader></div>) : ''}
                        />)}
                        {hasPermission(auth, 'dashboard_new_patient_view') && (<DashboardIconCard
                            icon={<FaBed />}
                            title={'Patienten'}
                            to={'/patienten?filter[notSeen]=1'}
                            enabled={hasPermission(auth, 'patient_list_view')}
                            count={`+ ${state.patients}`}
                            tooltip={'Neue Patienten'}
                            footer={state.patients ? (<div className={classes.readAll}><ActionLoader type={'ThreeDots'} onClick={() => { clearCounter('patient')}}><span>als gesehen markieren</span></ActionLoader></div>) : ''}
                        />)}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <br />
                        <br />
                        <br/>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <h2 className={[classes.headline].join(' ')}>Ihre Webinare</h2>
                                <h3 className={classes.subHeadline}>Prüfen Sie ihre zukünftigen Webinare</h3>
                            </Grid>
                            {meetings.map(item => (
                                <Grid item xs={12} md={12}>
                                    <div className={classes.meetingBox}>
                                        <h3>{item.title}</h3>
                                        <div className={classes.meetingBoxState}>

                                            {!item.date && (<div className={classes.planning}>Planung</div>)}
                                            {item.date && (
                                                <Fragment>
                                                    {moment(`${item.date} ${item.time}`).add(3, 'hours').isBefore() && (
                                                        <div className={classes.planning}>Beendet</div>
                                                    )}
                                                    {moment(`${item.date} ${item.time}`).add(3, 'hours').isAfter() && (
                                                        <Countdown
                                                            date={moment(`${item.date} ${item.time}`)}
                                                            callback={<a href={item.url} className={classes.participating}>Teilnehmen</a>}
                                                        />
                                                    )}
                                                    <div className={classes.finalDay}>
                                                        {moment(`${item.date} ${item.time}`).format('DD.MM.YYYY')} um&nbsp;
                                                        {moment(`${item.date} ${item.time}`).format('HH:mm')} Uhr</div>
                                                </Fragment>
                                            )}
                                            <div className={classes.system}>{meetingSystem(item)}</div>
                                        </div>
                                        <div className={classes.meetingInformations}>
                                            <span className={classes.meetingDescription}>{item.description}</span>
                                            {item.info && (<Alert className={classes.alert}>{item.info}</Alert>)}
                                        </div>
                                    </div>
                                </Grid>)
                            )}
                        </Grid>
                        {faq.length > 0 && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2} />
                                <Grid item xs={12} md={10}>
                                    <h2 className={[classes.headline, classes.headlineMargin].join(' ')}>Wussten Sie schon!</h2>
                                    <Grid container spacing={2}>
                                        {faq.map((item, index) => (
                                            <Grid key={index} item xs={12}>
                                                {/*<Alert severity="info"><strong><label className={classes.faqLabel} htmlFor={`faq-${index}`}>{item.title}</label></strong><br />*/}
                                                {/*    <input className={classes.faqChecker} type={'checkbox'} id={`faq-${index}`} />*/}
                                                {/*    <div className={`faq-content ${classes.faqContent}`} dangerouslySetInnerHTML={{__html: item.content}} />*/}
                                                {/*</Alert>*/}
                                                <div className={classes.faqContainer}>
                                                    <label className={classes.faqLabel} htmlFor={`faq-${index}`}>
                                                        <BsInfoCircle className={classes.faqIcon}/>
                                                        <strong>{item.title}</strong>
                                                    </label><br />
                                                    <input className={classes.faqChecker} type={'checkbox'} id={`faq-${index}`} checked={true}/>
                                                    <div className={`faq-content ${classes.faqContent}`} dangerouslySetInnerHTML={{__html: item.content}} />
                                                </div>
                                            </Grid>))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {changeLog.length > 0 && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={10}>
                                    <br />
                                    <br />
                                    <br/>
                                    <h2 className={classes.headline}>Systemänderungen</h2>
                                    <h3 className={classes.subHeadline}>Was haben wir an MediCharge geändert</h3>
                                    <div onClick={changeAccordion} className={classes.changelog} dangerouslySetInnerHTML={{__html: changeLog}} />
                                </Grid>
                                <Grid item xs={12} md={2} />
                            </Grid>)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Fragment>
}

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
import React, { useEffect } from 'react';
import $ from 'jquery';

const SideBarToggler = () => {
    useEffect(() => {
        $(function () {
            const body = $('body');

            $('.sidebar-toggler').on('click', function (e) {
                e.preventDefault();
                $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                body.toggleClass('sidebar-folded');
                // if (window.matchMedia('(min-width: 992px)').matches) {
                //     e.preventDefault();
                //     body.toggleClass('sidebar-folded');
                // }
                // else if (window.matchMedia('(max-width: 991px)').matches) {
                //     e.preventDefault();
                //     body.toggleClass('sidebar-open');
                // }
            });
        });
    }, []);

    return (
        <div className='sidebar-toggler not-active'>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default SideBarToggler;

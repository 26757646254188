import Col from "@components/Col";
import Card from "@components/Card";
import Row from "@components/Row";
import PropTypes from 'prop-types';
import {getRoleName} from "@utils/enumName";
import {rand} from "@utils/utils";
import {formData} from "@utils/forms";
import Doctor from "./Doctor";
import Home from "./Home";
import {Fragment, useState} from "react";
import Icon from "@components/Icon"
import LastChange from "@components/LastChange";


const Summary = ({form, edit}) => {
    const [displayPassword, setDisplayPassword] = useState(false);

    const showPassword = () => {
        setDisplayPassword(!displayPassword);
    }

    return (
        <Row className='mb-12'>
            <Col sm={12}>
                <Card title={edit ? 'Fast geschafft' : 'Benutzer Daten'} center={false} className={'form-card'}>
                    <p className='text-muted text-center mb-4 pb-2'>
                    {edit && 'Bitte überprüfe noch einmal deine Daten.'}
                    </p>
                    <div className='container'>
                        <Row>
                            <Col sm={6} md={form.user && (form.user.doctor || form.user.home ? 6 : 8)}>
                                <h5 className={'text-uppercase mt-1 mb-4'}>Stammdaten</h5>
                                <Row>
                                    <Col sm={6} md={6} lg={3}><strong>Nutzername</strong></Col>
                                    <Col sm={6} md={6} lg={6}>{formData(form.user.username)}</Col>
                                </Row>
                                {edit && (
                                <Row className={'mt-3'}>
                                    <Col sm={6} md={6} lg={3}>
                                        <strong>Passwort</strong>
                                        {form.user.password_once && (<Fragment>&nbsp;<small className={'optional-field'}>(einmalig)</small></Fragment>)}
                                    </Col>
                                    <Col sm={6} md={6} lg={6}>
                                        {displayPassword ? formData(form.user.password) : '********'}
                                        {displayPassword ? <Icon onClick={showPassword} icon={'eye_off'} className={'password-summary-icon'} title={'Passwort zeigen'}/> : <Icon onClick={showPassword} icon={'eye'} className={'password-summary-icon'} title={'Passwort verstecken'}/>}
                                    </Col>
                                </Row>)}
                                {/*<Row className={'mt-3'}>*/}
                                {/*    <Col sm={3} omd={3}><strong>Anrede</strong></Col>*/}
                                {/*    <Col sm={9} md={3}>{formData(salutation(form.user.salutation))}</Col>*/}
                                {/*</Row>*/}
                                {/*<Row className={'mt-3'}>*/}
                                {/*    <Col sm={3} omd={3}><strong>Titel</strong></Col>*/}
                                {/*    <Col sm={9} md={3}>{formData(form.user.title)}</Col>*/}
                                {/*</Row>*/}
                                {/*<Row className={'mt-3'}>*/}
                                {/*    <Col sm={3} omd={3}><strong>Vorname</strong></Col>*/}
                                {/*    <Col sm={9} md={3}>{formData(form.user.firstname)}</Col>*/}
                                {/*</Row>*/}
                                {/*<Row className={'mt-3'}>*/}
                                {/*    <Col sm={3} omd={3}><strong>Nachname</strong></Col>*/}
                                {/*    <Col sm={9} md={3}>{formData(form.user.lastname)}</Col>*/}
                                {/*</Row>*/}
                                <Row className={'mt-3'}>
                                    <Col sm={6} md={6} lg={3}><strong>Nutzergruppe</strong></Col>
                                    <Col sm={6} md={6} lg={6}><ul className={'simple-item-listing'}>{(form.user.roles && form.user.roles.map((item) => (<li key={rand()}>{getRoleName(item)}</li>))) || (<li><small className={'optional-field'}>Keine angabe.</small></li>)}</ul></Col>
                                </Row>
                                {!edit && (
                                <Row className={'mt-3'}>
                                    <Col sm={6} md={6} lg={3}><strong>Erstellt<br />Geändert</strong></Col>
                                    <Col sm={6} md={6} lg={6}>
                                        <LastChange displayLabels={false} className={'last-change-normal'} data={form.user} align={'left'}/>
                                    </Col>
                                </Row>)}
                            </Col>
                            {form.user && (form.user.doctor || form.user.home) && (
                                <Col sm={6}>
                                    {form.user.doctor && <Doctor entity={form.user.doctor}/>}
                                    {form.user.home && <Home entity={form.user.home}/>}
                                </Col>
                            )}
                        </Row>
                    </div>
                </Card>
            </Col>
        </Row>
    );
}

Summary.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired
};

Summary.defaultProps = {
    edit: true
};

export default Summary;
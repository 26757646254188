import {Breadcrumbs, Button, CardActions, CardContent, Checkbox, FormControlLabel, FormControl, FormHelperText, FormLabel, Grid, TextField, Typography} from '@material-ui/core';
import axios from 'axios';
import React, {Fragment, useContext, useMemo, useState} from 'react';
import Dropzone, {useDropzone} from 'react-dropzone';
import {MdSend} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import AuthContext from '../context/auth';
import {inputChange} from "../utils/tools";

const baseStyle = {
    alignItems: 'center',
    backgroundColor: '#fafafa',
    borderColor: '#eeeeee',
    borderRadius: 2,
    borderStyle: 'dashed',
    borderWidth: 2,
    cursor: 'pointer',
    color: '#434343',
    display: 'flex',
    flexFlow: 'column wrap',
    height: '170px',
    justifyContent: 'space-evenly',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const ImportForm = ({ history, match: { params } }) => {
    /**
     * CONTEXT
     */
    const { auth } = useContext(AuthContext);

    const [state, setState] = useState({
        file: '',
        filterPharma: '',
        insertHomes: false,
        insertHomeGroups: false,
    });

    const acceptFile = (files) => {
        if (files.length > 0) {
            setState({
                ...state,
                file: files[0],
                filename: files[0].name,
            });
        }
    };

    //text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    const { isDragActive, isDragAccept, isDragReject } = useDropzone({ accept: 'application/vnd.ms-excel' });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragActive ? activeStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragAccept,
        isDragActive,
        isDragReject,
    ]);

    /**
     * SUBMIT
     */
    const submit = async (evt) => {
        evt.preventDefault();
        let data = new FormData();

        for (const [name, value] of Object.entries(state)) {
            if (value !== null) {
                data.append(name, value);
            }
        }

        try {
            let response = await axios.put(`/api/import/task`, data, {
                headers: {
                    'x-auth-token': auth.token,
                    'Content-Type': 'multipart/form-data',
                },
            });
            let rData = response.data;
            toast.success(() => <div>
                Daten erfolgreich importiert.<br/>
                &nbsp; &nbsp; {(rData.homes && rData.homes.nInserted) || 0} &nbsp; Pflegeeinrichtungen<br/>
                &nbsp; &nbsp; {(rData.homeGroups && rData.homeGroups.nInserted) || 0} &nbsp; Wohnbereiche<br/>
                &nbsp; &nbsp; {(rData.patients && rData.patients.nUpserted) || 0} &nbsp; Patienten<br/>
                &nbsp; &nbsp; {(rData.doctors && rData.doctors.nUpserted) || 0} &nbsp; Ärzte<br/>
                &nbsp; &nbsp; {(rData.tasks && rData.tasks.nUpserted) || 0} &nbsp; Aufträge
            </div>);
        } catch (err) {
            console.log(err);
        }

        return false;
    };

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Typography color='textPrimary'>Import</Typography>
            </Breadcrumbs>
            <CardContent>
                <form onSubmit={(e) => submit(e)}>
                    <CardContent>
                        <Typography component='h1' color='textSecondary' gutterBottom>
                            Import Aufträge
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item md={6} sm={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.insertHomes}
                                                        color='default'
                                                        id='insertHomes'
                                                        name='insertHomes'
                                                        onChange={(e) => setState(inputChange(e, state))}
                                                    />
                                                }
                                                label='Einrichtungen einfügen wenn nicht vorhanden'
                                                // style={{'marginTop': '18px', 'marginLeft': '10px'}}
                                            />
                                            <FormHelperText>{'Wenn Daten für Einrichtungen gefunden werden, die nicht existieren, wird ohne diese Option der Import abgebrochen. Wenn ausgewählt ' +
                                                'wird eine fehlende Einrichtung automatisch angelegt. Der Name der Einrichtung entspricht der ersten Zeile des Wertes im Feld "konfigAbsKopf2" ' +
                                                'z.B. "Pflegeheim Am Stadtwald"'}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.insertHomeGroups}
                                                        color='default'
                                                        id='insertHomeGroups'
                                                        name='insertHomeGroups'
                                                        onChange={(e) => setState(inputChange(e, state))}
                                                    />
                                                }
                                                label='Wohnbereiche einfügen wenn nicht vorhanden'
                                                // style={{'marginTop': '18px', 'marginLeft': '10px'}}
                                            />
                                            <FormHelperText>{'Wenn Daten für Wohnbereiche gefunden werden, die nicht existieren, wird ohne diese Option der Import abgebrochen. Wenn ausgewählt ' +
                                                'wird ein fehlender Wohnbereich automatisch angelegt. Der Name des Wohnbereiches entspricht dem Wert im Feld "Unterbringung" ' +
                                                'z.B. "Wohnbereich 2 / 216"'}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            formHelperTextProps={{focused: true}}
                                            fullWidth
                                            helperText={'Wenn die Datei Daten für mehrere Apotheken enthält, kann hier die Apotheke angegeben werden, die importiert werden soll. ' +
                                            'Entspricht dem Wert in Feld "ApothekenName" z.B. "Boddenapotheke". Wenn leer wird versucht alle Datensätze zu importieren.'}
                                            id='filterPharma'
                                            label='Apothekenfilter'
                                            name='filterPharma'
                                            onChange={(e) => setState(inputChange(e, state))}
                                            value={state.filterPharma}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <FormLabel style={{fontSize:'11px'}}>Import Aufträge</FormLabel>
                                <Dropzone accept={'application/vnd.ms-excel'} onDrop={acceptFile}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps({style})}>
                                                <input {...getInputProps({style: {display: 'block'}})} />
                                                <p style={{cursor: 'pointer', textAlign: 'center',}}>Import-Datei per Drag and Drop hier ablegen oder hier klicken zum wählen.</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container justify='space-between'>
                            <Grid item>
                                &nbsp;
                            </Grid>
                            <Grid item>
                                <Button color='primary' endIcon={<MdSend />} type='submit' variant='contained'>
                                    Importieren
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </form>
            </CardContent>
        </Fragment>
    );
};

export default ImportForm;

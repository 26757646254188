import React, {useContext, useEffect, useState} from "react";
import {
    Card, CardContent,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import handleErrors from "../../utils/handleErrors";
import AuthContext from "../../context/auth";
import {useHistory, useLocation} from "react-router-dom";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    filter: {
        position: 'relative',
        '& label': {
            left: '10px',
            top: '8px',
        }
    },
    markAsRead: {
        ['@media (max-width:1001px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: '-8px',
        }
    },
    refreshButton: {
        backgroundColor: '#4caf50',
        '&:hover, &:focus': {
            backgroundColor: '#388e3c'
        },
        ['@media (max-width:1001px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: '-8px',
        }
    },
    newOrderButton: {
        ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: '-8px',
        }
    },
    filterButton: {
        '&': {
            display: 'inline-block',
            position: 'absolute',
            right: '24px',
            top: '72px',
            ['@media (max-width:601px)']: { // eslint-disable-line no-useless-computed-key
                right: '10px',
                top: '64px',
            }
        },
    },
    filterChkLabel: {
        display: "inline-block",
        padding: "12px 0"
    }
}));

const TaskFilter = ({onChange, className, ...args}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);

    /**
     * Context
     */
    const {auth, setAuth} = useContext(AuthContext);
    const {history} = useHistory();

    /**
     * State
     */
    const [delay, setDelay] = useState(null);

    const [doctors, setDoctors] = useState([]);
    const [medicine, setMedicine] = useState([]);
    const [homes, setHomes] = useState([]);
    const [doctorAutoCompleteInput, setDoctorAutoCompleteInput] = useState('');
    const [medicineAutoCompleteInput, setMedicineAutoCompleteInput] = useState('');
    const [homeAutoCompleteInput, setHomeAutoCompleteInput] = useState('');
    const [filter, setFilter] = useState({
        latestDelivery: 0,
        status: 'new',
        text: !!query.get('display[task]') ? `id:${query.get('display[task]')}` : '',
        doctor: '',
        medicine: '',
        home: '',
        notSeen: !!query.get('filter[notSeen]') ? 1 : 0,
        notSeenEvents: !!query.get('filter[notSeenEvents]') ? 1 : 0,
        from: '',
        to: '',
        filter_date_type: 'created'
    });

    /**
     * Hooks
     */
    useEffect(() => {
        fetchDoctors();
        fetchMedicine();
        fetchHomes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Functions
     */
    const fetchDoctors = async () => {
        try {
            const response = await axios.get(`/api/doctors?limit=99999`, {
                headers: {'x-auth-token': auth.token},
            });
            let list = [...response.data.data];
            list.sort((a, b) => {
                if (a.lastname < b.lastname) {
                    return -1;
                }
                if (a.lastname > b.lastname) {
                    return 1;
                }
                return 0;
            });
            setDoctors(list);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchMedicine = async () => {
        try {
            const response = await axios.get(`/api/tasks/medicine?limit=99999`, {
                headers: {'x-auth-token': auth.token},
            });
            setMedicine(response.data);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchHomes = async () => {
        try {
            const response = await axios.get(`/api/homes?limit=99999`, {
                headers: {'x-auth-token': auth.token},
            });
            setHomes(response.data.data);

            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const changeFilter = (e) => {
        const update = {...filter, [e.target.name]: e.target.value};
        setFilter(update);

        if(delay) {
            clearTimeout(delay);
            setDelay(null);
        }

        if (onChange) {
            setDelay(setTimeout((onChange, update) => {
                onChange(update);
            }, 500, onChange, update))
        }
    };

    const changeFilterCheckbox = (e) => {
        const update = {...filter, [e.target.name]: e.target.checked ? 1 : 0};
        setFilter(update);

        if (onChange) {
            onChange(update);
        }
    }

    const buildDoctorName = (option) => {
        return `${option && (option.firstname || '')} ${
            option && (option.lastname || '')
        } ${
            option && ((option.expertise && ' (' + option.expertise + ')') || '')
        }`.trim();
    }

    return <Card className={className}>
        <CardContent>
            <Typography component='h1' color='textSecondary' gutterBottom>
                Filter
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            id='text-filter'
                            label='Suche'
                            name='text'
                            onChange={changeFilter}
                            value={filter.text}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={3} xs={12} className={classes.filter}>
                            <FormControl fullWidth>
                                <InputLabel id='status-filter'>Status</InputLabel>
                                <Select
                                    labelId='status-filter'
                                    id='status-filter-select'
                                    name={'status'}
                                    value={filter.status}
                                    onChange={changeFilter}
                                    fullWidth
                                >
                                    <MenuItem value={''}/>
                                    <MenuItem value={'new'}>Neu</MenuItem>
                                    <MenuItem value={'pickup'}>In Abholung</MenuItem>
                                    <MenuItem value={'delivery'}>Auslieferung</MenuItem>
                                    <MenuItem value={'finished'}>Erledigt</MenuItem>
                                    <MenuItem value={'canceled'}>Storniert</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {!auth.roles.includes('ROLE_DOCTOR') && (
                            <Grid item sm={3} xs={12} className={classes.filter}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        getOptionLabel={(option) => buildDoctorName(option)}
                                        id='doctor-filter-select'
                                        name='doctor'
                                        noOptionsText={'Lade Daten...'}
                                        inputValue={doctorAutoCompleteInput}
                                        onChange={(e, v) => {
                                            changeFilter({
                                                target: {
                                                    name: 'doctor',
                                                    value: v ? v._id : ''
                                                }
                                            });

                                            setDoctorAutoCompleteInput(v ? buildDoctorName(v) : '');
                                        }}
                                        options={[{firstname: '', lastname: ''}].concat(doctors)}
                                        renderInput={(params) => <TextField onChange={(e) => {
                                            setDoctorAutoCompleteInput(e.target.value)
                                        }} {...params}
                                                                            label='Arzt'/>}
                                        value={filter.doctor ? filter.doctor : null}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item sm={3} xs={12} className={classes.filter}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    getOptionLabel={(option) => option}
                                    id='medicine-filter-select'
                                    name='medicine'
                                    noOptionsText={'Lade Daten...'}
                                    inputValue={medicineAutoCompleteInput}
                                    onChange={(e, v) => {
                                        changeFilter({
                                            target: {
                                                name: 'medicine',
                                                value: v ? v : ''
                                            }
                                        });

                                        setMedicineAutoCompleteInput(v ? v : '');
                                    }}
                                    options={medicine}
                                    renderInput={(params) => <TextField onChange={(e) => {
                                        setMedicineAutoCompleteInput(e.target.value)
                                    }} {...params}
                                                                        label='Medikament'/>}
                                    value={filter.medicine ? filter.medicine : null}
                                />
                            </FormControl>
                        </Grid>
                        {!auth.roles.includes('ROLE_HOME') && (
                            <Grid item sm={3} xs={12} className={classes.filter}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        getOptionLabel={(option) => option.name}
                                        id='home-filter-select'
                                        name='home'
                                        noOptionsText={'Lade Daten...'}
                                        inputValue={homeAutoCompleteInput}
                                        onChange={(e, v) => {
                                            changeFilter({
                                                target: {
                                                    name: 'home',
                                                    value: v ? v._id : ''
                                                }
                                            });

                                            setHomeAutoCompleteInput(v ? v.name : '');
                                        }}
                                        options={homes}
                                        renderInput={(params) => <TextField onChange={(e) => {
                                            setHomeAutoCompleteInput(e.target.value)
                                        }} {...params}
                                                                            label='Heime'/>}
                                        value={filter.home ? filter.home : null}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sm={4} xs={12} className={classes.filter}>
                            <FormControl fullWidth>
                                <InputLabel id='filter-date-type-select'>Datum filtern nach</InputLabel>
                                <Select
                                    labelId='filter-date-type-select'
                                    id='filter-date-type-select'
                                    name={'filter_date_type'}
                                    value={filter.filter_date_type}
                                    onChange={changeFilter}
                                    fullWidth
                                >
                                    <MenuItem value={'created'}>Erstellung</MenuItem>
                                    <MenuItem value={'modified'}>Änderung</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={4} xs={12} className={classes.filter}>
                            <FormControl fullWidth>
                                <TextField
                                    id="from"
                                    label="Ab"
                                    type="date"
                                    name={'from'}
                                    defaultValue={filter.from}
                                    onChange={changeFilter}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sm={4} xs={12} className={classes.filter}>
                            <FormControl fullWidth>
                                <TextField
                                    id="to"
                                    label="Bis"
                                    type="date"
                                    name={'to'}
                                    defaultValue={filter.to}
                                    onChange={changeFilter}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sm={3} xs={12} className={classes.filter}>
                            <Grid container spacing={2}>
                                <Grid item sm={1}>
                                    <FormControl fullWidth>
                                        <Checkbox
                                            checked={filter.latestDelivery}
                                            value={1}
                                            name={'latestDelivery'}
                                            onChange={changeFilterCheckbox}
                                            color='primary'
                                            label={'nur Anforderungen mit Liefertermin anzeigen'}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={11}>
                                    <span className={classes.filterChkLabel}>nur Anforderungen mit Liefertermin anzeigen</span></Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={3} xs={12} className={classes.filter}>
                            <Grid container spacing={2}>
                                <Grid item sm={1}>
                                    <FormControl fullWidth>
                                        <Checkbox
                                            checked={filter.notSeen === 1}
                                            value={1}
                                            name={'notSeen'}
                                            onChange={changeFilterCheckbox}
                                            color='primary'
                                            label={'nur neue Anforderungen anzeigen'}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={11}><span className={classes.filterChkLabel}>nur neue Anforderungen anzeigen</span></Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={3} xs={12} className={classes.filter}>
                            <Grid container spacing={2}>
                                <Grid item sm={1}>
                                    <FormControl fullWidth>
                                        <Checkbox
                                            checked={filter.notSeenEvents}
                                            value={1}
                                            name={'notSeenEvents'}
                                            onChange={changeFilterCheckbox}
                                            color='primary'
                                            label={'nur Anforderungen mit neuen Nachrichten anzeigen'}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={11}><span className={classes.filterChkLabel}>nur Anforderungen mit neuen Nachrichten anzeigen</span></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}

TaskFilter.propTypes = {};

TaskFilter.defaultProps = {};

export default TaskFilter;
import React from 'react';
import { Link } from 'react-router-dom';

const ProfileMenuLink = ({ children, to, icon }) => {
    return (
        <li className='dropdown-item py-2'>
            <Link to={to} className='text-body ms-0'>
                {icon}
                <span>{children}</span>
            </Link>
        </li>
    );
};

export default ProfileMenuLink;

import factory from "@factories/factory";


const aclServiceFactory = (config) => {
    return {
        ...config,
        ...factory,
        message: {
            update: 'Rechte wurden geändert'
        },
        path: 'acl',
    }
}

export default aclServiceFactory;
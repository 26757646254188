/* jshint esversion: 8 */
import React, {Fragment, useContext, useEffect, useState} from "react";
import Row from "@components/Row";
import Col from "@components/Col";
import Headline from "@components/Headline";
import moment from "moment";
import Button from "@components/Button";
import Alert from "@components/Alert";
import contractServiceFactory from "@factories/contractServiceFactory";
import AuthContext from "@context/AuthContext";
import Modal from "@components/Modal";
//import Shop from "@components/Shop";
import Table from "@components/Table/Table";
import {toast} from "react-toastify";
import Icon from "@components/Icon";
import {useTranslation} from "react-i18next";
import Link from "@components/Link";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const ContractCollapse = ({item, possibleOptions, refresh, ...args}) => {
    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [contract, setContract] = useState(null);
    const [bookedOptions, setBookedOptions] = useState([]);

    /**
     * Services
     */
    const contractService = contractServiceFactory({auth});
    const itemService = {
        get: () => {
            const items = bookedOptions.filter(item => item.priceFormula === undefined && !item.required);

            return {
                items: items,
                max: items.length
            };
        }
    };

    /**
     * Hooks
     */
    const { t } = useTranslation();
    useEffect(() => {
        fetch();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setBookedOptions(getBookedOptions());
    }, [contract]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(itemService.refresh) {
            itemService.refresh();
        }
    }, [bookedOptions]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const fetch = async () => {
        const response = await contractService.get(`${item._id}_${getDebtorId(item)}`);
        //const response = await contractService.get(item._id);

        setContract(response);
    };

    // const fetchMandate = async () => {
    //     const response = await userService.hasMandate(auth.user);
    //
    //     console.log(response);
    // }

    const getDebtorId = (item) => {
        if(item.home) {
            return item.home.debtorId;
        } else if(item.doctor) {
            return item.doctor.debtorId;
        }

        return item.debtorId;
    };

    const getCancelDate = (date) => {
        const mom = moment(date);

        return mom.add(-1, 'month').format('DD.MM.YYYY');
    };
    
    const getExtendDate = (date) => {
        const mom = moment(date);

        return mom.add(1, 'day').format('DD.MM.YYYY');
    };

    const getBookedOptions = () => {
        return contract && contract.items ? contract.items : [];
    };

    const cancel = async (id, revoke) => {
        await contractService.cancel(contract._id, {type: 'cancel', _id: id, revoke: revoke ? revoke : false});
        fetch();
        //itemService.refresh();
    };

    // const renderShop = (collection, closeHandler) => {
    //     return <Shop onSubmit={() => {
    //         closeHandler();
    //         fetch();
    //         }
    //     } products={collection} contractId={getContractId()}/>
    // };

    const renderContractTakeOverOptIn = (data, closeHandler) => {
        return (<div className={'contract-take-over'}>
            <div className={'contract-take-over-message'}>
                Sie sind dabei den Vertrag zu übernehmen.
            </div>
            Sind Sie sicher?
            <div className={'contract-take-over-submit'}>
                <Button onClick={async function () { await contractTakeOver(closeHandler) }} color={'success'}>Ja, Vertrag übernehmen!</Button>
            </div>

        </div>);
    }

    const getContractId = () => {
        return `${contract._id}_${getDebtorId(contract)}`;
    };

    const changeContractType = async (product) => {
        await contractService.update(getContractId(), {
            action: 'runtime_change',
            product: product
        });
        toast.success('Laufzeit des Vertrages wurde geändert.');
        if(refresh) {
            refresh();
        }
        fetch();
    };

    const cancelContract = async (contract) => {
        await contractService.update(contract ? contract._id : getContractId(), {action: 'cancel_contract'});

        if(isRuntimeChanging()) {
            toast.success('Vertragsänderung wurde zurückgenommen');
            if(refresh) {
                refresh();
            }
        } else {
            toast.success('Vertrag wurde zum nächstmöglichen Termin gekündigt.');
            fetch();
        }
    };

    const revertContractChange = async (contract) => {
        await contractService.update(contract ? contract._id : getContractId(), {
            action: 'revert_contract'
        });

        toast.success('Vertragsänderung wurde zurückgenommen');
        if(refresh) {
            refresh();
        }
    };

    const addPricing = (settings) => {
        const services = {
            incl_fax_service: {description: '\'Faxfunktion inkl. alle Fax-Seiten<sup class="contract-notice-star">*1</sup>\''},
            incl_email_service: {description: 'Mailfunktion'},
            incl_patient_service: {description: 'Alle Patienten*innen'},
            incl_doctor_service: {description: 'Alle Ärzte*innen (ohne Login)'},
            incl_employees_service: {description: 'Alle Mitarbeiter*innen'},
            incl_driver_service: {description: 'Alle Fahrer*innen'},
            incl_online_introduction: {description: 'Erste Online Einführung'},
            incl_once_doctor_import: {description: 'Einmaliger Import von Ärzte*innen<sup class="contract-notice-star">*2</sup><sup class="contract-notice-star">*3</sup>', type: 3},
            incl_once_patient_import: {description: 'Einmaliger Import von Patienten*innen<sup class="contract-notice-star">*2</sup><sup class="contract-notice-star">*3</sup>', type: 3},
            incl_task_import: {description: 'Anforderungsimportfunktion<sup class="contract-notice-star">*3</sup>', type: 3},
            unlimited_homes: {description: 'unbegrenzte Pflegeheim/-dienst<sup class="contract-notice-star">*2</sup>'},
        }

        const servicesList = [];
        for(let name in services) {
            if(settings.services.indexOf(name) !== -1) {
                servicesList.push(<tr key={`service-${name.split(' ').join('-')}-row`}>
                    <td className={'contract-feature-enabled'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round" className="feather feather-check icon-md text-primary me-2">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                    </td>
                    <td><p><span dangerouslySetInnerHTML={{__html: services[name].description}} /></p></td>
                </tr>);
            } else {
                if(services[name].type && services[name].type === 3) {
                    servicesList.push(<tr key={`service-${name.split(' ').join('-')}-row`}>
                        <td className={'contract-feature-disabled'}>
                            <Icon icon={'minus'} fill={'#fbbc06'}/>
                        </td>
                        <td><p className="text-muted"><span dangerouslySetInnerHTML={{__html: services[name].description}} /></p></td>
                    </tr>);
                } else {
                    servicesList.push(<tr key={`service-${name.split(' ').join('-')}-row`}>
                        <td className={'contract-feature-disabled'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-x icon-md text-danger me-2">
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </td>
                        <td><p className="text-muted"><span dangerouslySetInnerHTML={{__html: services[name].description}} /></p></td>
                    </tr>);
                }
            }
        }

        return (<div className={`contract-pricing style-${settings.style}${settings.disabled ? ' contract-pricing-disabled' : ''}`}>
            <h4 className="text-center mt-3 mb-4 contract-type">{settings.type}</h4>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                 className="feather feather-award text-primary icon-xxl d-block mx-auto my-3">
                <circle cx="12" cy="8" r="7" />
                <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
            </svg>
            <h1 className="text-center contract-price">{settings.price.toFixed(2)}&euro;</h1>
            <p className="text-muted text-center mb-4 fw-light">
                {settings.perHome ?  'pro Monat' : 'pro Monat'}
            </p>
            <h5 className="text-primary text-center mb-4">
                {settings.perHome ?  'Monatlicher Preis pro' : 'unbegrenzte'} Pflegeheim/-dienst</h5>
            <table className="mx-auto">
                <tbody>
                    {servicesList}
                </tbody>
            </table>
            <div className="d-grid">
                <button className="btn btn-primary mt-4" disabled={settings.disabled} onClick={() => changeContractType(settings.product)}>Buchen</button>
            </div>
        </div>)
    }

    const addChangeContractRuntimeButton = () => {
        const response = [];

        if(!contract.ownContract || contract.doctor || contract.product.runtime === 'year') {
            response.push(<Row className={'mt-1 mb-2'}>
                <Col sm={12}><Button
                tooltip={'Ändern Sie Ihren Vertrag auf eine andere Laufzeit.'}
                onClick={() => changeContractType({runtime: contract.product.runtime === 'month' ? 'year' : 'month', type: 'basic'})} fullwidth={true} disabled={contract.state !== 'booked'}>
                {contract.product.runtime === 'month' ? 'Auf Jahresvertrag wechseln' : 'Auf Monatsvertrag wechseln'}
                </Button></Col></Row>)
        }

        if(contract.ownContract && !contract.doctor) {
            const colSize = contract.home ? 6 : 4;

            response.push(<Row className={'mt-1 mb-2'}>
                <Col sm={12}><Modal
                size={{xl: [1024, -1]}}
                title={'Pakete'}
                button={
                    <Button
                        fullwidth={true}
                        tooltip={'Übernehmen sie die kosten des Vertrages'}
                        color={'warning'}>{contract.product.runtime === 'month' ? 'Auf Jahresvertrag wechseln' : 'Vertragspaket wechseln'}</Button>
                }
            >
                <Fragment>
                    <h4 className="text-center mb-3 mt-4">Jahres Pakete</h4>
                    <p className="text-muted text-center mb-4 pb-2">Wählen sie für sich das passende paket aus</p>
                    <Row>
                        <Col sm={12} md={colSize}>
                            {addPricing({
                                disabled: contract.product.runtime === 'year' && contract.product.type === 'basic',
                                type: 'Basis',
                                price: 59.00,
                                perHome: true,
                                services: [
                                    'incl_fax_service',
                                    'incl_email_service',
                                    'incl_patient_service',
                                    'incl_doctor_service',
                                    'incl_employees_service',
                                    'incl_driver_service',
                                    'incl_online_introduction'
                                ],
                                style: 'success',
                                product: {
                                    runtime: 'year',
                                    type: 'basic'
                                }
                            })}
                        </Col>
                        <Col sm={12} md={colSize}>
                            {addPricing({
                                disabled: contract.product.runtime === 'year' && contract.product.type === 'premium',
                                type: 'Full Service',
                                price: 89.00,
                                perHome: true,
                                services: [
                                    'incl_fax_service',
                                    'incl_email_service',
                                    'incl_patient_service',
                                    'incl_doctor_service',
                                    'incl_employees_service',
                                    'incl_driver_service',
                                    'incl_online_introduction',
                                    'incl_once_doctor_import',
                                    'incl_once_patient_import',
                                    'incl_task_import'
                                ],
                                style: 'info',
                                product: {
                                    runtime: 'year',
                                    type: 'premium'
                                }
                            })}
                        </Col>
                        {!contract.home && (
                            <Col sm={12} md={colSize}>
                                {addPricing({
                                    disabled: contract.product.runtime === 'year' && contract.product.type === 'unlimited',
                                    type: 'Unlimited',
                                    price: 499.00,
                                    perHome: false,
                                    services: [
                                        'incl_fax_service',
                                        'incl_email_service',
                                        'incl_patient_service',
                                        'incl_doctor_service',
                                        'incl_employees_service',
                                        'incl_driver_service',
                                        'incl_online_introduction',
                                        'incl_once_doctor_import',
                                        'incl_once_patient_import',
                                        'incl_task_import',
                                        'unlimited_homes'],
                                    style: 'secondary',
                                    product: {
                                        runtime: 'year',
                                        type: 'unlimited'
                                    }
                                })}
                            </Col>
                        )}
                    </Row>
                    <Row className={'mt-3'}>
                        <Col sm={12} md={12}>
                            <span className="contract-notice-star">*1</span>
                            <span className="contract-notice">&nbsp;Auf den Rezept-Anforderungen, die zu den Arztpraxen gehen, stehen auf den Faxen wie auch auf den Mails die Apotheke als Absender. Siehe auch <Link target={'_blank'} to={{pathname: 'https://www.medicharge.de/#elementor-tab-title-52614'}}>FAQ</Link>.</span>
                        </Col>
                    </Row>
                    <Row className={'mt-2'}>
                        <Col sm={12} md={12} >
                            <sup className="contract-notice-star">*2</sup>
                            <sup className="contract-notice">&nbsp;Um Ärzte*innen, Patienten*innen und Anforderungen importieren zu können müssen jeweils entsprechende CSV-Dateien geliefert werden.</sup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} >
                            <sup className="contract-notice-star">*3</sup>
                            <sup className="contract-notice">&nbsp;Kann einmalig dazugebucht werden</sup>
                        </Col>
                    </Row>
                </Fragment>
            </Modal></Col></Row>);
        }

        return response;
    }

    // const revertCancelContract = async() => {
    //     await contractService.update(getContractId(), {change: {
    //             contract: 'booked'
    //         }});
    //     toast.success('Kündigung wurde erfolgreich widerrufen');
    //     fetch();
    // };

    const collapsePackages = (item) => {
        return (<div className={'item-collapse-container'}>
            <div className={'contract-item-description'}>
                {item.description}
                {!item.description && (
                    <Alert type={'secondary'}>
                        Keine detaillierte Beschreibung vorhanden.
                    </Alert>
                )}
            </div>
            {!item.required && (
                <div className={'actions'}>
                    {item.validTo && (<span className={'cancel-info'}>
                    <Button
                        tooltip={'Wiederrufen Sie Ihre Kündigung für dieses Paket und nutzen Sie die Vorteile weiter.'}
                        onClick={() => cancel(item._id, true) } className={'contract-item-cancel-revert'} size={'small'}>Kündigung widerrufen</Button>
                </span>)}
                    {!item.validTo && (<span className={'cancel-info'}>
                    <Button
                        tooltip={'Kündigen Sie dieses Paket zum nächst möglichen Zeitpunkt.'}
                        onClick={() => cancel(item._id) } className={'contract-item-cancel'} outlined={true} size={'small'} color={'danger'}>Kündigen</Button>
                </span>)}
                </div>
            )}
        </div>)
    }

    const getNextContract = () => {
        return !!item.nextContract && typeof item.nextContract === 'object' ? item.nextContract : contract;
    }

    const getPrevContract = () => {
        return !!item.prevContract && typeof item.prevContract === 'object' ? item.prevContract : contract;
    }

    const isRuntimeChanging = () => {
        if(item.nextContract) {
            return item.nextContract.product.runtime !== contract.product.runtime;
        }

        return false;
    }

    /**
     * Columns
     */
    const columns = [
        {title: 'Paket', render: (item) => { return item.shopTitle ? item.shopTitle : item.title; }},
        {title: 'Gebucht am', render: (item) => { return moment(item.bookedAt).format('DD.MM.YYYY'); }},
        {title: 'Abo', render: (item) => { return item.interval === 'once' ? 'Nein' : 'Ja' }},
        {title: '', render: (item) => {
            if(item.validTo) {
                return (<span className={'cancel-info'}>gekündigt zum {moment(item.validTo).format('DD.MM.YYYY')}</span>);
            }

            return <span />;
        }},
        {title: 'Preis',align: 'right', render: (item) => {
            if(!isNaN(item.price)) {
                return `${parseFloat(item.price).toFixed(2)}&euro;`;
            }

            return '';
        }}
    ];

    const adoptionRequest = async (message, callback) => {
        if(contract.withLogin && !isRuntimeChanging()) {
            const response = await contractService.adoption(contractService.buildContractId(item), message);

            if(callback) {
                if(response) {
                    callback(response.id);
                }
            } else {
                fetch();
            }
        }
    }

    const contractTakeOver = async (closeHandler) => {
        await adoptionRequest('Sie sind nun Vertragsinhaber und tragen die kosten.', refresh);
        closeHandler();
    }

    const getOptionPrice = () => {
        const price = getPrevContract().optionPrice;

        return price !== undefined ? price : 0;
    }

    if(contract === null) {
        return <Fragment />;
    }

    const getContractType = (type) => {
        switch(type) {
            case 'unlimited':
                return 'Unlimitierter';
            case 'premium':
                return 'Premium';
            default:
                return 'Basis'
        }
    }

    return <Fragment>
        {contract.state === 'adoption_request' && (
            <Row className={'mb-4'}>
                <Col sm={12}>
                    <Alert type={'info'}>
                        {t(`contract_adoption_request_wait_${contract.home ? 'home' : 'doctor'}`)}
                    </Alert>
                </Col>
            </Row>
        )}

        {contract.state === 'adoption_request_pharma' && (
            <Row className={'mb-4'}>
                <Col sm={12}>
                    <Alert type={'info'}>
                        Die Apotheke wird über die Kostenübernahme informiert. Sobald die Apotheke die Kostenübernahme
                        bestätigt hat verschwindet der Vertrag aus dieser Anzeige.
                    </Alert>
                </Col>
            </Row>
        )}

        {(!(contract.validFrom && contract.validTo) || contract.state === 'new') && (
            <Row className={'mb-4'}>
                <Col sm={12}>
                    <Alert type={'secondary'}>
                        Die Apotheke fragt an, ob Sie alle Vorteile von MediCharge nutzen möchten und die Kosten für
                        die Nutzung selber übernehmen?
                        <div className={'mt-3'}>
                            <strong>Sind Sie mit der Kostenübernahme einverstanden?</strong>
                        </div>
                        <div className={'mt-1'}>
                            <Button
                                color={'success'} onClick={async () => {
                                await contractService.accept(contractService.buildContractId(item));
                                if(refresh) {
                                    refresh();
                                }
                                fetch();
                            }}>Ja, Vertrag kostenpflichtig akzeptieren</Button>
                            &nbsp;
                            <Button color={'danger'} onClick={async () => { await contractService.reject(
                                contractService.buildContractId(item));
                                if(refresh) {
                                    refresh();
                                }
                                fetch();
                            }}>Nein, Vertragsübernahme ablehnen</Button>
                        </div>
                    </Alert>
                </Col>
            </Row>
        )}

        {contract.state === 'adoption' && (
            <Row className={`mb-4`}>
                <Col md={12}>
                    <Alert type={'primary'}>Die Kosten des Vertrages werden vom Vertragspartner übernommen.</Alert>
                </Col>
            </Row>)}

        <Row className={`mb-4 ${contract.state === 'adoption' ? 'paid-by-other' : ''}`}>
            <Col lg={5} xl={3}>
                <Headline size={"h4"} design={"line"}>
                    Vertragsdaten
                </Headline>
                <Row>
                    <Col md={4}>Vertragsart</Col>
                    <Col md={8}>
                        {contract.product.runtime === 'month' ? 'Monatsvertrag' : 'Jahresvertrag'}
                        {isRuntimeChanging() ? <span
                            className="contract-period-change">(wechsel zu {getNextContract().product.runtime === "month" ? 'Monatsvertrag' : 'Jahresvertrag'})</span> : ''}
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col md={4}>Verlängerung</Col>
                    <Col md={8}>Ja, am {getExtendDate(isRuntimeChanging() ? getNextContract().validFrom  : getPrevContract().validTo)}</Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col md={4}>Laufzeit</Col>
                    <Col md={8}>
                        {moment(getPrevContract().validFrom).format('DD.MM.YYYY')} - {moment(getPrevContract().validTo).format('DD.MM.YYYY')}
                    </Col>
                </Row>
                <Row className={'mt-2'}>
                    {contract.ownContract &&!isRuntimeChanging() && <Fragment>
                        <Col md={12}>
                            {contract.state !== 'canceled' && (
                                <Alert type={'warning'}>
                                    Kündigung möglich bis <strong>{getCancelDate(getPrevContract().validTo)}</strong>
                                </Alert>)}
                            {contract.state === 'canceled' && (
                                <Alert type={'danger'}>
                                    Ihr Vertrag ist gekündigt und endet am <strong>{moment(contract.validTo).format('DD.MM.YYYY')}</strong>.
                                </Alert>)}
                        </Col>
                    </Fragment>}
                    {isRuntimeChanging() && <Fragment>
                        <Col md={12}>
                            <Alert type={'success'}>
                                Ihr Vertrag wird am <strong>{moment(getNextContract().validFrom).format('DD.MM.YYYY')}</strong>&nbsp;
                                zu einem {getNextContract().product.runtime === "month" ? 'Monatsvertrag' : 'Jahresvertrag'} umgestellt.
                            </Alert>
                        </Col>
                    </Fragment>}
                </Row>
                {(contract.home || contract.doctor) && contract.type === 'ROLE_PHARMA' &&
                    <Fragment>
                        <Row>
                            <Col md={4}>Mit Login</Col>
                            <Col md={8}>{contract.withLogin ? 'Ja' : 'Nein'}</Col>
                        </Row>
                        <Row className={'mt-3'}>
                            <Col md={4}>Kostenübername</Col>
                            <Col md={8}>{contract.assumptionOfCosts ? 'Ja' : 'Nein'}
                            </Col>
                        </Row>
                    </Fragment>}
                <Row className={'mt-5'}>
                    <Col md={12}>
                        <Headline size={"h4"} design={"line"}>
                            Aktionen
                        </Headline>
                    </Col>
                </Row>
                {!contract.ownContract && (
                    <Row className={'mt-1 mb-2'}>
                        <Col lg={12}>
                            <Modal
                                title={'Vertragsübernahme'}
                                size={{xl: [500, 300]}}
                                content={renderContractTakeOverOptIn}
                                contentData={[]}
                                button={<Button
                                    fullwidth={true}
                                    tooltip={'Übernehmen sie die kosten des Vertrages'}
                                    color={'warning'}>Kosten übernehmen</Button>}
                                />
                        </Col>
                    </Row>
                )}
                {contract.ownContract && (<Fragment>
                {contract.state !== 'adoption'  && (contract.home || contract.doctor) && (
                <Row className={'mt-1 mb-2'}>
                    <Col lg={12}>
                        {contract.type === 'ROLE_PHARMA' && contract.state === 'booked' && <Button
                            disabled={isRuntimeChanging() || !contract.withLogin}
                            tooltip={contract.withLogin ? `Fragen Sie ob ${contract.home ? 'die Pflegeeinrichtung' : 'der Arzt/Ärztin'} die Kosten dieses Vertrages übernehmen` : 'Kostenübernahme ist nur bei Verträgen möglich die einen Login besitzen.'}
                            color={'warning'}
                            fullwidth={true}
                            onClick={adoptionRequest}
                        >Kostenübernahmeantrag</Button>}
                        {contract.state === 'adoption_request' && <Button
                                disabled={isRuntimeChanging()}
                            color={'danger'}
                            fullwidth={true}
                            onClick={async () => { if(!isRuntimeChanging()) {await contractService.withdraw(contractService.buildContractId(item)); fetch(); } }}>
                            Antrag zurückziehen
                        </Button>}
                    </Col>
                </Row>)}
                    {!isRuntimeChanging() && addChangeContractRuntimeButton()}
                <Row className={'mt-1 mb-2'}>
                    <Col sm={12}>
                        {isRuntimeChanging() &&
                        <Button
                            tooltip={'Wiederrufen Sie Ihre Laufzeitänderung und kehren zur ursprünglichen Laufzeit zurück.'}
                            onClick={() => { revertContractChange(getNextContract()) }} fullwidth={true} color={'danger'}>
                            Änderung wiederrufen
                        </Button>}
                    </Col>
                </Row>
                <Row className={'mt-1'}>
                    <Col sm={12}>
                        {contract.state !== 'canceled' && (
                            <Button
                                tooltip={'Kündigen Sie diesen Vertrag zum nächstmöglichen Termin.'}
                                onClick={cancelContract} color={'danger'} outlined={true} fullwidth={true} disabled={contract.state !== 'booked' || isRuntimeChanging()}>
                                Vertrag kündigen
                            </Button>)}
                        {contract.state === 'canceled' && (
                            <Button
                                tooltip={'Kündigen Sie diesen Vertrag zum nächstmöglichen Termin.'}
                                onClick={cancelContract} color={'danger'} outlined={true} fullwidth={true} disabled={contract.state !== 'booked' || isRuntimeChanging()}>
                                Vertrag kündigen
                            </Button>)}
                    </Col>
                </Row>
                </Fragment>)}
            </Col>
            <Col olg={1} lg={6} oxl={1} xl={8}>
                <Headline size={"h4"} design={"line"}>
                    Buchbare und gebuchte Optionen
                </Headline>
                <div className={'shop-container'}>
                    <div className={contract.state !== 'booked' || isRuntimeChanging() ? 'shop-readonly' : ''}>
                        <Row className={'mt-4'}>
                            {/*<Col md={12}>*/}
                            {/*    <Alert type={'secondary'}>*/}
                            {/*        Nachfolgend finden Sie alle von Ihnen gebuchten Leistungen.*/}
                            {/*    </Alert>*/}
                            {/*</Col>*/}
                            {/*<Col md={2}>*/}
                            {/*    {!(contract.state !== 'booked' || isRuntimeChanging() || !contract.ownContract) && (*/}
                            {/*        <Modal*/}
                            {/*            size={{xl: [1200, 600], lg: [768, 600]}}*/}
                            {/*            content={renderShop}*/}
                            {/*            contentData={possibleOptions ? possibleOptions.filter(item => !item.required) : []}*/}
                            {/*            button={<Button*/}
                            {/*                fullwidth={true}*/}
                            {/*                tooltip={'Buchen Sie zusätzliche Pakete zu Ihren Vertrag hinzu.'}*/}
                            {/*                icon={<Icon icon={'cart'} /> } className={'contract-shop'} size={'xxl'} color={'success'}>Shop</Button>}*/}
                            {/*        />)}*/}
                            {/*    {(contract.state !== 'booked' || isRuntimeChanging() || !contract.ownContract) && (*/}
                            {/*        <Button icon={<Icon icon={'cart'} /> } className={'contract-shop'} disabled fullwidth={true} color={'success'}>Shop</Button>*/}
                            {/*    )}*/}
                            {/*</Col>*/}
                        </Row>
                        {/*{bookedOptions.filter(item => !item.required).length === 0 && (*/}
                        {/*    <Row className={'mb-2'}>*/}
                        {/*        <Col md={12}>*/}
                        {/*            <Alert type={'warning'}>Keine Optionen gebucht.</Alert>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*)}*/}
                        <Row className={'mb-2'}>
                            <Col md={12}>
                                <Table
                                    columns={columns}
                                    data={[{title: `${getContractType(contract.product.type)}vertrag`, bookedAt:  contract.validFrom,price: contract.invoice.sub.base, interval: contract.product.runtime}]}
                                    searchable={false}
                                    pagination={false}
                                />
                            </Col>
                        </Row>
                        {bookedOptions && bookedOptions.filter(item => !item.required).length > 0 && (
                            <Row className={'mb-2'}>
                                <Col md={12}>
                                    <Table
                                        columns={columns}
                                        service={itemService}
                                        searchable={false}
                                        pagination={false}
                                        onCollapseContent={collapsePackages}
                                        self={itemService}
                                    />
                                </Col>
                            </Row>
                        )}
                    </div>
                    {isRuntimeChanging() && contract.state !== 'adoption' &&
                    <div className={'shop-blocker-overlay'}>
                        <Row className={'mt-6 mb-6'}>
                            <Col omd={2} md={8}>
                                <Alert type={'danger'}>Während des Laufzeitwechsels können keine weiteren Optionen gebucht oder gekündigt werden.</Alert>
                            </Col>
                        </Row>
                    </div>}
                    {contract.state !== 'booked' && contract.state !== 'adoption' &&
                    <div className={'shop-blocker-overlay'}>
                        <Row className={'mt-6 mb-6'}>
                            <Col omd={2} md={8}>
                                <Alert type={'danger'}>Während eines Kostenübernahmeantrags können keine weiteren Optionen gebucht oder gekündigt werden.</Alert>
                            </Col>
                        </Row>
                    </div>
                    }
                </div>
                <Row className={`sum-price-row contract-sum-price-row ${contract.state !== 'booked' || isRuntimeChanging() ? 'shop-readonly' : ''}`}>
                    <Col olg={4} lg={8} oxl={8} xl={4}>
                        {/*<Row className={'mt-3 part-price pt-2 pb-1 '}>*/}
                        {/*    <Col md={8} className={'pb-1'}><strong>Zwischensumme</strong></Col>*/}
                        {/*    <Col md={4} className={'price pb-1'}><strong>{parseFloat(getPrevContract().subTotal).toFixed(2)}&euro;</strong></Col>*/}
                        {/*</Row>*/}
                        {/*{bookedOptions.filter(item => item.priceFormula).map(item => <Row className={'pt-2 pb-1 price-invoice contract-item-price'}>*/}
                        {/*    <Col md={8}>{item.shopTitle ? item.shopTitle : item.title}</Col>*/}
                        {/*    <Col md={4}>{parseFloat(item.price).toFixed(2)}&euro;</Col>*/}
                        {/*</Row>)}*/}
                        <Row className={'mt-1 final-price pt-2 pb-1 '}>
                            <Col lg={6} xl={8} className={'pb-1'}><strong>Gesamtbetrag</strong></Col>
                            <Col lg={6} xl={4} className={'price pb-1'}><strong>{parseFloat(contract.invoice.sub.base + getOptionPrice()).toFixed(2)}&euro;</strong></Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Fragment>
}

ContractCollapse.propTypes = {};

ContractCollapse.defaultProps = {};

export default ContractCollapse;
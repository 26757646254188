import React, {useContext, useState} from "react";
import Col from "@components/Col";
import Input from "@components/Input";
import Button from "@components/Button";
import Row from "@components/Row";
import eventServiceFactory from "@factories/eventServiceFactory";
import AuthContext from "@context/AuthContext";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const CommunicationForm = ({doRefresh, id, ...args}) => {
    /**
     * Context
     */
    const {auth} = useContext(AuthContext);


    /**
     * Service
     */
    const service = eventServiceFactory({auth: auth});

    /**
     * State
     */
    const [state, setState] = useState({message: ''});

    /**
     * Functions
     */
    const change = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const submit = async () => {
        console.log(state);
        let data = new FormData();
        data.append('type', 'message');
        data.append('data', state.message.length > 0 ? state.message : '[{SYSTEM_FILE_UPLOAD}]');

        await service.create(id, data);
        if(doRefresh) {
            doRefresh();
        }

        setState({message: ''});
    }


    return <Row sm={12} className={'form-new-message'}>
        <Col sm={11}>
            <Input required onChange={change} value={state.message} name={'message'} placeholder={'Neue Nachricht'}/>
        </Col>
        <Col sm={1}>
            <Button disabled={state.message.length === 0} onClick={submit} color={'success'} size={'large'}>Senden</Button>
        </Col>
    </Row>
}

CommunicationForm.propTypes = {};

CommunicationForm.defaultProps = {};

export default CommunicationForm;
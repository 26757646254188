//import axios from "axios";
import mockxios from "../mock/mockxios";

//TODO to execute to real server switch objects from mockxios to axios
const request = {
    get: async (url, config) => { return await mockxios.get(url, config ? config : {}); },
    delete: async (url, config) => { return await mockxios.delete(url, config ? config : {}); },
    post: async (url, data, config) => { return await mockxios.post(url, data, config ? config : {}); },
    patch: async (url, data, config) => { return await mockxios.patch(url, data, config ? config : {}); },
    put: async (url, data, config) => { return await mockxios.put(url, data, config ? config : {}); }
}

export default request;
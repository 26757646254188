/* jshint esversion: 8 */
import {Fragment, useContext, useEffect, useState} from "react";
import Table from "@components/Table/Table";
import Content from "@components/Content";
import Row from "@components/Row";
import Col from "@components/Col";
import Card from "@components/Card";
import AuthContext from "@context/AuthContext";
import Link from "@components/Link";
import routeServiceFactory from "@factories/routeServiceFactory";
import Icon from "@components/Icon";
import userServiceFactory from "@factories/userServiceFactory";
import homeServiceFactory from "@factories/homeServiceFactory";
import {getRoleName} from "@utils/enumName";
import {getRoleCollection} from "@utils/utils";
import {hasPermission} from "@utils/utils";
import roleEnum from "@enum/roleEnum";
import Button from "@components/Button";
import { useHistory } from "react-router-dom";
import {toast} from "react-toastify";


const UserList = () => {

    /**
     * States
     */
    const [filterFields, setFilterFields] = useState([]);

    /**
     * Context
     */
    const {auth, setAuth} = useContext(AuthContext);
    const history = useHistory();

    /**
     * Vars
     */
    const table = {};

    /**
     * Services
     */
    const routeService = routeServiceFactory();
    const userService = userServiceFactory({auth: auth});
    const homeService = homeServiceFactory({auth: auth});

    /**
     * Hooks
     */
    useEffect(() => {
        fetchFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Functions
     */
    const readAll = async () => {
        await userService.seen();
        table.refresh();
    };

    const masqueradeLogin = async (id, name) => {
        const response = await userService.masqueradeLogin(id);

        localStorage.setItem('auth', JSON.stringify({...response, switchBackToken: auth}));
        setAuth({...response, switchBackToken: auth});

        toast.success(`Auf den Nutzer "${name}" gewechselt!!!`);

        history.push('/profil');
    };

    const fetchFilter = async () => {
        //initFilterFields
        let roles = getRoleCollection().filter((item) => {
            switch(item.key) {
                case roleEnum.ROLE_HOME:
                    return hasPermission(auth, 'user_list_filter_home_view', true);
                case roleEnum.ROLE_DOCTOR:
                    return hasPermission(auth, 'user_list_filter_doctor_view', true);
                case roleEnum.ROLE_DRIVER:
                    return hasPermission(auth, 'user_list_filter_driver_view', true);
                case roleEnum.ROLE_PHARMA:
                    return hasPermission(auth, 'user_list_filter_pharma_view', true);
                default:
                    return hasPermission(auth, [], true);
            }
        });
        roles = roles.map((item) => {item.value = item.key; return item; });

        let fields = [];
        fields.push({name: 'roles', placeholder: 'Nutzergruppe', type: 'select', data: roles});

        if(hasPermission(auth, 'user_list_filter_home_view', true)) {
            let homeCollection = await homeService.get(null, {}, 0, 99999);
            homeCollection = homeCollection.items.map((item) => {
                return {value: item._id, label: `${item.name}`};
            });
            fields.push({name: 'home', placeholder: 'Wohnheime', type: 'select', data: homeCollection});
        }

        const states = [
            {value: 'new', label: 'Neu'},
            {value: 'removed', label: 'Gelöscht'}
        ];
        fields.push({name: 'status', placeholder: 'Status', type: 'select', data: states});

        setFilterFields([fields]);
    };

    const doRowHighlight = (item) => {
        return !item.seen || item.seen.indexOf(auth.user) === -1;
    };

    /**
     * Table Columns
     */
    const columns = [
        {name: 'username', display: hasPermission(auth, 'user_column_username_view'), copyable: true, title: 'Nutzername', render: (item, helper) => {
            return (<Link type={'uncolored'} to={routeService.userView(item._id)} >{helper.highlight(item.username)}</Link>);
        }},
        {name: 'roles', display: hasPermission(auth, 'user_column_group_view'), title: 'Nutzergruppe', render: (item) => {
            return item.roles.map((item) => getRoleName(item)).join(', ');
            }},
        {name: 'home', display: hasPermission(auth, 'user_column_location_view'), title: 'Ort', render: (item, helper) => {
                let location = item.home ? item.home.name : '';

                if(item.home && item.homeGroup.length > 0) {
                    location += ` / ${item.homeGroup.map(item => item.name).join(', ')}`
                }

                return helper.highlight(location);
            }},
        {name: 'actions', display: hasPermission(auth, 'user_column_actions_view'), title: 'Aktionen', align: 'right', render: (item) => {
                const id = item._id;

                return (<Fragment>
                    {auth.user !== id && (
                        <Link permission={'masquerade_view'} to={'#'} onClick={() => masqueradeLogin(id, item.username)}><Icon icon={'user-switch-in'} tooltip={`Als ${item.username} anmelden`} className={'color-primary'}/></Link>
                    )}
                    <Link permission={'user_edit_view'} to={routeService.userEdit(id)}><Icon icon={'edit'} tooltip={'Nutzer bearbeiten'} className={'color-primary'}/></Link>
                    </Fragment>);
            }
        }
    ];

    return (<Fragment>
        <Content>
            <Row sm={12}>
                <Col sm={12}>
                    <Card title={'Nutzer'} actions={
                        <Fragment>
                            {hasPermission(auth, 'user_create_view') && (
                            <Link to={routeService.userCreate()} type={'button'} >Neuer Nutzer</Link>)}
                            <Button onClick={readAll}>Alle Nutzer als Gelesen markieren</Button>
                        </Fragment>
                    }>
                        <Table
                            self={table}
                            columns={columns}
                            service={userService}
                            onRowHighlight={doRowHighlight}
                            filterElements={filterFields}
                            filterElementsExpanded={true}
                        />
                    </Card>
                </Col>
            </Row>
        </Content>
    </Fragment>);
}

export default UserList;
/* jshint esversion: 8 */
import {Fragment, useContext} from "react";
import Table from "@components/Table/Table";
import Content from "@components/Content";
import Row from "@components/Row";
import Col from "@components/Col";
import Card from "@components/Card";
import doctorOfficeServiceFactory from "@factories/doctorOfficeServicefactory";
import AuthContext from "@context/AuthContext";
import Link from "@components/Link";
import routeServiceFactory from "@factories/routeServiceFactory";
import Icon from "@components/Icon";
import {hasPermission} from "@utils/utils";
import Alert from "@components/Alert";


const DoctorOfficeList = () => {
    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * Services
     */
    const routeService = routeServiceFactory();
    const doctorOfficeService = doctorOfficeServiceFactory({auth: auth});

    /**
     * Table Columns
     */
    const columns = [
        {name: 'name', display: hasPermission(auth, 'doctor_office_column_name_view'), title: 'Name', render: (item, helper) => {
                return (<Link type={'uncolored'} to={routeService.doctorOfficeView(item._id)} >{helper.highlight(item.name)}</Link>);
            }},
        {name: 'contact_type', display: hasPermission(auth, 'doctor_office_column_contact_type_view'), title: 'Primär Kontakt', render: (item) => {
            switch(item.contactType) {
                case 'phone':
                    return 'Telefon';
                case 'fax':
                    return 'Fax';
                case 'mail':
                    return 'E-Mail';
                default:
                    return '';
            }
            }},
        {name: 'phone', display: hasPermission(auth, 'doctor_office_column_phone_view'), title: 'Telefon'},
        {name: 'email', display: hasPermission(auth, 'doctor_office_column_email_view'), title: 'E-Mail'},
        {name: 'fax', display: hasPermission(auth, 'doctor_office_column_fax_view'), title: 'Fax'},
        {name: 'actions', display: hasPermission(auth, 'doctor_office_column_actions_view'), title: 'Aktionen', align: 'right', render: (item) => {
                const id = item._id;

                return (
                    <ul className={'list-item-actions'}>
                        <Link permission={'doctor_office_list_view'} to={routeService.doctorOfficeView(id)}><Icon icon={'details'} /></Link>
                        <Link permission={'doctor_office_edit_view'} to={routeService.doctorOfficeEdit(id)}><Icon icon={'edit'} /></Link>
                    </ul>);
            }
        }
    ];

    return (<Fragment>
        <Content>
                <Row sm={12}>
                    <Col sm={12}>
                        <Card title={'Arztpraxen'} actions={<Fragment>
                            {hasPermission(auth, 'doctor_office_create_view') && (
                            <Link to={routeService.doctorOfficeCreate()} type={'button'} >Neue Arztpraxis</Link>
                            )}
                            </Fragment>}>
                            <Alert type={'info'}>
                                <div>
                                    <div style={{display: 'inline-block', verticalAlign: 'top'}}><Icon icon={'info'} style={{width: 30, height: 30}}/></div>
                                    <div style={{display: 'inline-block'}}>
                                        <ul style={{margin: 0, padding: 0}}>
                                            <li>Arztpraxen fassen mehrere Ärzte in einer Praxis zusammen. Dies ermöglicht dass, der Praxisbesitzer alle Aufträge und Patienten aller Ärzte die dieser Praxis zugeordnet sind sehen kann.</li>
                                            <li>Ärzte innerhalb einer Praxis können jedoch nur ihre eigenen Patienten und Aufträge sehen.</li>
                                        </ul>
                                    </div>
                                </div>
                            </Alert>
                            <Table columns={columns}
                                   service={doctorOfficeService}
                            />
                        </Card>
                    </Col>
                </Row>
        </Content>
    </Fragment>);
}

export default DoctorOfficeList;
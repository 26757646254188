/* jshint esversion: 8 */
import { TableContainer } from '@material-ui/core';
import React  from 'react';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useRespTableThemeStyles } from '../utils/respTableTheme';
import PickupGroupRow from './PickupGroupRow';
import {random} from "mathjs";

const PickupTable = ({ tasks, fetch, setSelectedTasks, selectedTasks }) => {
    /**
     * CSS
     */
    const classes = useRespTableThemeStyles();

    return (
        <TableContainer>
            <Table>
                <Thead>
                    <Tr className={classes.tbodyTh}>
                        <Th />
                        <Th />
                        <Th>Patient</Th>
                        <Th>Heim</Th>
                        <Th>Medikament</Th>
                        <Th>Typ</Th>
                        <Th>Bemerkungen</Th>
                        <Th align='right'>Aktionen</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tasks.map((taskGroup) => (
                        <PickupGroupRow
                            key={random()}
                            group={taskGroup}
                            selectedTasks={selectedTasks}
                            setSelectedTasks={setSelectedTasks}
                            fetch={fetch}
                        />
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    );
};

export default PickupTable;

import axios from "axios";
import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumbs, Card, CardContent, Grid, Link, Typography } from '@material-ui/core';

const Imprint = () => {
	const [state, setState] = useState({
		company: '',
		street: '',
		postalCode: '',
		city: '',
		phone: '',
		fax: '',
		email: '',
	});

	const fetchData = async () => {
		try {
			const response = await axios.get('/api/settings');
			setState(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<Breadcrumbs separator='›' aria-label='breadcrumb'>
				<Link to='/'>Medicharge</Link>
				<Typography color='textPrimary'>Impressum</Typography>
			</Breadcrumbs>
			<Card>
				<CardContent>
					<Typography component='h1' color='textSecondary' gutterBottom>
						Impressum
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<h2>Angaben gemäß § 5 TMG:</h2>
							<p><strong>{state.company}</strong><br/>
								{state.street}<br/>
								{state.postalCode} {state.city}</p>

							<h3>Kontakt:</h3>
							<p>{state.phone && "Telefon: " + state.phone}<br/>
								{state.fax && "Telefax: " + state.fax}<br/>
								{state.email && "E-Mail: " + state.email}</p>

							<h2>Technische Umsetzung</h2>
							<p><strong>MediCharge GmbH</strong><br/>
								Langenstr. 66b<br/>
								18439 Stralsund</p>

							<h3>Kontakt:</h3>
							<p>Telefon: +49 3831 2259130<br/>
								E-Mail: info@medicharge.de</p>

							<h3>Umsatzsteuer:</h3>
							<p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br/>
								DE331995393</p>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Fragment>
	);
};

export default Imprint;

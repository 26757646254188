import React, {Fragment, useContext} from "react";
import Card from "@components/Card";
import Row from "@components/Row";
import Col from "@components/Col";
import Table from "@components/Table/Table";
import AuthContext from "@context/AuthContext";
import meetingServiceFactory from "@factories/meetingServiceFactory";
import Link from "@components/Link";
import Icon from "@components/Icon";
import routeServiceFactory from "@factories/routeServiceFactory";
import moment from "moment/moment";

const MeetingList = ({...args}) => {

    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * Services
     */
    const meetingService = meetingServiceFactory({auth: auth});
    const routeService = routeServiceFactory();

    /**
     * Columns
     */
    const columns = [
        {name: 'company', title: 'Unternehmen'},
        {name: 'title', title: 'Packet'},
        {name: 'type', title: 'Typ'},
        {name: 'date', title: 'Datum', render: (item) => {
            if(item.date) {
                const mom = moment(item.date);

                return mom.format('DD.MM.YYYY');
            }

            return '';

            }},
        {name: 'time', title: 'Uhrzeit'},
        {name: 'bookedAt', title: 'Gebucht am', render: item => {
                const mom = moment(item.bookedAt);

                return mom.format('DD.MM.YYYY');
            }},
        {name: 'actions', title: 'Aktionen', align: 'right',  render: (item) => {
                const id = `${item.contractId}/${item.optionId}`;

                return (<Fragment>
                    <Link permission={'meeting_edit_view'} to={routeService.meetingEdit(id)}><Icon icon={'edit'} tooltip={'Termin bearbeiten'} className={'color-primary'}/></Link>
                </Fragment>);
            }
        }
    ];

    return (<Fragment>
        <Row sm={12}>
            <Col sm={12}>
                <Card title={'Termine'}>
                    <Table
                        columns={columns}
                        service={meetingService}
                    />
                </Card>
            </Col>
        </Row>
    </Fragment>);
};

MeetingList.propTypes = {};

MeetingList.defaultProps = {};

export default MeetingList;
import React from "react";
import PropTypes from "prop-types";
import {addClass} from "@utils/utils"; //comment in if you add Proptypes to your Component


const Alert = ({children, type, className, ...args}) => {



    return <div className={'alert-container'}>
        <div className={addClass([`alert alert-${type}`, className])}>
            {children}
        </div>
    </div>
}

Alert.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'])
};

Alert.defaultProps = {
    type: 'primary',
    className: ''
};

export default Alert;
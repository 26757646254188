import {getRandomPastDate, getRandomSingleItem, makeMockxiosId} from "../maxiosUtils";

const db = (homeGroups) => {
    return {
        '/api/patients': [
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                firstname: 'Mario',
                lastname: 'Zimmermann',
                title: 'Dr. ',
                street: 'Grubenstr 12',
                postalCode: 85512,
                city: 'Stralsund',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                firstname: 'Isabell',
                lastname: 'Flimmer',
                title: null,
                street: 'Falkenstr 8',
                postalCode: '18736',
                city: 'Ribnitz',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                firstname: 'Sonja',
                lastname: 'Diemens',
                title: '',
                street: 'Astraalle 78',
                postalCode: 97721,
                city: 'München',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                firstname: 'Heiko',
                lastname: 'Lübke',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                firstname: 'Tilo',
                lastname: 'Hanke',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                firstname: 'Paul',
                lastname: 'Emiesen',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                firstname: 'Wibke',
                lastname: 'Rebrecht',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                firstname: 'Uthe',
                lastname: 'Veigle',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                firstname: 'Jochen',
                lastname: 'Schmiedt',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                firstname: 'Claudia',
                lastname: 'Weber',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                firstname: 'Yoko',
                lastname: 'Kalsen',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                firstname: 'Doloris',
                lastname: 'Backer',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                firstname: 'Olga',
                lastname: 'Trenlich',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                firstname: 'Konrad',
                lastname: 'Landenauer',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                firstname: 'Ines',
                lastname: 'Kränkle',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                firstname: 'Robert',
                lastname: 'Koch',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                firstname: 'Tim',
                lastname: 'Malnicht',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                firstname: 'Otto',
                lastname: 'Lobecht',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                firstname: 'Heidi',
                lastname: 'Weide',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                firstname: 'Franja',
                lastname: 'Marlin',
                dateOfBirth: getRandomPastDate(),
                homeGroup: getRandomSingleItem(homeGroups)
            },
        ]
    }
}

export default db;
import Content from "@components/Content";
import Row from "@components/Row";
import Col from "@components/Col";
import ButtonDelete from "@components/ButtonDelete";
import {Fragment, useContext, useEffect, useState} from "react";
import AuthContext from "@context/AuthContext";
import {useHistory, useParams} from "react-router-dom";
import Icon from "@components/Icon";
import routeServiceFactory from "@factories/routeServiceFactory";
import userServiceFactory from "@factories/userServiceFactory";
import User from "@views/parts/forms/dialog/user/User";
import Button from "@components/Button";
import LastChange from "@components/LastChange";
import {toast} from "react-toastify";


const UserEdit = () => {
    const { id } = useParams();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const history = useHistory();

    /**
     * States
     */
    const [state, setState] = useState({});
    const [valid, setValid] = useState(false);

    /**
     * Services
     */
    const routeService = routeServiceFactory();
    const userService = userServiceFactory({auth: auth});

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Functions
     */
    const fetch = async () => {
        const data = await userService.get(id, {__exclude: ['avatar']});

        setState({...data});
    }

    const change = (e) => {
        const data = e.target ? {[e.target.name]: e.target.value} : e;
        setState({...state, ...data});
    }

    const submit = async () => {
        const reactivate = state.status === 'removed';
        let update = {...state, status: 'new'};
        delete update.avatar;
        const response = await userService.update(id, update);

        if(response.success) {
            if(reactivate) {
                toast.success('User wurde erfolgreich reaktiviert.');
            } else {
                toast.success('User wurde erfolgreich aktualisiert.');
            }
        }

        history.push(routeService.userList());

        return false;
    }

    const remove = async () => {
        await userService.delete(id);
        history.push(routeService.userList());
    }

    return (
        <Content>
            <form noValidate onSubmit={submit}>
                <Row sm={12}>
                    <Col sm={12}>
                        <User
                            edit={true}
                            form={state}
                            name={'user'}
                            title={'Benutzer bearbeiten'}
                            icon={<Icon icon={'person'} />}
                            setValid={setValid}
                            onChange={change}
                            footer={
                                <Fragment>
                                    <div className='container'>
                                        <Row className={'mt-3'}>
                                            <Col sm={6}>
                                                <LastChange data={state}/>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <Col sm={6}>
                                            {state.status !== 'removed' &&
                                            (<ButtonDelete
                                                placeholder={'Name des zu löschenden Nutzers'}
                                                onClick={remove}
                                                compare={state.username}
                                            />)}
                                        </Col>
                                        <Col sm={6}>
                                            <Button
                                                type={'submit'}
                                                color={'success'}
                                                disabled={!valid}
                                                className='mt-4 float-end'
                                                onClick={submit}
                                            >
                                                {state.status === 'removed' ? 'Reaktivieren' : 'Speichern'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                        />
                    </Col>
                </Row>
            </form>
        </Content>
    );
}

export default UserEdit;
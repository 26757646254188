import React, {Fragment, useContext} from "react";
import AuthContext from '@context/AuthContext';
import Icon from "../../components/Icon";
import Link from "../../components/Link";
import {useHistory} from "react-router-dom";
import routeServiceFactory from "@factories/routeServiceFactory";
import {toast} from "react-toastify";


const Masquerade = ({...args}) => {

    /**
     * Context
     */
    const { auth, setAuth } = useContext(AuthContext);
    const history = useHistory();

    /**
     * Services
     */
    const routeService = routeServiceFactory();

    /**
     * Functions
     */

    const switchBack = () => {
        localStorage.setItem('auth', JSON.stringify(auth.switchBackToken));
        setAuth(auth.switchBackToken);

        toast.success(`Als Administrator Nutzer angemeldet.`);

        history.push(routeService.userList());
    }

    if(auth.switchBackToken) {
        return <Link to={'#'} className={'masquerade-link'} onClick={() => switchBack()}>
            <Icon icon={'user-switch-out'} tooltip={'Zum ursprünglichen Nutzer wechseln'} />
        </Link>
    }

    return <Fragment />
}

Masquerade.propTypes = {};

Masquerade.defaultProps = {};

export default Masquerade;
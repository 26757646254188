/* jshint esversion: 8 */
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TableContainer,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {AiOutlineDelete} from 'react-icons/ai';
import {FiFilter, MdOutlineMarkEmailRead} from "react-icons/all";
import {BiSortAZ, BiSortZA} from 'react-icons/bi';
import {FiEdit2} from 'react-icons/fi';
import {MdAdd} from 'react-icons/md';
import {Link, useLocation} from 'react-router-dom';
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import McPagination from "../components/Pagination";
import AuthContext from '../context/auth';
import {filterThemeStyles, serialize} from "../utils/filter";
import handleErrors from '../utils/handleErrors';
import {useRespTableThemeStyles} from "../utils/respTableTheme";
import {createUriSortParameters, initSorting} from "../utils/sorting";
import Loader from "../components/Loader";
import New from "../components/new";
import {hasPermission} from "@utils/utils";

const PatientList = ({history}) => {
    /**
     * CSS
     */
    const classes = {
        ...useRespTableThemeStyles(),
        ...filterThemeStyles()
    };

    const {auth, setAuth} = useContext(AuthContext);
    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);

    /**
     * State
     */
    const [state, setState] = useState('loading');
    const [sort, setSort] = useState({});
    const [filter, setFilter] = useState({text: '', home: '', notSeen: !!query.get('filter[notSeen]')});
    const [filterShow, showFilter] = useState(!!query.get('filter[notSeen]'));
    const [homes, setHomes] = useState([]);
    const [loading, setLoading] = useState(true);


    const fetchData = async (page, limit) => {
        try {
            setLoading(true);
            page = page ? page : 1;
            const filterQuery = serialize({filter: filter});
            let response = await axios.get(`/api/patients?${filterQuery}&${createUriSortParameters(sort)}&limit=${limit}&offset=${(page - 1) * limit}`, {headers: {'x-auth-token': auth.token}});
            setState(response.data.data);

            setLoading(false);
            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchHomes = async () => {
        try {
            const response = await axios.get(`/api/homes?limit=99999`, {
                headers: {'x-auth-token': auth.token},
            });
            setHomes(response.data.data);

            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchHomes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * CONTROLS
     */
    const changeFilter = (e) => {
        setFilter({...filter, [e.target.name]: e.target.value});
    };

    const changeFilterCheckbox = (e) => {
        if (e.target.checked) {
            setFilter({...filter, [e.target.name]: 1});
        } else {
            setFilter({...filter, [e.target.name]: 0});
        }
    }

    /**
     * Functions
     */
    const setAllAsRead = async () => {
        try {
            await axios.put(`/api/statistics/patient_read_all`, {}, {
                headers: {'x-auth-token': auth.token},
            });
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    }

    initSorting(sort, setSort, filter, setFilter);

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Typography color='textPrimary'>Patienten</Typography>
            </Breadcrumbs>
            <Tooltip title='Filter anzeigen/verbergen' className={classes.filterButton}>
                <IconButton onClick={() => showFilter(!filterShow)} size='small'>
                    <FiFilter/>
                </IconButton>
            </Tooltip>
            <Card>
                <CardContent>
                    <Typography component='h1' color='textSecondary' gutterBottom>
                        Patienten
                    </Typography>
                    <Grid container spacing={2}>
                        {filterShow && (
                            <Grid item xs={12} className='filterWidget'>
                                <Card>
                                    <CardContent>
                                        <Typography component='h1' color='textSecondary' gutterBottom>
                                            Filter
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id='text-filter'
                                                        label='Suche'
                                                        name='text'
                                                        onChange={changeFilter}
                                                        value={filter.text}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.filter}>
                                                <InputLabel id='home-filter'>Heime</InputLabel>
                                                <Select
                                                    labelId='home-filter'
                                                    id='home-filter-select'
                                                    name={'home'}
                                                    value={filter.home}
                                                    onChange={changeFilter}
                                                    fullWidth
                                                >
                                                    <MenuItem value={''}/>
                                                    {homes.map((option) => (
                                                        <MenuItem value={option._id}>{option.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item sm={3} xs={12} className={classes.filter}>
                                                <Grid container spacing={2}>
                                                    <Grid item sm={1}>
                                                        <FormControl fullWidth>
                                                            <Checkbox
                                                                checked={filter.notSeen}
                                                                value={1}
                                                                name={'notSeen'}
                                                                onChange={changeFilterCheckbox}
                                                                color='primary'
                                                                label={'nur neue Patienten anzeigen'}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item sm={11}><span className={classes.filterChkLabel}>nur neue Patienten anzeigen</span></Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container justify='flex-end' spacing={2}>
                                <Grid xl={4} lg={5} md={6} sm={12} xs={12} item>
                                    {hasPermission(auth, 'patient_seen_view') && (
                                    <Button
                                        className={classes.markAsRead}
                                        color='primary'
                                        endIcon={<MdOutlineMarkEmailRead/>}
                                        fullWidth
                                        onClick={setAllAsRead}
                                        type='submit'
                                        variant='contained'
                                    >
                                        alle Patienten als Gelesen markieren
                                    </Button>)}
                                </Grid>
                                <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                                    {hasPermission(auth, 'patient_create_view') && (
                                        <Button
                                            color='primary'
                                            component={Link}
                                            endIcon={<MdAdd/>}
                                            fullWidth
                                            to='/patient/neu'
                                            variant='contained'
                                        >
                                            Patient hinzufügen
                                        </Button>)}
                                </Grid>
                            </Grid>
                            {!loading && (<TableContainer>
                                <Table>
                                    <Thead>
                                        <Tr className={classes.tbodyTh}>
                                            {hasPermission(auth, 'patient_column_firstname_view') && (
                                            <Th align={'left'} className='filterable' data-field='firstname'>
                                                Vorname
                                                <BiSortAZ className='sort-icon asc'/>
                                                <BiSortZA className='sort-icon desc'/>
                                            </Th>)}
                                            {hasPermission(auth, 'patient_column_lastname_view') && (
                                            <Th align={'left'} className='filterable' data-field='lastname'>
                                                Nachname
                                                <BiSortAZ className='sort-icon asc'/>
                                                <BiSortZA className='sort-icon desc'/>
                                            </Th>)}
                                            {hasPermission(auth, 'patient_column_birthday_view') && (
                                            <Th align={'left'} className='filterable' data-field='dateOfBirTableCell'>
                                                Geburtsdatum
                                                <BiSortAZ className='sort-icon asc'/>
                                                <BiSortZA className='sort-icon desc'/>
                                            </Th>)}
                                            {hasPermission(auth, 'patient_column_home_view') && (
                                            <Th align={'left'} data-field='home'>Pflegeeinrichtung</Th>)}
                                            {hasPermission(auth, 'patient_column_home_group_view') && (
                                            <Th align={'left'} data-field='homeGroup'>Wohnbereich</Th>)}
                                            {hasPermission(auth, 'patient_column_actions_view') && (
                                            <Th align='right'>Aktionen</Th>)}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {state === 'loading' ? (
                                            <Tr>
                                                <Td align='center' colSpan={6}>
                                                    Lade Daten...
                                                </Td>
                                            </Tr>
                                        ) : state.length === 0 ? (
                                            <Tr>
                                                <Td align='center' colSpan={6}>
                                                    Kein Daten vorhanden.
                                                </Td>
                                            </Tr>
                                        ) : (
                                            state.map((patient, i) => (
                                                <Tr key={i} className={classes.tbodyTr}>
                                                    {hasPermission(auth, 'patient_column_firstname_view') && (
                                                    <Td>{patient.firstname    && (<Link to={`/patient/details/${patient._id}`} title='Bearbeiten'>{patient.firstname}</Link>)} {!(patient.seen && patient.seen.includes(auth.user)) && (<New />)} </Td>
                                                    )}
                                                    {hasPermission(auth, 'patient_column_lastname_view') && (
                                                        <Td>{patient.lastname     && (<Link to={`/patient/details/${patient._id}`} title='Bearbeiten'>{patient.lastname}</Link>)} </Td>
                                                    )}
                                                    {hasPermission(auth, 'patient_column_birthday_view') && (
                                                        <Td>{patient.dateOfBirth  && (<Link to={`/patient/details/${patient._id}`} title='Bearbeiten'>{moment(patient.dateOfBirth).format('DD.MM.YYYY')}</Link>)} </Td>
                                                    )}
                                                    {hasPermission(auth, 'patient_column_home_view') && (
                                                        <Td>
                                                            {hasPermission(auth, 'home_edit_view') && patient.home && (
                                                                <Link to={`/einrichtung/${patient.home._id}`} title='Bearbeiten'>{patient.home.name}</Link>
                                                            )}
                                                            {!hasPermission(auth, 'home_edit_view') && patient.home && (
                                                                <Fragment>
                                                                    {patient.home.name}
                                                                </Fragment>
                                                            )}

                                                             </Td>
                                                    )}
                                                    {hasPermission(auth, 'patient_column_home_group_view') && (
                                                        <Td>{patient.homeGroup && patient.homeGroup.name} </Td>
                                                    )}
                                                    {hasPermission(auth, 'patient_column_actions_view') && (
                                                    <Td align='right'>
                                                        {hasPermission(auth, 'patient_edit_view') && (
                                                        <Tooltip title='Bearbeiten'>
                                                            <IconButton color='primary' size='small' component={Link} to={`/patient/${patient._id}`} aria-label='bearbeiten'>
                                                                <FiEdit2/>
                                                            </IconButton>
                                                        </Tooltip>)}
                                                        {hasPermission(auth, 'patient_delete_view') && (
                                                            <Tooltip title='Löschen'>
                                                                <IconButton color='secondary' test = {`${patient.firstname}`} size='small' component={Link} to={`/patient/${patient._id}/loeschen`} aria-label='löschen'>
                                                                    <AiOutlineDelete/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </Td>
                                                    )}
                                                </Tr>
                                            ))
                                        )}
                                    </Tbody>
                                </Table>
                            </TableContainer>)}
                        </Grid>
                    </Grid>
                    <McPagination fetch={fetchData} filter={filter}/>
                    {loading && (<Loader center size={'large'} color={'#5588a3'}/>)}
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default PatientList;

import React, {useContext} from "react";
import PropTypes from "prop-types";
import {addClass, rand} from "@utils/utils";
import moment from "moment";
import Icon from "@components/Icon";
import AuthContext from "@context/AuthContext";
import eventServiceFactory from "@factories/eventServiceFactory";
import FileDownload from "js-file-download"; //comment in if you add Proptypes to your Component


const Message = ({entry, creator, date, files, children, type, ...args}) => {
    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * Service
     */
    const eventService = eventServiceFactory({auth})

    /**
     * Function
     */
    const getIcon = (key) => {
        switch(key) {
            case 'message':
                return 'user';
            case 'self':
                return 'user';
            case 'document':
                return 'document';
            case 'fax':
                return 'fax';
            case 'faxresponse':
                return 'fax';
            case 'email':
                return 'email';
            default:
                return '';
        }
    }

    const fileType = (item) => {
        if(item.indexOf('.pdf') !== -1) {
            return 'pdf'
        } else if((item.indexOf('.jpg') !== -1 || item.indexOf('.jpeg') !== -1 || item.indexOf('.png') !== -1)) {
            return 'jpg'
        }

        return 'unknown_document';
    }

    const download = async (name) => {
        const file = await eventService.download(entry.task, entry._id, name);

        FileDownload(file, name);
    }

    return <div className={addClass(['content', type])}>
        <div className={'avatar img-xs rounded-circle'}>
            <Icon icon={getIcon(type)} tooltip={creator}/>
        </div>
        <div className={'message-content'}>
            <div className={'message'}>
                <div className={'bubble-context'}>
                    <div className={'bubble'}>
                        {children}
                    </div>
                    <ul className={'files'}>
                        {files.map((item) => <li key={rand()}><Icon onClick={() => download(item)} icon={fileType(item)} tooltip={item} /></li>)}
                    </ul>
                </div>
                <span className={'time'}>{moment().format('HH:mm')}</span>
                <span className={'author'}>{creator}</span>
            </div>
        </div>
    </div>
}

Message.propTypes = {
    type: PropTypes.oneOf(['message', 'document', 'fax', 'self', 'faxresponse', 'email'])
};

Message.defaultProps = {
    type: 'message'
};

export default Message;
import React, {useEffect, useState} from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
    paginationContainer: {
        position: 'relative',
        margin: '30px 0 0 0',
        textAlign: 'left',
    },
    paginationDataSet: {
        fontSize: '12px',
        marginTop: '8px',
        textAlign: 'center',
    },
    paginationLimit: {
        textAlign: 'right',
        // eslint-disable-next-line no-useless-computed-key
        ['@media screen and (max-width: 40em)']: {
            // eslint-disable-line no-useless-computed-key
            textAlign: 'center',
        },
    },
    pagination: {
        // eslint-disable-next-line no-useless-computed-key
        ['@media screen and (max-width: 40em)']: {
            // eslint-disable-line no-useless-computed-key
            textAlign: 'center',
            '& > nav': {
                display: 'inline-block',
                '& > ul': {
                    justifyContent: 'center',
                },
            },
        },
        // eslint-disable-next-line no-useless-computed-key
        ['@media screen and (min-width: 1001px)']: {
            // eslint-disable-line no-useless-computed-key
            textAlign: 'left',
        },
    },
});

const McPagination = ({ fetch, filter, setLimit }) => {
    const classes = useStyles();
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const limit = new URLSearchParams(search).get('limit');

    let limits = [limit ? limit : 20, 20, 50];

    limits = limits.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });

    limits.sort((a, b) => {
        return a - b;
    });

    const [pagination, setPagination] = React.useState({
        current: page ? page : 1,
        max: 0,
        limit: limit ? limit : 50,
        items: 0,
    });
    const [delay, setDelay] = useState(null);

    const paginate = async (evt, value, options) => {
        let max = 0;
        let limit = options && options.limit ? options.limit : pagination.limit;
        if(setLimit){
            setLimit(limit);
        }
        

        if (fetch) {
            max = await fetch(value, limit);
        }

        setPagination({
            ...pagination,
            current: value,
            max: Math.ceil(max / limit),
            items: max,
            ...options,
        });
    };

    const change = (evt) => {
        paginate(null, 1, { limit: evt.target.value });
    };

    useEffect(() => {
        if(delay) {
            clearTimeout(delay);
            setDelay(null);
        }

        setDelay(setTimeout((paginate) => {
            paginate(null, 1);
        }, 500, paginate))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return (
        <Grid container justify='space-between' className={classes.paginationContainer}>
            <Grid item xs={12} sm={6} lg={7} xl={9} className={classes.pagination}>
                {pagination.current <= pagination.max && (
                    <Pagination
                        count={pagination.max}
                        onChange={paginate}
                        page={pagination.current}
                        showFirstButton
                        showLastButton
                    />
                )}
            </Grid>
            <Grid item xs={12} sm={4} lg={3} xl={2}>
                <div className={classes.paginationDataSet}>
                    {(pagination.current - 1) * pagination.limit + 1}
                    &nbsp;-&nbsp;
                    {pagination.current * pagination.limit > pagination.items
                        ? pagination.items
                        : pagination.current * pagination.limit}
                    &nbsp;von&nbsp;
                    {pagination.items}
                    &nbsp;Datens&auml;tzen
                </div>
            </Grid>
            <Grid item xs={12} sm={2} lg={1} xl={1}>
                <div className={classes.paginationLimit}>
                    <span>Zeilen:&nbsp;</span>
                    <Select labelId='pagination-limit' id='pagination-limit' value={pagination.limit} onChange={change}>
                        {limits.map((item) => (
                            <MenuItem key={`pagination-key-${item}`} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </Grid>
        </Grid>
    );
};

export default McPagination;

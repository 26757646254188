import React, {Fragment, useEffect, useState} from "react";
import {IconButton} from "@material-ui/core";
import {Circles, ThreeDots} from "react-loading-icons";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
//comment in if you add Proptypes to your Component

const useStyles = makeStyles({
    loader: {
        width: 24,
        height: 24,
        padding: 3,
        fontSize: "1.125rem",
        verticalAlign: 'top',
        cursor: "default"
    }
});

const ActionLoader = ({color, children, onClick, type, className, size, ...args}) => {

    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * State
     */
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Hooks
     */
    useEffect(() => {
        if(isLoading) {
            if(onClick) {
                onClick();
            }

            setIsLoading(!isLoading);
        }
    }, [isLoading]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const click = async () => {
        setIsLoading(!isLoading);
    }

    return <Fragment>
        {!isLoading && (<IconButton onClick={click} size={size} className={className}>
            {children}
        </IconButton>)}
        {isLoading && type === 'Circles' && (<Circles stroke={color} fill={color} className={classes.loader}/>)}
        {isLoading && type === 'ThreeDots' && (<ThreeDots stroke={color} fill={color} className={classes.loader}/>)}
    </Fragment>
}

ActionLoader.propTypes = {
    color: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['Circles', 'ThreeDots'])
};

ActionLoader.defaultProps = {
    color: 'orange',
    type: 'Circles'
};

export default ActionLoader;
import Content from "@components/Content";
import Row from "@components/Row";
import Col from "@components/Col";
import {useContext, useEffect, useState} from "react";
import AuthContext from "@context/AuthContext";
import {useParams} from "react-router-dom";
import Icon from "@components/Icon";
import routeServiceFactory from "@factories/routeServiceFactory";
import userServiceFactory from "@factories/userServiceFactory";
import homeServiceFactory from "@factories/homeServiceFactory";
import doctorServiceFactory from "@factories/doctorServiceFactory";
import Summary from "@views/parts/forms/dialog/user/summary/Summary";
import Link from "@components/Link"
const UserView = () => {
    const { id } = useParams();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [state, setState] = useState({});

    /**
     * Services
     */
    const routeService = routeServiceFactory();
    const userService = userServiceFactory({auth: auth});
    const homeService = homeServiceFactory({auth: auth});
    const doctorService = doctorServiceFactory({auth: auth});

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Functions
     */
    const fetch = async () => {
        let data = await userService.get(id);

        if(typeof data.home === 'string') {
            data.home = await homeService.get(data.home);
        }

        if(typeof data.doctor === 'string') {
            data.doctor = await doctorService.get(data.doctor);
        }

        setState({...data});
    }

    return (
        <Content title='Nutzer'>
                <Row sm={12}>
                    <Col sm={12}>
                        <div className={'last-change-view'}>
                            <div>
                                <Link permission={'user-edit-btn'} className={'user-edit-btn'} to={routeService.userEdit(id)} size={'small'} type={'button-outlined'}>bearbeiten</Link>
                            </div>
                        </div>
                        <Summary
                            edit={false}
                            form={{user: state}}
                            name={'user'}
                            title={'Benutzer'}
                            icon={<Icon icon={'person'} />}
                        />
                    </Col>
                </Row>
        </Content>
    );
}

export default UserView;
/* jshint esversion: 8 */
import { Checkbox, FormControlLabel, Grid, TableContainer } from '@material-ui/core';
import React from 'react';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useRespTableThemeStyles } from '../utils/respTableTheme';
import TaskRow from '../components/TaskRow';
import PropTypes from 'prop-types';
import { getScreenResolution, hasPermission } from '@utils/utils';

const TaskTable = ({ eventCount, auth, hidePatientName, tasks, fetch, parentContainer}) => {
    /**
     * CSS
     */
    const classes = useRespTableThemeStyles();

    const checkAll = (e) => {
        let collection = document.querySelectorAll('.task-chk input');

        for (let ii = 0; ii < collection.length; ii++) {
            window.setTimeout(
                (input, checked) => {
                    if (checked !== input.checked) {
                        input.click();
                    }
                },
                0,
                collection[ii],
                e.target.checked
            );
        }
    };

    return (
        <>
            {tasks && tasks.length > 1 && (
                <Grid container spacing={2} className={classes.checkAllContainer}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    id={'check-all-tasks'}
                                    onClick={checkAll}
                                    title='Alle auswählen'
                                />
                            }
                            label='Alle auswählen'
                        />
                    </Grid>
                </Grid>
            )}
            <TableContainer>
                <Table className={classes.root}>
                    <Thead>
                        <Tr className={classes.theadTr}>
                            <Th />
                            <Th style={{ width: 0 }} />
                            {hasPermission(auth, 'task_column_messages_count_view') && <Th />}
                            {hasPermission(auth, 'task_column_patient_view') && !hidePatientName && <Th>Patient</Th>}
                            {getScreenResolution() > 768 && hasPermission(auth, 'task_column_home_view') && (
                                <Th>Heim</Th>
                            )}
                            {hasPermission(auth, 'task_column_medicine_view') && <Th align='right'>Medikament</Th>}
                            {getScreenResolution() > 768 && hasPermission(auth, 'task_column_doctor_view') && (
                                <Th>Arzt</Th>
                            )}
                            {hasPermission(auth, 'task_column_actions_view') && <Th align='right'>Aktionen</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {tasks.map((task) => (
                            <TaskRow
                                eventCount={eventCount && eventCount[task._id] ? eventCount[task._id] : null}
                                hidePatientName={hidePatientName}
                                key={task._id}
                                task={task}
                                fetch={fetch}
                                parentContainer={parentContainer}
                            />
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};

TaskTable.propTypes = {
    hidePatientName: PropTypes.bool,
};

TaskTable.defaultProps = {
    hidePatientName: false,
};

export default TaskTable;

import React, {Fragment, useContext, useEffect, useState} from "react";
import Organisation from "@views/parts/forms/dialog/doctor/office/Organisation";
import Icon from "@components/Icon";
import {useHistory, useParams} from "react-router-dom";
import doctorOfficeServiceFactory from "@factories/doctorOfficeServicefactory";
import AuthContext from "@context/AuthContext";
import Row from "@components/Row";
import Col from "@components/Col";
import Content from "@components/Content";
import routeServiceFactory from "@factories/routeServiceFactory";
import ButtonDelete from "@components/ButtonDelete";
import Card from "@components/Card";
import LastChange from "@components/LastChange";
import Button from "../../../components/Button";
import doctorServiceFactory from "@factories/doctorServiceFactory";
import Modal from "@components/Modal";
import Alert from "@components/Alert";
import Checkbox from "@components/Checkbox";

const DoctorOfficeEdit = () => {
    const { id } = useParams();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const history = useHistory();

    /**
     * States
     */
    const [state, setState] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [openEmptyDoctorsModal, setOpenEmptyDoctorsModal] = useState(false);

    /**
     * Services
     */
    const routeService = routeServiceFactory();
    const doctorOfficeService = doctorOfficeServiceFactory({auth: auth});
    const doctorService = doctorServiceFactory({auth});

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Functions
     */
    const closeEmptyDoctorModal = () => {
        setOpenEmptyDoctorsModal(false)
    }

    const fetch = async () => {
        const data = await doctorOfficeService.get(id);

        setState({...data, oldDoctors: data.doctors});
    }

    const change = (e) => {
        const data = e.target ? {[e.target.name]: e.target.value} : e;
        setState({...state, ...data});
    }

    const submit = async (e) => {
        if(!state.doctors || state.doctors.length === 0) {
            setOpenEmptyDoctorsModal(true);
        } else {
            await sendRequest();
        }

        return false;
    };

    const sendRequest = async () => {
        await doctorOfficeService.update(id, {...state, doctors: null, oldDoctors: null});

        if(state.doctors) {

            const toDeleteDoctors = state.oldDoctors.filter(item => !state.doctors.find(elem => item._id === elem._id));

            for(let item of state.doctors.concat(toDeleteDoctors)) {
                const element = state.doctors.find(elem => item._id === elem._id)
                await doctorService.update(item._id, {office: element ? id : null, lastname: item.lastname, fax: item.fax, email: item.email}, null);
            }
        }

        history.push(routeService.doctorOfficeList());
    }

    const remove = async () => {
        await doctorOfficeService.delete(id);
        history.push(routeService.doctorOfficeList());
    }

    return (<Fragment>
        <Content title='Arztpraxis bearbeiten'>
            <form>
                <Card title={'Daten der Arztpraxis'} center={true}>
                <Row sm={12}>
                    <Col sm={12}>
                        <Organisation
                            description={'Arztpraxis Daten ändern'}
                            edit={true}
                            form={state}
                            name={'edit_praxis'}
                            title={'Arztpraxis bearbeiten'}
                            icon={<Icon icon={'medical_home'} />}
                            setValid={setIsValid}
                            onChange={change}
                        />
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col md={12}>
                        <div className='container'>
                            <LastChange data={state}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <ButtonDelete permission={'doctor_office_create_view'} placeholder={'Name der zu löschenden Arztpraxis'} onClick={remove} compare={state.name}/>
                    </Col>
                    <Col sm={6}>
                        <Button
                            onClick={submit}
                            permission={'doctor_office_edit_view'}
                            type={'submit'}
                            disabled={!isValid}
                            className='btn btn-primary mt-4 float-end'
                        >
                            Speichern
                        </Button>
                    </Col>
                </Row>
                </Card>
            </form>
            <Modal button={null} canClose={false} title={'keine Ärzte in der Praxis'} open={openEmptyDoctorsModal} size={[550, 270]}>
                <Alert type={'warning'}>
                    Der Praxis sind keine Ärzte zugewiesen. es wird empfohlen Arztpraxen zu verwenden, wenn mindestens 2 Ärzte in der Praxis arbeiten.
                    Ansonsten ist es ausreichend das der Arzt alleinstehen ohne Arztpraxis erzeugt wird.
                </Alert>

                <Row>
                    <Col lg={6}>
                        <Button color={'danger'} onClick={closeEmptyDoctorModal}>Abbrechen</Button>
                    </Col>
                    <Col lg={6} className={'text-end'}>
                        <Button color={'success'} onClick={sendRequest}>Trotzdem aktualisieren</Button>
                    </Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col lg={12}>
                        <Checkbox label={'nicht mehr zeigen'} value={'1'} name={'hide-question-empty-doctor'} onChange={(e) => {
                            if(e.target.checked) {
                                document.cookie = `hide-question-empty-doctor=1;expires=Thu, 18 Dec 2113 12:00:00 UTC; path=/`
                            } else {
                                document.cookie = `hide-question-empty-doctor=0;expires=Thu, 18 Dec 1970 12:00:00 UTC; path=/`
                            }
                        }}/>
                    </Col>
                </Row>
            </Modal>
        </Content>

    </Fragment>);
}

export default DoctorOfficeEdit;
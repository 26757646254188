import {rand} from "@utils/utils";
import PropTypes from "prop-types";
import Chip from "@components/Chip";
import {useEffect, useRef, useState} from "react";

const Checkbox = ({id, observer, onChange, type, checked, value, name, label, className, ...args}) => {
    /**
     * State
     */
    const [state, setState] = useState(checked);

    /**
     * Reference
     */
    const stateReference = useRef();
    stateReference.current = state;

    id = id !== undefined ? id : rand();

    /**
     * Hooks
     */
    useEffect(() => {
        if(observer) {
            observer.register({
                get: () => {
                    if(stateReference.current && value && typeof value === 'string' && value.length > 0) {
                        return value;
                    }

                    return stateReference.current},
                update: (args) => setState(args ? args : false),
                key: `${name}-${id}`,
                name: name,
                unique: true
            });
        }

        return () => {
            setState(false);
        }
    }, [observer]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(observer) {
            observer.notify({[name]: state});
        }
    }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const change = (e) => {
        if(onChange) {
            onChange(e);
        }

        const value = e.target.value ? e.target.value : e.target.checked;
        setState(e.target.checked ? value : false);
    }

    return (<div className={[`form-check`, className ? className : ''].join(' ').trim()}>
            <input name={name} type="checkbox" onChange={change} className="form-check-input" id={`checkbox-${name}-${id}`} value={value} checked={state}/>
            <label className="form-check-label" htmlFor={`checkbox-${name}-${id}`} {...args}>
                {label}
            </label>
        </div>
    );
};

Checkbox.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool
};

Chip.defaultProps = {
    checked: false,
};

export default Checkbox;
import {getRandomDate, getRandomSingleItem, makeMockxiosId} from "../maxiosUtils";

const db = (homes) => {
    return {
        '/api/homes/groups':[
            {
                _id: makeMockxiosId(),
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                name: 'Keller Ebene 45',
                home: getRandomSingleItem(homes)
            },
            {
                _id: makeMockxiosId(),
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                name: 'Scheune mit Stroh',
                home: getRandomSingleItem(homes)
            },
            {
                _id: makeMockxiosId(),
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                name: 'Wohnflur A',
                home: getRandomSingleItem(homes)
            },
            {
                _id: makeMockxiosId(),
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                name: 'Wohnbereich G',
                home: getRandomSingleItem(homes)
            },
            {
                _id: makeMockxiosId(),
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                name: 'Wohnbereich für Leichtsterbliche',
                home: getRandomSingleItem(homes)
            },
            {
                _id: makeMockxiosId(),
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                name: 'Keller Ebene 98',
                home: getRandomSingleItem(homes)
            },
            {
                _id: makeMockxiosId(),
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                name: 'Wohnbereich Pflegeleicht',
                home: getRandomSingleItem(homes)
            },
            {
                _id: makeMockxiosId(),
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                name: 'Sonnenwohnbereich',
                home: getRandomSingleItem(homes)
            },
            {
                _id: makeMockxiosId(),
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                name: 'Fragenwohnbereich',
                home: getRandomSingleItem(homes)
            },
            {
                _id: makeMockxiosId(),
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                name: 'Luxus Wohnbereich',
                home: getRandomSingleItem(homes)
            }
        ]
    }
}

export default db;
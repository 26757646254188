/* jshint esversion: 8 */
import DateFnsUtils from '@date-io/moment';
import {
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/de';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ImCancelCircle } from 'react-icons/im';
import { MdAdd, MdDelete, MdSend } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TaskTable from '../components/TaskTable';
import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';
import { makeStyles } from '@material-ui/core';
import McPagination from '../components/Pagination';
import { useParams } from 'react-router-dom';
import LinkWithContractRequirements from '../components/LinkWithContractRequirements';

moment.locale('de');
const useStyles = makeStyles({
    warning: {
        backgroundColor: '#f44336',
        borderRadius: 4,
        padding: '8px 16px',
        fontSize: 13,
        color: 'white',
        margin: '24px 8px 5.6px',
    },
    existingWarning: {
        color: '#444',
        border: '1px solid #5588a3',
        backgroundColor: 'rgb(229, 246, 253)',
        margin: '0 0 -8px 0',
    },
    modified: {
        margin: '0 0 0 5px',
        color: '#888',
        fontSize: 12,
    },
    editLink: {
        color: '#fff',
        fontSize: 10,
        margin: '0 0 0 10px',
        textTransform: 'none',
    },
});
const PatientForm = ({ readonly, history }) => {
    const classes = useStyles();
    let params = useParams();

    /**
     * STATES
     */
    const { auth, setAuth } = useContext(AuthContext);
    const [state, setState] = useState({
        _id: null,
        lastname: '',
        firstname: '',
        title: '',
        salutation: '',
        street: '',
        postalCode: '',
        city: '',
        country: '',
        homepatient: false,
        deceased: false,
        home: '',
        homeGroup: '',
        dateOfBirth: null,
    });
    const [existingPatient, setExistingPatient] = useState(false);
    const [patients, setPatients] = useState([]);
    const [homes, setHomes] = useState([]);
    const [homeGroups, setHomeGroups] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [limit, setLimit] = useState(50);
    const [hasSelection, setHasSelection] = useState(false);

    const mappedChildFunctionReference = React.useRef();
    mappedChildFunctionReference.current = {
        count: {},
        parent: { hasSelection: { data: hasSelection, set: setHasSelection } },
    };

    /**
     * QUERIES
     */
    const fetchData = async () => {
        try {
            let response = await axios.get(`/api/patients/${params.id}`, { headers: { 'x-auth-token': auth.token } });
            response.data.dateOfBirth = moment(response.data.dateOfBirth);
            let homepatient = response.data.home ? true : false;
            response.data.home = response.data.home ? response.data.home._id : '';
            response.data.home !== '' && fetchHomeGroups(response.data.home);
            response.data.homeGroup = response.data.homeGroup ? response.data.homeGroup._id : '';
            setState({
                country: 'Deutschland',
                ...response.data,
                homepatient,
                createdAt: moment(response.data.createdAt),
                modifiedAt: moment(response.data.modifiedAt),
            });
            fetchTasks();
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchHomes = async () => {
        try {
            let response = await axios.get(`/api/homes?limit=99999`, { headers: { 'x-auth-token': auth.token } });
            setHomes(response.data.data);

            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchHomeGroups = async (homeId) => {
        try {
            let response = await axios.get(`/api/homes/${homeId}/groups?limit=99999`, {
                headers: { 'x-auth-token': auth.token },
            });
            setHomeGroups(response.data);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchPatients = async () => {
        try {
            let response = await axios.get(`/api/patients?limit=99999`, { headers: { 'x-auth-token': auth.token } });
            setPatients(response.data.data);

            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchTasks = async (page, olimit) => {
        try {
            const response = await axios.get(
                `/api/tasks?filter[patient]=${params.id}&limit=${olimit || limit}&offset=${(page - 1) * limit}`,
                {
                    headers: { 'x-auth-token': auth.token },
                }
            );
            setTasks(response.data.data);

            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }

        return 0;
    };

    const setOwnHome = () => {
        if (auth.roles.includes('ROLE_HOME') && homes.length > 0) {
            const home = homes[0];
            setState({ ...state, homepatient: true, home: home._id });
            fetchHomeGroups(home._id);
        }
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchHomes();
        fetchPatients();
        if (params.hasOwnProperty('id')) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOwnHome();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homes]);

    /**
     * CONTROLS
     */
    const change = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        if (e.target.name === 'homepatient') {
            if (value) {
                setState({ ...state, [e.target.name]: value });
            } else {
                setState({ ...state, [e.target.name]: value, home: '', homeGroup: '' });
            }
        } else {
            setState({ ...state, [e.target.name]: value });
        }
    };

    const validateName = (e) => {
        checkPatientData({ ...state, [e.target.name]: e.target.value });
        change(e);
    };

    const checkPatientData = (data) => {
        for (let item of patients) {
            const date = moment(item.dateOfBirth);
            const otherDate = moment(data.dateOfBirth);
            if (
                item.firstname.toLowerCase() === data.firstname.toLowerCase() &&
                item.lastname.toLowerCase() === data.lastname.toLowerCase() &&
                date.format('DDMMYYYY') === otherDate.format('DDMMYYYY')
            ) {
                setExistingPatient(true);
                return false;
            }
        }

        setExistingPatient(false);

        return true;
    };

    const changeZip = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setState({ ...state, [e.target.name]: value.replace(/[^0-9]+/, '').substr(0, 5) });
    };

    const homeChange = (e) => {
        if (e.target.value === '') {
            setState({ ...state, [e.target.name]: e.target.value, homeGroup: '' });
            setHomeGroups([]);
        } else {
            setState({ ...state, [e.target.name]: e.target.value });
            fetchHomeGroups(e.target.value);
        }
    };

    const dateSelect = (e) => {
        const update = { ...state, dateOfBirth: e };
        checkPatientData(update);
        setState(update);
    };

    /**
     * SUBMIT
     */
    const submit = async (e) => {
        e.preventDefault();
        let data = { ...state, dateOfBirth: state.dateOfBirth.format('DD.MM.YYYY') };

        delete data.modifiedFrom;
        delete data.createdFrom;

        try {
            if (params.hasOwnProperty('id') && params.type && params.type === 'loeschen') {
                await axios.delete(`/api/patients/${params.id}`, { headers: { 'x-auth-token': auth.token } });
                toast.success('Patient gelöscht.');
            } else if (params.hasOwnProperty('id')) {
                await axios.put(`/api/patients/${params.id}`, data, { headers: { 'x-auth-token': auth.token } });
                toast.success('Patient geändert.');
            } else {
                await axios.post('/api/patients', data, { headers: { 'x-auth-token': auth.token } });
                toast.success('Patient gespeichert.');
            }

            history.push('/patienten');
        } catch (err) {
            handleErrors(err, setAuth, history);
        }

        return false;
    };

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Link to='/patienten'>Patienten</Link>
                {state && state.firstname && state.lastname && params.id && (
                    <Link to={`/patienten/details/${params.id}`}>
                        {state.firstname} {state.lastname}
                    </Link>
                )}
                {!readonly && (
                    <Typography color='textPrimary'>
                        {params.hasOwnProperty('id')
                            ? params.type && params.type === 'loeschen'
                                ? 'Patient löschen'
                                : 'bearbeiten'
                            : 'Neuer Patient'}
                    </Typography>
                )}
            </Breadcrumbs>
            <form onSubmit={(e) => submit(e)}>
                <Card>
                    <CardContent>
                        <Typography component='h1' color='textSecondary' gutterBottom>
                            {!readonly && (
                                <Fragment>
                                    {params.hasOwnProperty('id')
                                        ? params.type && params.type === 'loeschen'
                                            ? 'Patient löschen'
                                            : 'Patient bearbeiten'
                                        : 'Neuer Patient'}
                                </Fragment>
                            )}
                            {readonly && (
                                <Fragment>
                                    Details
                                    <Link className={classes.editLink} to={`/patient/${params.id}`}>
                                        bearbeiten
                                    </Link>
                                </Fragment>
                            )}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id='salutation-label'>Anrede</InputLabel>
                                    <Select
                                        disabled={readonly || (params.type && params.type === 'loeschen')}
                                        id='salutation'
                                        labelId='salutation-label'
                                        name='salutation'
                                        onChange={(e) => {
                                            change(e);
                                        }}
                                        value={state.salutation}
                                    >
                                        <MenuItem value={''}>Nicht angegeben</MenuItem>
                                        <MenuItem value={'Herr'}>Herr</MenuItem>
                                        <MenuItem value={'Frau'}>Frau</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={readonly || (params.type && params.type === 'loeschen')}
                                        id='title'
                                        label='Titel'
                                        name='title'
                                        onChange={(e) => change(e)}
                                        value={state.title}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={readonly || (params.type && params.type === 'loeschen')}
                                        id='firstname'
                                        label='Vorname'
                                        name='firstname'
                                        onChange={(e) => validateName(e)}
                                        value={state.firstname}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={readonly || (params.type && params.type === 'loeschen')}
                                        id='lastname'
                                        label='Nachname'
                                        name='lastname'
                                        onChange={(e) => validateName(e)}
                                        required
                                        value={state.lastname}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <FormControl fullWidth>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disabled={readonly || (params.type && params.type === 'loeschen')}
                                            disableFuture
                                            format='DD.MM.YYYY'
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            id='birthday-date'
                                            label='Geburtstag'
                                            onChange={(e) => dateSelect(e)}
                                            required
                                            value={state.dateOfBirth}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Grid>
                            <Grid item sm={8} xs={12}>
                                {existingPatient && (
                                    <Grid container>
                                        <Typography className={`${classes.warning} ${classes.existingWarning}`}>
                                            Es existiert bereits ein Patient mit ähnlichen Namen und Geburtsdatum.
                                            <br />
                                            <strong>Wurde der Patient vielleicht schon erstellt?</strong>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={readonly || (params.type && params.type === 'loeschen')}
                                        id='street'
                                        label='Straße'
                                        name='street'
                                        onChange={(e) => change(e)}
                                        value={state.street}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={readonly || (params.type && params.type === 'loeschen')}
                                        id='postalCode'
                                        label='Postleitzahl'
                                        name='postalCode'
                                        onChange={(e) => changeZip(e)}
                                        value={state.postalCode}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={8} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={readonly || (params.type && params.type === 'loeschen')}
                                        id='city'
                                        label='Ort'
                                        name='city'
                                        onChange={(e) => change(e)}
                                        value={state.city}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={readonly || (params.type && params.type === 'loeschen')}
                                        id='country'
                                        label='Land'
                                        name='country'
                                        onChange={(e) => change(e)}
                                        value={state.country}
                                    />
                                </FormControl>
                            </Grid>
                            {!auth.roles.includes('ROLE_HOME') && (
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.homepatient}
                                                color='primary'
                                                disabled={readonly || (params.type && params.type === 'loeschen')}
                                                name='homepatient'
                                                onChange={(e) => change(e)}
                                            />
                                        }
                                        label='Heimpatient'
                                    />
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.deceased}
                                            color='primary'
                                            disabled={readonly || (params.type && params.type === 'loeschen')}
                                            name='deceased'
                                            onChange={(e) => change(e)}
                                        />
                                    }
                                    label='Verstorben'
                                />
                            </Grid>
                            {state.homepatient && (
                                <Fragment>
                                    {!auth.roles.includes('ROLE_HOME') && (
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id='home-label' required={state.homepatient}>
                                                    Pflegeeinrichtung
                                                </InputLabel>
                                                <Select
                                                    disabled={readonly || (params.type && params.type === 'loeschen')}
                                                    id='home'
                                                    labelId='home-label'
                                                    name='home'
                                                    onChange={(e) => {
                                                        homeChange(e);
                                                    }}
                                                    required={state.homepatient}
                                                    value={state.home}
                                                >
                                                    <MenuItem value={''}>Nicht angegeben</MenuItem>
                                                    {homes.map((home, i) => (
                                                        <MenuItem key={i} value={home._id}>
                                                            {home.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    {homeGroups.length > 0 && (
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id='homeGroup-label'>Wohnbereich</InputLabel>
                                                <Select
                                                    disabled={readonly || (params.type && params.type === 'loeschen')}
                                                    id='homeGroup'
                                                    labelId='homeGroup-label'
                                                    name='homeGroup'
                                                    onChange={(e) => {
                                                        change(e);
                                                    }}
                                                    value={state.homeGroup}
                                                >
                                                    <MenuItem value={''}>Nicht angegeben</MenuItem>
                                                    {homeGroups.map((group, i) => (
                                                        <MenuItem key={i} value={group._id}>
                                                            {group.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )}
                                </Fragment>
                            )}
                            {state.createdFrom && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={classes.modified}>
                                            <div>
                                                Am {state.createdAt.format('DD.MM.YYYY')} erstellt von{' '}
                                                {state.createdFrom.username}
                                            </div>
                                            <div>
                                                Am {state.modifiedAt.format('DD.MM.YYYY')} geändert von{' '}
                                                {state.modifiedFrom.username}
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                            {state._id && (
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} />
                                    </Grid>
                                    <Grid className='mg-t-24' container justify='flex-end' spacing={2}>
                                        <Grid item xs={12}>
                                            <LinkWithContractRequirements
                                                message={'required_contract_message'}
                                                fullWidth
                                                type={'button'}
                                                permission={'task_create_view'}
                                                to={`/anforderung/neu/${params.id}`}
                                            >
                                                Bestellung hinzufügen
                                                <MdAdd />
                                            </LinkWithContractRequirements>
                                        </Grid>
                                    </Grid>
                                    <Typography
                                        className='mg-t-24'
                                        component='h1'
                                        color='textSecondary'
                                        gutterBottom
                                        style={{ width: '100%' }}
                                    >
                                        Letzte Aufträge
                                    </Typography>

                                    <Grid container spacing={2} className='mg-t-4'>
                                        <Grid item xs={12}>
                                            {hasSelection && (
                                                <Grid container spacing={1} className='mg-b-4'>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            color='primary'
                                                            component={Link}
                                                            endIcon={<MdAdd />}
                                                            fullWidth
                                                            to={`/anforderungen/erzeugen`}
                                                            variant='contained'
                                                        >
                                                            Medikamente erneut bestellen
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <TaskTable
                                                auth={auth}
                                                tasks={tasks}
                                                fetch={fetchTasks}
                                                parentContainer={mappedChildFunctionReference}
                                            />
                                            <McPagination fetch={fetchTasks} setLimit={setLimit} />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </CardContent>
                    {!readonly && (
                        <Grid container justify='center'>
                            <Typography className={classes.warning}>
                                {' '}
                                Der Patient kann nicht gelöscht werden, sondern wird deaktiviert. Zu
                                dokumentationszwecken bleiben die Daten des Patienten erhalten, werden aber in der
                                Abrechnung nicht mehr berücksichtigt. Achtung! Ein deaktivierter Patient kann das System
                                nicht mehr benutzen, bis er wieder aktiviert wird.
                            </Typography>
                        </Grid>
                    )}
                    {!readonly && (
                        <CardActions>
                            <Grid container justify='space-between'>
                                <Grid item xs={12} sm={3} md={2} xl={1}>
                                    <Button
                                        color='primary'
                                        component={Link}
                                        fullWidth
                                        startIcon={<ImCancelCircle />}
                                        to='/patienten'
                                        variant='outlined'
                                    >
                                        Abbrechen
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={3} md={2} xl={1}>
                                    {params.type && params.type === 'loeschen' ? (
                                        <Button
                                            color='secondary'
                                            endIcon={<MdDelete />}
                                            fullWidth
                                            type='submit'
                                            variant='contained'
                                        >
                                            Löschen
                                        </Button>
                                    ) : (
                                        <Button
                                            color='primary'
                                            endIcon={<MdSend />}
                                            fullWidth
                                            type='submit'
                                            variant='contained'
                                        >
                                            Speichern
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </CardActions>
                    )}
                </Card>
            </form>
        </Fragment>
    );
};

PatientForm.defaultProps = {
    readonly: false,
};

export default PatientForm;

import {toast} from "react-toastify";

export const copyToClipboard = (entity) => {

    if(typeof entity !== 'string') {
        if(entity.target) {
            entity = entity.target;
        }

        if (entity.hasAttribute('data-entity')) {
            entity = entity.getAttribute('data-entity');
        } else if(entity.value) {
            entity.select();
            entity.setSelectionRange(0, 99999);
            entity = entity.value
        } else {
            entity = '';
        }
    }

    if(entity.length > 0) {
        navigator.clipboard.writeText(entity);
        toast.success(`"${entity}" in Zwischenspeicher kopiert.`);
    }
}
import {Td} from 'react-super-responsive-table';
import {IoMdClipboard} from "react-icons/all";
import {copyToClipboard} from "../utils/tools";
import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core";
import {getScreenResolution} from "@utils/utils";
import PropTypes from "prop-types";
import Input from "@components/Input"; //comment in if you add Proptypes to your Component

const useStyles = makeStyles({
    clipboard: {
        margin: '0 0 0 5px',
        fontSize: '16px',
        verticalAlign: 'middle',
        color: 'rgba(0, 0, 0, 0.87)',

        '&:hover': {
            color: '#2a5d78'
        }
    },
    cell: {
        '& .clipboard': {
            opacity: 0,
        },
        '&:hover': {
            '& .clipboard': {
                opacity: 1,
            },
        }
    }
});

const TCell = ({ align, header, children, clipboard, classnames, args }) => {

    const classes = useStyles();

    let list = [];

    if(clipboard.data instanceof Array) {
        for(let element of clipboard.data) {
            list.push(<Fragment>
                {element}
                {clipboard && (<IoMdClipboard className={['clipboard', classes.clipboard].join(' ')} onClick={copyToClipboard} data-entity={element} title={clipboard.help}/>)}
            </Fragment>);
        }
    } else {
        list.push(<Fragment>
            {children}
            {clipboard && (<IoMdClipboard className={['clipboard', classes.clipboard].join(' ')} onClick={copyToClipboard} data-entity={clipboard.data} title={clipboard.help}/>)}
        </Fragment>);
    }

    return (<Td align={align} className={`${classes.cell} ${classnames ? classnames : ''}`}>
        {getScreenResolution() < 426 && (<div className={'tdBefore'}>{header}</div>)}
        {list}
    </Td>);
}

TCell.propTypes = {
    align: PropTypes.oneOf(['left', 'right', 'center'])
};

TCell.defaultProps = {
    align: 'left',
};

export default TCell;
import {makeMockxiosId, getRandomSingleItemId} from "../maxiosUtils";

const db = (organisations) => {
    return {
        '/api/doctors':[
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                title: 'Dr. med.',
                firstname: 'Frank',
                lastname: 'Stein',
                department: 'Chirurgie',
                phone: '123144353463',
                organisation: getRandomSingleItemId(organisations)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                title: 'Dr.',
                firstname: 'Sabine',
                lastname: 'Schnitzer',
                department: 'Allgemeinmedizin',
                mail: 'sabine@mail-doctors.com',
                organisation: getRandomSingleItemId(organisations)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mrs',
                title: 'Dr.',
                firstname: 'Frauke',
                lastname: 'Hübner',
                organisation: getRandomSingleItemId(organisations)
            },
            {
                _id: makeMockxiosId(),
                salutation: 'mr',
                title: 'Dr. Dr. med.',
                firstname: 'Albert',
                lastname: 'Listner',
                department: 'Notfallmedizin',
                fax: '94641782783',
                organisation: getRandomSingleItemId(organisations)
            }
        ]
    };
}

export default db;
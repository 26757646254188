import React, {useContext, useState} from "react";
import Button from "@components/Button";
import Col from "@components/Col";
import Row from "@components/Row";
import Icon from "@components/Icon";
import Headline from "@components/Headline";
import Table from "@components/Table/Table";
//import {toast} from "react-toastify";
import contractServiceFactory from "@factories/contractServiceFactory";
import AuthContext from "@context/AuthContext";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const Shop = ({onSubmit, products, contractId, ...args}) => {

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [cart, setCart] = useState([]);
    const [checkout, setCheckout] = useState(false);

    /**
     * Service
     */
    const contractService = contractServiceFactory({auth});

    /**
     * Functions
     */
    const interval = (value) => {
        switch(value) {
            case '1 month':
                return 'Monatlich';
            case '12 months':
                return 'Jährlich';
            case 'once':
                return 'Einmalig';
            default:
                return '';
        }
    }

    const removeFromCart = (product) => {
        //toast.success(`Das Produkt "${product.title}" wurde vom Warenkorb entfernt.`);
        let update = [...cart];
        const index = update.findIndex((item) => product.name === item);

        if(index !== -1) {
            update.splice(index, 1);
            setCart(update);
        }
    }

    const addToCart = (product) => {
        //toast.success(`Das Produkt "${product.title}" wurde zum Warenkorb hinzugefügt.`);
        let update = [...cart]
        update.push(product.name);

        setCart(update);

        return false;
    }

    const getCartItems = () => {
        const items = {};

        for(let ii = 0; ii < cart.length; ii++) {
            const index = products.findIndex((item) => item.name === cart[ii]);

            if(index !== -1) {
                const item = products[index];
                if(!items[item.name]) {
                    items[item.name] = {
                        name: item.title,
                        price: item.price,
                        once: item.interval === 'once',
                        amount: 0
                    }
                }

                items[item.name].amount++;
            }

        }

        const cartProducts = Object.values(items);

        return {items: cartProducts, max: cartProducts.length}
    }

    const hasItems = (once) => {
        const items = getCartItems();
        for(const item of items.items) {
            if(once === item.once) {
                return true;
            }
        }

        return false;
    }

    const getPrice = (once, render, label) => {
        let price = 0;
        const items = getCartItems();
        for(const item of items.items) {
            if(once === item.once) {
                price+= item.amount * item.price;
            }
        }

        if(render) {
            if(price > 0 && label) {
                return(<Row className={'mt-3'}>
                    <Col md={8}>{label}</Col>
                    <Col md={4} className={'price'}>{parseFloat(price).toFixed(2)}&euro;</Col>
                </Row>)
            }

            return '';
        }

        return price;
    }

    /*
    const group = (collection) => {
        let groups = {};
        let products = collection.filter(item => !isNaN(parseFloat(item.price)))

        for(const item of products) {
            const groupName = item.shopGroup ? item.shopGroup : 'general';
            if(!groups[groupName]) { groups[groupName] = {title: groupName, items: []}; }

            groups[groupName].items.push(item);
        }

        return Object.values(groups);
    }
    */

    const buy = async () => {
        await contractService.bookOptions(contractId, cart);

        if(onSubmit) {
            onSubmit();
        }
    }

    /**
     * Columns
     */
    const column = [
        {title: 'Produkt', render: (item) => { return item.name; }},
        {title: 'Wiederkehrende Zahlung', render: (item) => { return item.once ? 'Nein' : 'Ja' }},
        {title: 'Einzelpreis', render: (item) => { return parseFloat(item.price).toFixed(2) + '€'; }},
        {title: 'Anzahl', render: (item) => { return item.amount + 'x'; }},
        {title: 'Preis', align: 'right', render: (item) => { return parseFloat(item.amount * item.price).toFixed(2) + '€'; }},
    ];

    const productColumns = [
        {title: 'Produkt', render: (item) => {
            return item.title;
            }
        },
        {title: 'Zahlungsintervall', render: (item) => {
                return interval(item.interval)
            }
        },
        {title: 'Preis', render: (item) => {
                return `${parseFloat(item.price).toFixed(2)}&euro;`
            }
        },
        {title: '', align: 'right', render: (item) => {
            const count = cart.filter(entry => item.name === entry).length;

            if(count > 0) {
                return <span className={'shop-cart-actions'}>
                    <span className="in-cart">{count}x</span>
                    <Icon onClick={() => removeFromCart(item)} className={'remove-from-cart'} icon={'delete'} tooltip={'Vom Warenkorb entfernen'} />
                </span>
            }

            return <span className={'shop-cart-actions'} />;
        }},
        {
            title: '',
            align: 'right',
            render: (item) => {
                return <Button
                    icon={<Icon icon={'cart'} /> }
                    disabled={cart.indexOf(item.name) !== -1 && item.interval === 'once'}
                    onClick={(e) => addToCart(item)}>Warenkorb</Button>
            }
        }
    ];

    if(checkout) {
        return <div className={'shop-checkout mt-3'}>
            <Headline size={'h3'} align={'center'} design={'none'} className={'checkout-headline'}>Kasse</Headline>
            <Headline size={'h4'} align={'center'} design={'none'} className={'checkout-sub-headline mb-3'}>Bitte prüfen Sie ihre Bestellung noch einmal.</Headline>
            <Table
                className={hasItems(false) && hasItems(true) ? 'rows-4' : 'rows-5'}
                searchable={false}
                pagination={false}
                columns={column}
                service={{get: () => { return getCartItems(); }}}
            />
            <Row className={'checkout-section'}>
                <Col md={12}>
                    <Row className={'mt-4 sum-price-row'}>
                        <Col omd={8} md={4}>
                            {getPrice(true, true, 'Einmalig')}
                            {getPrice(false, true, 'Monatlich')}
                            <Row className={'mt-3 final-price pt-2 pb-1 '}>
                                <Col md={8} className={'pb-1'}><strong>Gesamtbetrag</strong></Col>
                                <Col md={4} className={'price pb-1'}><strong>{parseFloat(getPrice(true) + getPrice(false)).toFixed(2)}&euro;</strong></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={'mt-4 buy-cart'}>
                        <Col omd={8} md={4}>
                            <Button onClick={() => buy()} size={'large'} color={'success'}>Jetzt Kostenpflichtig buchen</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    }

    return <div className='container'>
        <Headline size={'h3'} align={'center'} design={'none'} className={'checkout-headline mt-3'}>Zusätzliche Leistungen</Headline>
        <Headline size={'h4'} align={'center'} design={'none'} className={'checkout-sub-headline mb-3'}>Buchen sie eine oder mehrere Leistungen zu ihren Vertrag hinzu.</Headline>
        <div className={'shop-item-list'}>
            <Table
                searchable={false}
                pagination={false}
                columns={productColumns}
                service={{get: () => { return products ? {items: products, max: products.length} : []; }}}
            />
        </div>
        <div className={'shop-footer'}>
            <Row>
                <Col md={3} omd={9} className={'checkout'}>
                    {cart.length > 0 && (
                    <span className={'cart-container'}>
                        <div className={'cart-amount'}>{cart.length}</div>
                        <Icon icon={'cart'} className={'checkout-cart'}/>
                    </span>)}
                    <Button onClick={() => setCheckout(true)} disabled={cart.length === 0} color={'primary'} size={'large'}>Zur Kasse</Button>
                </Col>
            </Row>
        </div>
    </div>;
}

Shop.propTypes = {};

Shop.defaultProps = {};

export default Shop;
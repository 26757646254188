const db = {
    '/api/doctors/offices': [
        {
            _id: '6183e64d327638002c51b1y7',
            name: 'Praxis Boizenburg',
            street: 'Baulehnenstr. 2',
            zip: '18015',
            city: 'Buchenhausen',
            image: 'https://www.praxis-vorfahr.de/wp-content/uploads/arztpraxis-boizenburg-2.jpg',
            contact_type: 'phone',
            phone: '123456789'
        },
        {
            _id: '6183e64d323138002c51b1y7',
            name: 'Praxis Alterhausen',
            street: 'Möhrenstr. 122',
            zip: '14012',
            city: 'München',
            image: 'https://www.storebest.at/wp-content/uploads/2018/09/Arzt_Andonov_1.jpg',
            contact_type: 'mail',
            mail: 'praxis@altenhausen.de'
        },
        {
            _id: '6183e64d327638002c51u6f3',
            name: 'Praxis Dr. Alexania',
            street: 'Stroemerstr. 256',
            zip: '11515',
            city: 'Paris',
            image: 'https://tischlerei-beckmann.de/wp-content/gallery/praxis-im-lindenhof/objekteinrichtung-arztpraxis-im-lindenhof-01.jpg',
            contact_type: 'mail',
            mail: 'praxis@praxis-alexania.de',
            phone: '05752863846'
        },
        {
            _id: '6183e64d327638002c510k1c6',
            name: 'Praxis Dr. med. Marios',
            street: 'Maxlerstr. 234',
            zip: '19745',
            city: 'Möxien',
            image: '',
            contact_type: 'fax',
            phone: '05752863846',
            fax: '566731267432'
        }
    ]
}

export default db;
import React, {useContext, useEffect, useState} from "react";
import Row from "@components/Row";
import Col from "@components/Col";
import Link from "@components/Link";
import routeServiceFactory from "@factories/routeServiceFactory";
import Icon from "@components/Icon";
import Organisation from "@views/parts/forms/dialog/doctor/office/Organisation";
import AuthContext from "@context/AuthContext";
import doctorOfficeServiceFactory from "@factories/doctorOfficeServicefactory";
import {useParams} from "react-router-dom";
import Content from "@components/Content";
import Card from "../../components/Card";
import LastChange from "../../components/LastChange";
import Table from "../../components/Table/Table";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const DoctorOfficeDetails = ({...args}) => {
    const { id } = useParams();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [state, setState] = useState({});

    /**
     * Services
     */
    const routeService = routeServiceFactory();
    const doctorOfficeService = doctorOfficeServiceFactory({auth: auth});
    const tableService = {
        get: () => {
            const items = state.doctors ? state.doctors : [];
            return {items: items, max: items.length}
        }
    }

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        tableService.tableRefresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    /**
     * Functions
     */
    const fetch = async () => {
        const data = await doctorOfficeService.get(id);

        setState({...data});
    }

    /**
     * Table Columns
     */
    const columns = [
        {name: 'name', title: 'Name', render: (item) => {
                return `${item.title} ${item.firstname} ${item.lastname}`
            }},
        {name: 'phone', title: 'Telefon'},
        {name: 'mail', title: 'E-Mail'},
        {name: 'fax', title: 'Fax'},
        {name: 'actions', title: '', align: 'right', render: (item) => {
                const id = item._id;

                return (
                    <ul className={'list-item-actions'}>
                        <li>
                            <Link permission={'doctor_edit_view'} to={routeService.doctorEdit(id)}><Icon icon={'edit'} /></Link>
                        </li>
                    </ul>);
            }
        }
    ];

    return <Content title='Arztpraxis'>
        <Row sm={12}>
            <Col sm={12}>
                <Card title={'Daten der Arztpraxis'} center={true} className={'form-card'}>
                    <div className={'last-change-view'}>
                        <div>
                            <Link permission={'doctor_office_edit_view'} className={'user-edit-btn'} to={routeService.doctorOfficeEdit(id)} size={'small'} type={'button-outlined'}>bearbeiten</Link>
                        </div>
                    </div>
                    <Organisation
                        readonly={true}
                        description={'Arztpraxis Daten'}
                        edit={true}
                        form={state}
                        name={'edit_praxis'}
                        title={'Arztpraxis'}
                        icon={<Icon icon={'medical_home'} />}
                    />
                    <Row className={'mt-3'}>
                        <Col md={12}>
                            <div className='container'>
                                <h5 className={'text-uppercase mt-5 mb-4'}>Ärzte</h5>
                                <Table
                                    searchable={false}
                                    pagination={false}
                                    columns={columns}
                                    service={tableService} />
                            </div>
                        </Col>
                    </Row>
                    <Row className={'mt-6'}>
                        <Col md={12}>
                            <div className='container'>
                                <LastChange data={state}/>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </Content>
}

DoctorOfficeDetails.propTypes = {};

DoctorOfficeDetails.defaultProps = {};

export default DoctorOfficeDetails;
import React from 'react';

const Card = ({ labeled, children, title, center, className, actions, description}) => {
    return (
        <div className={`card ${className ? className : ''}`}>
            <div className='card-body'>
                {title && center && !labeled && (<h4 className='text-center mb-3 mt-4'>{title} </h4>)}
                {title && !center && (<h6 className='card-title'>{title}</h6>)}
                {description && (<p className='text-muted text-center mb-4 pb-2'>
                    {description}
                </p>)}
                {actions && (<div className={'card-actions'}>{actions}</div>)}
                {children}
            </div>
        </div>
    );
};

export default Card;

import { Breadcrumbs, Button, Card, CardActions, CardContent, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ImCancelCircle } from 'react-icons/im';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';

const HomeGroupDeleteForm = ({ history, match: { params } }) => {
	const { auth, setAuth } = useContext(AuthContext);
	const [state, setState] = useState({
		name: '',
		street: '',
		postalCode: '',
		city: '',
		country: '',
		homeGroups: [],
	});

	const fetchData = async () => {
		try {
			let response = await axios.get(`/api/homes/${params.id}`, { headers: { 'x-auth-token': auth.token } });
			setState(response.data);
		} catch (err) {
			handleErrors(err, setAuth, history);
		}
	};

	useEffect(() => {
		if (params.hasOwnProperty('id')) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submit = async (e) => {
		e.preventDefault();

		try {
			await axios.delete(`/api/homegroups/${params.groupId}`, { headers: { 'x-auth-token': auth.token } });
			toast.success('Wohnbereich gelöscht.');
			history.push(`/einrichtung/${params.id}`);
		} catch (err) {
			handleErrors(err, setAuth, history);
		}

		return false;
	};

	const getGroupName = () => {
		for (let group of state.homeGroups) {
			if (group._id === params.groupId) {
				return group.name;
			}
		}

		return '';
	};

	return (
		<Fragment>
			<Breadcrumbs separator='›' aria-label='breadcrumb'>
				<Link to='/'>Medicharge</Link>
				<Link to='/einrichtungen'>Pflegeeinrichtungen</Link>
				<Link to={`/einrichtung/${params.id}`}>{state.name}</Link>
				<Typography color='textPrimary'>Wohnbereich löschen</Typography>
			</Breadcrumbs>
			<form onSubmit={(e) => submit(e)}>
				<Card>
					<CardContent>
						<Typography component='h1' color='textSecondary' gutterBottom>
							Wohnbereich
						</Typography>
						{getGroupName()}
						<Typography className='mg-t-24' component='h1' color='textSecondary' gutterBottom>
							Pflegeeinrichtung
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<TextField id='name' name='name' value={state.name} disabled label='Name' />
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<TextField id='street' name='street' value={state.street} disabled label='Straße' />
								</FormControl>
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControl fullWidth>
									<TextField id='postalCode' name='postalCode' value={state.postalCode} disabled label='Postleitzahl' />
								</FormControl>
							</Grid>
							<Grid item xs={12} md={8}>
								<FormControl fullWidth>
									<TextField id='city' name='city' value={state.city} disabled label='Ort' />
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<TextField id='country' name='country' value={state.country} disabled label='Land' />
								</FormControl>
							</Grid>
						</Grid>
					</CardContent>
					<CardActions>
						<Grid container justify='space-between'>
							<Grid item>
								<Button to={`/einrichtung/${params.id}`} component={Link} startIcon={<ImCancelCircle />} variant='outlined' color='primary'>
									Abbrechen
								</Button>
							</Grid>
							<Grid item>
								<Button type='submit' variant='contained' endIcon={<MdDelete />} color='secondary'>
									Löschen
								</Button>
							</Grid>
						</Grid>
					</CardActions>
				</Card>
			</form>
		</Fragment>
	);
};

export default HomeGroupDeleteForm;

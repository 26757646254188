import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Circles} from 'react-loading-icons'
import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component

const useStyles = makeStyles({
    container: {
        marginTop: '40px !important',
        marginBottom: '24px !important',
        textAlign: 'center'
    },
    loader: {
        width: 24,
        height: 24,
        padding: 3,
        fontSize: "1.125rem",
        verticalAlign: 'top',
        cursor: "default"
    },
    center: {
        maxWidth: 320,
        margin: '0 auto'
    },
    normal: {
        width: 28,
        height: 28,
        fontSize: "2.125rem",
    },
    small: {
        width: 16,
        height: 24,
        fontSize: "1.125rem",
    },
    big: {
        width: 40,
        height: 40,
        fontSize: "2.625rem",
    },
    large: {
        width: 52,
        height: 52,
        fontSize: "3.125rem",
    }
});

const Loader = ({center, size, color, ...args}) => {

    /**
     * CSS
     */
    const classes = useStyles();

    return <div className={[
        classes.container,
        center ? classes.center : '',
    ].join(' ')}>
        <Circles stroke={color} fill={color} className={[classes.loader, size ? classes[size] : '',].join(' ')} />
    </div>
}

Loader.propTypes = {
    size: PropTypes.oneOf(['little', 'small', 'normal', 'big', 'large'])
};

Loader.defaultProps = {
    center: false,
    size: 'normal',
    color: '#5588a3'
};

export default Loader;
import {makeStyles} from "@material-ui/core";

const filterThemeStyles = makeStyles({
    filter: {
        position: 'relative',
        '& label': {
            left: '10px',
            top: '8px',
        }
    },
    filterButton: {
        '&': {
            display: 'inline-block',
            position: 'absolute',
            right: '24px',
            top: '72px',
            ['@media (max-width:601px)']: { // eslint-disable-line no-useless-computed-key
                right: '10px',
                top: '64px',
            }
        },
    }
});

const serialize = (obj, prefix) => {
    let str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];

            if (v.length === 0) {
                continue;
            }
            str.push((typeof v === "object") ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
};

export { filterThemeStyles, serialize };
import {Button, FormControl, Grid, makeStyles, TextField} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, {Fragment, useContext, useEffect, useState} from "react";
import AuthContext from "../../context/auth";
import {Link} from "react-router-dom";
import {ImCancelCircle, MdSend} from 'react-icons/all';
import {toast} from "react-toastify";
import axios from "axios";
import handleErrors from "../../utils/handleErrors";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    action: {
        margin: '16px 0 0 0'
    }
}));

const PaymentData = () => {
    const {auth} = useContext(AuthContext);
    const [state, setState] = useState({email: '', company: '', iban: '', bic: '', mandate: false});
    const classes = useStyles();

    const change = (e) => {
        setState({...state, [e.target.name]: e.target.value.replace(/ /g, "")});
    };

    const changeMandate = (e) => {
        const update = {...state};

        update.mandate = !update.mandate;

        setState(update);
    };

    const fetch = async () => {
        try {
            const response = await axios.get(`/api/users/${auth.user}/paymentData`, {headers: {'x-auth-token': auth.token}});

            let customer = {company: '', email: ''}
                if(auth.roles.includes('ROLE_HOME')) {
                    const responseUser = await axios.get(`/api/users/${auth.user}`, {headers: {'x-auth-token': auth.token}});
                    const responseHome = await axios.get(`/api/homes/${responseUser.data.home}`, {headers: {'x-auth-token': auth.token}});

                    if(responseHome) {
                        customer.company = responseHome.data.company || responseHome.data.name;
                        customer.email = responseHome.data.email;
                    }
                } else if(auth.roles.includes('ROLE_DOCTOR')) {
                    const responseUser = await axios.get(`/api/users/${auth.user}`, {headers: {'x-auth-token': auth.token}});
                    const responseDoctor = await axios.get(`/api/doctors/${responseUser.data.doctor}`, {headers: {'x-auth-token': auth.token}});
                    if(responseDoctor) {
                        customer.company = responseDoctor.data.lastname;
                        customer.email = responseDoctor.data.email;
                    }
                } else {
                    const responseSettings = await axios.get(`/api/settings`, {headers: {'x-auth-token': auth.token}});
                    if(responseSettings) {
                        customer.company = responseSettings.data.company;
                        customer.email = responseSettings.data.email;
                    }
                }

            if(response.data) {
                setState({...state, ...response.data, ...customer, mandate: !!response.data.mandate});
            }
        } catch(err) {
            handleErrors(err);
        }
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            let response = await axios.put(`/api/users/${auth.user}/paymentData`, {
                    ...state
                }, {headers: {'x-auth-token': auth.token}});

            if(response.data.success) {
                if (response.data.action && response.data.action === 'revoked') {
                    toast.success('SEPA-Lastschriftmandat wurden erfolgreich entzogen.');
                }
                else {
                    toast.success('SEPA-Lastschriftmandat wurden erfolgreich erteilt.');
                }
            } else if(response.data.action && response.data.action === 'error') {
                toast.error('SEPA-Lastschriftmandat konnten nicht erteilt werden. Bitte überprüfen Sie ihre IBAN und BIC');
            }
        } catch(err) {
            console.log(err);
            toast.error('SEPA-Lastschriftmandat konnten nicht erteilt werden.');
        }

        return false;
    };

    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<Fragment>
        {((!state.email || (state.email && state.email.length === 0)) || (!state.company || (state.company && state.company.length === 0))) && (
            <>
                <Alert severity="error">Sie müssen ihre Email-Addresse und Unternehmensnamen in den Einstellungen angeben bevor sie ein Mandat erteilen können.</Alert>
                <br />
            </>
        )}
        <FormControl fullWidth>
            <TextField
                id='iban'
                name='iban'
                value={state.iban ? state.iban.replace(/ /g, "").replace(/([0-9A-Z]{4})/g, '$1 ') : ''}
                label='IBAN'
                onChange={change}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                id='bic'
                name='bic'
                value={state.bic}
                label='BIC'
                onChange={change}
            />
        </FormControl>
        <FormControl fullWidth>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={state.mandate}
                        name={'mandate'}
                        onClick={changeMandate}
                    />}
                label="SEPA-Lastschriftmandat erteilt."
            />
        </FormControl>

        <Grid container justify='space-between' className={classes.action}>
            <Grid item xs={12} sm={3} md={2} xl={2}>
                <Button
                    color='primary'
                    component={Link}
                    fullWidth
                    startIcon={<ImCancelCircle />}
                    to='/'
                    variant='outlined'
                >
                    Abbrechen
                </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={2} xl={2}>
                {state.email && state.email.length > 0 && state.company && state.company.length > 0 && (
                <Button
                    color='primary'
                    endIcon={<MdSend />}
                    fullWidth
                    type='submit'
                    variant='contained'
                    onClick={submit}
                >
                    Speichern
                </Button>)}

            </Grid>
        </Grid>
    </Fragment>);
};

export default PaymentData;
import factory from "@factories/factory";
import request from "@utils/request";
import {toast} from "react-toastify";

const taskServiceFactory = (config) => {
    return {
        ...factory,
        ...config,
        path: 'tasks',
        get: async function(id, filter, page, limit) {
            id = id ? `/${id}` : '';

            let connectWith = '?';
            let splice = '';

            if(page !== undefined) {
                limit = limit === -1 ? 999999 : limit;
                limit = limit ? limit : this.limit;
                splice = `?offset=${page*limit}&limit=${limit}`;
                connectWith = '&';
            }

            const response = await request.get(`/api/tasks${id}${splice}${this.toFilterQuery(filter, connectWith)}`, {
                headers: {'x-auth-token': this.auth.token}
            });

            return response.data;
        },
        downloadPickup: async function(tasks) {
            const response = await request.post(`/api/tasks/pickup`, {tasks}, {
                headers: {'x-auth-token': this.auth.token},
                responseType: 'blob'
            });

            return response.data;
        },
        getDosageList: async function() {
            const response = await request.get(`/api/tasks/dosage`, {
                headers: {'x-auth-token': this.auth.token}
            });

            return response.data;
        },
        seen: async function(tasks) {
            if(tasks) {
                await request.put(`/api/tasks/seen`, {tasks}, {
                    headers: {'x-auth-token': this.auth.token}
                });
                toast.success('Aufträge als gelesen markiert.');
            } else {
                await request.put(`/api/tasks/seen`, {tasks: true}, {
                    headers: {'x-auth-token': this.auth.token}
                });
                toast.success('Alle Aufträge als gelesen markiert.');
            }
        }
    }
}

export default taskServiceFactory;
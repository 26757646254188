/* jshint esversion: 8 */
import {Breadcrumbs, Button, CardActions, CardContent, FormLabel, Grid, IconButton, TextField, Typography} from '@material-ui/core';
import axios from 'axios';
import React, {Fragment, useContext, useEffect, useMemo, useState} from 'react';
import Dropzone, {useDropzone} from 'react-dropzone';
import {ImCancelCircle} from 'react-icons/im';
import {MdSend} from 'react-icons/md';
import {RiDeleteBinLine} from "react-icons/ri";
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import AuthContext from '../context/auth';

const baseStyle = {
    alignItems: 'center',
    // backgroundColor: '#fafafa',
    borderColor: '#eeeeee',
    borderRadius: 2,
    borderStyle: 'dashed',
    borderWidth: 2,
    cursor: 'pointer',
    color: '#434343',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '0',
    outline: 'none',
    paddingBottom: '100%',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const SettingsForm = ({ history, match: { params } }) => {
    /**
     * CONTEXT
     */
    const { auth } = useContext(AuthContext);

    const [state, setState] = useState({
        company: '',
        street: '',
        postalCode: '',
        city: '',
        phone: '',
        fax: '',
        email: '',
        debtorId: 0,
        instanceId: 0,
        isApomondoInstance: false,
    });

    const acceptFile = (files) => {
        if (files.length > 0) {
            files[0].preview = URL.createObjectURL(files[0]);
            setState({
                ...state,
                logo: files[0]
            });
        }
    };

    const deleteFile = (e) => {
        setState({
            ...state,
            'logo': '_delete',
        });
    };

    const fetchData = async () => {
        try {
            const response = await axios.get('/api/settings', {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
            setState(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * CONTROLS
     */
    const change = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    const changeZip = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setState({...state, [e.target.name]: value.replace(/[^0-9]+/, '').substr(0,5)});
    };

    const { isDragActive, isDragAccept, isDragReject } = useDropzone({ accept: 'image/*' });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragActive ? activeStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragAccept,
        isDragActive,
        isDragReject,
    ]);

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * SUBMIT
     */
    const submit = async (evt) => {
        evt.preventDefault();
        let data = new FormData();

        for (const [name, value] of Object.entries(state)) {
            if (value !== null) {
                data.append(name, value);
            }
        }

        try {
            await axios.put(`/api/settings`, data, {
                headers: {
                    'x-auth-token': auth.token,
                    'Content-Type': 'multipart/form-data',
                },
            });

            toast.success('Einstellungen geändert.');
        } catch (err) {
            console.log(err);
        }

        return false;
    };

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Typography color='textPrimary'>Einstellungen</Typography>
            </Breadcrumbs>
            <CardContent>
                <form onSubmit={(e) => submit(e)}>
                    <CardContent>
                        <Typography component='h1' color='textSecondary' gutterBottom>
                            Einstellungen bearbeiten
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item sm={8} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled={state.isApomondoInstance}
                                            formHelperTextProps={{focused: true}}
                                            fullWidth
                                            helperText='Firmenname, der auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'
                                            id='name'
                                            label='Firmenname'
                                            name='company'
                                            onChange={(e) => change(e)}
                                            required
                                            value={state.company}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            formHelperTextProps={{focused: true}}
                                            helperText='Straße der Firma, die auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'
                                            id='street'
                                            name='street'
                                            label='Straße'
                                            value={state.street}
                                            required
                                            onChange={(e) => change(e)}
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <TextField
                                            fullWidth
                                            formHelperTextProps={{focused: true}}
                                            helperText='Postleitzahl der Firma, die auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'
                                            id='postal_code'
                                            name='postalCode'
                                            label='Postleitzahl'
                                            value={state.postalCode}
                                            required
                                            onChange={(e) => changeZip(e)}
                                        />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                        <TextField
                                            fullWidth
                                            formHelperTextProps={{focused: true}}
                                            helperText='Stadt der Firma, die auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'
                                            id='city'
                                            name='city'
                                            label='Ort'
                                            value={state.city}
                                            required
                                            onChange={(e) => change(e)}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            formHelperTextProps={{focused: true}}
                                            helperText='Telefonnummer der Firma, die auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'
                                            id='phone'
                                            label='Telefon'
                                            name='phone'
                                            value={state.phone}
                                            required
                                            onChange={(e) => change(e)}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            formHelperTextProps={{focused: true}}
                                            helperText='Faxnummer der Firma, die auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'
                                            id='company_fax'
                                            label='Fax'
                                            name='fax'
                                            value={state.fax}
                                            required
                                            onChange={(e) => change(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            formHelperTextProps={{focused: true}}
                                            helperText='E-Mail Adresse der Firma, die auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'
                                            id='email'
                                            label='E-Mail'
                                            name='email'
                                            value={state.email}
                                            required
                                            onChange={(e) => change(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled={!auth.roles || !auth.roles.includes('ROLE_ADMIN')}
                                            formHelperTextProps={{focused: true}}
                                            fullWidth
                                            helperText='In der Regel dreistellig. z.B. 001'
                                            id='debtorId'
                                            label='Kundennummer'
                                            name='debtorId'
                                            onChange={(e) => change(e)}
                                            value={state.debtorId}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled={!auth.roles || !auth.roles.includes('ROLE_ADMIN')}
                                            formHelperTextProps={{focused: true}}
                                            fullWidth
                                            helperText='In der Regel dreistellig. z.B. 001'
                                            id='instanceId'
                                            label='Instanz-Nummer'
                                            name='instanceId'
                                            onChange={(e) => change(e)}
                                            value={state.instanceId}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <FormLabel style={{fontSize:'11px'}}>Logo</FormLabel>
                                <div className="container" style={{
                                    'backgroundColor': '#ffffff',
                                    'backgroundImage': `url(${state.logo && state.logo.preview ? state.logo.preview : state.logo})`,
                                    'backgroundPosition': 'center center',
                                    'backgroundRepeat': 'no-repeat',
                                    'backgroundSize': 'auto 60%',
                                    'height': '0',
                                    'paddingBottom': '100%',
                                    'width': '100%',
                                }}>
                                    <Dropzone accept={'image/*'} onDrop={acceptFile}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                {state.logo !== '' && state.logo !== '_delete' && <IconButton
                                                    aria-label="delete"
                                                    color="primary"
                                                    onClick={deleteFile}
                                                    style={{
                                                        'position': 'absolute'
                                                    }}
                                                >
                                                    <RiDeleteBinLine/>
                                                </IconButton>}
                                                <div {...getRootProps({style})}>
                                                    <input {...getInputProps()} />
                                                    {(state.logo === '' || state.logo === '_delete') && <p style={{
                                                        cursor: 'pointer',
                                                        marginTop: '50%',
                                                        textAlign: 'center',
                                                    }}>Logo per Drag and Drop hier ablegen oder hier klicken zum wählen.</p>}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container justify='space-between'>
                            <Grid item xs={12} sm={3} md={2} xl={1}>
                                <Button
                                    color='primary'
                                    component={Link}
                                    fullWidth
                                    startIcon={<ImCancelCircle />}
                                    to='/'
                                    variant='outlined'
                                >
                                    Abbrechen
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} xl={1}>
                                <Button
                                    color='primary'
                                    endIcon={<MdSend />}
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                >
                                    Speichern
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </form>
            </CardContent>
        </Fragment>
    );
};

export default SettingsForm;

//TODO Remove if v3 is released
import AuthContext from '../../context/auth';
export default AuthContext;

//TODO V3 comment in if v3 is released

// import { createContext } from 'react';
//
// const AuthContext = createContext(JSON.parse(localStorage.getItem('auth')));
//
// export default AuthContext;

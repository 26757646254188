import {makeStyles} from "@material-ui/core";
import Link from "@components/Link"
import React from "react";

const useStyles = makeStyles({
    contractRequiredLink: {
        '&.btn': {
            textDecoration: 'none',
            textAlign: 'center',
            display: 'inline-block',
            verticalAlign: 'middle',
            border: 0,
            cursor: 'pointer',
            color: '#fff',
            padding: '6px 16px',
            fontSize: '0.875rem',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: '500',
            lineHeight: '1.75',
            borderRadius: '4px',
            letterSpacing: '0.02857em',
            textTransform: 'uppercase',
            backgroundColor: '#2a5d78',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            '&:hover': {
                backgroundColor: '#00334e',
            },
        },

        '&.btn svg': {
            margin: '-5px 0 0 5px !important',
            display: 'inline-block',
            verticalAlign: 'middle',
            color: '#fff',
            fontSize: '20px',
        },

        '& svg': {
            margin: '0 0 0 5px !important',
            display: 'inline-block',
            verticalAlign: 'middle',
            color: '#2a5d78',
            fontSize: '18px',
        },

        '&.missing-contract': {
            '&.btn-warning': {
                backgroundColor: '#fbbc06',
                '&:hover': {
                    backgroundColor: '#956f02',
                },

                '& svg': {
                    margin: '-5px 0 0 5px !important',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    color: '#fff',
                },
            },

            '& svg': {
                color: '#fbbc06',

                '&:hover': {
                    color: '#956f02',
                }
            },

        },

        '&.fullwidth': {
            width: '100%'
        },

        '& +.modal-content-box': {
            textAlign: 'left',
            '& .content-headline': {
                color: '#5588a3',
                borderBottom: '1px solid #5588a3',
                cursor: 'default',
                fontSize: '18px',
                padding: '10px 15px 5px 15px',
                margin: '-12px -12px 11px -12px'
            },
            '& .contract-required-text': {
                padding: '15px 30px'
            },
            '& .contract-required-link-container': {
                textAlign: 'center',
                margin: '25px 0 0 0'
            },
            '& .link': {
                border: '1px solid #05a34a',
                backgroundColor: '#05a34a',
                color: '#fff',
                padding: '3px 10px',
                outline: 'none !important',
                boxShadow: 'none !important',
                display: 'inline-block',
                fontWeight: 400,
                lineHeight: 1.5,
                textAlign: 'center',
                verticalAlign: 'middle',
                cursor: 'pointer',
                fontSize: '0.875rem',
                borderRadius: '0.25rem',
                transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                textDecoration: 'none',
                '&:hover': {
                    backgroundColor: '#025727',
                    borderColor: '#02431e',
                }
            }
        }
    }
});

const LinkWithContractRequirements = ({children, to, permission, className, type, fullWidth, message}) => {
    const classes = useStyles();

    return (<Link
        message={message}
        fullWidth={fullWidth}
        type={type}
        permission={permission}
        className={`${className} ${classes.contractRequiredLink}`}
        to={to}>
        {children}
    </Link>);
}

export default LinkWithContractRequirements;
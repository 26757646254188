import React from 'react';

const NavbarDropdown = ({ children, id, icon }) => {

    
    return (
        <li className='nav-item dropdown'>
            <a
                className='nav-link dropdown-toggle'
                href={`#${id}`}
                id={`#${id}Dropdown`}
                role='button'
                data-bs-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
            >
                {icon}
            </a>
            <div className='dropdown-menu p-0' aria-labelledby={`#${id}Dropdown`}>
                { children }
            </div>
        </li>
    );
};

export default NavbarDropdown;

import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import defaultAvatar from '../assets/img/default.png';

const User = () => {
    const { auth } = useContext(AuthContext);

    return (
        <div className='d-flex flex-column align-items-center border-bottom px-5 py-3'>
            <div className='mb-3'>
                <img className='wd-80 ht-80 rounded-circle' src={defaultAvatar} alt='' />
            </div>
            <div className='text-center'>
                <p className='tx-16 fw-bolder'></p>
                <p className='tx-12 text-muted'>{auth.username}</p>
            </div>
        </div>
    );
};

export default User;

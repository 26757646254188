/* jshint esversion: 8 */
import {
    AppBar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import axios from "axios";
import fileDownload from 'js-file-download'
import PropTypes from 'prop-types';
import React, {Fragment, useContext, useEffect, useMemo, useState} from 'react';
import Dropzone, {useDropzone} from "react-dropzone";
import {AiOutlineLock, FaRegAddressCard, ImCancelCircle, MdSend} from 'react-icons/all';
import {RiDeleteBinLine} from "react-icons/ri";
import {Link} from 'react-router-dom';
import {toast} from "react-toastify";
// import InvoiceTable from "../components/InvoiceTable";
import List from "../components/List";
import PasswordForm from "../components/PasswordForm";
import Pdf from "../components/Pdf";
import AuthContext from "../context/auth";
import handleErrors from "../utils/handleErrors";
import {checkRequiredFields} from "../utils/tools";
import PaymentData from "./profile/PaymentData";
import BackupTab from "./profile/BackupTab";
import InvoiceTab from "@views/profile/tabs/invoice/InvoiceTab";
import ContractTab from "@views/profile/tabs/contract/ContractTab";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        margin: 0,
    },
}));

const baseStyle = {
    alignItems: 'center',
    // backgroundColor: '#fafafa',
    borderColor: '#eeeeee',
    borderRadius: 2,
    borderStyle: 'dashed',
    borderWidth: 2,
    cursor: 'pointer',
    color: '#434343',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '0',
    outline: 'none',
    paddingBottom: '100%',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const ProfileForm = ({history, match: {params}}) => {
    const classes = useStyles();
    /**
     * STATES
     */
    const {auth, setAuth} = useContext(AuthContext);
    const [tab, setTab] = React.useState(0);
    const [pdf, setPdf] = React.useState(false);
    const [documents, setDocuments] = React.useState([]);
    const [state, setState] = useState({
        lastname: '',
        firstname: '',
        title: '',
        salutation: '',
        street: '',
        postalCode: '',
        city: '',
        country: '',
        password: '',
        password_confirm: '',
        once_password: false,
        username: ''
    });
    const [validation, setValidation] = useState({error: false, errorMessage: {},});

    /**
     * REFS
     */
    const pdfRef = React.useRef();
    pdfRef.current = {
        data: pdf,
        name: ''
    };

    /**
     * QUERIES
     */
    const fetchData = async () => {
        try {
            let response = await axios.get(`/api/users/${auth.user}/profile`, {headers: {'x-auth-token': auth.token}});
            response.data.password = '';
            setState({...response.data});
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    // const fetchInvoices = async () => {
    //     try {
    //         let response = await axios.get(`/api/invoice/list`, {headers: {'x-auth-token': auth.token}});
    //         setInvoices(response.data);
    //     } catch (err) {
    //         handleErrors(err, setAuth, history);
    //     }
    // };

    const fetchDocuments = async () => {
        try {
            let response = await axios.get(`/api/users/${auth.user}/profile/documents`, {headers: {'x-auth-token': auth.token}});
            setDocuments(response.data);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchData();
        //fetchInvoices();
        fetchDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const downloadInvoice = (args) => {
    //     axios.get(`/api/invoice/download/${args['name']}?path=${args['path']}`, {
    //         responseType: 'blob',
    //         headers: {'x-auth-token': auth.token}
    //     })
    //         .then((res) => {
    //             fileDownload(res.data, args['name']);
    //             fetchInvoices();
    //         });
    //
    //     return false;
    // };

    useEffect(() => {
        // code to run after render goes here
        const searchParams = new URLSearchParams(document.location.search);

        const tabIndex = searchParams.get('tab');
        if(tabIndex !== null) {
            setTab(parseInt(tabIndex));
        } else {
            let match = window.location.href.match(/#(.*)/);
            if (match && match.length > 0) {
                let elem = document.getElementById(match[1]);
                if (elem) {
                    elem.click();
                    if (elem.id === 'scrollable-force-tab-1') {
                        toast.warning('Das Passwort ist nur einmal gültig und muss geändert werden!');
                    }
                }
            }
        }
    }, []);


    const acceptFile = (files) => {
        if (files.length > 0) {
            files[0].preview = URL.createObjectURL(files[0]);
            setState({
                ...state,
                avatar: files[0]
            });
        }
    };

    const deleteFile = (e) => {
        setState({
            ...state,
            'avatar': '_delete',
        });
    };

    const {isDragActive, isDragAccept, isDragReject} = useDropzone({accept: 'image/*'});

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragActive ? activeStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragAccept,
        isDragActive,
        isDragReject,
    ]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const change = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    };

    const changeZip = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setState({...state, [e.target.name]: value.replace(/[^0-9]+/, '').substr(0, 5)});
    };

    const loadPdf = async (args) => {
        try {
            const response = await axios.get(`/api/users/${auth.user}/profile/documents/${args['name']}`, {
                headers: {'x-auth-token': auth.token}
            });
            setPdf(response.data);
            pdfRef.current.name = args['name'];
        } catch (e) {
        }
    };

    const downloadDocument = (args) => {
        axios.get(`/api/users/${auth.user}/profile/documents/${args['name']}?noBase64=1`, {
            responseType: 'blob',
            headers: {'x-auth-token': auth.token}
        })
            .then((res) => {
                fileDownload(res.data, args['name'].replace(/{.*?}_/, ''));
                fetchDocuments();
            });

        return false;
    };

    const validate = (e, subFormValidation) => {
        // saved validation is only required for form output, because we validates the form complete again, so we use an empty validation!!!
        let newValidation = {};
        let requiredFields = {};
        newValidation = checkRequiredFields(newValidation, requiredFields, state);
        if (subFormValidation !== undefined && subFormValidation['error']) {
            newValidation['error'] = true;
            newValidation['errorMessage'] = Object.assign(
                newValidation['errorMessage'],
                subFormValidation['errorMessage']
            );
        }
        setValidation(newValidation);

        // call validate function of subform
        // e is given if called by callback
        if (e !== undefined && 'current' in parentRef && 'onValidate' in parentRef.current) {
            parentRef.current.onValidate();
        }

        return !newValidation.error;
    };

    /**
     * SUBMIT
     */
    const submit = async (e) => {
        e.preventDefault();

        if (validation && validation['error']) {
            return false;
        }

        let data = new FormData();

        for (const [name, value] of Object.entries(state)) {
            if (value !== null) {
                if (value instanceof Array) {
                    for (const val of value) {
                        data.append(name, val);
                    }
                } else {
                    if (name === 'home') {
                        data.append(name, value._id);
                    } else {
                        data.append(name, value);
                    }
                }
            }
        }

        try {
            await axios.put(`/api/users/${auth.user}/profile`, data, {
                headers: {
                    'x-auth-token': auth.token,
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('Profil geändert.');

            let once = auth.once_password;
            if (state.password && state.password.trim() !== '') {
                once = false;
                localStorage.setItem('auth', JSON.stringify({...auth, once_password: once}));
                setAuth({...auth, once_password: once});
            }

            if (once) {
                history.push('/profil');
            } else {
                history.push('/');
            }

        } catch (err) {
            handleErrors(err, setAuth, history);
        }

        return false;
    };

    const renameDocumentFile = async (newName) => {
        try {
            await axios.put(`/api/users/${auth.user}/profile/documents/${pdfRef.current.name}`, {name: newName}, {headers: {'x-auth-token': auth.token}});
            toast.success('Auswahl wurde gespeichert');
            fetchDocuments();
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const subFormEdit = (e, aPage, object) => {
        e.preventDefault();
        let newState = Object.assign({}, state);
        switch (aPage) {
            case 'bearbeiten':
                if (object !== undefined) {
                    newState.password = object.password;
                    newState.password_confirm = object.password_confirm;
                    newState.current_password = object.current_password;
                }
                break;
            case 'cancel':
            default:
                break;
        }

        setState(newState);
    };

    let parentRef = React.useRef(state);

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Typography color='textPrimary'>Profil bearbeiten</Typography>
            </Breadcrumbs>
            <form>
                <Card id='settingsUserCard'>
                    <CardContent>
                        <Typography component='h1' color='textSecondary' gutterBottom>
                            Profil bearbeiten
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AppBar className={classes.appBar} position='static' color='default'>
                                    <Tabs
                                        value={tab}
                                        onChange={handleTabChange}
                                        indicatorColor='primary'
                                        textColor='primary'
                                    >
                                        {auth.permissions.indexOf('profile_personal_data_view') !== -1 && (
                                            <Tab label='Stammdaten' icon={<FaRegAddressCard/>} {...a11yProps(0)} />
                                        )}
                                        {auth.permissions.indexOf('profile_password_view') !== -1 && (
                                            <Tab label='Passwort' icon={<AiOutlineLock/>} {...a11yProps(1)} />
                                        )}
                                        {(auth.permissions.indexOf('profile_invoice_list_view') !== -1) && (
                                            <Tab label='Rechnungen' icon={<AiOutlineLock/>} {...a11yProps(2)} />)}
                                        {auth.permissions.indexOf('profile_contract_list_view') !== -1 && (
                                            <Tab label='Verträge' icon={<AiOutlineLock/>} {...a11yProps(3)} />)}
                                        {auth.permissions.indexOf('profile_document_view') !== -1 && (
                                            <Tab label='Dokumente' icon={<AiOutlineLock/>} {...a11yProps(4)} />)}
                                        {auth.permissions.indexOf('profile_mandate_view') !== -1 && (
                                            <Tab label='Mandat' icon={<AiOutlineLock/>} {...a11yProps(5)} />)}
                                        {auth.permissions.indexOf('profile_backup_view') !== -1 && (
                                            <Tab label='Backup' icon={<AiOutlineLock/>} {...a11yProps(6)} />)}
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={tab} index={5}>
                                    <Grid container spacing={1}>
                                        <Grid item sm={8} xs={12}>
                                            <PaymentData/>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tab} index={0}>
                                    <Grid container spacing={1}>
                                        <Grid item sm={8} xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled={true}
                                                            id='username'
                                                            name='username'
                                                            value={state.username}
                                                            label='Nutzername'
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id='salutation-label'>Anrede</InputLabel>
                                                        <Select
                                                            disabled={params.type && params.type === 'loeschen'}
                                                            labelId='salutation-label'
                                                            id='salutation'
                                                            name='salutation'
                                                            value={state.salutation}
                                                            onChange={(e) => {
                                                                change(e);
                                                            }}
                                                        >
                                                            <MenuItem value={''}>Nicht angegeben</MenuItem>
                                                            <MenuItem value={'Herr'}>Herr</MenuItem>
                                                            <MenuItem value={'Frau'}>Frau</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled={params.type && params.type === 'loeschen'}
                                                            id='title'
                                                            name='title'
                                                            value={state.title}
                                                            onChange={(e) => change(e)}
                                                            label='Titel'
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled={params.type && params.type === 'loeschen'}
                                                            id='firstname'
                                                            value={state.firstname}
                                                            name='firstname'
                                                            onChange={(e) => change(e)}
                                                            label='Vorname'
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled={params.type && params.type === 'loeschen'}
                                                            id='lastname'
                                                            name='lastname'
                                                            value={state.lastname}
                                                            onChange={(e) => change(e)}
                                                            label='Nachname'
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled={params.type && params.type === 'loeschen'}
                                                            id='street'
                                                            name='street'
                                                            value={state.street}
                                                            onChange={(e) => change(e)}
                                                            label='Straße'
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={4} xs={12}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled={params.type && params.type === 'loeschen'}
                                                            id='postalCode'
                                                            name='postalCode'
                                                            value={state.postalCode}
                                                            onChange={(e) => changeZip(e)}
                                                            label='Postleitzahl'
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={8} xs={12}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled={params.type && params.type === 'loeschen'}
                                                            id='city'
                                                            name='city'
                                                            value={state.city}
                                                            onChange={(e) => change(e)}
                                                            label='Ort'
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled={params.type && params.type === 'loeschen'}
                                                            id='country'
                                                            name='country'
                                                            value={state.country}
                                                            onChange={(e) => change(e)}
                                                            label='Land'
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <FormLabel style={{fontSize: '11px'}}>Avatar</FormLabel>
                                            <div className="container" style={{
                                                'backgroundColor': '#ffffff',
                                                'backgroundImage': `url(${state.avatar && state.avatar.preview ? state.avatar.preview : state.avatar})`,
                                                'backgroundPosition': 'center center',
                                                'backgroundRepeat': 'no-repeat',
                                                'backgroundSize': '100% auto',
                                                'height': '0',
                                                'paddingBottom': '100%',
                                                'width': '100%',
                                            }}>
                                                <Dropzone accept={'image/*'} onDrop={acceptFile}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <section>
                                                            {state.avatar !== '' && state.avatar !== '_delete' &&
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="primary"
                                                                onClick={deleteFile}
                                                                style={{
                                                                    'position': 'absolute'
                                                                }}
                                                            >
                                                                <RiDeleteBinLine/>
                                                            </IconButton>}
                                                            <div {...getRootProps({style})}>
                                                                <input {...getInputProps()} />
                                                                {(state.avatar === '' || state.avatar === '_delete') &&
                                                                <p style={{
                                                                    cursor: 'pointer',
                                                                    marginTop: '50%',
                                                                    textAlign: 'center',
                                                                }}>Avatar per Drag and Drop hier ablegen oder hier
                                                                    klicken zum wählen.</p>}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container justify='space-between' style={{marginTop: '18px'}}>
                                        <Grid item xs={12} sm={3} md={2} xl={1}>
                                            <Button
                                                color='primary'
                                                component={Link}
                                                fullWidth
                                                startIcon={<ImCancelCircle/>}
                                                to='/'
                                                variant='outlined'
                                            >
                                                Abbrechen
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={2} xl={1}>
                                            <Button
                                                color='primary'
                                                endIcon={<MdSend/>}
                                                fullWidth
                                                type='submit'
                                                variant='contained'
                                                onClick={(e) => submit(e)}
                                            >
                                                Speichern
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    <Grid container spacing={2}>
                                        {
                                            <PasswordForm
                                                current_password
                                                generate_password
                                                minLength={12}
                                                minLowercaseCount={2}
                                                minUppercaseCount={2}
                                                minNumbersCount={2}
                                                minSpecialcharsCount={2}
                                                maxLength={4096}
                                                forbiddenchars={true}
                                                parentRef={parentRef}
                                                onValidate={validate}
                                                subState={{
                                                    password: '',
                                                    password_confirm: '',
                                                    once_password: false,
                                                    showPassword: false,
                                                }}
                                                onSave={subFormEdit}
                                            />
                                        }
                                    </Grid>
                                    <Grid container justify='space-between' style={{marginTop: '18px'}}>
                                        <Grid item xs={12} sm={3} md={2} xl={1}>
                                            <Button
                                                color='primary'
                                                component={Link}
                                                fullWidth
                                                startIcon={<ImCancelCircle/>}
                                                to='/'
                                                variant='outlined'
                                            >
                                                Abbrechen
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={2} xl={1}>
                                            <Button
                                                color='primary'
                                                endIcon={<MdSend/>}
                                                fullWidth
                                                type='submit'
                                                variant='contained'
                                                onClick={submit}
                                            >
                                                Speichern
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tab} index={2}>
                                    <div className={'v3'}>
                                        <InvoiceTab />
                                    </div>
                                    {/*{Object.values(invoices).length === 0 && (*/}
                                    {/*    <Typography>Keine Rechnungen gefunden</Typography>*/}
                                    {/*)}*/}
                                    {/*{Object.values(invoices).length > 0 && (*/}
                                    {/*    <InvoiceTable collection={invoices} eventClick={downloadInvoice}*/}
                                    {/*                  groupBy='month'/>*/}
                                    {/*)}*/}
                                </TabPanel>
                                <TabPanel value={tab} index={3}>
                                    <div className={'v3'}>
                                        <ContractTab />
                                    </div>
                                </TabPanel>
                                <TabPanel value={tab} index={4}>
                                    <Grid container spacing={2}>
                                        {documents.length === 0 ? (
                                            <Typography>Keine Dokumente gefunden</Typography>
                                        ) : (
                                            <>
                                                <List collection={documents} eventClick={loadPdf}/>
                                                {pdf && (<Pdf pdf={pdfRef} setPdf={setPdf} buttons={
                                                    <>
                                                        <Button
                                                            color='primary'
                                                            type='submit'
                                                            variant='contained'
                                                            onClick={() => {
                                                                renameDocumentFile(`{accepted}_${pdfRef.current.name.replace(/{.*?}_/, '')}`)
                                                            }}
                                                        >
                                                            Akzeptieren
                                                        </Button>
                                                        <Button
                                                            color='secondary'
                                                            type='submit'
                                                            variant='contained'
                                                            onClick={() => {
                                                                renameDocumentFile(`{reject}_${pdfRef.current.name.replace(/{.*?}_/, '')}`)
                                                            }}
                                                        >
                                                            Ablehnen
                                                        </Button>
                                                        <Button
                                                            color='success'
                                                            type='submit'
                                                            variant='contained'
                                                            onClick={() => {
                                                                downloadDocument({name: `${pdfRef.current.name}`})
                                                            }}
                                                        >
                                                            Download
                                                        </Button>
                                                    </>
                                                }/>)}
                                            </>)}
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tab} index={6}>
                                    <Grid container spacing={2}>
                                        <BackupTab/>
                                    </Grid>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </form>
        </Fragment>
    );
};

export default ProfileForm;

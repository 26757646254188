import React, {Fragment, useContext, useState} from "react";
import AuthContext from "@context/AuthContext";
import invoiceServiceFactory from "@factories/invoiceServiceFactory";
import Table from "@components/Table/Table";
import FileDownload from "js-file-download";
import Icon from "@components/Icon";
import TableRowAction from "@components/Table/TableRowAction";
import moment from "moment";
import Alert from "@components/Alert";
import Headline from "@components/Headline";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const InvoiceCollapse = ({item, ...args}) => {

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [state] = useState(item);


    /**
     * Services
     */
    const invoiceService = invoiceServiceFactory({auth});
    const service = {
        get: async (id, filter) => {
            const mom = moment(state.createdAt);
            return await invoiceService.getProof(id, {...filter, debtorId: item.path.replace('/', ''), dateRange: mom.format('YYMM')}, 0, 99999);
        }
    }

    const download = async (item) => {
        const response = await invoiceService.download(item._id, item.path);

        FileDownload(response, `${item.name}`);
    }

    /**
     * Columns
     */
    const columns = [
        {title: 'Schuldner', render: (item, helper) => {
                return item.debtor;
            }},
        {title: 'Typ', render: (item, helper) => {
                switch (item.role) {
                    case 'pharma': return 'Apotheke';
                    case 'homes': return 'Pflegeeinrichtungen';
                    case 'doctors': return 'Arztpraxen';
                    default: return item.role;
                }
            }},
        {title: 'Mit Login?', render: (item, helper) => {
                return item.withLogin ? 'Ja' : 'Nein';
            }},
        {title: 'Rechnung', render: (item, helper) => {
                return item.name;
            }},
        {title: 'Betrag', render: (item, helper) => {
                return `<span class="price-collapse">${Number(item.price).toLocaleString(undefined, { minimumFractionDigits: 2 })}</span> €`;
            }},
        {title: 'Status', render: (item, helper) => {
            return <span />;
            }},
        {title: 'Letzte Änderung', render: (item, helper) => {
                const mom = moment(item.modifiedAt);
                return mom.format('DD.MM.YYYY');
            }},
        {name: 'actions', render: (item, helper) => {
                return <Fragment>
                    <TableRowAction permission={'download_invoice_view'} onClick={download}>
                        <Icon icon={'download'} tooltip={'Nachweis herunterladen'}/>
                    </TableRowAction>
                </Fragment>
            }},
    ];

    return <Fragment>
        {!!item.creditNote && (
        <tr>
            <td colSpan={1} className={'offset-cell'}/>
            <td colSpan={8} className={'offset-cell'}>
                <div className={'invoice-proof'}>
                    <Alert type={'secondary'} className={'invoice-proof-info'}>
                        Diese Rechnung wurde storniert.
                    </Alert>
                </div>
            </td>
        </tr>)}
        <tr>
            <td colSpan={1} className={'offset-cell'}/>
            <td colSpan={8} className={'offset-cell'}>
                <div className={'invoice-proof'}>
                    <Headline size={'h4'}>Nachweise</Headline>
                    <Alert type={'info'} className={'invoice-proof-info'}>
                        Hier können Sie noch einmal alle Einzelnachweise separat herunterladen.
                    </Alert>
                </div>
            </td>
        </tr>
        <Table
            fullTable={false}
            offset={1}
            className={'invoice-proof-table'}
            columns={columns}
            service={service}
            searchable={false}
            pagination={false}
            header={false}
        />
    </Fragment>
}

InvoiceCollapse.propTypes = {};

InvoiceCollapse.defaultProps = {};

export default InvoiceCollapse;
import React, {Fragment, useContext, useEffect} from "react";
import invoiceServiceFactory from "@factories/invoiceServiceFactory";
import AuthContext from "@context/AuthContext";
import Table from "@components/Table/Table";
import moment from "moment";
import FileDownload from "js-file-download";
import Alert from "@components/Alert";
import InvoiceCollapse from "@views/profile/tabs/invoice/InvoiceCollapse";
import TableRowAction from "@components/Table/TableRowAction";
import Icon from "@components/Icon";
import {Link} from "react-router-dom";

//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const InvoiceTab = ({...args}) => {

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * Services
     */
    const invoiceService = invoiceServiceFactory({auth});
    const tableService = {
        get: async function(id, filter, page, limit) {
            const response = await invoiceService.get(id, filter, page, limit);

            return response;
        }
    }


    /**
     * Hooks
     */
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Functions
     */
    const download = async (item) => {
        const response = await invoiceService.download(item._id, item.path);

        if(item.status === 'unread') {
            item.status = 'open';
        }

        FileDownload(response, `${item.name}`);

        return item;
    }

    const downloadCreditNote = async (item) => {
        const response = await invoiceService.download(item.creditNote, item.path);

        FileDownload(response, `${item.creditNote}`);

        return item;
    }

    const switchPaymentState = async (item) => {
        const status = item.status === 'paid' ? 'open' : 'paid';
        const response = await invoiceService.update(item._id, item.path, {action: 'change_status', data: status});

        if(response.success) {
            return {status: status, _id: response._id};
        }
    }

    const doRowHighlight = (item) => {
        return item.status === 'unread';
    }

    const collapse = (item) => {
        return <InvoiceCollapse item={item} rows={true}/>;
    }

    const cancelCell = (item) => {
        return !!item.creditNote ? 'text-muted' : ''
    }

    /**
     * Columns
     */
    const columns = [
        {title: 'Monat', classes: [cancelCell], render: (item, helper) => {
            const mom = moment(item.createdAt);
                return mom.format('MM/YYYY');
            }},
        {title: 'Schuldner', classes: [cancelCell], render: (item, helper) => {
                return item.debtor;
            }},
        {title: 'Typ', classes: [cancelCell], render: (item, helper) => {
                switch (item.role) {
                    case 'pharma': return 'Apotheke';
                    case 'homes': return 'Pflegeeinrichtungen';
                    case 'doctors': return 'Arztpraxen';
                    default: return item.role;
                }
            }},
        {title: 'Mit Login?', classes: [cancelCell], render: (item, helper) => {
                return item.withLogin ? 'Ja' : 'Nein';
            }},
        {title: 'Rechnung', classes: [cancelCell], width: 320, render: (item, helper) => {
                return item.name;
            }},
        {title: 'Betrag', classes: [cancelCell], render: (item, helper) => {
                return `${Number(item.price).toLocaleString(undefined, { minimumFractionDigits: 2 })} €`;
            }},
        {title: 'Status', classes: [cancelCell], render: (item, helper) => {
                switch (item.status) {
                    case 'paid': return 'bezahlt';
                    case 'payed': return 'bezahlt';
                    case 'canceled': return 'storniert';
                    case 'open': return 'zu bezahlen';
                    case 'unread': return 'zu bezahlen';
                    case 'pending': return 'zu bezahlen';
                    default: return '';
                }
            }},
        {title: 'Letzte Änderung', classes: [cancelCell], render: (item, helper) => {
                const mom = moment(item.modifiedAt);
                return mom.format('DD.MM.YYYY');
            }},
        {name: 'actions', render: (item, helper) => {
                return <Fragment>
                    {item.creditNote && (
                        <TableRowAction permission={'download_invoice_view'} onClick={downloadCreditNote}>
                            <Icon icon={'download'} tooltip={'Gutschrift herunterladen'}/>
                        </TableRowAction>
                    )}
                    <TableRowAction permission={'download_invoice_view'} onClick={download}>
                        <Icon icon={'download'} tooltip={'Rechnung herunterladen'} className={item.creditNote ? 'text-muted' : ''}/>
                    </TableRowAction>
                    <TableRowAction permission={'switch_payment_state_invoice_view'} onClick={switchPaymentState}>
                        <Icon icon={'paid'} tooltip={'Rechnungsstatus auf bezahlen/zu bezahlen setzen.'}/>
                    </TableRowAction>
                </Fragment>
            }},
    ];

    return <Fragment>
        <Alert type={'secondary'}>
            Hier werden alle Rechnungen angezeigt, die von Ihnen zu begleichen sind. Für die aufgeführten Pflegeeinrichtungen und Arztpraxen (kurz Prozessbeteiligten) übernehmen Sie automatisch
            die Kosten. Sie können die jeweiligen Prozessbeteiligten überzeugen die Rechnung selber zu übernehmen. Für einige Optionen ist das sogar notwendig. Wenn abgesprochen können Sie dann
            unter <Link to={'/profil#scrollable-force-tab-3'}><strong>VERTRÄGE</strong></Link> den jeweiligen Prozessbeteiligten auf <strong>Ohne Kostenübernahme</strong> stellen. Sobald
            dann die notwendigen Daten eingegeben und der Prozessbeteiligte der Kostenübernahme zugestimmt hat, verschwindet die entsprechende Position hier und wird direkt im Profil des Managers
            des Prozessbeteiligten angezeigt.
        </Alert>
        <Table
            columns={columns}
            service={tableService}
            onCollapseContent={collapse}
            searchable={false}
            onRowHighlight={doRowHighlight}
        />
    </Fragment>
}

InvoiceTab.propTypes = {};

InvoiceTab.defaultProps = {};

export default InvoiceTab;
/* jshint esversion: 8 */
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    TableContainer,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import axios from 'axios';
import React, {Fragment, useContext, useState} from 'react';
import {AiOutlineDelete} from 'react-icons/ai';
import {FiFilter, MdOutlineMarkEmailRead} from "react-icons/all";
import {BiSortAZ, BiSortZA} from 'react-icons/bi';
import {FiEdit2} from 'react-icons/fi';
import {MdAdd} from 'react-icons/md';
import {Link, useLocation} from 'react-router-dom';
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import McPagination from "../components/Pagination";
import AuthContext from '../context/auth';
import {filterThemeStyles, serialize} from "../utils/filter";
import handleErrors from '../utils/handleErrors';
import {useRespTableThemeStyles} from "../utils/respTableTheme";
import {createUriSortParameters, initSorting} from "../utils/sorting";
import Loader from "../components/Loader";
import New from "../components/new";
import {makeStyles} from "@material-ui/core/styles";
import {hasPermission} from "@utils/utils";
import TCell from "../components/TCell";

const useStyles = makeStyles({
    contractNotice: {
        color: '#5588a3',
        fontSize: 10,
        margin: '0 0 0 5px',
        display: "inline-block"
    }
});

const HomeList = ({history}) => {
	/**
	 * CSS
	 */
    const classes = {
        ...useStyles(),
        ...useRespTableThemeStyles(),
        ...filterThemeStyles()
    };

    const {auth, setAuth} = useContext(AuthContext);
    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);

    const [state, setState] = useState('loading');
    const [sort, setSort] = useState({});
    const [filter, setFilter] = useState({text: '', expertises: [], notSeen: !!query.get('filter[notSeen]')});
    const [filterShow, showFilter] = useState(!!query.get('filter[notSeen]'));
    const [loading, setLoading] = useState(true);

    const fetchData = async (page, limit) => {
        try {
            setLoading(true);
            page = page ? page : 1;
            const filterQuery = serialize({filter: filter});
            let response = await axios.get(`/api/homes?${filterQuery}&${createUriSortParameters(sort)}&limit=${limit}&offset=${(page - 1) * limit}`, {
                headers: {'x-auth-token': auth.token}
            });
            setState(response.data.data);

            setLoading(false);

            return response.data.count;
        } catch (err) {
            console.log(err);
            handleErrors(err, setAuth, history);
        }
    };

    // useEffect(() => {
    //     fetchData();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [sort]);

    /**
     * CONTROLS
     */
    const changeFilter = (e) => {
        setFilter({...filter, [e.target.name]: e.target.value});
    };

    const changeFilterCheckbox = (e) => {
        if (e.target.checked) {
            setFilter({...filter, [e.target.name]: 1});
        } else {
            setFilter({...filter, [e.target.name]: 0});
        }
    }

    /**
     * Functions
     */
    const setAllAsRead = async () => {
        try {
            await axios.put(`/api/statistics/home_read_all`, {}, {
                headers: {'x-auth-token': auth.token},
            });
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    }

    initSorting(sort, setSort, filter, setFilter);

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Typography color='textPrimary'>Pflegeeinrichtungen</Typography>
            </Breadcrumbs>
            <Tooltip title='Filter anzeigen/verbergen' className={classes.filterButton}>
                <IconButton onClick={() => showFilter(!filterShow)} size='small'>
                    <FiFilter/>
                </IconButton>
            </Tooltip>
            <Card>
                <CardContent>
                    <Typography component='h1' color='textSecondary' gutterBottom>
                        Pflegeeinrichtungen
                    </Typography>
                    <Grid container spacing={2}>
                        {filterShow && (
                            <Grid item xs={12} className='filterWidget'>
                                <Card>
                                    <CardContent>
                                        <Typography component='h1' color='textSecondary' gutterBottom>
                                            Filter
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id='text-filter'
                                                        label='Suche'
                                                        name='text'
                                                        onChange={changeFilter}
                                                        value={filter.text}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item sm={3} xs={12} className={classes.filter}>
                                                <Grid container spacing={2}>
                                                    <Grid item sm={1}>
                                                        <FormControl fullWidth>
                                                            <Checkbox
                                                                checked={filter.notSeen}
                                                                value={1}
                                                                name={'notSeen'}
                                                                onChange={changeFilterCheckbox}
                                                                color='primary'
                                                                label={'nur neue Anforderungen anzeigen'}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item sm={11}><span className={classes.filterChkLabel}>nur neue Pflegeeinrichtungen anzeigen</span></Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container justify='flex-end' spacing={2}>
                                <Grid xl={4} lg={5} md={6} sm={12} xs={12} item>
                                    {hasPermission(auth, 'home_seen_view') && (
                                    <Button
                                        className={classes.markAsRead}
                                        color='primary'
                                        endIcon={<MdOutlineMarkEmailRead/>}
                                        fullWidth
                                        onClick={setAllAsRead}
                                        type='submit'
                                        variant='contained'
                                    >
                                        alle Pflegeeinrichtungen als Gelesen markieren
                                    </Button>)}
                                </Grid>
                                <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
                                    {hasPermission(auth, 'home_create_view')  && (
                                        <Button
                                            color='primary'
                                            component={Link}
                                            endIcon={<MdAdd/>}
                                            fullWidth
                                            to='/einrichtung/neu'
                                            variant='contained'
                                        >
                                            Pflegeeinrichtung hinzufügen
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            {!loading && (<TableContainer>
                                <Table>
                                    <Thead>
                                        <Tr className={classes.tbodyTh}>
                                            {hasPermission(auth, 'home_column_name_view') && (
                                            <Th align={'left'} data-field='name' className='filterable'>
                                                Name
                                                <BiSortAZ className='sort-icon asc'/>
                                                <BiSortZA className='sort-icon desc'/>
                                            </Th>)}
                                            {hasPermission(auth, 'home_column_login_view') && (
                                            <Th align={'left'}>Mit Login?</Th>)}
                                            {hasPermission(auth, 'home_column_pay_own_view') && (
                                            <Th align={'left'}>Zahlt selbst?</Th>)}
                                            {hasPermission(auth, 'home_column_home_groups_view') && (
                                            <Th align={'left'} data-field='homeGroups'>
                                                Wohnbereiche
                                            </Th>)}
                                            {hasPermission(auth, 'home_column_actions_view') && (
                                                <Th align='right'>Aktionen</Th>
                                            )}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {state === 'loading' ? (
                                            <Tr>
                                                <Td align='center' colSpan={7}>
                                                    Lade Daten...
                                                </Td>
                                            </Tr>
                                        ) : state.length === 0 ? (
                                            <Tr>
                                                <Td align='center' colSpan={7}>
                                                    Kein Daten vorhanden.
                                                </Td>
                                            </Tr>
                                        ) : (
                                            state.map((home, i) => (
                                                <Tr key={i} className={classes.tbodyTr}>
                                                    {hasPermission(auth, 'home_column_name_view') && (
                                                        <TCell clipboard={{data: home.name, help: `Name "${home.name}" kopieren`}}>
                                                        {home.name && hasPermission(auth, 'home_edit_view') && (<Link to={`/einrichtung/${home._id}`} title='Bearbeiten'>{home.name}</Link>)}
                                                        {home.name && !hasPermission(auth, 'home_edit_view') && home.name}
                                                        {!(home.seen && home.seen.includes(auth.user)) && (<New />)}
                                                    </TCell>)}
                                                    {hasPermission(auth, 'home_column_login_view') && (
                                                    <Td title={'Mit Login'}>
                                                        {home.withLogin ? 'Ja' : 'Nein'}
                                                    </Td>)}
                                                    {hasPermission(auth, 'home_column_pay_own_view') && (
                                                    <Td>{home.selfPay ? 'Ja' : 'Nein'}</Td>)}
                                                    {hasPermission(auth, 'home_column_home_groups_view') && (
                                                        <TCell clipboard={{data: home.homeGroups.map(item => item.name), help: `Wohnbereiche kopieren`}}>
                                                            {home.homeGroups && home.homeGroups.length > 0 && home.homeGroups
                                                        .map((homeGroup) => homeGroup.name)
                                                        .reduce((prev, curr) => [prev, ", ", curr])} </TCell>)}
                                                        <Td align='right'>
                                                            {hasPermission(auth, 'home_column_actions_view') && home.editable && (<Fragment>
                                                            {hasPermission(auth, 'home_edit_view') && (
                                                            <Tooltip title='Bearbeiten'>
                                                                <IconButton color='primary' size='small' component={Link} to={`/einrichtung/${home._id}`} aria-label='bearbeiten'>
                                                                    <FiEdit2/>
                                                                </IconButton>
                                                            </Tooltip>)}
                                                            {hasPermission(auth, 'home_delete_view') && (
                                                                <Tooltip title='Löschen'>
                                                                    <IconButton color='secondary' test={`${home.name}`} size='small' component={Link} to={`/einrichtung/${home._id}/loeschen`} aria-label='löschen'>
                                                                        <AiOutlineDelete/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                            </Fragment>)}
                                                        </Td>
                                                </Tr>
                                            ))
                                        )}
                                    </Tbody>
                                </Table>
                            </TableContainer>)}
                        </Grid>
                    </Grid>
                    <McPagination fetch={fetchData} filter={filter}/>
                    {loading && (<Loader center size={'large'} color={'#5588a3'}/>)}
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default HomeList;

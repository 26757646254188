import React from 'react';
import {Box, Modal} from '@material-ui/core';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles"; //comment in if you add Proptypes to your Component

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    zIndex: 9000,
    background: '#fff',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: 16,
    borderRadius: 4,
    '&.small': {
        maxWidth: 500
    }
};

const useStyles = makeStyles({
    small: {
        maxWidth: 500
    },
    normal: {
        maxWidth: 1024
    }
});

const McModal = ({size, children, open, handleClose}) => {
    /**
     * Styles
     */
    const classes = useStyles();

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={style} className={classes[size]}>
                    {children}
                </Box>
            </Modal>
        </div>
    );
};

McModal.propTypes = {
    size: PropTypes.oneOf(['normal', 'small'])
};

McModal.defaultProps = {
    size: 'normal'
};

export default McModal;
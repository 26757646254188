import moment from 'moment';
import React, { Fragment } from 'react';
import { TiDocumentText } from 'react-icons/all';
import { Grid, makeStyles, Typography, Paper } from '@material-ui/core';
import { AiOutlineClockCircle } from 'react-icons/ai';

const useStyles = makeStyles({
    message: {
        flexGrow: 1,
    },
    container: {
        padding: 12,
    },
    messageContainer: {
        padding: 8,
        border: '1px solid #4caf50',
        background: '#e8f5e9',
    },
    messageContainerError: {
        padding: 8,
        border: '1px solid #f44336',
        background: '#ffebee',
    },
    iconColor: {
        marginRight: 8,
        color: '#4caf504',
    },
    iconColorError: {
        marginRight: 8,
        color: '#f44336',
    },
    icon: {
        marginRight: 8,
    },
    messageText: {
        paddingLeft: 12,
    },
    user: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    date: {
        fontSize: 10,
        color: '#888',
    },
    new: {
        background: '#e6ebe7',
    },
});

const FaxResponse = ({ event }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    return (
        <Fragment>
            <Grid item xs={12}>
                <Paper
                    className={`${event.data === 'SENT' ? classes.messageContainer : classes.messageContainerError}`}
                    elevation={1}
                >
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item>
                            <Typography className={classes.user}>
                                <TiDocumentText
                                    className={event.data === 'SENT' ? classes.iconColor : classes.iconColorError}
                                />
                                {event.createdFrom.username}
                            </Typography>
                            <Typography className={classes.date}>
                                <AiOutlineClockCircle className={classes.icon} />
                                {moment(event.createdAt).format('DD.MM.YYYY HH:mm')}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.message}>
                            <Typography className={classes.messageText}>
                                {event.data === 'SENT' ? 'Fax wurde versendet' : 'Fehler beim senden des Faxes'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Fragment>
    );
};

export default FaxResponse;

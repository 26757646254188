/* jshint esversion: 8 */
import {Fragment, useEffect, useRef, useState} from "react";
import {rand} from "@utils/utils";
import Observer from "@utils/observer";
import Select from "@components/Select";
import Icon from "@components/Icon";
import Checkbox from "@components/Checkbox";
import TableRowGroup from "@components/Table/TableRowGroup";

const TableData = ({tableRowData, collapseLabel, resolution, table, offset, fullTable, service, observer, searchObserver, rowCheckAble, rowCollapseAble, onCollapseContent, columns, onRowHighlight, filterElements, pagination, header}) => {

    /**
     * State
     */
    const [data, setData] = useState([]);
    const [refreshing, setRefreshing] = useState(true);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(pagination ? 25 : 99999);
    const [filter, setFilter] = useState(null);
    const [count, setCount] = useState(0);
    const [collapsedRows] = useState([]);
    const [selectionObserver] = useState(new Observer((args) => {
        if(observer) {
            observer.notify(args);
        }
    }));

    let filterSetDelay = null;

    /**
     * References
     */
    const collapsedRowsReference = useRef();
    collapsedRowsReference.current = collapsedRows;

    const filterReference = useRef();
    filterReference.current = filter;

    /**
     * Hooks
     */
    useEffect(() => {
        if (filter === null) {
            const flt = {};
            for (let filterRow in filterElements) {
                for (let ii = 0; ii < filterElements[filterRow].length; ii++) {
                    const item = filterElements[filterRow][ii];
                    if (item.type !== 'hidden' && item.value !== undefined) {
                        flt[item.name] = item.value;
                    }
                }
            }

            setFilter(flt);
        }
    }, [filterElements]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetch();
    }, [filter, page, limit]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (searchObserver) {
            searchObserver.register({
                update: async (data) => {
                    if(filterSetDelay) {
                        clearTimeout(filterSetDelay);
                        filterSetDelay = null;
                    }

                    filterSetDelay = setTimeout(async function() { //eslint-disable-line  react-hooks/exhaustive-deps
                        setFilter({...filterReference.current, ...data});
                    }, 250)
                },
                key: 'table'
            });
        }
    }, [searchObserver, filterSetDelay]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(observer) {
            observer.register({
                collect: () => {
                    return selectionObserver.collect();
                },
                key: 'table-selection'
            });
        }
    }, [observer]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const updateCollapsedRows = (id, isRemoving) => {
        const index = collapsedRowsReference.current.indexOf(id);
        if(isRemoving && index !== -1) {
            collapsedRowsReference.current.splice(index, 1);
        } else if(index === -1) {
            collapsedRowsReference.current.push(id);
        }
    };

    const buildHiddenFilter = () => {
        let hidden = {};

        if (filterElements) {
            for (let filterRow in filterElements) {
                for (let ii = 0; ii < filterElements[filterRow].length; ii++) {
                    const item = filterElements[filterRow][ii];

                    if (item.type === 'hidden') {
                        hidden[item.name] = item.value;
                    }
                }
            }
        }

        return hidden;
    };

    const buildFilter = () => {
        let result = {};

        for(let name in filter) {
            const item = filter[name];
            if((typeof item === 'string' || item instanceof Array)) {
                if(item.length > 0) {
                    result[name] = item;
                }
            } else if(item === true) {
                result[name] = 1;
            } else {
                result[name] = item;
            }
        }

        return result;
    };

    const fetch = async () => {
        if (filter) {
            const additionalData = tableRowData ? tableRowData : [];
            const hidden = buildHiddenFilter();
            const filter = buildFilter();
            const response = service ? (await service.get(null, {...filter, ...hidden}, page ? page : 0, limit)) : {items: [], max: 0};
            setData(response.items.concat(additionalData));
            setRefreshing(false);
            setCount(response.max + additionalData.length);
            filterSetDelay = null;
        }
    };

    const createPagination = (itemCount) => {
        let list = [];
        let pages = Math.ceil(itemCount / limit);
        let displayRange = 3;

        if (pages < 2) {
            return list;
        }

        for (let ii = 0; ii < pages; ii++) {
            list.push({
                page: ii + 1,
                current: ii === page,
                click: true
            });
        }

        let range = displayRange - page;
        let from = page - displayRange < 0 ? 0 : page - displayRange;
        let to = page + displayRange + (range > 1 ? range - 1 : 0);

        list = list.slice(from, to + 1);

        if (list[0] && list[0].page > 2) {
            list = [{
                page: '...',
                current: false,
                click: false
            }].concat(list);
        }

        if (list[0] && list[0].page !== 1) {
            list = [{
                page: 1,
                current: false,
                click: true
            }].concat(list);
        }

        const length = list.length - 1;
        if (list[length] && list[length].page < pages - 1) {
            list = list.concat([{
                page: '...',
                current: false,
                click: false
            }]);
        }

        if (list[length] && list[length].page !== pages) {
            list = list.concat([{
                page: pages,
                current: false,
                click: true
            }]);
        }

        return list;
    };

    const updatePage = (page) => {
        setPage(page - 1);
    };

    const checkAll = (e) => {
        selectionObserver.pushUpdate(e.target.checked);
    };

    const renderHeader = (column, index) => {
        const options = column.options ? column.options : {};
        const exist = filter && filter.sort && column.name in filter.sort;
        const order = exist && filter ? filter.sort[column.name] === 1 : false;
        const style = column.width ? {width: column.width} : {};

        return (<th key={rand()} style={style} className={`table-header-cell cell-align-${column.align ? column.align : 'left'}`} {...options}>
            {column.title && (<Fragment>
                {index === 0 && !!rowCheckAble && (<Checkbox className={'cell-checkbox'} onChange={checkAll} value={''} label={''} name={''} />)}
                {column.title}
                {column.name && order && (<Icon tooltip={'Alphabetisch aufsteigend sortiert'} className={'column-sort'} icon={'sort_asc'} onClick={() => {
                    addSort(column.name, -1);
                }}/>)}
                {column.name && !order && exist && (<Icon tooltip={'Alphabetisch absteigend sortiert'} className={'column-sort'} icon={'sort_desc'} onClick={() => {
                    addSort(column.name, 1);
                }}/>)}
                {column.name && !order && !exist && (<Icon tooltip={'Alphabetisch aufsteigend sortieren'} className={'hover-visible column-sort'} icon={'sort_asc'} onClick={() => {
                    addSort(column.name, 1);
                }}/>)}
            </Fragment>)}
        </th>);
    };

    const addSort = (field, order) => {
        let update = {...filter};
        update.sort = {};
        update.sort[field] = order;

        setFilter(update);
    }

    /**
     * References
     */
    if(table) {
        table.refresh = (collapse) => {
            collapse = collapse instanceof Array ? collapse : [collapse];
            collapse.forEach((item) => updateCollapsedRows(item));
            fetch();
        };
    }

    if(service) {
        service.tableRefresh = (collapse) => {
            collapse = collapse instanceof Array ? collapse : [collapse];
            collapse.forEach((item) => updateCollapsedRows(item));
            fetch();
        };
    }

    if(!fullTable) {
        return !!data && data.map((item, index) => (
                <TableRowGroup
                    updateCollapse={updateCollapsedRows}
                    collapsedRows={collapsedRowsReference.current}
                    offset={offset}
                    service={service}
                    filter={searchObserver.collect(true)}
                    id={index}
                    key={`row-${rand()}-${rand()}`}
                    onRowHighlight={onRowHighlight}
                    row={item}
                    observer={selectionObserver}
                    columns={columns}
                    onCollapseContent={onCollapseContent}
                    rowCheckAble={rowCheckAble}
                    rowCollapseAble={rowCollapseAble}/>
            ))
    }

    return <Fragment>
        <table className={'table table-hover'}>
            {resolution > 425 && header && (
            <thead>
            <tr key={rand()}>
                {columns && columns.filter(item => item.display === undefined || !!item.display).map((item, index) => {
                    return renderHeader(item, index)
                })}
            </tr>
            </thead>)}
            <tbody>
            {!!data && data.map((item, index) => (
                <TableRowGroup
                    collapseLabel={collapseLabel}
                    resolution={resolution}
                    updateCollapse={updateCollapsedRows}
                    collapsedRows={collapsedRowsReference.current}
                    service={service}
                    filter={searchObserver.collect(true)}
                    id={index}
                    key={`row-${rand()}-${rand()}`}
                    onRowHighlight={onRowHighlight}
                    row={item}
                    observer={selectionObserver}
                    columns={columns}
                    onCollapseContent={onCollapseContent}
                    rowCheckAble={rowCheckAble}
                    rowCollapseAble={rowCollapseAble}/>
            ))}
            </tbody>
        </table>
        {refreshing && (<div className={'table-loading-container'}>
            <Icon icon={'spinner'} className={'table-spinner'}/>
        </div>)}
        {!refreshing && (<Fragment>
            {filter && filter.length > 0 && data.length === 0 && (
                <div className={'table-empty alert alert-warning'}>Keine Suchergebnisse</div>)}
            {(!data || (data && data.length === 0)) && (
                <div className={'table-empty alert alert-warning'}>Keine Daten</div>
            )}
        </Fragment>)}

        {pagination && data && data.length > 0 && (<Fragment>
            <Select
                className={'pagination-limit'}
                name={'limit'}
                defaultValue={limit}
                options={[10, 25, 50, 75, 100, 250, 500]}
                onChange={(item) => {
                    setPage(0);
                    setLimit(item.target.value);
                }}
            />
            <nav aria-label="Page navigation example">
                <ul className={'table-pagination pagination pagination-sm'} key={rand()}>
                    {count && createPagination(count).map((item) => (
                        <li
                            onClick={() => {
                                if (item.click) {
                                    updatePage(item.page)
                                }
                            }}
                            className={`${!item.click ? 'table-pagination-item-more-icon' : ''} page-item ${item.current ? 'active' : ''}`}
                            key={rand()}
                        >
                            <span className={'page-link'}>{item.page}</span>
                        </li>))}
                </ul>
            </nav>
            <span
                className={'page-item-range'}>{(page * limit) + 1} - {(page + 1) * limit > count ? count : (page + 1) * limit} von {count}</span>
        </Fragment>)}
    </Fragment>
}

TableData.defaultProps = {
    table: {},
    resolution: 2048
}

export default TableData;
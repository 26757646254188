import request from "@utils/request";
import {toast} from "react-toastify";

const factory = {
    limit: 10,
    path: '',
    addV3: function() {
        return this.v3 ? 'v3/' : '';
    },
    fields: [],
    get: async function(id, filter, page, limit, subPath) {
        if(this.path.length > 0) {
            id = id ? `/${id}` : '';
            subPath = subPath ? subPath : '';

            let connectWith = '?';
            let splice = '';

            if(page !== undefined) {
                limit = limit === -1 ? 999999 : limit;
                limit = limit ? limit : this.limit;
                splice = `?offset=${page*limit}&limit=${limit}`;
                connectWith = '&';
            }

            const response = await request.get(`/api/${this.addV3()}${this.path}${subPath}${id}${splice}${this.toFilterQuery(filter, connectWith)}`, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(id) {
                return response.data;
            }

            return response.data;
        }

        throw new Error("Path is Missing");
    },
    delete: async function(id) {
        await request.delete(`/api/${this.addV3()}${this.path}/${id}`, {
            headers: {'x-auth-token': this.auth.token}
        });
    },
    create: async function(data, message) {
        const response = await request.post(`/api/${this.addV3()}${this.path}`, data, {
            headers: {'x-auth-token': this.auth.token}
        });

        if(response.data.success) {
            if(message) {
                toast.success(message);
            } else if(this.message && this.message['create']){
                toast.success(this.message['create']);
            }
        }

        if(response.data.item) {
            return response.data.item;
        }

        return response.data;
    },
    put: async function(id, data, message) {
        return await this.update(id, data, message);
    },
    patch: async function(data, message) {
        if(this.path.length > 0) {
            const response = await request.put(`/api/${this.addV3()}${this.path}`, data, {
                headers: {'x-auth-token': this.auth.token}
            });

            if (response.data.success) {
                if(message) {
                    toast.success(message);
                } else if(this.message && this.message['update']){
                    toast.success(this.message['update']);
                }
            }

            return;
        }

        throw new Error("Path is Missing");
    },
    update: async function (id, data, message) {
        try {
            if(this.path.length > 0) {
                const response = await request.put(`/api/${this.addV3()}${this.path}/${id}`, this.buildRequestData(data), {
                    headers: {'x-auth-token': this.auth.token}
                });

                if (response.data.success) {
                    if(message !== null) {
                        if(message) {
                            toast.success(message);
                        } else if(this.message && this.message['update']){
                            toast.success(this.message['update']);
                        }
                    }
                }

                return true;
            }
        } catch(e) {
            console.log(e);

            return false;
        }

        throw new Error("Path is Missing");
    },
    toFilterQuery: function(data, connectWith) {
        if(data === undefined) {
            return '';
        }
        data = {filter: data};
        let out = this.buildQuery(data);

        return out && out.length > 0 ? `${connectWith ? connectWith : '?'}${out.join('&')}` : '';
    },
    buildQuery: function(data, parent) {
        let out = [];

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if(!(data[key] instanceof Object)) {
                    const par = parent ? `${parent}[${key}]` : key;
                    out.push(par + '=' + encodeURIComponent(data[key]));
                } else {
                    const par = parent ? `${parent}[${key}]` : key;
                    const result = this.buildQuery(data[key], par);

                    if(result) {
                        out = out.concat(result);
                    }
                }
            }
        }

        if(out.length > 0)
        return out.length > 0 ? out : null;
    },
    buildRequestData: function(raw) {
        //remove data meta data
        if(this.fields.length === 0) {
            delete raw.createdFrom;
            delete raw.modifiedFrom;
            delete raw.createdAt;
            delete raw.modifiedAt;
            return raw;
        }

        let data = {};

        for(let ii = 0; ii < this.fields.length; ii++) {
            const field = this.fields[ii];
            data[field] = raw[field];
        }

        return data;
    }
}

export default factory;
import moment from 'moment';
import React, { useContext, Fragment } from 'react';
import { AiOutlineMessage, AiOutlineFilePdf, AiOutlineFileImage, AiOutlineFileUnknown } from 'react-icons/ai';
import AuthContext from '../context/auth';
import {
    Grid,
    makeStyles,
    Typography,
    Paper,
} from '@material-ui/core';
import { AiOutlineClockCircle } from 'react-icons/ai';
import axios from "axios";
import fileDownload from "js-file-download";
import Icon from "../_v3/components/Icon";
import {getScreenResolution} from "@utils/utils";

const useStyles = makeStyles({
    message: {
        flexGrow: 1,
    },
    container: {
        padding: 12,
    },
    messageContainer: {
        padding: 8,
    },
    icon: {
        marginRight: 8,
    },
    messageText: {
        paddingLeft: 12,
        paddingTop: 8,
    },
    user: {
        fontSize: 14,
        fontWeight: 'bold',
        position: 'relative'
    },
    date: {
        fontSize: 10,
        color: '#888',
    },
    new: {
        background: '#e6ebe7',
    },
    eventAttachment: {
        fontSize: 24,
        cursor: 'pointer',
        height: 40,
        width: 40,
        borderRadius: '50%',
        display: 'inline-block',
        textAlign: 'center',
        padding: 6,
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
        }
    },
    messageIcon: {
        display: "inline-block",
        margin: "-3px 0 0 5px",
        color: "#2a5d78",
        lineHeight: "12px",
        fontSize: 18,
        position: 'relative',
        top: 2

    }
});

const Message = ({ event }) => {
    const classes = useStyles();
    const { auth } = useContext(AuthContext);

    const downloadAttachment = (e) => {
        const name = e.target.hasAttribute('data-name') ? e.target.getAttribute('data-name') : e.target.parentNode.getAttribute('data-name');

        axios.get(`/api/tasks/${event.task._id}/events/${event._id}/attachment/${name}`, {
            responseType: 'blob',
            headers: {'x-auth-token': auth.token}
        })
            .then((res) => {
                fileDownload(res.data, name)
            });

        return false;
    };

    const icon = (role) => {
        if(role.includes('ROLE_HOME')) {
            return <Icon icon={'medical_home'} tooltip={{content: 'Mitarbeiter einer Pflegeeinrichtung', classes: classes.messageIcon}}/>
        } else if(role.includes('ROLE_DOCTOR')) {
            return <Icon icon={'doctor'} tooltip={{content: 'Mitarbeiter einer Arztpraxis', classes: classes.messageIcon}}/>
        } else if(role.includes('ROLE_PHARMA')) {
            return <Icon icon={'medicine'} tooltip={{content: 'Mitarbeiter einer Apotheke', classes: classes.messageIcon}}/>
        } else if(role.includes('ROLE_DRIVER')) {
            return <Icon icon={'truck'} tooltip={{content: 'Fahrer', classes: classes.messageIcon}}/>
        }

        return <Fragment />;
    }

    return (
        <Fragment>
            {getScreenResolution() > 768 && event.createdFrom && event.createdFrom._id === auth.user && (
                <Grid item xs={4}>
                    &nbsp;
                </Grid>
            )}
            <Grid item xs={getScreenResolution() > 768 ? 8 : 12}>
                <Paper className={`${event.new && classes.new} ${classes.messageContainer}`} elevation={1}>
                    <Typography className={classes.user}>
                        <AiOutlineMessage className={classes.icon} />
                        {event.createdFrom && event.createdFrom.username}
                        {event.createdFrom && icon(event.createdFrom.roles)}
                    </Typography>
                    <Typography className={classes.date}>
                        <AiOutlineClockCircle className={classes.icon} />
                        {moment(event.createdAt).format('DD.MM.YYYY HH:mm')}
                    </Typography>
                    <Typography className={classes.messageText}>{event.data}</Typography>
                    <div>
                        {event.files.map((file) => (
                            <label title={file} className={classes.eventAttachment} onClick={downloadAttachment} data-name={file}>
                                {file.indexOf('.pdf') !== -1 && (<AiOutlineFilePdf />)}
                                {(file.indexOf('.jpg') !== -1 || file.indexOf('.jpeg') !== -1 || file.indexOf('.png') !== -1) && (<AiOutlineFileImage />)}
                                {file.indexOf('.pdf') === -1 && file.indexOf('.jpg') === -1 && file.indexOf('.jpeg') === -1 && file.indexOf('.png') === -1 && (<AiOutlineFileUnknown />)}
                            </label>
                        ))}
                    </div>
                </Paper>
            </Grid>
            {event.createdFrom && event.createdFrom._id !== auth.user && <Grid item xs={4}></Grid>}
        </Fragment>
    );
};

export default Message;

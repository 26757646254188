import React from 'react';

const Col = ({ sm, md, lg, xl, xxl, osm, omd, olg, oxl, oxxl, children, style, className, args}) => {
    return (
        <div
            style={style ? style : {}}
            className={`${sm ? `col-sm-${sm}` : ''} ${md ? `col-md-${md}` : ''} ${lg ? `col-lg-${lg}` : ''} ${
                xl ? `col-xl-${xl}` : ''
            } ${xxl ? `col-xxl-${xxl}` : ''} ${osm ? `offset-sm-${osm}` : ''} ${omd ? `offset-md-${omd}` : ''} ${olg ? `offset-lg-${olg}` : ''} ${
                oxl ? `offset-xl-${oxl}` : ''
            } ${oxxl ? `offset-xxl-${oxxl}` : ''} ${className ? className : ''}`}
        >
            {children}
        </div>
    );
};

export default Col;

/* jshint esversion: 8 */
import React, {Fragment, useContext} from "react";
import AuthContext from "@context/AuthContext";
import medicineServiceFactory from "@factories/medicineFactoryService";
import Content from "@components/Content";
import Row from "@components/Row";
import Col from "@components/Col";
import Card from "@components/Card";
import Table from "@components/Table/Table";
import Icon from "@components/Icon";
import TableRowAction from "@components/Table/TableRowAction";
import {hasPermission} from "@utils/utils";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const MedicineList = ({...args}) => {

    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * Services
     */
    const medicineService = medicineServiceFactory({auth: auth});

    /**
     * Columns
     */
    const columns = [
        {name: 'name', display: hasPermission(auth, 'medicine_column_name_view'), title: 'Name'},
        {name: 'actions', display: hasPermission(auth, 'medicine_column_actions_view'), title: 'Aktionen', align: 'right', render: (item) => {
            return (<Fragment>
                {hasPermission(auth, 'medicine_activate_view') && item.disabled && (
                    <TableRowAction data={{disabled: false}} action={'update'} message={'Medizin wurde aktiviert.'}>
                        <Icon icon={'revert'} tooltip={'Medikament aktivieren'}/>
                    </TableRowAction>
                )}
                {hasPermission(auth, 'medicine_disable_view') && !item.disabled && (
                    <TableRowAction data={{disabled: true}} action={'update'} message={'Medizin wurde deaktiviert.'}>
                        <Icon icon={'trash'} tooltip={'Medikament deaktivieren'}/>
                    </TableRowAction>
                )}
            </Fragment>)
            }
        }
    ];


    return <Content>
        <Row sm={12}>
            <Col sm={12}>
                <Card title={'Medikamente'}>
                    <Table
                        columns={columns}
                        service={medicineService}
                        filterElements={[[{name: 'disabled', placeholder: 'Deaktiviert?', type: 'select', data: [{value: 1, label: 'Ja'}, {value: 0, label: 'Nein'}], value: 0}]]}
                    />
                </Card>
            </Col>
        </Row>
    </Content>;
}

MedicineList.propTypes = {};

MedicineList.defaultProps = {};

export default MedicineList;
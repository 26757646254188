/* jshint esversion: 8 */
import DateFnsUtils from '@date-io/moment';
import {
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    TextField,
    Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/de';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ImCancelCircle } from 'react-icons/im';
import { MdSend } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';

moment.locale('de');

const TaskForm = ({ history, match: { params } }) => {
    /**
     * STATES
     */
    const { auth, setAuth } = useContext(AuthContext);
    const [state, setState] = useState({
        type: '1',
        latestDelivery: null,
        medicine: '',
        dose: '',
    });
    const [patient, setPatient] = useState({});
    const [doctor, setDoctor] = useState({});
    const [doctors, setDoctors] = useState([]);
    const [medicine, setMedicine] = useState([]);
    const [dosage, setDosage] = useState([]);

    const taskId = params.taskId ? params.taskId : params.copyTaskId
    const copy = params.taskId === undefined;

    /**
     * QUERY
     */
    const fetchPatient = async () => {
        try {
            let response = await axios.get(`/api/patients/${params.patientId}?limit=99999`, {
                headers: { 'x-auth-token': auth.token },
            });
            setPatient(response.data);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchDoctors = async () => {
        try {
            const response = await axios.get(`/api/doctors?limit=99999`, {
                headers: { 'x-auth-token': auth.token },
            });
            console.log(response);
            setDoctors(response.data.data);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchMedicine = async () => {
        try {
            const response = await axios.get(`/api/medicine?filter[disabled]=0&limit=99999`, {
                headers: { 'x-auth-token': auth.token },
            });
            setMedicine(response.data.items);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchDosage = async () => {
        try {
            const response = await axios.get(`/api/tasks/dosage?limit=99999`, {
                headers: { 'x-auth-token': auth.token },
            });
            setDosage(response.data);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchDoctor = async (doctorId) => {
        try {
            if(doctorId) {
                const response = await axios.get(`/api/doctors/${doctorId}`, {
                    headers: { 'x-auth-token': auth.token },
                });
                setDoctor(response.data);
            }
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchTask = async () => {
        try {
            let response = await axios.get(`/api/tasks/${taskId}`, {
                headers: { 'x-auth-token': auth.token },
            });
            setState({
                patient: response.data.patient,
                medicine: response.data.medicine,
                dose: response.data.dose,
                type: String(response.data.type),
                doctor: response.data.doctor,
                status: 'new',
                latestDelivery: null,
            });
            fetchDoctor(response.data.doctor);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        if (params.patientId) {
            fetchPatient();
        }
        fetchDoctors();
        fetchMedicine();
        fetchDosage();
        if (taskId) {
            fetchTask();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * CONTROLS
     */
    const change = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setState({ ...state, [e.target.name]: value });
    };

    const changeMedicine = (e, v) => {
        setState({ ...state, medicine: v });

        return true;
    };

    const changeDosage = (e, v) => {
        setState({ ...state, dose: v });

        return true;
    };

    const changeDoctor = (e, v) => {
        setDoctor(v);
    };

    const dateSelect = (e) => {
        setState({ ...state, latestDelivery: e });
    };

    /**
     * SUBMIT
     */
    const submit = async (e, redirect) => {
        e.preventDefault();
        let data = {
            ...state,
            patient: patient._id,
        };

        data.latestDelivery = data.latestDelivery != null ? data.latestDelivery.format('DD.MM.YYYY') : null;
        data.doctor = doctor ? doctor._id : null;
        try {
            if (!copy && taskId) {
                await axios.put(`/api/tasks/${taskId}`, data, { headers: { 'x-auth-token': auth.token } });
                await axios.post('/api/medicine', {name: state.medicine}, { headers: { 'x-auth-token': auth.token } });
                toast.success('Auftrag geändert');
            } else {
                await axios.post('/api/tasks', data, { headers: { 'x-auth-token': auth.token } });

                await axios.post('/api/medicine', {name: state.medicine}, { headers: { 'x-auth-token': auth.token } });

                toast.success('Auftrag angelegt');
            }

            history.push(redirect);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }

        return false;
    };

    const escape = (str) => {
        const items = ['(', ')', '[', '[', '*', '+'];
        let strChars =  str.split('');
        for (let ii = 0; ii < strChars.length; ii++) {
            const char = strChars[ii];
            if(items.indexOf(char) !== -1) {
                strChars[ii] = `\\${char}`;
            }
        }

        return strChars.join('');
    }

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Link to='/patienten'>Patienten</Link>
                <Link to='/anforderungen'>Aufträge</Link>
                <Typography color='textPrimary'>Neuer Auftrag</Typography>
            </Breadcrumbs>
            <form>
                <Card>
                    <CardContent>
                        <Typography component='h1' color='textSecondary' gutterBottom>
                            {patient != null ? `${patient.firstname} ${patient.lastname}` : ''}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        filterOptions={(option) => {
                                            let list = [];
                                            let reg = new RegExp(`${escape(state.medicine)}.*`, 'i');

                                            for (let items of option) {
                                                if (items && items.name.match(reg) !== null) {
                                                    list.push(items.name);
                                                }
                                            }

                                            return list;
                                        }}
                                        freeSolo
                                        id='medicine'
                                        name='medicine'
                                        noOptionsText={'Lade Daten...'}
                                        onChange={changeMedicine}
                                        options={medicine}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={(e) => changeMedicine(e, e.target.value)}
                                                label='Wirkstoff/Medikament/Pflegemittel'
                                                required
                                            />
                                        )}
                                        value={state.medicine}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        filterOptions={(option) => {
                                            let list = [];
                                            let reg = new RegExp(`${escape(state.dose)}.*`, 'i');

                                            for (let items of option) {
                                                if (items && items.match(reg) !== null) {
                                                    list.push(items);
                                                }
                                            }

                                            return list;
                                        }}
                                        freeSolo
                                        id='dose'
                                        name='dose'
                                        noOptionsText={'Lade Daten...'}
                                        onChange={changeDosage}
                                        options={dosage}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={(e) => changeDosage(e, e.target.value)}
                                                label='Dosierung/Darreichungsform'
                                                required
                                            />
                                        )}
                                        value={state.dose}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            disablePast
                                            test='deliveryDate'
                                            format='DD.MM.YYYY'
                                            label='Spätester Liefertermin'
                                            onChange={dateSelect}
                                            value={state.latestDelivery}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        label='Rezept anfordern'
                                        control={
                                            <Radio
                                                checked={state.type === '1'}
                                                name='type'
                                                onChange={(e) => change(e)}
                                                value='1'
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        label='Selbstkauf'
                                        control={
                                            <Radio
                                                checked={state.type === '2'}
                                                name='type'
                                                onChange={(e) => change(e)}
                                                value='2'
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        label='Rezept in Wohnbereich'
                                        control={
                                            <Radio
                                                checked={state.type === '3'}
                                                name='type'
                                                disabled={!patient}
                                                onChange={(e) => change(e)}
                                                value='3'
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        label='Rezept in Arztpraxis'
                                        control={
                                            <Radio
                                                checked={state.type === '4'}
                                                name='type'
                                                onChange={(e) => change(e)}
                                                value='4'
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            {(state.type === '1' || state.type === '4') && (
                                <Grid item xs={12}>
                                    <Autocomplete
                                        getOptionLabel={(option) =>
                                            `${option && (option.firstname || '')} ${
                                                option && (option.lastname || '')
                                            } ${
                                                option && ((option.expertise && ' (' + option.expertise + ')') || '')
                                            }`.trim()
                                        }
                                        id='doctor'
                                        name='doctor'
                                        noOptionsText={'Lade Daten...'}
                                        onChange={(e, v) => changeDoctor(e, v)}
                                        options={doctors}
                                        renderInput={(params) => <TextField {...params} label='Arzt' required />}
                                        value={doctor}
                                    />
                                </Grid>
                            )}
                            {state.type === '3' && (
                                <Fragment>
                                    {!patient.home ? (
                                        <Typography color='secondary'>
                                            Weisen Sie dem Patienten erst einen Wohnbereich zu.
                                        </Typography>
                                    ) : (
                                        <Grid item xs={12}>
                                            {patient.home.name}
                                            {patient.homeGroup && ` / ${patient.homeGroup.name}`}
                                        </Grid>
                                    )}
                                </Fragment>
                            )}
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container justify='space-between' spacing={2}>
                            <Grid item xs={12} sm={3} md={2} xl={1}>
                                <Button
                                    color='primary'
                                    component={Link}
                                    fullWidth
                                    startIcon={<ImCancelCircle />}
                                    to={`/anforderungen`}
                                    variant='outlined'
                                >
                                    Abbrechen
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} xl={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} md={4} xl={4}>
                                        <Button
                                            color='primary'
                                            endIcon={<MdSend />}
                                            fullWidth
                                            type='submit'
                                            variant='contained'
                                            onClick={(e) => submit(e, '/anforderungen')}
                                        >
                                            Speichern
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} xl={8}>
                                        <Button
                                            color='primary'
                                            endIcon={<MdSend />}
                                            fullWidth
                                            type='submit'
                                            variant='contained'
                                            onClick={(e) => submit(e, `/anforderung/patient/${patient._id}`)}
                                        >
                                            Speichern & Neuen Auftrag
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </form>
        </Fragment>
    );
};

export default TaskForm;

const routeServiceFactory = () => {
    const path = '/v3'
    const addId = (id, idName) => {
        idName = idName ? idName : 'id'
        return id ? id : `:${idName}`;
    }

    return {
        login: function() { return `${path}/login`; },
        logout: function() { return '/logout'; },
        profile: function() { return '/profil' },
        dashboard: function () { return '/'; },
        homeCreate: function() { return '/pflegeeinrichtung/neu'; },
        homeList: function() { return '/pflegeeinrichtungen'; },
        homeEdit: function(id) { return `/pflegeeinrichtungen/bearbeiten/${addId(id)}`; },
        doctorCreate: function() { return '/arzt/neu'; },
        doctorList: function() { return '/aerzte'; },
        doctorEdit: function (id) { return `/arzt/${addId(id)}` },
        doctorOfficeCreate: function() { return `${path}/arztpraxis/neu`; },
        doctorOfficeList: function() { return `${path}/arztpraxis`; },
        doctorOfficeEdit: function(id) { return `${path}/arztpraxis/bearbeiten/${addId(id)}`; },
        doctorOfficeView: function(id) { return `${path}/arztpraxis/${addId(id)}`; },
        services: function() { return '/dienste'; },
        userCreate: function() { return `${path}/nutzer/neu` },
        userEdit: function(id) { return `${path}/nutzer/bearbeiten/${addId(id)}` },
        userView: function(id) { return `${path}/nutzer/details/${addId(id)}` },
        userList: function() { return `${path}/nutzer`; },
        taskList: function() { return '/anforderungen' },
        taskEdit: function(id) { return `${path}/anforderungen/bearbeiten/${addId(id, 'editId')}` },
        taskCopy: function(id) { return `${path}/anforderung/neu/${addId(id, 'taskId')}`; },
        taskCreate: function() { return `${path}/anforderung/neu`; },
        taskImport: function() { return '/anforderungen/import' },
        pickUpList: function() { return `${path}/abholauftraege`; },
        patientList: function() { return `/patienten` },
        medicineList: function() { return `${path}/medikamente`; },
        settings: function() { return `/einstellungen`; },
        meetingList: function() { return `${path}/termine`; },
        meetingEdit: function(id) { return `${path}/termine/${addId(id)}`; },
        acl: function() { return `${path}/acl`; }
    }
}

export default routeServiceFactory;
import React, {Fragment} from "react";
import Col from "@components/Col";
import Row from "@components/Row";
import PropTypes from "prop-types";
import {salutation} from "@utils/utils"; //comment in if you add Proptypes to your Component
import {formData} from "@utils/forms";


const Doctor = ({entity, ...args}) => {
    return <Fragment>
        <Row>
            <Col sm={3} omd={3}><h5 className={'text-uppercase mt-1 mb-4'} style={{padding: 0, marginLeft: 0}}>Arzt</h5></Col>
        </Row>
        <Row>
            <Col sm={3} omd={3}><strong>Anrede</strong></Col>
            <Col sm={9} md={3}>{formData(salutation(entity.salutation))}</Col>
        </Row>
        <Row className={'mt-3'}>
            <Col sm={3} omd={3}><strong>Title</strong></Col>
            <Col sm={9} md={3}>{formData(entity.title)}</Col>
        </Row>
        <Row className={'mt-3'}>
            <Col sm={3} omd={3}><strong>Vorname</strong></Col>
            <Col sm={9} md={3}>{formData(entity.firstname)}</Col>
        </Row>
        <Row className={'mt-3'}>
            <Col sm={3} omd={3}><strong>Nachname</strong></Col>
            <Col sm={9} md={3}>{formData(entity.lastname)}</Col>
        </Row>
        <Row className={'mt-3'}>
            <Col sm={3} omd={3}><strong>Fachbereich</strong></Col>
            <Col sm={9} md={3}>{formData(entity.department)}</Col>
        </Row>
    </Fragment>
}

Doctor.propTypes = {
    entity: PropTypes.any.isRequired
};

Doctor.defaultProps = {};

export default Doctor;
import {makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import BackupTab from "../forms/profile/BackupTab";
const moment = require('moment');

const useStyles = makeStyles({
    clickable:{
        cursor: 'pointer'
    },
    unread: {
        fontWeight: 'bold',
        '&::before': {
            content: '"* "'
        }
    }
});

const List = ({collection, title, defaultState, eventClick}) => {

    /**
     * CSS
     */
    const classes = useStyles();

    const click = (e) => {
          if(eventClick) {
              eventClick({name: e.target.getAttribute('data-name')});
          }
    };

    const trStatus = (status) => {
        switch (status) {
            case 'new': return 'neu';
            case 'downloaded': return 'bereits heruntergeladen'
            case 'accepted': return 'akzeptiert';
            case 'reject': return 'abgelehnt';
            default: return 'offen';
        }
    };

    return (
        <TableContainer>
            <Table aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell>{title}</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>letzte Änderung</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {collection.map((file) => (
                    <TableRow>
                        <TableCell><span className={`${eventClick ? classes.clickable : ''} ${file.status === defaultState ? classes.unread : ''}`} onClick={click} data-name={file.original}>{file.name.replace(/{.*?}_/, '')}</span></TableCell>
                        <TableCell>{trStatus(file.status)}</TableCell>
                        <TableCell>{file.stats ? moment(file.stats.mtime).format('DD.MM.YYYY') : ''}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

BackupTab.defaultProps = {
    title: 'Dokument',
    defaultState: 'unread'
};

export default List;
import React, {Fragment, useContext, useEffect, useState} from 'react';
import SideBarTitle from '@components/SideBarTitle';
import SideBarToggler from '@components/SideBarToggler';
import SideBarMenu from '@components/SideBarMenu';
import SideBarCategory from '@components/SideBarCategory';
import SideBarLink from '@components/SideBarLink';
import SideBarHeader from '@components/SideBarHeader';
import SideBarWrapper from '@components/SideBarWrapper';
import AuthContext from '@context/AuthContext';
import {hasPermission} from "@utils/utils";
import routeServiceFactory from "@factories/routeServiceFactory";
import Icon from "@components/Icon";
import {Link} from "react-router-dom";
import axios from "axios";

const SideBar = () => {
    /**
     * Context
     */
    const { auth } = useContext(AuthContext);

    /**
     * States
     */
    const [state, setState] = useState({ logo: '', company: '' });

    /**
     * Services
     */
    const routeService = routeServiceFactory();

    /**
     * Hooks
     */
    useEffect(() => {
        fetchData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // $(function () {
        //     const body = $('body');
        //     $('.sidebar .sidebar-body')
        //         .on('mouseenter', function () {
        //             if (body.hasClass('sidebar-folded')) {
        //                 body.addClass('open-sidebar-folded');
        //             }
        //         })
        //         .on('mouseleave', function () {
        //             if (body.hasClass('sidebar-folded')) {
        //                 body.removeClass('open-sidebar-folded');
        //             }
        //         });
        //
        //     $(document).on('click touchstart', function (e) {
        //         e.stopPropagation();
        //
        //         if (!$(e.target).closest('.sidebar-toggler').length) {
        //             var sidebar = $(e.target).closest('.sidebar').length;
        //             var sidebarBody = $(e.target).closest('.sidebar-body').length;
        //             if (!sidebar && !sidebarBody) {
        //                 if ($('body').hasClass('sidebar-open')) {
        //                     $('body').removeClass('sidebar-open');
        //                     $('body').addClass('sidebar-folded');
        //                 } else {
        //                     $('body').removeClass('sidebar-folded');
        //                 }
        //             }
        //         }
        //     });
        //
        //     $(document).on('click touchstart', function (e) {
        //         e.stopPropagation();
        //
        //         // closing of sidebar menu when clicking outside of it
        //         if (!$(e.target).closest('.sidebar-toggler').length) {
        //             var sidebar = $(e.target).closest('.sidebar').length;
        //             var sidebarBody = $(e.target).closest('.sidebar-body').length;
        //             if (!sidebar && !sidebarBody) {
        //                 if ($('body').hasClass('sidebar-open')) {
        //                     $('body').removeClass('sidebar-open');
        //                 }
        //             }
        //         }
        //     });
        // });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const fetchData = async () => {
        if(hasPermission(auth, 'settings_list')) {
            try {
                const response = await axios.get('/api/settings', {
                    headers: {
                        'x-auth-token': auth.token,
                    },
                });
                setState(response.data);
            } catch (err) {
                console.log(err);
            }
        }
    };

    return (
        <SideBarWrapper>
            <SideBarHeader>
                <Link to={routeService.dashboard()} title='Startseite'>
                    <Fragment>
                        {state.logo && (
                            <img className='logo'
                                 src={state.logo  ? (state.logo.preview ? state.logo.preview : state.logo) : ''}
                                alt={state.logo ? state.company + ' Logo' : 'MediCharge Logo'}/>
                                )}
                        {!state.logo && (<SideBarTitle>
                            Medi<span>Charge</span>
                        </SideBarTitle>)}
                    </Fragment>
                </Link>
                <SideBarToggler />
            </SideBarHeader>
            <SideBarMenu>
                {hasPermission(auth, 'dashboard_view') && (
                <SideBarLink to={routeService.dashboard()} icon={<Icon icon={'home'} className='link-icon' />}>
                    Dashboard
                </SideBarLink>)}
                {hasPermission(auth, 'section_general_view') && (
                    <Fragment>
                        <SideBarCategory>Aufträge & Abholung</SideBarCategory>
                        {hasPermission(auth, 'task_list_view') && (
                        <SideBarLink to={routeService.taskList()} icon={<Icon icon={'tasks'} className='link-icon fill' />}>
                            Aufträge
                        </SideBarLink>)}
                        {hasPermission(auth, 'pickup_list_view') && (
                        <SideBarLink
                            to={routeService.pickUpList()}
                            icon={<Icon icon={'truck'} className='link-icon fill' />}
                            notice={<Link to={'/abholauftraege'} className={'old-view'}>alte</Link>}
                        >
                            Abholaufträge
                        </SideBarLink>)}
                    </Fragment>)}
                {hasPermission(auth, ['section_base_data_view']) && (
                    <Fragment>
                        <SideBarCategory>Stammdaten</SideBarCategory>
                        {hasPermission(auth, ['patient_list_view']) && (
                        <SideBarLink to={routeService.patientList()} icon={<Icon icon={'patient'} className='link-icon fill' />}>
                            Patienten
                        </SideBarLink>)}
                        {hasPermission(auth, 'home_list_view') && (
                        <SideBarLink to={routeService.homeList()} icon={<Icon icon={'medical_home'} className='link-icon fill' />}>
                            Pflegeeinrichtungen
                        </SideBarLink>)}
                        {hasPermission(auth, 'doctor_list_view') && (
                        <SideBarLink to={routeService.doctorList()} icon={<Icon icon={'medical_note'} className='link-icon fill' />}>
                            Ärzte
                        </SideBarLink>)}
                        {hasPermission(auth, 'doctor_office_list_view') && (
                            <SideBarLink to={routeService.doctorOfficeList()} icon={<Icon icon={'medical_note'} className='link-icon fill' />}>
                                Arztpraxen
                            </SideBarLink>)}
                        {hasPermission(auth, 'medicine_list_view') && (
                            <SideBarLink
                                to={routeService.medicineList()}
                                icon={<Icon icon={'medicine'} className='link-icon fill' />}
                            >
                                Medikamente
                            </SideBarLink>)}
                    </Fragment>
                )}
                {/*{hasPermission(auth, ['ROLE_PHARMA']) && (*/}
                {/*    <Fragment>*/}
                {/*        <SideBarCategory>Ärzte & Praxen</SideBarCategory>*/}
                {/*        <SideBarLink to={routeService.doctorList()} icon={<Icon icon={'list'} className='link-icon' />}>*/}
                {/*            Ärzte*/}
                {/*        </SideBarLink>*/}
                {/*        <SideBarLink to={routeService.doctorOfficeList()} icon={<Icon icon={'list'} className='link-icon' />}>*/}
                {/*            Praxen*/}
                {/*        </SideBarLink>*/}
                {/*        <SideBarLink to={routeService.doctorOfficeCreate()} icon={<Icon icon={'plus'} className={'link-icon'} />}>*/}
                {/*            Anlegen*/}
                {/*        </SideBarLink>*/}
                {/*    </Fragment>*/}
                {/*)}*/}
                {hasPermission(auth, 'section_settings_view') && (
                    <Fragment>
                        <SideBarCategory>Verwaltung</SideBarCategory>
                        {hasPermission(auth, 'user_list_view') && (
                        <SideBarLink
                            to={routeService.userList()}
                            icon={<Icon icon={'users'} className='link-icon' />}
                            notice={<Link to={'/nutzer'} className={'old-view'}>alte</Link>}
                        >
                            Nutzer
                        </SideBarLink>)}
                        {hasPermission(auth, 'settings_list_view') && (
                        <SideBarLink
                            to={routeService.settings()}
                            icon={<Icon icon={'settings'} className='link-icon fill' />}
                        >
                            Einstellungen
                        </SideBarLink>)}
                        {hasPermission(auth, 'task_import_view') && (
                            <SideBarLink
                                to={routeService.taskImport()}
                                icon={<Icon icon={'import'} className='link-icon fill' />}
                            >
                                Aufträge importieren
                            </SideBarLink>
                        )}
                        {hasPermission(auth, 'meeting_list_view') && (
                            <SideBarLink
                                to={routeService.meetingList()}
                                icon={<Icon icon={'meeting'} className='link-icon fill' />}
                            >
                                Termine
                            </SideBarLink>
                        )}
                        {hasPermission(auth, 'acl_list_view') && (
                            <SideBarLink
                                to={routeService.acl()}
                                icon={<Icon icon={'protected_lock'} className='link-icon fill' />}
                            >
                                ACL
                            </SideBarLink>
                        )}
                    </Fragment>
                )}
                {/*{hasPermission(auth, ['ROLE_MANAGER']) && (*/}
                {/*    <Fragment>*/}
                {/*        <SideBarCategory>Zusätzliche Dienste</SideBarCategory>*/}
                {/*        <SideBarLink to={routeService.services()} icon={<Icon icon={'list'} className='link-icon' />}>*/}
                {/*            Übersicht*/}
                {/*        </SideBarLink>*/}
                {/*    </Fragment>*/}
                {/*)}*/}
                {/*<Fragment>*/}
                {/*    <SideBarCategory>Demo</SideBarCategory>*/}
                {/*    <a onClick={() => { localStorage.removeItem('mockxios'); return false; }} href={'/'} style={{cursor: 'pointer'}}>*/}
                {/*        System Reset*/}
                {/*    </a>*/}
                {/*</Fragment>*/}
            </SideBarMenu>
        </SideBarWrapper>
    );
};

export default SideBar;

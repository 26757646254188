import request from "@utils/request";
import factory from "@factories/factory";
import {toast} from "react-toastify";

const eventServiceFactory = (config) => {
    return {
        ...factory,
        ...config,
        path: 'events',
        seen: async function(id, all) {
            if(id) {
                await request.put(`/api/${this.path}/${id}/seen`, {}, {
                    headers: {'x-auth-token': this.auth.token}
                });
                toast.success('Nachricht als gelesen markiert.');
            } else {
                await request.put(`/api/${this.path}/seen`, {}, {
                    headers: {'x-auth-token': this.auth.token}
                });
                toast.success('Alle Nachrichten als gelesen markiert.');
            }
        },
        list: async function(filter) {
            const response = await request.get(`/api/${this.path}${this.toFilterQuery(filter, '?')}`, {
                headers: {'x-auth-token': this.auth.token}
            });

            return response.data;
        },
        get: async function(id, filter) {
            id = id ? `/${id}` : '';

            const response = await request.get(`/api/tasks${id}/events${this.toFilterQuery(filter, '?')}`, {
                headers: {'x-auth-token': this.auth.token}
            });

            return response.data;
        },
        create: async function(id, data) {
            const response = await request.post(`/api/tasks/${id}/events`, data, {
                headers: {
                    'x-auth-token': this.auth.token,
                    'Content-Type': 'multipart/form-data',
                }
            });

            return response.data;
        },
        download: async function(taskId, eventId, name) {
            const response = await request.get(`/api/tasks/${taskId}/events/${eventId}/attachment/${name}`, {
                headers: {
                    'x-auth-token': this.auth.token,
                },
                responseType: 'blob',
            });

            return response.data;
        }
    }
}

export default eventServiceFactory;
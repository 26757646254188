import React, {useEffect, useState} from "react";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component

import { German } from "flatpickr/dist/l10n/de.js"
import "flatpickr/dist/themes/material_green.css";


const DatePicker = ({readonly, options, name, initialValue, label, className, onChange, ...args}) => {
    /**
     * States
     */
    const [state, setState] = useState(!!initialValue ? initialValue : '');

    /**
     * Hooks
     */
    useEffect(() => {
        buildInitialState(initialValue);
    }, [initialValue]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const buildInitialState = (initialValue) => {
        if(!!initialValue) {
            if(!(initialValue instanceof Date)) {
                setState(new Date(initialValue));
            } else {
                setState(initialValue);
            }
        }
    }

    const change = ([date]) => {
        setState(date);

        const mom = moment(date);
        if(onChange) {
            onChange({target: {name: name, value: mom}});
        }

        return true;
    }

    const formatDate = (datestr, format) => {
        if(format) {
            const mom = moment(datestr);

            return mom.format(format.replace('d', 'DD').replace('m', 'MM').replace('Y', 'YYYY'));
        }

        return datestr;
    }


    return <div className={`form-input-field-container ${className}`}>
        <label className="form-label">{label}</label>
        {readonly && <input type={'text'} className={'form-control d-block form-readonly'} value={formatDate(state, options.dateFormat)} readOnly={true}/>}
        {!readonly && <Flatpickr
            className='form-control'
            data-enable-time
            placeholder={label}
            options={{...options, locale: German}}
            value={state}
            onChange={change}
        />}
    </div>
}

DatePicker.propTypes = {
    readonly: PropTypes.bool

};

DatePicker.defaultProps = {
    options: {},
    readonly: false
};

export default DatePicker;
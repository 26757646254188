import {Checkbox, makeStyles} from "@material-ui/core";
import React, {Fragment} from "react";
import {Td, Tr} from "react-super-responsive-table";
import PickupRow from "./PickupRow";

const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    truck: {
        color: '#ff5722',
    },
    tbodyTr: {
        '& td': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            padding: '16px',
            ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
                padding: '0'
            }
        }
    },
    th: {
        fontWeight: 'bold'
    }
});

const PickupGroupRow = ({group, selectedTasks, setSelectedTasks, fetch}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    const selectTask = (e) => {
        if (e.target.checked) {
            setSelectedTasks([...group.tasks.map((item) => item._id), ...selectedTasks]);
        }
        else {
            setSelectedTasks(selectedTasks.filter((item) => !group.tasks.map((item) => item._id).includes(item)));
        }
    };

    return (
        <Fragment>
            <Tr className={classes.tbodyTr}>
                <Td>
                    <Checkbox
                        value={group.id}
                        onChange={selectTask}
                        color='primary'
                    />
                </Td>
                <Td colSpan={7} className={classes.th}>
                    {group.label}
                </Td>
            </Tr>
            {group.tasks.map((task) => (
                <PickupRow
                    selectedTasks={selectedTasks}
                    setSelectedTasks={setSelectedTasks}
                    key={task._id}
                    task={task}
                    fetch={fetch}
                />
            ))}
        </Fragment>
    );
};

export default PickupGroupRow;
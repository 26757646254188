import React from "react";
import PropTypes from "prop-types";

const Chip = ({value, type, size, connected, description}) => {

    return (<div
        className={`data-chip ${type} ${size} ${connected}`.trim()}
        title={description}
    >
        {value}
    </div>);
}

Chip.propTypes = {
    value: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['primary', 'secondary']),
    size: PropTypes.oneOf(['normal', 'small']),
    connected: PropTypes.oneOf(['none-connected', 'left-connected', 'right-connected', 'full-connected']),
    description: PropTypes.string
};

Chip.defaultProps = {
    type: 'primary',
    size: 'normal',
    connected: 'none-connected',
    description: ''
};

export default Chip;

import request from "@utils/request";
import {toast} from "react-toastify";
import factory from "@factories/factory";

const homeServiceFactory = (config) => {
    return {
        ...config,
        ...factory,
        path: 'homes',
        update: async function (id, data) {
            const response = await request.put(`/api/homes/${id}`, data, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                toast.success('Pflegeheim wurde erfolgreich aktualisiert.');
            }
        },
        delete: async function (id) {
            const response = await request.delete(`/api/homes/${id}`, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                toast.success('Pflegeheim wurde erfolgreich gelöscht.');
            }
        }
    };
};

export default homeServiceFactory;
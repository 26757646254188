import productsHomes from "./db/products/home";
import productsDoctors from "./db/products/doctors";
import doctorOffices from "./db/doctorOffices";
import doctors from "./db/doctors";
import homes from "./db/homes";
import tasks from "./db/tasks";
import homeGroups from "./db/homeGroups";
import patientDB from "./db/patients";
import userDB from "./db/user";
import {mapById} from "./maxiosUtils";

const doc = doctors(doctorOffices['/api/doctors/offices']);
let home = homes();
const hg = homeGroups([...home['/api/homes']]);
const users = userDB([...hg['/api/homes/groups']], [...doc['/api/doctors']]);
const patients = patientDB([...hg['/api/homes/groups']]);
const task = tasks([...doc['/api/doctors']],  [...patients['/api/patients']], [...users['/api/users']]);
home['/api/homes'] = mapById([...home['/api/homes']], [...hg['/api/homes/groups']], 'homeGroups', 'home');

const moxiosDb = {
    ...productsHomes,
    ...productsDoctors,
    ...doctorOffices,
    ...patients,
    ...doc,
    ...home,
    ...task,
    ...hg,
    ...users
}

export default moxiosDb;
import React, {Fragment, useContext, useEffect, useState} from "react";
import taskServiceFactory from "@factories/taskServiceFactory";
import Content from "@components/Content"
import Row from "@components/Row";
import Col from "@components/Col";
import Card from "@components/Card";
import Table from "@components/Table/Table";
import AuthContext from "@context/AuthContext";
import {getTaskTypeName} from "@utils/enumName";
import taskTypeEnum from "@enum/taskTypeEnum";
import Communication from "@components/communication/Communication";
import Observer from "@utils/observer";
import TableRowAction from "@components/Table/TableRowAction";
import Icon from "@components/Icon";
import ActionBar from "@views/lists/PickUpList/ActionBar";
import homeServiceFactory from "@factories/homeServiceFactory";
import doctorServiceFactory from "@factories/doctorServiceFactory";
import {hasPermission} from "@utils/utils";
import moment from "moment";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const PickUpList = ({...args}) => {

    /**
     * State
     */
    const [homes, setHomes] = useState([]);
    const [doctors, setDoctors] = useState([]);

    const [observer] = useState(new Observer((args, that) => {
        that.pushUpdate(that.collect().length > 0, 'has_selection');
    }));

    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * Services
     */
    const homeService = homeServiceFactory({auth});
    const doctorService = doctorServiceFactory({auth});
    const taskService = taskServiceFactory({auth: auth});
    const tableService = {
        update: async (id, data, message) => {
            await taskService.update(id, data, message);
        },
        get: async (id, filter, page, limit) => {
            let groups = {}

            const response = await taskService.get(id, filter, page, limit);

            for(let item of response.items) {
                let label = 'Andere';
                let  name = null
                if(item.type === taskTypeEnum.ORDER || item.type === taskTypeEnum.DOCTOR_OFFICE_RECIPE) {
                    if(item.doctor) {
                        name = 'Arzt'
                        label = `${item.doctor.title} ${item.doctor.firstname} ${item.doctor.lastname}`
                    }
                } else if(item.patient && item.patient.home && item.type === taskTypeEnum.LIVING_AREA_RECIPE) {
                    name = 'Wohnheim'
                    label = `${item.patient.home.name}`
                }

                if(!groups[label]) {
                    groups[label] = {
                        title: label,
                        name: name,
                        items: []
                    }
                }

                groups[label].items.push(item);
            }

            return {...response, items: Object.values(groups)};
        }
    }

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Columns
     */
    const columns = [
        {name: 'patient', display: hasPermission(auth, 'pickup_column_patient_view'), width: 300, title: 'Patienten', render: (item, helper) => {
                return (<Fragment>
                    {helper.highlight(`${item.patient.firstname} ${item.patient.lastname}`)}
                </Fragment>);
            }
        },
        {name: 'home', display: hasPermission(auth, 'pickup_column_home_view'), width: 300, title: 'Pflegeheim', render: (item) => {
            return <span>
                {item.patient.home && item.patient.home.name}
                {item.patient.homeGroup && ` / ${item.patient.homeGroup.name}`}
            </span>
        }},
        {name: 'medicine', display: hasPermission(auth, 'pickup_column_medicine_view'), align: 'left', mobile: {align: 'left'}, title: 'Medikament'},
        {name: 'dose', display: hasPermission(auth, 'pickup_column_medicine_view'), align: 'left', mobile: {align: 'left'}, title: 'Dosierung'},
        {title: 'Rezept', display: hasPermission(auth, 'pickup_column_type_view'), align: 'left', mobile: {align: 'left'},render: (item) => getTaskTypeName(item.type === taskTypeEnum.ORDER ? taskTypeEnum.DOCTOR_OFFICE_RECIPE : item.type)},
        {name: 'actions', display: hasPermission(auth, 'pickup_column_actions_view'),  align: 'right', mobile: {align: 'center'}, render: () => {
                return (<Fragment>
                    <TableRowAction label={'ausliefern'} permission={'pickup_task_delivery_view'} data={{__removed: true, status: 'delivery'}} message={'Der Auftrag wurde als in Auslieferung markiert'}>
                        <Icon icon={'package'} className={'color-orange'} tooltip={'In Auslieferung geben'}/>
                    </TableRowAction>
                    <TableRowAction label={'abschließen'} permission={'pickup_task_solve_view'} data={{__removed: true, status: 'finished'}} message={'Der Auftrag wurde als abgeschlossen markiert'}>
                        <Icon icon={'truck'} className={'color-orange'} tooltip={'Auftrag abschließen'}/>
                    </TableRowAction>
                    <TableRowAction label={'zurückgeben'} permission={'pickup_task_back_view'} data={{__removed: true, status: 'new'}} message={'Der Auftrag wurde wieder an das Heim Zurückgegeben.'}>
                        <Icon icon={'revert'} className={'color-primary'} tooltip={'Auftrag zurückgeben'}/>
                    </TableRowAction>
                </Fragment>)
            }
        }
    ];

    /**
     * Functions
     */
    const collapse = (item, resolution) => {
        return (<Fragment>
            <Communication id={item._id} types={['message']} formLocation={resolution > 425 ? 'top' : 'bottom'}/>
            <div className={'collapse-meta-data'}>
                <div>erstellt am {moment(item.createdAt).format('DD.MM.YYYY')}</div>
                <div>bearbeitet am {moment(item.modifiedAt).format('DD.MM.YYYY')}</div>
            </div>
        </Fragment>)
    }

    const doRowHighlight = (item) => {
        return !item.seen || item.seen.indexOf(auth.user) === -1;
    }

    const fetch = async () => {
        const homeCollection = await homeService.get(null, {}, 0, 99999);

        setHomes(homeCollection.items.map((item) => {
            return {value: item._id, label: `${item.name}`}
        }));

        const doctorCollection = await doctorService.get(null, {}, 0, 99999);

        setDoctors(doctorCollection.items.map((item) => {
            return {value: item._id, label: `${item.title} ${item.firstname} ${item.lastname}`}
        }));
    }

    return <Content>
        <Row sm={12}>
            <Col sm={12}>
                <Card title={'Abholaufträge'} actions={<ActionBar observer={observer} />}>
                    <Table
                        observer={observer}
                        columns={columns}
                        service={tableService}
                        rowCheckAble={true}
                        onRowHighlight={doRowHighlight}
                        filterElements={[
                            [
                                {name: 'home', placeholder: 'Wohnheime', type: 'select', data: homes},
                                {name: 'doctor', placeholder: 'Arzt', type: 'select', data: doctors}
                            ],
                            [{name: 'notSeen',label: 'nur neue Abhohlaufträge anzeigen',  type: 'checkbox'}],
                            [{name: 'status', type: 'hidden', value: 'pickup'}]
                        ]}
                        collapseLabel={'Nachrichten'}
                        onCollapseContent={collapse}
                    />
                </Card>
            </Col>
        </Row>
    </Content>;
}

PickUpList.propTypes = {};

PickUpList.defaultProps = {};

export default PickUpList;
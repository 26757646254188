/* jshint esversion: 9 */
import {
    AppBar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    // Checkbox,
    FormControl,
    // FormControlLabel,
    // FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import axios from 'axios';
import moment from 'moment';
import PropTypes from "prop-types";
import React, {Fragment, useContext, useEffect, useMemo, useState} from 'react';
import Dropzone, {useDropzone} from "react-dropzone";
import {AiOutlineDelete} from 'react-icons/ai';
import {
    AiOutlineLock, FaBed,
    FaRegAddressCard,
    // FiHelpCircle
} from "react-icons/all";
import {ImCancelCircle} from 'react-icons/im';
import {MdAdd, MdDelete, MdSend} from 'react-icons/md';
import {RiDeleteBinLine} from "react-icons/ri";
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';
// import {inputChange} from "../utils/tools";
import {isEmpty} from '../utils/validation';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function tabProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        margin: 0,
    },
    helpButton: {
        display: 'inline-block',
        width: '30px',
        marginTop: '-33px',
        marginLeft: '330px'
    },
    modified: {
        margin: '0 0 0 25px',
        color: '#888',
        fontSize: 12
    },
    helpText: {
        color: 'rgba(0, 0, 0, 0.54)',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1.66,
        letterSpacing: '0.03333em'
    }
}));

const baseStyle = {
    alignItems: 'center',
    // backgroundColor: '#fafafa',
    borderColor: '#eeeeee',
    borderRadius: 2,
    borderStyle: 'dashed',
    borderWidth: 2,
    cursor: 'pointer',
    color: '#434343',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '0',
    outline: 'none',
    paddingBottom: '100%',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const HomeForm = ({history, match: {params}}) => {
    const classes = useStyles();

    /**
     * STATES
     */
    const {auth, setAuth} = useContext(AuthContext);
    const [state, setState] = useState({
        name: '',
        street: '',
        postalCode: '',
        city: '',
        country: '',
        canSendTasks: false,
        // allowSendTasks: false,
        // canImportTasks: false,
        // allowImportTasks: false,
        company: '',
        phone: '',
        fax: '',
        email: '',
        logo: '',
        debtorId: '',
    });
    const [homeGroups, setHomeGroups] = useState([]);
    const [errors, setErrors] = useState({
        name: false,
        street: false,
        postalCode: false,
        city: false,
        country: false,
    });
    const [tab, setTab] = React.useState(0);
    // const [help, setHelp] = React.useState({
    //     allowSendTasksHelp: false,
    //     canImportTasksHelp: false,
    // });

    /**
     * REQUESTS
     */
    const fetchData = async () => {
        try {
            let response = await axios.get(`/api/contracts/find?home=${params.id}`, {headers: {'x-auth-token': auth.token}})
            let canSendTasks = false;
            // let canImportTasks = false;
            let contract = response.data;
            if (contract && contract.items) {
                canSendTasks = true;
                // let faxLineItems = contract.items.filter((item) => item.name === "faxLine");
                // if (faxLineItems.length > 0) {
                //     canSendTasks = moment(faxLineItems[0].validFrom).isSameOrBefore() && moment(faxLineItems[0].bookedAt).isSameOrBefore();
                // }
                // let importTasksItems = contract.items.filter((item) => item.name === "importTasks");
                // if (importTasksItems.length > 0) {
                //     canImportTasks = moment(importTasksItems[0].validFrom).isSameOrBefore() && moment(importTasksItems[0].bookedAt).isSameOrBefore();
                // }
            }

            response = await axios.get(`/api/homes/${params.id}`, {headers: {'x-auth-token': auth.token}});
            setHomeGroups(response.data.homeGroups);
            delete response.data.homeGroups;
            setState({
                country: 'Deutschland',
                ...response.data,
                createdAt: moment(response.data.createdAt),
                modifiedAt: moment(response.data.modifiedAt),
                canSendTasks: canSendTasks,
                // canImportTasks: canImportTasks,
            });
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        if (params.hasOwnProperty('id')) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const acceptFile = (files) => {
        if (files.length > 0) {
            files[0].preview = URL.createObjectURL(files[0]);
            setState({
                ...state,
                logo: files[0]
            });
        }
    };

    const deleteFile = (e) => {
        setState({
            ...state,
            'logo': '_delete',
        });
    };

    const {isDragActive, isDragAccept, isDragReject} = useDropzone({accept: 'image/*'});

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragActive ? activeStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragAccept,
        isDragActive,
        isDragReject,
    ]);

    /**
     * CONTROLS
     */
    const change = (e) => {
        validate(e);
        setState({...state, [e.target.name]: e.target.value});
    };

    const changeZip = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setState({...state, [e.target.name]: value.replace(/[^0-9]+/, '').substr(0,5)});
    };

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    // const helpChange = (e) => {
    //     let parentButton = e.target.closest('button');
    //     setHelp({...help, [parentButton.name]: !help[parentButton.name]});
    //     console.log(parentButton.name + ":" + !help[parentButton.name]);
    // }

    const addStation = () => {
        setHomeGroups([...homeGroups, {name: '', _id: ''}]);
    };

    const changeStation = (e) => {
        let newhomeGroups = [...homeGroups];
        newhomeGroups[e.target.dataset.index].name = e.target.value;

        setHomeGroups(newhomeGroups);
    };

    const removeStation = (e) => {
        const group = homeGroups[e.currentTarget.dataset.index];
        if (group._id === '') {
            let newhomeGroups = [...homeGroups];
            newhomeGroups.splice(e.currentTarget.dataset.index, 1);

            setHomeGroups(newhomeGroups);
        } else {
            history.push(`/einrichtung/${params.id}/wohnbereich/${group._id}/loeschen`);
        }
    };

    /**
     * VALIDATION
     */
    const validate = (e) => {
        switch (e.target.name) {
            case 'name':
            case 'street':
            case 'postalCode':
            case 'city':
            case 'country':
                setErrors({...errors, name: isEmpty(e.target.value)});
                break;
            default:
        }
    };

    /**
     * SUBMIT
     */
    const submit = async (e) => {
        e.preventDefault();
        let data = new FormData(document.getElementById('homeForm'));
        data.append('logo', state.logo)

        // for (const [name, value] of Object.entries(state)) {
        //     if (value !== null && name !== 'allowSendTasks' && name !== 'allowImportTasks') {
        //         data.append(name, value);
        //     }
        // }

        try {
            if (params.hasOwnProperty('id') && params.type && params.type === 'loeschen') {
                await axios.delete(`/api/homes/${params.id}`, {headers: {'x-auth-token': auth.token}});
                toast.success('Pflegeeinrichtung gelöscht.');
            } else if (params.hasOwnProperty('id')) {
                const response = await axios.put(`/api/homes/${params.id}`, data, {
                    headers: {
                        'x-auth-token': auth.token,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                for (const group of homeGroups) {
                    if (group._id !== '') {
                        await axios.put(`/api/homes/${response.data.id}/groups/${group._id}`, group, {
                            headers: {'x-auth-token': auth.token},
                        });
                    } else {
                        await axios.post(`/api/homes/${response.data.id}/groups`, group, {
                            headers: {'x-auth-token': auth.token},
                        });
                    }
                }
                toast.success('Pflegeeinrichtung geändert.');

                // if(response.data.permissions) {
                //     const perm = {...auth.permissions, canCreateDocument: !!response.data.permissions.canCreateDocument};
                //     setAuth({...auth, permissions: perm});
                // }
            } else {
                const response = await axios.post('/api/homes', data, {headers: {'x-auth-token': auth.token}});
                for (const group of homeGroups) {
                    await axios.post(`/api/homes/${response.data.id}/groups`, group, {
                        headers: {'x-auth-token': auth.token},
                    });
                }
                toast.success('Pflegeeinrichtung gespeichert.');
            }
            history.push('/einrichtungen');
        } catch (err) {
            handleErrors(err, setAuth, history);
        }

        return false;
    };

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Link to='/einrichtungen'>Pflegeeinrichtungen</Link>
                <Typography color='textPrimary'>
                    {params.hasOwnProperty('id')
                        ? params.type && params.type === 'loeschen'
                            ? 'Einrichtung löschen'
                            : 'Einrichtung bearbeiten'
                        : 'Neue Einrichtung'}
                </Typography>
            </Breadcrumbs>
            <form id='homeForm' onSubmit={(e) => submit(e)}>
                <Card>
                    <CardContent>
                        <Typography component='h1' color='textSecondary' gutterBottom>
                            {params.hasOwnProperty('id')
                                ? params.type && params.type === 'loeschen'
                                    ? 'Einrichtung löschen'
                                    : 'Einrichtung bearbeiten'
                                : 'Neue Einrichtung'}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AppBar className={classes.appBar} position='static' color='default'>
                                    <Tabs
                                        value={tab}
                                        onChange={handleTabChange}
                                        indicatorColor='primary'
                                        textColor='primary'
                                    >
                                        <Tab label='Stammdaten' icon={<FaRegAddressCard/>} {...tabProps(0)} />
                                        <Tab label='Wohnbereich' icon={<FaBed/>} {...tabProps(2)} />
                                        {(state.canSendTasks && (auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_MANAGER'))) && (
                                            <Tab label='Absendereinstellungen' icon={<AiOutlineLock/>} {...tabProps(1)} />
                                        )}
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={tab} index={0}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    disabled={params.type && params.type === 'loeschen'}
                                                    required
                                                    id='name'
                                                    name='name'
                                                    error={errors.name}
                                                    helperText={errors.name && 'Geben Sie einen Namen an'}
                                                    value={state.name}
                                                    onChange={(e) => change(e)}
                                                    label='Name'
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    disabled={params.type && params.type === 'loeschen'}
                                                    id='street'
                                                    name='street'
                                                    error={errors.street}
                                                    helperText={errors.street && 'Geben Sie eine Straße an'}
                                                    required
                                                    value={state.street}
                                                    onChange={(e) => change(e)}
                                                    label='Straße'
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    disabled={params.type && params.type === 'loeschen'}
                                                    id='postalCode'
                                                    name='postalCode'
                                                    required
                                                    error={errors.postalCode}
                                                    helperText={errors.postalCode && 'Geben Sie eine Postleitzahl an'}
                                                    value={state.postalCode}
                                                    onChange={(e) => changeZip(e)}
                                                    label='Postleitzahl'
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={8} xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    disabled={params.type && params.type === 'loeschen'}
                                                    id='city'
                                                    name='city'
                                                    error={errors.city}
                                                    helperText={errors.city && 'Geben Sie eine Stadt an'}
                                                    required
                                                    value={state.city}
                                                    onChange={(e) => change(e)}
                                                    label='Ort'
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    disabled={params.type && params.type === 'loeschen'}
                                                    id='country'
                                                    name='country'
                                                    required
                                                    error={errors.country}
                                                    helperText={errors.country && 'Geben Sie ein Land an'}
                                                    value={state.country}
                                                    onChange={(e) => change(e)}
                                                    label='Land'
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                disabled={params.type && params.type === 'loeschen'}
                                                formHelperTextProps={{focused: true}}
                                                fullWidth
                                                helperText={(state.canSendTasks && (auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_MANAGER')))
                                                    ? 'E-Mail Adresse der Firma, die auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'
                                                    : 'E-Mail Adresse für Systembenachrichtigungen.'}
                                                id='email'
                                                label='E-Mail'
                                                name='email'
                                                onChange={(e) => change(e)}
                                                required
                                                type={'email'}
                                                value={state.email}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                disabled={params.type && params.type === 'loeschen'}
                                                formHelperTextProps={{focused: true}}
                                                fullWidth
                                                helperText={'Faxnummer der Firma, die auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'}
                                                id='fax'
                                                label='Fax'
                                                name='fax'
                                                onChange={(e) => change(e)}
                                                required
                                                type={'text'}
                                                value={state.fax}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled={!auth.roles || !auth.roles.includes('ROLE_ADMIN')}
                                                formHelperTextProps={{focused: true}}
                                                fullWidth
                                                helperText='In der Regel dreistellig. z.B. 001'
                                                id='debtorId'
                                                label='Kundennummer'
                                                name='debtorId'
                                                onChange={(e) => change(e)}
                                                value={state.debtorId}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel index={1} value={tab}>
                                    {(auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_MANAGER')) && (
                                        <Grid className='mg-t-24' container justify={(auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_MANAGER')) ? 'space-between' : 'flex-end'} spacing={2}>
                                            {!params.type && (
                                                <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                                                    <Button
                                                        color='primary'
                                                        endIcon={<MdAdd/>}
                                                        fullWidth
                                                        onClick={addStation}
                                                        variant='contained'
                                                    >
                                                        Wohnbereich hinzufügen
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>)}

                                    {homeGroups.length > 0 && (
                                        <Fragment>
                                            <Typography className='mg-t-24' component='h1' color='textSecondary' gutterBottom>
                                                Wohnbereiche
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TableContainer>
                                                        <Table aria-label='simple table'>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Name</TableCell>
                                                                    <TableCell align='right'>Aktionen</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {homeGroups.map((station, i) => (
                                                                    <TableRow key={i}>
                                                                        <TableCell>
                                                                            <FormControl fullWidth>
                                                                                <TextField
                                                                                    disabled={
                                                                                        (params.type && params.type === 'loeschen') || (!auth.roles.includes('ROLE_ADMIN') && !auth.roles.includes('ROLE_MANAGER'))
                                                                                    }
                                                                                    inputProps={{'data-index': i}}
                                                                                    onChange={(e) => changeStation(e)}
                                                                                    required
                                                                                    value={station.name}
                                                                                />
                                                                            </FormControl>
                                                                        </TableCell>
                                                                        <TableCell align='right'>
                                                                            <Tooltip title='Wohnbereich löschen'>
                                                                                <IconButton
                                                                                    aria-label='löschen'
                                                                                    color='secondary'
                                                                                    data-index={i}
                                                                                    disabled={
                                                                                        (params.type && params.type === 'loeschen') || (!auth.roles.includes('ROLE_ADMIN') && !auth.roles.includes('ROLE_MANAGER'))
                                                                                    }
                                                                                    onClick={(e) => removeStation(e)}
                                                                                    size='small'
                                                                                >
                                                                                    <AiOutlineDelete/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    )}
                                </TabPanel>
                                <TabPanel value={tab} index={2}>
                                    <Grid container spacing={1}>
                                        <Grid item sm={8} xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        formHelperTextProps={{focused: true}}
                                                        fullWidth
                                                        helperText='Firmenname, der auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'
                                                        id='name'
                                                        label='Name'
                                                        name='name'
                                                        onChange={(e) => change(e)}
                                                        required
                                                        value={state.name}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        formHelperTextProps={{focused: true}}
                                                        fullWidth
                                                        helperText='Telefonnummer der Firma, die auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'
                                                        id='phone'
                                                        label='Telefon'
                                                        name='phone'
                                                        onChange={(e) => change(e)}
                                                        required
                                                        value={state.phone}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        formHelperTextProps={{focused: true}}
                                                        fullWidth
                                                        helperText='Faxnummer der Firma, die auf den Dokumenten als Absender steht, die gefaxt oder per Mail gesendet werden.'
                                                        id='company_fax'
                                                        label='Fax'
                                                        name='fax'
                                                        onChange={(e) => change(e)}
                                                        value={state.fax}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <FormLabel style={{fontSize: '11px'}}>Logo</FormLabel>
                                            <div className="container" style={{
                                                'backgroundColor': '#ffffff',
                                                'backgroundImage': `url(${state.logo && state.logo.preview ? state.logo.preview : state.logo})`,
                                                'backgroundPosition': 'center center',
                                                'backgroundRepeat': 'no-repeat',
                                                'backgroundSize': 'auto 60%',
                                                'height': '0',
                                                'paddingBottom': '100%',
                                                'width': '100%',
                                            }}>
                                                <Dropzone accept={'image/*'} onDrop={acceptFile}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <section>
                                                            {state.logo !== '' && state.logo !== '_delete' && <IconButton
                                                                aria-label="delete"
                                                                color="primary"
                                                                onClick={deleteFile}
                                                                style={{
                                                                    'position': 'absolute'
                                                                }}
                                                            >
                                                                <RiDeleteBinLine/>
                                                            </IconButton>}
                                                            <div {...getRootProps({style})}>
                                                                <input {...getInputProps()} />
                                                                {(state.logo === '' || state.logo === '_delete') && <p style={{
                                                                    cursor: 'pointer',
                                                                    marginTop: '50%',
                                                                    textAlign: 'center',
                                                                }}>Logo per Drag and Drop hier ablegen oder hier klicken zum wählen.</p>}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </Grid>
                        </Grid>
                        {state.createdFrom && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className={classes.modified}>
                                        <div>Am {state.createdAt.format('DD.MM.YYYY')} erstellt von {state.createdFrom.username}</div>
                                        <div>Am {state.modifiedAt.format('DD.MM.YYYY')} geändert von {state.modifiedFrom.username}</div>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        {/*this page can only be displayed by admins, pharma users or home users for its own home*/}
                    </CardContent>
                    <CardActions>
                        <Grid container justify='space-between'>
                            <Grid item xs={12} sm={3} md={2} xl={1}>
                                <Button
                                    color='primary'
                                    component={Link}
                                    fullWidth
                                    startIcon={<ImCancelCircle/>}
                                    to='/einrichtungen'
                                    variant='outlined'
                                >
                                    Abbrechen
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} xl={1}>
                                {params.type && params.type === 'loeschen' ? (
                                    <Button
                                        color='secondary'
                                        endIcon={<MdDelete/>}
                                        fullWidth
                                        type='submit'
                                        variant='contained'
                                    >
                                        Löschen
                                    </Button>
                                ) : (
                                    <Button
                                        color='primary'
                                        endIcon={<MdSend/>}
                                        fullWidth
                                        type='submit'
                                        variant='contained'
                                    >
                                        Speichern
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </form>
        </Fragment>
    );
};

export default HomeForm;

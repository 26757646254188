import React, {Fragment, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Col from "@components/Col";
import Card from "@components/Card";
import Row from "@components/Row";
import Select from "@components/Select";
import medicineServiceFactory from "@factories/medicineFactoryService";
import AuthContext from "@context/AuthContext";
import Input from "@components/Input";
import Icon from "@components/Icon";
import CheckboxCard from "@components/CheckboxCard";
import doctorServiceFactory from "@factories/doctorServiceFactory";
import DatePicker from "@components/DatePicker";
import taskTypeEnum from "@enum/taskTypeEnum";
import taskGroupEnum from "@enum/taskGroupEnum";
import {hasPermission} from "@utils/utils";
import taskServiceFactory from "@factories/taskServiceFactory";
import moment from "moment";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component

const OrderDialogStep = ({form, setValid, onChange, ...args}) => {
    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * State
     */
    const [state, setState] = useState({});
    const [medicine, setMedicine] = useState([]);
    const [doctors, setDoctors] = useState(null);
    const [dose, setDose] = useState([]);

    /**
     * service
     */
    const medicineService = medicineServiceFactory({auth});
    const doctorService = doctorServiceFactory({auth})
    const taskService = taskServiceFactory({auth})

    /**
     * Hooks
     */
    useEffect(() => {
        setState({contact_type: null, group: taskGroupEnum.MEDICINE, amount: 1, ...(form.order ? form.order : {})})
    }, [form]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        validate();
    }, [state]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchMedicine();
        fetchDoctor();
        fetchDosage();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * functions
     * */
    const fetchMedicine = async () => {
        const response = await medicineService.get(null, {}, null, -1);

        setMedicine(response.items.map((item) => {
            return item.name
        }));
    }

    const fetchDosage = async () => {
        const response = await taskService.getDosageList();

        setDose(response.items.filter(item => item !== null));
    }

    const fetchDoctor = async () => {
        const response = await doctorService.get(null, {}, null, -1);

        setDoctors(response.items.map((item) => {
            return {key: item._id, label: `${item.title} ${item.lastname}`, value: item}
        }));
    }

    const validate = () => {
        if(state.medicine && state.dose && isValidRecipe()) {
            setValid(true);
        } else {
            setValid(false);
        }
    }

    const isValidRecipe = () => {
        if((parseInt(state.type) === 1 || parseInt(state.type) === 4) && state.doctor) {
            if(!state.doctor._id) {
                if(state.contact_type && state[state.contact_type]) {
                    return true;
                }
            } else {
                return true;
            }
        } else if(parseInt(state.type) === 2 || parseInt(state.type) === 3) {
            return true;
        }

        return false;
    }


    const change = (e) => {
        if(e.target && e.target.name) {
            setState({...state, [e.target.name]: e.target.value});
        } else {
            setState({...state, ...e});
        }

        if (onChange) {
            onChange(e);
        }
    }

    const addItem = (name) => {
        if(typeof name === 'string') {
            const entry = name.split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1));
            const lastName = entry.pop();
            const firstName = entry.join(' ');

            return {
                key: null,
                label: `${firstName} ${lastName}`,
                value: {_id: null, firstName: firstName, lastname: lastName}
            }
        }

        return name;
    }

    const onCreateLabel = (name) => {
        const patient = name.split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1));
        const lastName = patient.pop();
        const firstName = patient.join(' ');

        return (<span><Icon icon={'plus'} /> Arzt&nbsp;<strong>
            {firstName && (<span className={'create-info-group'}><span>Vorname</span>{firstName}&nbsp;</span>)}
            {lastName && (<span className={'create-info-group'}><span>Nachname</span>{lastName}</span>)}
            </strong>&nbsp;anlegen.</span>)
    }

    const medicineAutoComplete = () => {
        switch(state.group) {
            case taskGroupEnum.MEDICINE:
                return medicine;
            default:
                return [];
        }
    }

    const getDefaultSelectedDoctorId = (item) => {
        if(item && item._id) {
            return item._id
        }

        return item ? item : '';
    }

    const formatOptions = (item) => {
        return (<Fragment>{item.value.salutation} {item.value.title} {item.value.firstname} {item.value.lastname} <small className={'doctor-expertise'}>{item.value.expertise}</small></Fragment>)
    }

    const amount = [];

    for(let ii = 1; ii < 101; ii++) {
        amount.push({key: ii, label: `${ii}x`, value: ii});
    }

    return <Row className='mb-12'>
        <Col sm={12}>
            <Card title='Auftrag'
                  description={'Geben sie die Daten für ihre Bestellung ein.'}
                  className={['form-card'].join(' ')}
                  center={true}>
                <div className='container'>
                    <Row>
                        <Col sm={12}>
                            <Select
                                required
                                name={'group'}
                                placeholder={'Anforderungsart'}
                                value={state.group}
                                defaultValue={state.group}
                                options={[
                                    {key: 'medicine', label: `Medikament`, value: taskGroupEnum.MEDICINE, help: 'Nutzen Sie diesen Anforderungstyp zur Anforderung von Medikamenten.'},
                                    {key: 'document', label: `Dokument`, value: taskGroupEnum.DOCUMENT, help: 'Nutzen Sie diesen Anforderungstyp, wenn Sie Dokumente anfordern möchten. Z.B. Versichertenkarte, Rezepte oder Ähnliches.'},
                                    {key: 'other', label: `Andere`, value: taskGroupEnum.OTHER, help: 'Nutzen Sie diesen Anforderungstyp, wenn die Anforderung weder ein Medikament noch ein Dokument ist. Z.B. Hilfsmittel.'}
                                ]}
                                help={[
                                    {title: 'Medikamente', content: 'Markiert einen Task als Medikamenten Anforderung.'}
                                ]}
                                onChange={change}/>
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        {state.group === taskGroupEnum.MEDICINE && (
                            <Col lg={1} sm={12}>
                                <Select
                                    required
                                    name={'amount'}
                                    label={'Menge'}
                                    placeholder={'Menge'}
                                    defaultValue={state.amount}
                                    options={amount}
                                    onChange={change}/>
                            </Col>)}
                        <Col lg={state.group === taskGroupEnum.MEDICINE ? 11 : 12} sm={12} className={state.group === taskGroupEnum.MEDICINE ? 'mt-xs-3' : ''}>
                            <Input
                                required
                                name={'medicine'}
                                datalist={medicineAutoComplete()}
                                label={state.group === taskGroupEnum.MEDICINE ? 'Wirkstoff/Medikament/Pflegemittel' : 'Dokument'}
                                placeholder={state.group === taskGroupEnum.MEDICINE ? 'Wirkstoff/Medikament/Pflegemittel' : 'Dokument'}
                                value={state.medicine}
                                description={''}
                                onChange={change}/>
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        <Col sm={6}>
                            <Input
                                required={state.group === taskGroupEnum.MEDICINE}
                                name={'dose'}
                                datalist={state.group === taskGroupEnum.MEDICINE ? dose : []}
                                label={state.group === taskGroupEnum.MEDICINE ? 'Dosierung/Darreichungsform' : 'Bemerkung'}
                                placeholder={state.group === taskGroupEnum.MEDICINE ? 'Dosierung/Darreichungsform' : 'Bemerkung'}
                                value={state.dose}
                                description={''}
                                onChange={change}/>
                        </Col>
                        <Col sm={6} className={'mt-xs-3'}>
                            <DatePicker
                                name={'latestDelivery'}
                                label={'Spätester Liefertermin'}
                                placeholder='Spätester Liefertermin'
                                initialValue={state.latestDelivery}
                                onChange={change}
                                options={{
                                    dateFormat: 'd.m.Y',
                                    enableTime: false,
                                    minDate: moment(new Date()).startOf("day").toDate()
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        <Col md={12}>
                            <h5 className={'text-uppercase mt-2 mb-2'}>Rezept</h5>
                            <Row>
                                <Col xl={3} md={6}>
                                    <CheckboxCard
                                        type={'radio'}
                                        onClick={change}
                                        label={'Rezept anfordern'}
                                        name={'type'}
                                        value={taskTypeEnum.ORDER.toString()}
                                        help={''}
                                        icon={<Icon icon={'doctor'} />}
                                        checked={parseInt(state.type) === taskTypeEnum.ORDER}
                                        description={'Das Rezept muss beim Arzt angefordert werden.'}/>
                                </Col>
                                <Col xl={3} md={6}>
                                    <CheckboxCard
                                        type={'radio'}
                                        onClick={change}
                                        label={'Selbstkauf'}
                                        name={'type'}
                                        value={taskTypeEnum.SELF_PURCHASE.toString()}
                                        help={''}
                                        icon={<Icon icon={'money'} />}
                                        checked={parseInt(state.type) === taskTypeEnum.SELF_PURCHASE}
                                        description={'Kein Rezept notwendig.'}/>
                                </Col>
                                <Col xl={3} md={6}>
                                    <CheckboxCard
                                        type={'radio'}
                                        onClick={change}
                                        label={'Rezept in Wohnbereich'}
                                        name={'type'}
                                        value={taskTypeEnum.LIVING_AREA_RECIPE.toString()}
                                        help={''}
                                        icon={<Icon icon={'home'} />}
                                        checked={parseInt(state.type) === taskTypeEnum.LIVING_AREA_RECIPE}
                                        description={'Das Rezept liegt in der Pflegeeinrichtung vor.'}/>
                                </Col>
                                <Col xl={3} md={6}>
                                    <CheckboxCard
                                        type={'radio'}
                                        onClick={change}
                                        label={'Rezept in Arztpraxis'}
                                        name={'type'}
                                        value={taskTypeEnum.DOCTOR_OFFICE_RECIPE.toString()}
                                        help={''}
                                        icon={<Icon icon={'medical_home'} />}
                                        checked={parseInt(state.type) === taskTypeEnum.DOCTOR_OFFICE_RECIPE}
                                        description={'Das Rezept liegt in der Arztpraxis vor.'}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {(parseInt(state.type) === 1 || parseInt(state.type) === 4) && (
                    <Row>
                        <Col sm={12}>
                            <Select
                                required
                                placeholder={'Arzt'}
                                label={'Arzt'}
                                name={'doctor'}
                                defaultValue={getDefaultSelectedDoctorId(state.doctor)}
                                options={doctors}
                                onChange={change}
                                onAddItem={hasPermission(auth, 'doctor_create_view') ? addItem : null}
                                onCreateLabel={onCreateLabel}
                                formatOptionLabel={formatOptions}
                                loaderLabel={'Ärzte werden geladen ...'}
                            />
                        </Col>
                    </Row>)}
                    {state.doctor && typeof state.doctor === 'object' && !state.doctor._id && (
                        <Fragment>
                            <Row className={'mt-3'}>
                                <Col sm={12}>
                                    <Select
                                        required
                                        name={'contact_type'}
                                        label={'Primär Kontakt Art'}
                                        defaultValue={state.contact_type ? state.contact_type : ''}
                                        options={[{key: 'phone', label: 'Telefon'}, {key: 'mail', label: 'E-Mail'}, {key: 'fax', label: 'Fax'}]}
                                        onChange={change}
                                    >
                                    </Select>
                                </Col>
                            </Row>
                            <Row className={'mt-3'}>
                                <Col sm={12}>
                                    {state.contact_type === 'mail' && (
                                    <Input
                                        required
                                        name={'mail'}
                                        type={'email'}
                                        label={'E-Mail'}
                                        placeholder='E-Mail'
                                        value={state.mail ? state.mail : ''}
                                        onChange={change} />)}
                                    {state.contact_type === 'fax' && (
                                        <Input
                                            required
                                            name={'fax'}
                                            type={'text'}
                                            label={'Fax'}
                                            placeholder='Fax'
                                            value={state.fax ? state.fax : ''}
                                            onChange={change} />)}
                                    {state.contact_type === 'phone' && (
                                        <Input
                                            required
                                            name={'phone'}
                                            type={'text'}
                                            label={'Telefon'}
                                            placeholder='Telefon'
                                            value={state.phone ? state.phone : ''}
                                            onChange={change} />)}
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                </div>
            </Card>
        </Col>
    </Row>
}

OrderDialogStep.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    name: PropTypes.string.isRequired,
};

OrderDialogStep.defaultProps = {};

export default OrderDialogStep;
import React, { useContext, useEffect } from 'react';
import AuthContext from '../context/auth';
import userServiceFactory from "../_v3/factories/userServiceFactory";

const Logout = ({history}) => {
    const {auth, setAuth} = useContext(AuthContext);

    /**
     * Services
     */
    const userServices = userServiceFactory({auth});

    useEffect(() => {
        userServices.logout(auth.token);
        localStorage.removeItem('auth');
        setAuth(null);
        history.push('/login');
    })

    return <div></div>;
};

export default Logout;

import roleEnum from "@enum/roleEnum";
import {getRoleName} from "@utils/enumName";

import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import lang_de from "@languages/DE_de"

const getScreenResolution = () => {
    return window.innerWidth;
}

i18n.use(initReactI18next).init({
        lng: "de",
        fallbackLng: "de",
        resources: {
            de: {
                translation: lang_de
            }
        },
        interpolation: {
            escapeValue: false
        }
    });

const rand = (min, max) => {
    min =  min ? min : 0;
    max = max ? max : 9999999;

    return Math.floor(Math.random() * max) + min;
};

const getRoleCollection = () => {
    return [
        {key: roleEnum.ROLE_ADMIN, label: getRoleName(roleEnum.ROLE_ADMIN), permission: 'user_create_role_admin_view', description: 'Benutzer erhält Vollständigen Zugriff auf das System', icon: 'protection'},
        {key: roleEnum.ROLE_MANAGER, label: getRoleName(roleEnum.ROLE_MANAGER), permission: 'user_create_role_manager_view', description: 'Benutzer erhält erweiterte Zugriffsrechte ', icon: 'manage'},
        {key: roleEnum.ROLE_PHARMA, label: getRoleName(roleEnum.ROLE_PHARMA), permission: 'user_create_role_pharma_view',description: 'Benutzer erhält Zugriff auf den Arbeitsbereich für Apotheken', icon: 'apothecary'},
        {key: roleEnum.ROLE_DOCTOR, label: getRoleName(roleEnum.ROLE_DOCTOR), permission: 'user_create_role_doctor_view',description: 'Benutzer erhält Zugriff auf den Arbeitsbereich für Ärzte', icon: 'medical_note'},
        {key: roleEnum.ROLE_HOME, label: getRoleName(roleEnum.ROLE_HOME), permission: 'user_create_role_home_view',description: 'Benutzer erhält zugriff auf den Arbeitsbereich für Pflegeheime', icon: 'medical_home'},
        {key: roleEnum.ROLE_DRIVER, label: getRoleName(roleEnum.ROLE_DRIVER), permission: 'user_create_role_driver_view',description: 'Benutzer erhält Zugriff auf den Arbeitsbereich für Fahrer', icon: 'truck'},
        {key: roleEnum.ROLE_ACCOUNTING, label: getRoleName(roleEnum.ROLE_ACCOUNTING), permission: 'user_create_role_accounting_view',description: 'Benutzer erhält Zugriff auf Rechnungen',
            icon: 'wallet',
            help: [
                {title: 'Allgemein', content: 'Diese Nutzergruppe hat Zugriff auf <strong>alle</strong> Rechnungen, aber auf keine anderen MediCharge Bereiche.'},
                {title: 'Pflegeheime', content: 'Wird die Nutzergruppe <strong>Heim</strong> Zusätzlich hinzugefügt, erhält der Nutzer <strong>nur</strong> Zugriff auf die Rechnungen eines Pflegeheimes.'},
                {title: 'Ärzte', content: 'Wird die Nutzergruppe <strong>Arzt</strong> Zusätzlich hinzugefügt, erhält der Nutzer <strong>nur</strong> Zugriff auf die Rechnungen eines Arztes.'},
            ]
        },
    ];
}

const hasPermission = (auth, permission, allowOwn) => {
    permission = permission instanceof Array ? permission : [permission];
    allowOwn = allowOwn === undefined ? true : allowOwn;

    let collection = auth.permissions;

    if(collection instanceof Object && !collection.indexOf) {
        collection = Object.values(collection);
    }

    for(let perm of permission) {
        if(collection.indexOf(perm) !== -1 || (allowOwn && collection.indexOf(`${perm}_own`) !== -1)) {
            return true;
        }
    }

    return false;
}


const intersection = (arr1, arr2) => {
    return arr1.filter(x => arr2.includes(x));
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const toCurrencySymbol = (name) => {
    switch(name) {
        case 'eur':
            return '€'
        default:
    }

    return '';
}

const formatPrice = (price) => {
    return parseFloat(price).toFixed(2).toString().replace('.', ',');
}

const has = (list, values, field) => {
    field = field ? field : 'key';
    values = Array.isArray(values) ? values : [values];

    for(const value of values) {
        if(Array.isArray(list) && list.findIndex((item) => item[field] === value) !== -1) {
            return true;
        }
    }

    return false;
}

const salutation = (value) => {
    switch(value) {
        case 'mr':
            return 'Herr';
        case 'mrs':
            return 'Frau';
        default:
            return '';
    }
}

const isset = (item, path) => {
    const parts = path.trim().split('.');
    for(const element of parts) {
        if(item[element]) {
            item = item[element];
        } else {
            return false;
        }
    }

    return item;
}

const addClass = (classes, otherClasses) => {
    let baseClasses = [];
    if(otherClasses) {
        baseClasses = otherClasses instanceof Array ? otherClasses : [otherClasses];
    }

    const extendClasses = classes instanceof Array ? classes : [classes];

    return baseClasses.concat(extendClasses).join(' ').trim();
}

const translate = (t, key, auth) => {
    let trans = '';

    if(auth.roles.includes('ROLE_HOME')) {
        trans = t(`${key}_home`);
    } else if(auth.roles.includes('ROLE_DOCTOR')) {
        trans = t(`${key}_doctor`);
    }

    return t(trans);
}

const serialize = function(obj, prefix) {
    let str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}

const toHttpQuery = (data) => {
    return serialize(data);
}

export { toHttpQuery, translate, rand, intersection, validateEmail, toCurrencySymbol, formatPrice, has, hasPermission, isset, salutation, getRoleCollection, addClass, getScreenResolution };

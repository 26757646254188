import React from "react";
import {Route, Switch} from "react-router-dom";
import DefaultLayout from "./views/layouts/DefaultLayout";

import routeServiceFactory from "@factories/routeServiceFactory";
import UserList from "@views/lists/UserList";
import UserForm from "@views/forms/user/UserForm";
import UserEdit from "@views/forms/user/UserEdit";
import UserView from "@views/forms/user/UserView";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component

import "./assets/scss/main.scss";
import PickUpList from "@views/lists/PickUpList/PickUpList";
import MedicineList from "@views/lists/MedicineList";
import DoctorOfficeForm from "@views/forms/doctor/DoctorOfficeForm";
import DoctorOfficeList from "@views/lists/DoctorOfficeList";
import DoctorOfficeEdit from "@views/forms/doctor/DoctorOfficeEdit";
import ACL from "@views/ACL";
import DoctorOfficeDetails from "./views/details/DoctorOfficeDetails";
import MeetingList from "@views/lists/MeetingList";
import MeetingForm from "@views/forms/MeetingForm";
import TaskCreateForm from "@views/forms/task/create/TaskCreateform";


const Router = ({...args}) => {
    /**
     * Services
     */
    const routeService = routeServiceFactory();

    return <Switch>
        <Route exact path={routeService.userList()}>
            <DefaultLayout>
                <UserList />
            </DefaultLayout>
        </Route>
        <Route exact path={routeService.userCreate()}>
            <DefaultLayout>
                <UserForm />
            </DefaultLayout>
        </Route>
        <Route exact path={routeService.userEdit()}>
            <DefaultLayout>
                <UserEdit />
            </DefaultLayout>
        </Route>
        <Route exact path={routeService.userView()}>
            <DefaultLayout>
                <UserView />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.medicineList()}>
            <DefaultLayout>
                <MedicineList />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.taskEdit()}>
            <DefaultLayout>
                <TaskCreateForm />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.taskCreate()}>
            <DefaultLayout>
                <TaskCreateForm />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.taskCopy()}>
            <DefaultLayout>
                <TaskCreateForm />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.pickUpList()}>
            <DefaultLayout>
                <PickUpList />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.doctorOfficeCreate()}>
            <DefaultLayout>
                <DoctorOfficeForm />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.doctorOfficeList()}>
            <DefaultLayout>
                <DoctorOfficeList />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.doctorOfficeEdit()}>
            <DefaultLayout>
                <DoctorOfficeEdit />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.doctorOfficeView()}>
            <DefaultLayout>
                <DoctorOfficeDetails />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.meetingList()}>
            <DefaultLayout>
                <MeetingList />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.meetingEdit(':id/:option')}>
            <DefaultLayout>
                <MeetingForm />
            </DefaultLayout>
        </Route>

        <Route exact path={routeService.acl()}>
            <DefaultLayout>
                <ACL />
            </DefaultLayout>
        </Route>
    </Switch>
}

Router.propTypes = {};

Router.defaultProps = {};

export default Router;
const db = {
    '/api/products/doctors': [
        {
            _id: '6183e64d327638002c51u5v3',
            name: 'Arzt ohne Login',
            price: 561.01,
            interval: 'monthly',
            currency: 'eur',
            description: 'Zusätzlichen Arzt zum Verwalten in Ihrer MediCharge Suite',
            iconName: 'person',
            services: [
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {key: 'create-doctor', hidden: true}
            ]
        },
        {
            _id: '6183e64d327638002c51mk9a',
            name: 'Arzt mit Login',
            price: 374.67,
            interval: 'monthly',
            currency: 'eur',
            description: 'Erweitern Sie ihr Möglichkeiten und erhöhen Sie Ihre Produktivität noch mehr.',
            iconName: 'person',
            services: [
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'lorem ipsum doloim'},
                {name: 'Mit Login', key: 'with-login', hidden: true},
                {key: 'create-doctor', hidden: true}
            ]
        }
    ]
}

export default db;
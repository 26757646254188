import React, {useContext, useEffect, useState} from "react";
import Row from "@components/Row";
import Col from "@components/Col";
import Card from "@components/Card";
import Dialog from "@components/Dialog";
import Icon from "@components/Icon";
import Content from "@components/Content";
import PatientDialogStep from "@views/parts/forms/dialog/task/create/PatientDialogStep";
import MedicineDialogStep from "@views/parts/forms/dialog/task/create/MedicineDialogStep";
import OrderDialogStep from "@views/parts/forms/dialog/task/create/OrderDialogStep";
import Button from "@components/Button";
import {useHistory, useParams} from "react-router-dom";
import routeServiceFactory from "@factories/routeServiceFactory";
import AuthContext from "@context/AuthContext";
import patientServiceFactory from "@factories/patientServiceFactory";
import doctorServiceFactory from "@factories/doctorServiceFactory";
import taskServiceFactory from "@factories/taskServiceFactory";
import medicineServiceFactory from "@factories/medicineFactoryService";
import {toast} from "react-toastify";
import taskGroupEnum from "@enum/taskGroupEnum";
import moment from "moment";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const TaskCreateForm = ({...args}) => {
    const { taskId, editId } = useParams();

    /**
     * Context
     */
    const {auth} = useContext(AuthContext);
    const history = useHistory();

    /**
     * States
     */
    const [formData, setFormData] = useState(null);


    /**
     * services
     */
    const routeService = routeServiceFactory()
    const patientService = patientServiceFactory({auth});
    const doctorService = doctorServiceFactory({auth})
    const taskService = taskServiceFactory({auth});
    const medicineService = medicineServiceFactory({auth});

    /**
     * Hooks
     */
    useEffect(() => {
        fetchTask();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const fetchTask = async () => {
        if(editId || taskId) {
            const response = await taskService.get(editId ? editId : taskId);

            let latestDelivery = response.latestDelivery ? moment(response.latestDelivery) : null;
            if(taskId) {
                latestDelivery = null;
            }

            const data = {
                patient: {patient: response.patient},
                order: {
                    id: editId ? editId : null,
                    group: response.group ? response.group : taskGroupEnum.MEDICINE,
                    amount: response.amount ? response.amount : 1,
                    medicine: response.medicine.trim(),
                    dose: response.dose ? response.dose.trim() : '',
                    latestDelivery: latestDelivery,
                    type: response.type,
                    doctor: {_id: response.doctor}}
            }

            setFormData(data);
        }
    }

    const submit = async (form) => {
        await sendData(form);

        history.push(routeService.taskList());
        return false;
    };

    const submitAndAddNew = async (form, jumpToStep) => {
        await sendData(form);
        //window.location.reload();
        jumpToStep(1);
        //history.push(routeService.taskCreate());
        return false;
    }

    const sendData = async (form) => {
        try {
            //create patient
            if(!form.patient.patient._id) {
                form.patient.patient = await patientService.create( {
                    firstname: form.patient.patient.firstname,
                    lastname: form.patient.patient.lastname,
                    dateOfBirth: form.patient.dateOfBirth.format('DD.MM.YYYY'),
                    home: form.patient.home ? form.patient.home : null
                });
            }

            //create doctor
            if((parseInt(form.order.type) === 1 || parseInt(form.order.type) === 4)) {
                if(!form.order.doctor._id) {
                    form.order.doctor = await doctorService.create({
                        firstname: form.order.doctor.firstname,
                        lastname: form.order.doctor.lastname,
                        preferred: form.order.contact_type,
                        mail: form.order[form.order.contact_type]
                    });
                }

                form.order.doctor = form.order.doctor._id
            } else {
                form.order.doctor = null;
            }

            const data = {
                patient: form.patient.patient._id,
                medicine: form.order.medicine.trim(),
                dose: form.order.dose.trim(),
                type: form.order.type,
                amount: form.order.amount,
                group: form.order.group,
                latestDelivery: form.order.latestDelivery ? form.order.latestDelivery.format('DD.MM.YYYY') : '',
                doctor: form.order.doctor
            }

            await medicineService.create({name: form.order.medicine, group: form.order.group});

            if (form.order.id) {
                await taskService.update(form.order.id, data);
                toast.success('Auftrag geändert');
            } else {
                await taskService.create(data);
                toast.success('Auftrag angelegt');
            }

        } catch (err) {
            console.log(err);
            //setError(true);
        }
    }

    return <Content>
        <form noValidate>
            <Row sm={12}>
                <Col sm={12}>
                    <Card>
                        <Dialog onSubmit={submit} submitLabel={'Bestellen/Bestellung abschließen'} data={formData} initialStep={formData ? 2 : 0}>
                            <PatientDialogStep autosubmit={true} title={'Patient'} name={'patient'} icon={<Icon icon={'patient'} />} />
                            <MedicineDialogStep autosubmit={true} title={'Medikamente'} name={'medicine'} icon={<Icon icon={'medicine'} />} />
                            <OrderDialogStep submit={[(<Button onClick={submitAndAddNew}>weitere Bestellungen/Medikamente</Button>)]} title={'Auftrag'} icon={<Icon icon={'tasks'} />} name={'order'} />
                        </Dialog>
                    </Card>
                </Col>
            </Row>
        </form>
    </Content>
}

TaskCreateForm.propTypes = {};

TaskCreateForm.defaultProps = {};

export default TaskCreateForm;
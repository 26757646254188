import factory from "@factories/factory";
const doctorOfficeServiceFactory = (config) => {
    return {
        ...config,
        ...factory,
        v3: true,
        path: 'doctors/offices',
        message: {
            update: 'Arztpraxis wurde aktualisiert'
        }
    }
}

export default doctorOfficeServiceFactory;
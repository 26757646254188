import {getRandomDate, getRandomItemIds, getRandomSingleItem, makeMockxiosId} from "../maxiosUtils";
import taskTypeEnum from "@enum/taskTypeEnum";
import taskStateEnum from "@enum/taskStateEnum";
import eventTypeEnum from "@enum/eventTypeEnum";

const db = (doctors, patients, users) => {
    return {
        '/api/tasks': [
            {
                _id: makeMockxiosId(),
                doctor: getRandomSingleItem(doctors),
                patient: getRandomSingleItem(patients),
                medicine: 'Uran Tabletten',
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                type: taskTypeEnum.doctorOfficeRecipe,
                dose: '200ml/g',
                latestDelivery: getRandomDate(),
                status: taskStateEnum.new,
                events: [
                    {type: eventTypeEnum.message, data: 'Alles OK', createdAt: getRandomDate(), createdFrom: getRandomSingleItem(users), seen: getRandomItemIds(users), files: []},
                    {type: eventTypeEnum.message, data: 'Chipkarte fehlt', createdAt: getRandomDate(), createdFrom: getRandomSingleItem(users), seen: getRandomItemIds(users), files: []},
                    {type: eventTypeEnum.message, data: 'Nicht mehr verfügbar', createdAt: getRandomDate(), createdFrom: getRandomSingleItem(users), seen: getRandomItemIds(users), files: []}
                ],
            },
            {
                _id: makeMockxiosId(),
                doctor: getRandomSingleItem(doctors),
                patient: getRandomSingleItem(patients),
                medicine: 'Benzin',
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                type: taskTypeEnum.order,
                dose: '40cl',
                latestDelivery: getRandomDate(),
                status: taskStateEnum.new,
                events: [
                    {type: eventTypeEnum.message, data: 'Fahrer hatte Unfall', createdAt: getRandomDate(), createdFrom: getRandomSingleItem(users), seen: getRandomItemIds(users), files: []},
                    {type: eventTypeEnum.message, data: 'Lorem iopsumniom', createdAt: getRandomDate(), createdFrom: getRandomSingleItem(users), seen: getRandomItemIds(users), files: []},
                ],
            },
            {
                _id: makeMockxiosId(),
                doctor: getRandomSingleItem(doctors),
                patient: getRandomSingleItem(patients),
                medicine: 'Gammelfleisch',
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                type: taskTypeEnum.selfPurchase,
                dose: '150g',
                latestDelivery: null,
                status: taskStateEnum.new,
                events: [
                    {type: eventTypeEnum.message, data: 'Medikament nicht mehr verfügbar geht ein ähnliches?', createdAt: getRandomDate(), createdFrom: getRandomSingleItem(users), seen: getRandomItemIds(users), files: []},
                ],
            },
            {
                _id: makeMockxiosId(),
                doctor: getRandomSingleItem(doctors),
                patient: getRandomSingleItem(patients),
                medicine: 'Toxinimini',
                createdAt: getRandomDate(),
                modifiedAt: getRandomDate(),
                type: taskTypeEnum.livingAreaRecipe,
                dose: '54ml/g',
                latestDelivery: null,
                status: taskStateEnum.new,
                events: [
                    {type: eventTypeEnum.message, data: 'Bin im Urlaub, wenden sie sich an meine Vertertung', createdAt: getRandomDate(), createdFrom: getRandomSingleItem(users), seen: getRandomItemIds(users), files: []},
                    {type: eventTypeEnum.message, data: 'Brauche schnelle das Medikament neu', createdAt: getRandomDate(), createdFrom: getRandomSingleItem(users), seen: getRandomItemIds(users), files: []},
                    {type: eventTypeEnum.message, data: 'Lixuminumisus', createdAt: getRandomDate(), createdFrom: getRandomSingleItem(users), seen: getRandomItemIds(users), files: []},
                    {type: eventTypeEnum.message, data: 'exorbitantimus', createdAt: getRandomDate(), createdFrom: getRandomSingleItem(users), seen: getRandomItemIds(users), files: []},
                    {type: eventTypeEnum.message, data: 'Patient hat Gigantomanius durch Medikament bekommen.', createdAt: getRandomDate(), createdFrom: getRandomSingleItem(users), seen: getRandomItemIds(users), files: []}
                ],
            }
        ]
    }
}

export default db;
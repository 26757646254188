import request from "@utils/request";
import {toast} from "react-toastify";
import factory from "@factories/factory";


const doctorServiceFactory = (config) => {
    return {
        ...config,
        ...factory,
        path: 'doctors',
        messages: {
            update: 'Arzt wurde erstellt.'
        },
        update: async function (id, data) {
            return await request.put(`/api/doctors/${id}`, data, {
                headers: {'x-auth-token': this.auth.token}
            });
        },
        delete: async function (id) {
            const response = await request.delete(`/api/doctors/${id}`, {
                headers: {'x-auth-token': this.auth.token}
            });

            if(response.data.success) {
                toast.success('Arzt wurde erfolgreich gelöscht.');
            }
        }
    }
}

export default doctorServiceFactory;
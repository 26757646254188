/**
 * Repository
 *      https://github.com/chenjiahan/rodal
 */
import React, {Fragment, useEffect, useState} from "react";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import PropTypes from "prop-types";
import Headline from "./Headline"; //comment in if you add Proptypes to your Component

const Modal = ({contentData, content, button, title, animation, children, size, open, canClose, ...args}) => {

    /**
     * States
     */
    const [state, setState] = useState(false);

    /**
     * HOOKS
     */
    useEffect(() => {
        setState(open);
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * VARS *
     */
    const modalArgs = canClose ? {} : {
        showCloseButton: false,
        closeMaskOnClick: false,
        closeOnEsc: false
    }

    //functions
    const change = () => {
        setState(!state);
    }

    const buildButton = () => {
        if(button) {
            return React.cloneElement(button, {onClick: change});
        } else if(button === null) {
            return <Fragment />
        }

        return <button onClick={change}>show</button>;
    }

    const getSize = () => {
        if(size instanceof Array) {
            return size;
        }

        const browserSize = window.innerWidth;

        if(browserSize >= 1024 && size.lg) {
            return size.lg;
        }

        if(browserSize >= 1440 && size.xl) {
            return size.xl;
        }

        return [1440, 768];
    }

    const getWidth = () => {
        const item = getSize();
        return item[0];
    }

    const getHeight = () => {
        const item = getSize();
        return item[1] === -1 ? 'auto' : item[1] ;
    }

    return <Fragment>
        {buildButton()}
        <Rodal width={getWidth()} height={getHeight()} animation={animation} className={`modal-content-box${getHeight() === 'auto' ? ' modal-margin': ''}`} visible={state} onClose={change} {...modalArgs}>
            {title && title.length > 0 && (
                <Headline design={'line'} size={'h4'}>{title}</Headline>
            )}
            <div className={'body'}>
                {children}
                {state && typeof content === "function" && content(contentData, change)}
            </div>
        </Rodal>
    </Fragment>
}

Modal.propTypes = {
    animation: PropTypes.oneOf(['zoom', 'flip']),
    open: PropTypes.bool,
    canClose: PropTypes.bool
};

Modal.defaultProps = {
    animation: 'flip',
    button: false,
    size: [400, 240],
    open: false,
    title: '',
    canClose: true
};

export default Modal;
import factory from "@factories/factory";
import request from "@utils/request";

const documentServiceFactory = (config) => {
    return {
        ...factory,
        ...config,
        path: 'documents',
        get: async function() {
            const response = await request.get(`/api/users/${this.auth.user}/profile/documents`, {
                headers: {'x-auth-token': this.auth.token}
            });

            return response.data;
        }
    }
}

export default documentServiceFactory;
import React, {Fragment} from "react";
import Col from "@components/Col";
import Row from "@components/Row";
import PropTypes from "prop-types";
import {formData} from "@utils/forms";


const Home = ({entity, ...args}) => {
    return <Fragment>
        <Row>
            <Col sm={3} omd={3}><h5 className={'text-uppercase mt-1 mb-4'} style={{padding: 0, marginLeft: 0}}>Pflegeeinrichtung</h5></Col>
        </Row>
        <Row className={'mt-3'}>
            <Col sm={3} omd={3}><strong>Name</strong></Col>
            <Col sm={9} md={3}>{formData(entity.name)}</Col>
        </Row>
        <Row className={'mt-3'}>
            <Col sm={3} omd={3}><strong>Leiter</strong></Col>
            <Col sm={9} md={3}>{formData(`${entity.firstname ? entity.firstname : ''} ${entity.lastname ? entity.lastname : ''}`.trim())}</Col>
        </Row>
        <h5 className={'text-center text-uppercase mt-5 mb-4'}>Adresse</h5>
        <Row className={'mt-3'}>
            <Col sm={3} omd={3}><strong>Straße</strong></Col>
            <Col sm={9} md={3}>{formData(entity.street)}</Col>
        </Row>
        <Row className={'mt-3'}>
            <Col sm={3} omd={3}><strong>Postleitzahl</strong></Col>
            <Col sm={9} md={3}>{formData(entity.postalCode)}</Col>
        </Row>
        <Row className={'mt-3'}>
            <Col sm={3} omd={3}><strong>Stadt</strong></Col>
            <Col sm={9} md={3}>{formData(entity.city)}</Col>
        </Row>
    </Fragment>
}

Home.propTypes = {
    entity: PropTypes.any.isRequired
};

Home.defaultProps = {};

export default Home;
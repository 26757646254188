import React, {Fragment, useContext, useEffect, useState} from "react";
import Button from "@components/Button";
import FileDownload from "js-file-download";
import moment from "moment";
import taskServiceFactory from "@factories/taskServiceFactory";
import AuthContext from "@context/AuthContext";
import Icon from "@components/Icon";
import {hasPermission} from "@utils/utils";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const ActionBar = ({observer, ...args}) => {
    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [hasSelection, setHasSelection] = useState(false);

    /**
     * Service
     */
    const taskService = taskServiceFactory({auth});

    useEffect(() => {
        if(observer) {
            observer.register({
                update: (args) => {
                    setHasSelection(args);
                },
                key: 'has_selection'
            })
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const download = async () => {
        const data = observer.collect();

        const response = await taskService.downloadPickup(data);

        FileDownload(response, `abholauftrag_${moment().format('YYYYMMDD_HHmmss')}.pdf`);
    }

    const seen = async () => {
        await taskService.seen(observer.collect());
    }

    return <Fragment>
        {hasPermission(auth, 'pickup_create_view') && <Button icon={<Icon icon={'download'} />} className={'mobile-fullwidth'} onClick={download}>Abholauftag Erzeugen</Button>}
        {hasPermission(auth, 'pickup_seen_view') && <Button icon={<Icon icon={'eye'} />} className={'mobile-fullwidth mt-xs-3'} onClick={seen}>{hasSelection && 'Ausgewählte'}{!hasSelection && 'Alle'} Fahraufträge als gelesen markieren</Button>}
    </Fragment>
}

ActionBar.propTypes = {};

ActionBar.defaultProps = {};

export default ActionBar;
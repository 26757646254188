/* jshint esversion: 8 */
import {Checkbox, Collapse, IconButton, Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import React, {Fragment, useContext, useRef, useState} from 'react';
import {BsChevronDown, BsChevronUp, FaTruckLoading, RiArrowGoBackFill} from 'react-icons/all';
import { Td, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';
import ActionLoader from "./ActionLoader";
import EventList from "./EventList";

const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    truck: {
        color: '#ff5722',
    },
    back: {
        color: '#009688',
    },
    note: {
        color: 'orange'
    },
    tbodyTr: {
        '& td': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            padding: '16px',
            // eslint-disable-next-line no-useless-computed-key
            ['@media (max-width:640px)']: {
                // eslint-disable-line no-useless-computed-key
                padding: '0',
            },
        },
    },
});

const PickupRow = ({ task, selectedTasks, setSelectedTasks, history, fetch }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONSTANT
     */
    const types = {
        1: 'Auftrag',
        2: 'Selbstkauf',
        3: 'Rezept in Wohnbereich',
        4: 'Rezept in Arztpraxis',
    };

    /**
     * CONTEXT
     */
    const [open, setOpen] = useState(false);
    const { auth, setAuth } = useContext(AuthContext);
    const [maxCount, setMaxCount] = useState(task.events.length);

    const maxCountRef = useRef();
    maxCountRef.current = {data: maxCount, set: setMaxCount};

    /**
     * CONTROLS
     */
    const selectTask = (e) => {
        if (e.target.checked) {
            if (!selectedTasks.includes(e.target.value)) {
                setSelectedTasks([e.target.value, ...selectedTasks]);
            }
        } else {
            setSelectedTasks(selectedTasks.filter((item) => item !== e.target.value));
        }
    };

    const sendPickedUp = async () => {
        try {
            let t = { ...task };
            t.status = 'finished';
            t.latestDelivery = moment(t.latestDelivery).format('DD.MM.YYYY');
            await axios.put(`/api/tasks/${task._id}`, t, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
            fetch();
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const sendBack = async () => {
        try {
            let t = { ...task };
            t.status = 'new';
            t.latestDelivery = moment(t.latestDelivery).format('DD.MM.YYYY');
            await axios.put(`/api/tasks/${task._id}`, t, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
            fetch();
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    return (
        <Fragment>
            <Tr className={classes.tbodyTr}>
                <Td>
                    <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
                        {open ? <BsChevronUp/> : <BsChevronDown/>}
                    </IconButton>
                </Td>
                <Td>
                    <Checkbox
                        checked={selectedTasks.includes(task._id)}
                        value={task._id}
                        onChange={selectTask}
                        color='primary'
                    />
                </Td>
                <Td>
                    {task.patient.firstname} {task.patient.lastname} (
                    {moment(task.patient.dateOfBirth).format('DD.MM.YYYY')})
                </Td>
                <Td>
                    {task.patient.home && task.patient.home.name}
                    {task.patient.homeGroup && ` / ${task.patient.homeGroup.name}`}
                </Td>
                <Td>{task.medicine}</Td>
                <Td>{types[task.type]}</Td>
                <Td>
                    {(task.type === 1 || task.type === 4) &&
                        task.doctor ? `Arztpraxis ${task.doctor.title && task.doctor.title} ${
                            task.doctor.firstname && task.doctor.firstname
                        } ${task.doctor.lastname}` : ''}
                </Td>
                <Td align='right' style={{minWidth: 110}}>
                    <Tooltip title='Rezept abgeholt'>
                        <ActionLoader color={'#ff5722'} onClick={sendPickedUp} size='small' className={classes.truck}>
                            <FaTruckLoading />
                        </ActionLoader>
                    </Tooltip>
                    <Tooltip title='Zurück geben'>
                        <ActionLoader color={'#009688'} onClick={sendBack} size='small' className={classes.back}>
                            <RiArrowGoBackFill />
                        </ActionLoader>
                    </Tooltip>
                </Td>
            </Tr>
            <Tr className={[classes.tbodyTr, open ? classes.tbodyTrB : ''].join(' ')}>
                <Td style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <EventList task={task}  eventTypes={['message']} parentContainer={maxCountRef} allowAttachments={false}/>
                    </Collapse>
                </Td>
            </Tr>
        </Fragment>
    );
};

export default PickupRow;

/* jshint esversion: 8 */
import React, {Fragment, useEffect, useState} from "react";
import {rand} from "@utils/utils";
import TableRow from "@components/Table/TableRow";
import Checkbox from "@components/Checkbox";
import Observer from "@utils/observer";
import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component

import {addClass} from "@utils/utils";

const TableRowGroup = ({collapseLabel, resolution, updateCollapse, collapsedRows, offset, id, service, observer, filter, onRowHighlight, highlightColumns, row, columns, onCollapseContent, rowCheckAble, rowCollapseAble}) => {
    /**
     * Vars
     */
    const items = row.items ? row.items : [row];
    const title = row.items ? row.title : false;
    const name = row.items ? row.name : false;

    /**
     * State
     */
    const [selectionObserver] = useState(new Observer((args) => {
        observer.notify(args);
    }));

    /**
     * Hooks
     */
    useEffect(() => {
        if(observer) {
            observer.register({
                update: (args) => {
                    selectionObserver.pushUpdate(args);
                    },
                collect: () => { return selectionObserver.collect(); },
                key: `table-row-group-${id}`,
                unique: true
            });
        }
    }, [observer]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const checkAll = (e) => {
        selectionObserver.pushUpdate(e.target.checked);
    };

    const compare = ( a, b ) => {
        if ( a.order < b.order ){
            return -1;
        }
        if ( a.order > b.order ){
            return 1;
        }
        return 0;
    }

    columns = resolution <= 425 && name && title ? [...columns, {name: 'group-name', title: name, render: () => title, order: columns.length * 10 - 15}] : columns

    columns.sort( compare );

    return <Fragment>
        {resolution > 425 && title && (<tr className={addClass(['table-row-group'])}>
            <td colSpan={columns.length}>
                <Checkbox className={'cell-checkbox'} id={id} onChange={checkAll} label={''} value={''} name={'selection'} />
                <span className={'row-group-title'}>{title}</span>
            </td>
        </tr>)}
        {items.map((item, index) => (
            <TableRow
                collapseLabel={collapseLabel}
                resolution={resolution}
                updateCollapse={updateCollapse}
                collapsed={collapsedRows.indexOf(item._id) !== -1}
                subTableOffset={offset}
                service={service}
                offset={!!title}
                filter={filter}
                id={`${id}-${index}`}
                isLastGroupRow={index === items.length -1}
                key={`row-${rand()}-${rand()}`}
                onRowHighlight={onRowHighlight}
                highlightColumns={highlightColumns}
                row={item}
                observer={selectionObserver}
                columns={columns}
                onCollapseContent={onCollapseContent}
                rowCheckAble={rowCheckAble}
                rowCollapseAble={rowCollapseAble}/>
        ))}
    </Fragment>
}

TableRowGroup.propTypes = {
    resolution: PropTypes.number
};

TableRowGroup.defaultProps = {
    highlightColumns: [0],
    resolution: 2048
};

export default TableRowGroup;
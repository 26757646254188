import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Grid, IconButton,
    Tooltip,
    Typography,
} from '@material-ui/core';
import axios from 'axios';
import FileDownload from 'js-file-download';
import moment from 'moment';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {TiDocumentText} from 'react-icons/ti';
import {Link, useLocation} from 'react-router-dom';
import PickupTable from '../components/PickupTable';
import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';
import {useRespTableThemeStyles} from "../utils/respTableTheme";
import {filterThemeStyles} from "../utils/filter";
import {FiFilter, MdOutlineMarkEmailRead} from "react-icons/all";
import {serialize} from "../utils/filter";
import PickUpFilter from "./filter/PickUpFilter";
import Loader from "../components/Loader";

const PickUpList = ({history}) => {
    /**
     * CSS
     */
    const classes = {
        ...useRespTableThemeStyles(),
        ...filterThemeStyles()
    };

    /**
     * CONTEXT
     */
    const {auth, setAuth} = useContext(AuthContext);
    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);

    /**
     * STATES
     */
    const [tasks, setTasks] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [filter, setFilter] = useState({status: 'pickup', text: '', home: '', doctor: '', notSeen: !!query.get('filter[notSeen]')  ? 1 : 0  });
    const [filterShow, showFilter] = useState(!!query.get('filter[notSeen]'));
    const [loading, setLoading] = useState(true);

    /**
     * QUERIES
     */
    const fetchTasks = async () => {
        try {
            const query = serialize({filter:filter, limit: 999});
            const response = await axios.get(`/api/tasks?${query}`, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });

            const result = {};
            const homeCollection = [];
            const doctorCollection = [];

            if(response.data.data) {
                for(const index in response.data.data) {
                    const item = response.data.data[index];
                    const id = item.doctor ? item.doctor._id : item.patient.home._id;
                    if(!result[id]) {
                        let label = '';
                        if(item.type === 3) {
                            label = item.patient && item.patient.home ? `Wohnheim ${item.patient.home.name}` : '';
                            if(item.patient && item.patient.home) {
                                homeCollection.push(item.patient.home);
                            }
                        } else if(item.type === 1 || item.type === 4) {
                                label = item.doctor ? `Arztpraxis ${item.doctor.title && item.doctor.title} ${
                                    item.doctor.firstname && item.doctor.firstname
                                } ${item.doctor.lastname}` : '';

                                if(item.doctor) {
                                    doctorCollection.push(item.doctor);
                                }
                        }

                        result[id] = {
                            label: label,
                            id: id,
                            tasks: []
                        };
                    }

                    result[id].tasks.push(item);
                }
            }

            setTasks(Object.values(result));

            /**
             * We want only set homes and doctors on the initial load task.
             * because in the filtered task list we has not all homes and doctors
             */
            if(!filterData.homes) {
                doctorCollection.sort(( a, b ) => {
                    if ( a.lastname < b.lastname ){
                        return -1;
                    }
                    if ( a.lastname > b.lastname ){
                        return 1;
                    }
                    return 0;
                });
                setFilterData({homes: homeCollection, doctors: doctorCollection});
            }

            setLoading(false);

            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    /**
     * CONTROLS
     */
    const changeFilter = (e) => {
        setFilter({...filter, [e.target.name]: e.target.value});
    };

    const download = async () => {
        try {
            setDownloading(true);
            const response = await axios.post(
                `/api/tasks/pickup`,
                {
                    tasks: selectedTasks,
                },
                {
                    headers: {
                        'x-auth-token': auth.token,
                    },
                    responseType: 'blob',
                }
            );
            FileDownload(response.data, `abholauftrag_${moment().format('YYYYMMDD_HHmmss')}.pdf`);
            setDownloading(false);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    /**
     * HOOKS
     */

    useEffect(() => {
        setLoading(true);
        fetchTasks();
        setSelectedTasks([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    /**
     * Function
     */
    const setSelectedAsRead = async () => {
        for (let ii = 0; ii < selectedTasks.length; ii++) {
            const id = selectedTasks[ii];
            await axios.put(`/api/tasks/${id}/seen`, {}, {
                headers: {'x-auth-token': auth.token},
            });
        }
    }

    const setAllAsRead = async () => {
       try {
           await axios.put(`/api/statistics/pickup_read_all`, {}, {
               headers: {'x-auth-token': auth.token},
           });
       } catch (err) {
           handleErrors(err, setAuth, history);
       }
    }

    console.log(tasks);

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Typography color='textPrimary'>Abholaufträge</Typography>
            </Breadcrumbs>
            <Tooltip title='Filter anzeigen/verbergen' className={classes.filterButton}>
                <IconButton onClick={() => showFilter(!filterShow)} size='small'>
                    <FiFilter/>
                </IconButton>
            </Tooltip>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography component='h1' color='textSecondary' gutterBottom>
                                Abholaufträge
                            </Typography>
                            {filterShow && (
                                <Grid item xs={12} className='filterWidget'>
                                    <PickUpFilter filter={filter} changeFilter={changeFilter} filterData={filterData} />
                                </Grid>
                            )}
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2}>
                                    <Button
                                        variant='contained'
                                        endIcon={<TiDocumentText/>}
                                        color='primary'
                                        disabled={downloading}
                                        onClick={download}
                                    >
                                        Abholauftrag erzeugen
                                    </Button>
                                </Grid>
                                {selectedTasks.length === 0 && (
                                    <Grid xl={4} lg={selectedTasks.length > 0 ? 6 : 5} md={6} sm={12} xs={12} item>
                                        <Button
                                            className={classes.markAsRead}
                                            color='primary'
                                            endIcon={<MdOutlineMarkEmailRead/>}
                                            fullWidth
                                            onClick={setAllAsRead}
                                            type='submit'
                                            variant='contained'
                                        >
                                            alle Fahraufträge als Gelesen markieren
                                        </Button>
                                    </Grid>
                                )}
                                {selectedTasks.length > 0 && (
                                    <Grid xl={4} lg={selectedTasks.length > 0 ? 6 : 5} md={6} sm={12} xs={12} item>
                                        <Button
                                            className={classes.markAsRead}
                                            color='primary'
                                            endIcon={<MdOutlineMarkEmailRead/>}
                                            fullWidth
                                            onClick={setSelectedAsRead}
                                            type='submit'
                                            variant='contained'
                                        >
                                            ausgewählte Fahraufträge als Gelesen markieren
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                            {!loading && (
                            <PickupTable
                                selectedTasks={selectedTasks}
                                setSelectedTasks={setSelectedTasks}
                                tasks={tasks}
                                fetch={fetchTasks}
                            />)}
                            {loading && (<Loader center size={'large'} color={'#5588a3'}/>)}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default PickUpList;

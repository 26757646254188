/* jshint esversion: 8 */
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {FiFilter, HiRefresh, MdAdd, MdOutlineMarkEmailRead} from 'react-icons/all';
import {TiDocumentText} from 'react-icons/ti';
import {Link, useLocation} from 'react-router-dom';
import {toast} from "react-toastify";
import McPagination from "../components/Pagination";
import TaskTable from '../components/TaskTable';
import AuthContext from '../context/auth';
import SocketContext from '../context/socket';
import TaskContext from '../context/task';
import handleErrors from '../utils/handleErrors';
import Loader from "../components/Loader";
import PropTypes from "prop-types";
import {hasPermission, toHttpQuery} from "@utils/utils";
import TaskFilter from "./filter/TaskFilter"; //comment in if you add Proptypes to your Component
import McModal from "../components/Modal";
import LinkWithContractRequirements from "../components/LinkWithContractRequirements";
import routeServiceFactory from "@factories/routeServiceFactory";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    filter: {
        position: 'relative',
        '& label': {
            left: '10px',
            top: '8px',
        }
    },
    markAsRead: {
        ['@media (max-width:1001px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: '-8px',
        }
    },
    refreshButton: {
        backgroundColor: '#4caf50',
        '&:hover, &:focus': {
            backgroundColor: '#388e3c'
        },
        ['@media (max-width:1001px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: '-8px',
        }
    },
    newOrderButton: {
        ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: '-8px',
        },

        '& svg': {
            margin: '-3px 0 0 5px !important'
        },

        '&.btn-warning': {
            backgroundColor: '#fbbc06',

            '&:hover': {
                backgroundColor: '#956f02',
            }
        }
    },
    filterButton: {
        '&': {
            display: 'inline-block',
            position: 'absolute',
            right: '24px',
            top: '72px',
            ['@media (max-width:601px)']: { // eslint-disable-line no-useless-computed-key
                right: '10px',
                top: '64px',
            }
        },
    },
    missingAddress: {
      textAlign: "center",
        margin: '0 0 15px 0'
    },
    filterChkLabel: {
        display: "inline-block",
        padding: "12px 0"
    },
    mobileFullWidth: {
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            textIndent: '0 !important',
            width: '100% !important',
            '& .link-title': {
                position: 'static',
                textIndent: '0 !important',
            }
        }
    }
}));

const TaskList = ({filterVisible, history, hidePatientName}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONTEXT
     */
    const {auth, setAuth} = useContext(AuthContext);
    const {setSelectedTasks} = useContext(TaskContext);
    const {socket} = useContext(SocketContext);
    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);

    /**
     * STATES
     */
    const [tasks, setTasks] = useState([]);
    const [filter, setFilter] = useState({
        latestDelivery: 0,
        status: 'new',
        text: !!query.get('display[task]') ? `id:${query.get('display[task]')}` : '',
        doctor: '',
        medicine: '',
        home: '',
        notSeen: !!query.get('filter[notSeen]') ? 1 : 0,
        notSeenEvents: !!query.get('filter[notSeenEvents]') ? 1 : 0
    });

    const [hasSelection, setHasSelection] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [filterShow, showFilter] = useState(filterVisible);
    const [limit, setLimit] = useState(50);
    const [displayMissingAddress, setDisplayMissingAddress] = useState(false);
    const [eventCount, setEventCount] = useState(null);


    const [loading, setLoading] = useState(true);


    const mappedChildFunctionReference = React.useRef();
    mappedChildFunctionReference.current = {
        count: {},
        parent: {hasSelection: {data: hasSelection, set: setHasSelection}}
    };

    /**
     * Services
     */
    const routeService = routeServiceFactory();

    // if(auth.roles.includes('ROLE_DOCTOR')) {
    //     history.push('/');
    // }

    /**
     * QUERIES
     */
    const fetchTasks = async (page, olimit) => {
        try {
            const query = serialize({filter: filter});
            const response = await axios.get(
                `/api/tasks?${query}&limit=${olimit || limit}&offset=${(page - 1) * limit}`,
                {
                    headers: {
                        'x-auth-token': auth.token,
                    },
                }
            );

            setLoading(false);
            setTasks(response.data.data);
            setRefresh(false);

            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const serialize = (obj, prefix) => {
        let str = [],
            p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                let k = prefix ? prefix + "[" + p + "]" : p,
                    v = obj[p];

                if (v.length === 0) {
                    continue;
                }
                str.push((typeof v === "object") ?
                    serialize(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        return str.join("&");
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        //fetchTasks();
        setSelectedTasks([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (socket !== null) {
            socket.on(`newtask`, function (taskId) {
                setRefresh(true);
            });
        }

        return () => {
            if (socket !== null) {
                socket.off(`newtask`);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    /**
     * Hooks
     */
    useEffect(() => {
        const query = new URLSearchParams(window.location.search.replace('?', ''));
        const taskId = query.get('display[task]');
        const elem = document.getElementById(taskId);
        if(elem) {
            elem.scrollIntoView();
        }
    }, [tasks, filter]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        countEvents();
    }, [tasks]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * CONTROLS
     */

    const changeFilter = (update) => {
            setFilter({...filter, ...update});
    }

    const setTaskAsSeen = async () => {
        try {
            await axios.put(`/api/statistics/task_read_all`, {}, {
                headers: {'x-auth-token': auth.token},
            });

            await axios.put(`/api/statistics/event_read_all`, {}, {
                headers: {'x-auth-token': auth.token},
            });
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    }

    const countEvents = async () => {
        try {
            const ids = tasks.map(item => item._id);
            if(ids.length > 0) {
                const response = await axios.get(`/api/events/meta?${toHttpQuery({tasks: ids})}`, {
                    headers: {'x-auth-token': auth.token},
                });

                setEventCount(response.data);
            }
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    }

    const setAllAsRead = async () => {
        let collection = document.querySelectorAll('.task-chk input');

        for (let ii = 0; ii < collection.length; ii++) {
            if (collection[ii].checked) {
                const id = collection[ii].value;
                try {
                    await axios.put(`/api/tasks/${id}/seen`, {}, {
                        headers: {'x-auth-token': auth.token},
                    });

                    await axios.put(
                        `/api/tasks/${id}/events`,
                        {
                            seen: true,
                        },
                        {
                            headers: {
                                'x-auth-token': auth.token,
                            },
                        }
                    );
                    mappedChildFunctionReference.current.count[id].set(0);
                } catch (err) {
                    handleErrors(err, setAuth);
                }
            }
        }
        toast.success('Ausgewählte Aufträge wurden auf gelesen gesetzt.');
        document.getElementById('check-all-tasks').click();
    };

    const closeMissingAddressModal = () => {
        setDisplayMissingAddress(!displayMissingAddress);
    }

    const getLinkToForm = () => {
        if(auth.roles.includes('ROLE_PHARMA')) {
            return `/einstellungen`;
        } else if(auth.home) {
            return `/einrichtung/${auth.home._id}`
        }
    }

    const hasValidAddress = (e) => {
        if(hasPermission(auth, 'document_create')) {
            return true;
        }

        e.preventDefault();
        setDisplayMissingAddress(!displayMissingAddress);
        return false;
    }

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Typography color='textPrimary'>Aufträge</Typography>
            </Breadcrumbs>
            <Tooltip title='Filter anzeigen/verbergen' className={classes.filterButton}>
                <IconButton onClick={() => showFilter(!filterShow)} size='small'>
                    <FiFilter/>
                </IconButton>
            </Tooltip>
            <Grid container spacing={2}>
                {filterShow && (
                <Grid item xs={12} className='filterWidget'>
                    <TaskFilter onChange={changeFilter}/>
                </Grid>
                )}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography component='h1' color='textSecondary' gutterBottom>
                                Aufträge
                            </Typography>
                            <Grid container justifyContent='flex-start' spacing={2}>
                                <Grid xl={hasSelection ? 4 : 3} lg={hasSelection ? 6 : 4} md={hasSelection ? 6 : 5}
                                      sm={12} xs={12} item>
                                    {hasPermission(auth, 'task_action_create_recipe_requirement_view') && (
                                        <Button
                                            color='primary'
                                            component={Link}
                                            endIcon={<TiDocumentText/>}
                                            fullWidth
                                            onClick={hasValidAddress}
                                            to={`/anforderungen/erzeugen?state=${filter.status}`}
                                            variant='contained'
                                        >
                                            {hasSelection ? 'ausgewählte Rezeptanforderungen erzeugen' : 'Rezeptanforderungen erzeugen'}
                                        </Button>
                                    )}
                                </Grid>
                                {!hasSelection && (
                                    <Grid xl={3} lg={4} md={4} sm={12} xs={12} item>
                                        <Button
                                            className={classes.markAsRead}
                                            color='primary'
                                            endIcon={<MdOutlineMarkEmailRead/>}
                                            fullWidth
                                            onClick={setTaskAsSeen}
                                            type='submit'
                                            variant='contained'
                                        >
                                            Alle Aufträge als Gelesen markieren
                                        </Button>
                                    </Grid>
                                )}
                                {auth.permissions.includes('task_seen_view') && hasSelection && (
                                    <Grid xl={4} lg={hasSelection ? 6 : 5} md={6} sm={12} xs={12} item>
                                        <Button
                                            className={classes.markAsRead}
                                            color='primary'
                                            endIcon={<MdOutlineMarkEmailRead/>}
                                            fullWidth
                                            onClick={setAllAsRead}
                                            type='submit'
                                            variant='contained'
                                        >
                                            ausgewählte Aufträge als Gelesen markieren
                                        </Button>
                                    </Grid>
                                )}
                                {auth.permissions.includes('task_refresh_view') && (
                                <Grid xl={2} lg={2} md={3} sm={6} xs={12} item>
                                    <Button
                                        className={classes.refreshButton}
                                        color='primary'
                                        disabled={!refresh}
                                        endIcon={<HiRefresh/>}
                                        fullWidth
                                        onClick={e => fetchTasks(1, limit)}
                                        variant='contained'
                                    >
                                        Aktualisieren
                                    </Button>
                                </Grid>)}
                                <Grid xl={2} lg={3} md={3} sm={6} xs={12} item style={{marginLeft: 'auto'}}>
                                    <LinkWithContractRequirements
                                        message={'required_contract_message'}
                                        type={'button'}
                                        permission={'task_create_view'}
                                        className={`${classes.mobileFullWidth} MuiButtonBase-root MuiButton-root MuiButton-contained ${classes.contractRequiredButton} ${classes.newOrderButton} MuiButton-containedPrimary MuiButton-fullWidth`}
                                        to={routeService.taskCreate()}>
                                        Neuer Auftrag<MdAdd/>
                                    </LinkWithContractRequirements>
                                </Grid>
                            </Grid>
                            {!loading && (<Fragment>
                                <TaskTable auth={auth} hidePatientName={hidePatientName} eventCount={eventCount} tasks={tasks} fetch={fetchTasks} parentContainer={mappedChildFunctionReference}/>
                            </Fragment>)}
                            <McPagination fetch={fetchTasks} filter={filter} setLimit={setLimit}/>
                            {loading && (<Loader center size={'large'} color={'#5588a3'}/>)}
                        </CardContent>
                        <McModal open={displayMissingAddress} handleClose={closeMissingAddressModal} size={'small'}>
                            <div className={classes.missingAddress}>
                                <h3>Fehlende Absender Daten</h3>
                                Bitte vervollständigen Sie ihre Unternehmensdaten. <br />
                                Ihre Daten könne sie <strong><Link to={getLinkToForm()}>hier</Link></strong> vervollständigen
                            </div>
                        </McModal>
                    </Card>
                </Grid>
            </Grid>
        </Fragment>
    );
};

TaskList.propTypes = {
    hidePatientName: PropTypes.bool
};

TaskList.defaultProps = {
    hidePatientName: false,
    filterVisible: true
};

export default TaskList;

import { toast } from 'react-toastify';

// replacement for $.isEmptyObject()
export const isEmptyObject = ( obj ) => {
    let name;
    for (name in obj) {
        return false;
    }
    return true;
};

export const copyToClipboard = (entity) => {

    if(typeof entity !== 'string') {
        if(entity.target) {
            entity = entity.target;
        }

        if (entity.hasAttribute('data-entity')) {
            entity = entity.getAttribute('data-entity');
        } else if(entity.value) {
            entity.select();
            entity.setSelectionRange(0, 99999);
            entity = entity.value
        } else {
            entity = '';
        }
    }

    if(entity.length > 0) {
        navigator.clipboard.writeText(entity);
        toast.success(`"${entity}" in Zwischenspeicher kopiert.`);
    }
}

export const checkRequiredFields = (validation, requiredFields, data) => {
    let newValidation = Object.assign({
        error: false,
        errorMessage: [],
    }, validation);
    for(let requiredField in requiredFields) {
        if(!data[requiredField] || data[requiredField] === '') {
            newValidation['error'] = true;
            newValidation['errorMessage'] = {
                ...newValidation.errorMessage,
                [requiredField]: requiredFields[requiredField] + " muss ausgefüllt werden."
            };
        }
        else {
            let errorMessage = newValidation.errorMessage;
            delete (errorMessage[requiredField]);
            newValidation['errorMessage'] = {
                ...newValidation.errorMessage,
            };
            newValidation['error'] = !isEmptyObject(errorMessage);
        }
    }
    newValidation['validated'] = true;
    return newValidation;
};

export const inputChange = (e, data) => {
    let newState = Object.assign({}, data);
    switch(e.target.type) {
        case 'checkbox':
            newState[e.target.name] = e.target.checked;
            break;
        case 'file':
            newState[e.target.name] = e.target.files[0];
            break;
        case 'radio':
        default:
            newState[e.target.name] = e.target.value;
            break;
    }
    return newState;
};

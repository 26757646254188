import React from "react";
import {makeStyles} from "@material-ui/core/styles";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component

const useStyles = makeStyles({
    new: {
        background: '#5588a3',
        border: '1px solid #5588a3',
        color: '#fff',
        padding: '2px 5px',
        fontSize: 10,
        borderRadius: 5,
        margin: '0 0 0 10px',
        cursor: 'default',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
    }
});

const New = ({...args}) => {

    /**
     * CSS
     */
    const classes = useStyles();

    return <span className={classes.new}>neu</span>
}

New.propTypes = {};

New.defaultProps = {};

export default New;
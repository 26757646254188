/* jshint esversion: 8 */
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent, Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableContainer,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import axios from 'axios';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {AiOutlineDelete} from 'react-icons/ai';
import {BiSortAZ, BiSortZA} from 'react-icons/bi';
import {FiEdit2, FiFilter} from 'react-icons/fi';
import {FaTrashRestore, MdAdd, MdOutlineMarkEmailRead} from 'react-icons/all';
import {Link, useLocation} from 'react-router-dom';
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';
import McPagination from "../components/Pagination";
import {filterThemeStyles, serialize} from "../utils/filter";
import {useRespTableThemeStyles} from "../utils/respTableTheme";
import {createUriSortParameters, initSorting} from "../utils/sorting";
import TCell from "../components/TCell";
import Loader from "../components/Loader";
import New from "../components/new";

const UserList = ({history}) => {
    /**
     * CSS
     */
    const classes = {
        ...useRespTableThemeStyles(),
        ...filterThemeStyles()
    };

    const {auth, setAuth} = useContext(AuthContext);
    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);

    /**
     * State
     */
    const [state, setState] = useState([]);
    const [sort, setSort] = useState({});
    const [filter, setFilter] = useState({text: '', home: '', roles: [], notSeen: !!query.get('filter[notSeen]')});
    const [filterShow, showFilter] = useState(!!query.get('filter[notSeen]'));
    const [homes, setHomes] = useState([]);
    const [loading, setLoading] = useState(true);


    const fetchData = async (page, limit) => {
        try {
            setLoading(true);
            page = page ? page : 1;
            const filterQuery = serialize({filter: filter});
            let response = await axios.get(`/api/users?${filterQuery}&${createUriSortParameters(sort)}&limit=${limit}&offset=${(page - 1) * limit}`, {headers: {'x-auth-token': auth.token}});
            setState(response.data.data);

            setLoading(false);
            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    const fetchHomes = async () => {
        try {
            const response = await axios.get(`/api/homes?limit=99999`, {
                headers: {'x-auth-token': auth.token},
            });
            setHomes(response.data.data);

            return response.data.count;
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchHomes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * CONTROLS
     */
    const changeFilter = (e) => {
        setFilter({...filter, [e.target.name]: e.target.value});
    };

    const rolesToString = (roles) => {
        let newRoles = [];
        for(let index in roles) {
            let role = roles[index];
            switch (role) {
                case 'ROLE_ADMIN':
                    newRoles.push('Administrator');
                    break;
                case 'ROLE_MANAGER':
                    newRoles.push('Manager');
                    break;
                case 'ROLE_HOME':
                    newRoles.push('Heim');
                    break;
                case 'ROLE_PHARMA':
                    newRoles.push('Apotheke');
                    break;
                case 'ROLE_DOCTOR':
                    newRoles.push('Arztpraxis');
                    break;
                case 'ROLE_DRIVER':
                    newRoles.push('Fahrer');
                    break;
                default:
                    break;
            }
        }
        return newRoles.join(', ');
    };

    /**
     * Functions
     */
    const changeFilterCheckbox = (e) => {
        if (e.target.checked) {
            setFilter({...filter, [e.target.name]: 1});
        } else {
            setFilter({...filter, [e.target.name]: 0});
        }
    }

    const setAllAsRead = async () => {
        try {
            await axios.put(`/api/statistics/home_read_all`, {}, {
                headers: {'x-auth-token': auth.token},
            });
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    }

    initSorting(sort, setSort, filter, setFilter);

    return (
        <Fragment>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link to='/'>Medicharge</Link>
                <Typography color='textPrimary'>Nutzer</Typography>
            </Breadcrumbs>
            <Tooltip title='Filter anzeigen/verbergen' className={classes.filterButton}>
                <IconButton onClick={() => showFilter(!filterShow)} size='small'>
                    <FiFilter/>
                </IconButton>
            </Tooltip>
            <Card>
                <CardContent>
                    <Typography component='h1' color='textSecondary' gutterBottom>
                        Nutzer
                    </Typography>
                    <Grid container spacing={2}>
                        {filterShow && (
                            <Grid item xs={12} className='filterWidget'>
                                <Card>
                                    <CardContent>
                                        <Typography component='h1' color='textSecondary' gutterBottom>
                                            Filter
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item md={auth.roles && auth.roles.includes('ROLE_ADMIN') ? 3 : 4} xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id='text-filter'
                                                        label='Suche'
                                                        name='text'
                                                        onChange={changeFilter}
                                                        value={filter.text}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={auth.roles && auth.roles.includes('ROLE_ADMIN') ? 3 : 4} xs={12} className={classes.filter}>
                                                <InputLabel id='roles-filter'>Rolle</InputLabel>
                                                <Select
                                                    fullWidth
                                                    id='roles-filter-select'
                                                    labelId='roles-filter'
                                                    multiple
                                                    name={'roles'}
                                                    onChange={changeFilter}
                                                    value={filter.roles}
                                                >
                                                    {auth.roles && auth.roles.includes('ROLE_ADMIN') && (
                                                        <MenuItem value={'ROLE_ADMIN'}>Administrator</MenuItem>
                                                    )}
                                                        <MenuItem value={'ROLE_MANAGER'}>Manager</MenuItem>
                                                    {auth.roles && (auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_PHARMA') || auth.roles.includes('ROLE_HOME')) && (
                                                        <MenuItem value={'ROLE_HOME'}>Heim</MenuItem>
                                                    )}
                                                    {auth.roles && (auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_PHARMA')) && (
                                                        <MenuItem value={'ROLE_PHARMA'}>Apotheke</MenuItem>
                                                    )}
                                                    {auth.roles && (auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_PHARMA') || auth.roles.includes('ROLE_DOCTOR')) && (
                                                        <MenuItem value={'ROLE_DOCTOR'}>Arztpraxis</MenuItem>
                                                    )}
                                                    {auth.roles && (auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_PHARMA') || auth.roles.includes('ROLE_DRIVER')) && (
                                                        <MenuItem value={'ROLE_DRIVER'}>Fahrer</MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>
                                            <Grid item md={auth.roles && auth.roles.includes('ROLE_ADMIN') ? 3 : 4} xs={12} className={classes.filter}>
                                                <InputLabel id='home-filter'>Heime</InputLabel>
                                                <Select
                                                    labelId='home-filter'
                                                    id='home-filter-select'
                                                    name={'home'}
                                                    value={filter.home}
                                                    onChange={changeFilter}
                                                    fullWidth
                                                >
                                                    <MenuItem value={''}/>
                                                    {homes.map((option) => (
                                                        <MenuItem value={option._id}>{option.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            {auth.roles && auth.roles.includes('ROLE_ADMIN') && (
                                            <Grid item md={3} xs={12} className={classes.filter}>
                                                <InputLabel id='status-filter'>Status</InputLabel>
                                                <Select
                                                    labelId='status-filter'
                                                    id='status-filter-select'
                                                    name={'status'}
                                                    value={filter.status}
                                                    onChange={changeFilter}
                                                    fullWidth
                                                >
                                                    <MenuItem value={'all'}/>
                                                    <MenuItem value='new'>Neu</MenuItem>
                                                    <MenuItem value='removed'>Gelöscht</MenuItem>
                                                </Select>
                                            </Grid>
                                            )}
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item sm={3} xs={12} className={classes.filter}>
                                                <Grid container spacing={2}>
                                                    <Grid item sm={1}>
                                                        <FormControl fullWidth>
                                                            <Checkbox
                                                                checked={filter.notSeen}
                                                                value={1}
                                                                name={'notSeen'}
                                                                onChange={changeFilterCheckbox}
                                                                color='primary'
                                                                label={'nur neue Anforderungen anzeigen'}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item sm={11}><span className={classes.filterChkLabel}>nur neue Nutzer anzeigen</span></Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container justify='flex-end' spacing={2}>
                                <Grid xl={4} lg={5} md={6} sm={12} xs={12} item>
                                    <Button
                                        className={classes.markAsRead}
                                        color='primary'
                                        endIcon={<MdOutlineMarkEmailRead/>}
                                        fullWidth
                                        onClick={setAllAsRead}
                                        type='submit'
                                        variant='contained'
                                    >
                                        alle Nutzer als Gelesen markieren
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} xl={2}>
                                    {(auth.roles.includes('ROLE_MANAGER') || auth.roles.includes('ROLE_ADMIN')) && (
                                    <Button
                                        color='primary'
                                        component={Link}
                                        endIcon={<MdAdd/>}
                                        fullWidth
                                        to='/nutzer/neu'
                                        variant='contained'
                                    >
                                        Nutzer hinzufügen
                                    </Button>
                                    )}
                                </Grid>
                            </Grid>
                            {!loading && (<TableContainer>
                                <Table>
                                    <Thead>
                                        <Tr className={classes.tbodyTh}>
                                            <Th className='filterable' data-field='username'>
                                                Nutzername
                                                <BiSortAZ className='sort-icon asc'/>
                                                <BiSortZA className='sort-icon desc'/>
                                            </Th>
                                            <Th className='filterable' data-field='roles'>
                                                Rollen
                                                <BiSortAZ className='sort-icon asc'/>
                                                <BiSortZA className='sort-icon desc'/>
                                            </Th>
                                            <Th className='filterable' data-field='firstname'>
                                                Vorname
                                                <BiSortAZ className='sort-icon asc'/>
                                                <BiSortZA className='sort-icon desc'/>
                                            </Th>
                                            <Th className='filterable' data-field='lastname'>
                                                Nachname
                                                <BiSortAZ className='sort-icon asc'/>
                                                <BiSortZA className='sort-icon desc'/>
                                            </Th>
                                            <Th data-field='home'>Pflegeeinrichtung</Th>
                                            <Th data-field='homeGroup'>Wohnbereich</Th>
                                            <Th align='right'>Aktionen</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {state === 'loading' ? (
                                            <Tr>
                                                <TableCell align='center' colSpan={6}>
                                                    Lade Daten...
                                                </TableCell>
                                            </Tr>
                                        ) : state.length === 0 ? (
                                            <Tr>
                                                <TableCell align='center' colSpan={6}>
                                                    Kein Daten vorhanden.
                                                </TableCell>
                                            </Tr>
                                        ) : (
                                            state.map((user, i) => (
                                                <Tr key={i} className={classes.tbodyTr}>
                                                    <TCell clipboard={{data: user.username, help: `Nutzername "${user.username}" kopieren`}}>
                                                        {user.username  && (<Link to={`/nutzer/${user._id}`} title='Bearbeiten'>{user.username}</Link>)}
                                                        {!(user.seen && user.seen.includes(auth.user)) && (<New />)}
                                                    </TCell>
                                                    <Td>{user.roles     && (<Link to={`/nutzer/${user._id}`} title='Bearbeiten'>{rolesToString(user.roles)}</Link>)} </Td>
                                                    <Td>{user.firstname && (<Link to={`/nutzer/${user._id}`} title='Bearbeiten'>{user.firstname}</Link>)} </Td>
                                                    <Td>{user.lastname  && (<Link to={`/nutzer/${user._id}`} title='Bearbeiten'>{user.lastname}</Link>)} </Td>
                                                    <Td>{user.home      && (<Link to={`/einrichtung/${user.home._id}`} title='Bearbeiten'>{user.home.name}</Link>)} </Td>
                                                    <Td>{user.homeGroup && user.homeGroup.length > 0 && user.homeGroup[0].name} </Td>
                                                    <Td align='right'>
                                                        {
                                                            (
                                                                (auth.roles.includes('ROLE_PHARMA') && auth.roles.includes('ROLE_MANAGER')) ||
                                                                (auth.roles.includes('ROLE_ADMIN')) ||
                                                                (auth.roles.includes('ROLE_MANAGER') && (
                                                                    (auth.roles.includes('ROLE_HOME') && user.roles.includes('ROLE_HOME')) ||
                                                                    (auth.roles.includes('ROLE_DOCTOR') && user.roles.includes('ROLE_DOCTOR')) ||
                                                                    (auth.roles.includes('ROLE_DRIVER') && user.roles.includes('ROLE_DRIVER'))
                                                                ))
                                                            )
                                                        && (
                                                            <>
                                                        <Tooltip title='Bearbeiten'>
                                                            <IconButton color='primary' size='small' component={Link} to={`/nutzer/${user._id}`} aria-label='bearbeiten'>
                                                                <FiEdit2/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        {user.status !== 'removed' && auth._id !== user._id && (
                                                            <Tooltip title='Deaktivieren'>
                                                                <IconButton color='secondary' size='small' test = {`${user.firstname}`} component={Link} to={`/nutzer/${user._id}/loeschen`} aria-label='löschen'>
                                                                    <AiOutlineDelete/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        {user.status === 'removed' && (
                                                            <Tooltip title='Reaktivieren'>
                                                                <IconButton color='secondary' size='small' component={Link} to={`/nutzer/${user._id}/reaktivieren`} aria-label='reaktivieren'>
                                                                    <FaTrashRestore/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        </>
                                                        )}
                                                    </Td>
                                                </Tr>
                                            ))
                                        )}
                                    </Tbody>
                                </Table>
                            </TableContainer>)}
                        </Grid>
                    </Grid>
                    <McPagination fetch={fetchData} filter={filter}/>
                    {loading && (<Loader center size={'large'} color={'#5588a3'}/>)}
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default UserList;

import React, {Fragment, useContext, useState} from "react";
import Input from "@components/Input";
import Button from "./Button";
import Icon from "./Icon";
import AuthContext from "@context/AuthContext";
import {hasPermission} from "../utils/utils";

const ButtonDelete = ({permission, onClick, placeholder, compare, description}) => {

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({});
    const [valid, setValid] = useState(false);

    const cancel = (e) => {
        //e.preventDefault();
        setOpen(false);
        //return false;
    };

    const change = (e) => {
        setState({...state, [e.target.name]: e.target.value});
        setValid(e.target.value === compare);
    }

    const submit = () => {
        if(onClick) {
            onClick();
        }
    }

    const click = (e) => {
        e.preventDefault();
        setOpen(true);
    }

    if(permission && !hasPermission(auth, permission)) {
        return <Fragment />;
    }

    return (<Fragment>
        <div className="delete-opt-in-container" style={{display: open ? 'grid' : 'none'}}>
            <div className="delete-opt-in-popover">
                <div className="delete-opt-in-icon-container">
                    <div className="delete-opt-in-icon">!</div>
                </div>
                <h2 className="delete-opt-in-title" >
                    Sind Sie sich Sicher?
                </h2>
                {description || (<div className="delete-opt-in-description" dangerouslySetInnerHTML={{ __html: `Da dieser Datensatz endgültig gelöscht wird bestätigen Sie, dass löschen mit <strong>${compare}</strong>.` }} />)}
                <div className="delete-opt-in-input">
                    <div className={'mt-3'}>
                        <Input name={'opt_in'} placeholder={placeholder} onChange={change} autoComplete="off"/>
                    </div>
                    <small className={'help'}><Icon icon={'info'} />Bitte beachten Sie die Groß- und Kleinschreibung!</small>
                </div>
                <div className="delete-opt-in-actions">
                    <Button type="button" color={'danger'} className="me-2" onClick={cancel}>Nein, Abbrechen!
                    </Button>
                    <Button type="button" color={'success'} className="me-2" disabled={!valid} onClick={submit}>Löschen
                    </Button>
                </div>
            </div>
        </div>
        <button
            type={'delete'}
            className='btn btn-danger mt-4'
            onClick={click}
        >
            Löschen
        </button>
    </Fragment>);
}

export default ButtonDelete;
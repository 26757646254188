import {makeMockxiosId} from "../maxiosUtils";

const db = () => {
    return {
        '/api/homes': [
            {
                _id: makeMockxiosId(),
                name: 'Familienpflege Qual',
                firstname: 'Martina',
                lastname: 'Qual',
                mail: 'martina.qual@pflege-qual.de',
                street: 'Quallenalle 2',
                postalCode: '12431',
                city: 'Quallien'
            },
            {
                _id: makeMockxiosId(),
                name: 'Pflege Dolphin',
                firstname: 'Anton',
                lastname: 'Klücke',
                mail: 'anton@dolphin.com',
                street: 'brownstreet 123',
                postalCode: '09781',
                city: 'London'
            },
            {
                _id: makeMockxiosId(),
                name: 'Altenhaus Berlin',
                firstname: 'Rupert',
                lastname: 'Guy',
                mail: 'rupert.guy@berlin.de',
                street: 'Alexanderplatz 12',
                postalCode: '77681',
                city: 'Berlin'
            },
            {
                _id: makeMockxiosId(),
                name: 'Endstation olli',
                firstname: 'Olli',
                lastname: 'Gräber',
                mail: 'olli-gräber@endstation-hof.de',
                street: 'grabstr. 1',
                postalCode: '06721',
                city: 'Hof'
            }
        ]
    }
}

export default db;


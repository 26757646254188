import factory from "@factories/factory";
import axios from "axios";

const homeServiceFactory = (config) => {
    return {
        ...config,
        ...factory,
        path: 'homes',
        get: async function(homeId, groupId, filter, page, limit) {
            const response = await axios.get(`/api/homes/${homeId}/groups`, {headers: {'x-auth-token': this.auth.token}});

            return {
                items: response.data,
                max: response.data.length
            }
        }
    };
};

export default homeServiceFactory;
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useState, Fragment } from 'react';
import {  TiDocumentText } from 'react-icons/all';

import AuthContext from '../context/auth';
import handleErrors from '../utils/handleErrors';
import {
    Grid,
    makeStyles,
    Typography,
    IconButton,
    Paper,
    Tooltip,
} from '@material-ui/core';
import { AiOutlineClockCircle, AiOutlineFilePdf } from 'react-icons/ai';
import FileDownload from 'js-file-download';

const useStyles = makeStyles({
    message: {
        flexGrow: 1,
    },
    container: {
        padding: 12,
    },
    messageContainer: {
        padding: 8,
        border: '1px solid #ff9800',
        background: '#fff3e0',
    },
    iconColor: {
        marginRight: 8,
        color: '#ff9800',
    },
    icon: {
        marginRight: 8,
    },
    messageText: {
        paddingLeft: 12,
    },
    user: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    date: {
        fontSize: 10,
        color: '#888',
    },
    new: {
        background: '#e6ebe7',
    },
});

const Document = ({ event, history }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATES
     */
    const [downloading, setDownloading] = useState(false);

    /**
     * CONTEXT
     */
    const { auth, setAuth } = useContext(AuthContext);

    /**
     * QUERIES
     */
    const download = async () => {
        try {
            setDownloading(true);
            const response = await axios.get(`/api/documents/${event.document}/pdf`, {
                headers: {
                    'x-auth-token': auth.token,
                },
                responseType: 'blob',
            });
            FileDownload(response.data, `document_${moment(event.createdAt).format('YYYYMMDD_HHmmss')}.pdf`);
            setDownloading(false);
        } catch (err) {
            handleErrors(err, setAuth, history);
        }
    };

    return (
        <Fragment>
            <Grid item xs={12}>
                <Paper className={`${classes.messageContainer}`} elevation={1}>
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item>
                            <Typography className={classes.user}>
                                <TiDocumentText className={classes.iconColor} />
                                {event.createdFrom.username}
                            </Typography>
                            <Typography className={classes.date}>
                                <AiOutlineClockCircle className={classes.icon} />
                                {moment(event.createdAt).format('DD.MM.YYYY HH:mm')}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.message}>
                            <Typography className={classes.messageText}>Eine Anforderung wurde erzeugt</Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title='PDF herunterladen'>
                                <IconButton disabled={downloading} onClick={download} color='secondary' size='small'>
                                    <AiOutlineFilePdf />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Fragment>
    );
};

export default Document;

/* jshint esversion: 8 */
import React, {Fragment, useState} from "react";
import {addClass, rand} from "@utils/utils";
import Checkbox from "@components/Checkbox";
import Icon from "@components/Icon";
import Clipboard from "@components/Clipboard";
import ToolTip from "@components/ToolTip";

const TableRow = ({collapseLabel, resolution, updateCollapse, collapsed, subTableOffset, offset, service, id, observer, filter, onRowHighlight, highlightColumns, row, columns, onCollapseContent, rowCheckAble, rowCollapseAble, isLastGroupRow}) => {
    /**
     * State
     */
    const [expanded, setExpanded] = useState(collapsed);
    const [entity, setEntity] = useState(row);
    const [checked, setChecked] = useState(false);


    /**
     * Functions
     */
    const actionCallBack = async (item) => {
        if(item) {
            setEntity({...entity, ...item});
        }
    };

    const actionClick = async (data, message, action) => {
        const copy = {...data};

        delete data.__removed;
        delete data.__disabled;

        if(service[action]) {
            await service[action](entity._id, data, message);
        }

        if(action === 'update' && 'disabled' in copy) {
            setEntity({...entity, disabled: !!copy.disabled});
        } else if(action === 'update' && '__disabled' in copy) {
            setEntity({...entity, disabled: !!copy.__disabled});
        } else if(action === 'update' && '__removed' in copy ){
            setEntity({...entity, __removed: !!copy.__removed});
        }
    };

    const highlight = () => {
        if(onRowHighlight) {
            return onRowHighlight(row);
        }

        return false;
    };

    const escape = (str) => {
        const items = ['(', ')', '[', '[', '*', '+'];
        let strChars =  str.split('');
        for (let ii = 0; ii < strChars.length; ii++) {
            const char = strChars[ii];
            if(items.indexOf(char) !== -1) {
                strChars[ii] = `\\${char}`;
            }
        }

        return strChars.join('');
    }

    const highlightString = (str) => {
        if(filter._all && typeof str === 'string') {
            const matches = str.match(new RegExp(escape(filter._all), 'ig'));
            if(matches) {
                const indexes = [];
                let helper = str;
                let count = 0;
                for(let ii = 0; ii < matches.length; ii++) {
                    const index = helper.indexOf(matches[ii])
                    indexes.push({value: matches[ii], index: count + index});
                    count = index + 1;
                    helper = helper.substr(count);
                }

                for(let ii = indexes.length - 1; ii >= 0; ii--) {
                    str = stringSplice(str, indexes[ii].index, indexes[ii].value.length, `<strong class="highlight-match">${indexes[ii].value}</strong>`);
                }
            }
            return <ToolTip trigger={<span dangerouslySetInnerHTML={{__html: str}} />}>{str}</ToolTip>;
        }

        if(typeof str === 'string') {
            return <ToolTip trigger={str}>{str}</ToolTip>;
        }

        return str;
    };

    const stringSplice = (str, index, length, string) => {
        const start = str.substr(0, index);
        const end = str.substr(index + length);

        return `${start}${string}${end}`
    };

    const renderCell = (column, row, index, isLast) => {
        let content = '';

        if (row[column.name]) {
            content = row[column.name];
        }

        if (column.render) {
            content = column.render(row, {
                highlight: highlightString
            });

            if(column.name === 'actions') {
                if(!content.type.name) {
                    content = content.props.children;
                }

                if(!(content instanceof Array)) {
                    content = [content];
                }

                content = content.filter((item) => !!item).map((item) => {
                    let props = {item: row, callback: actionCallBack};
                    if(!!item.props.action && !item.props.onClick) {
                        props.onClick = actionClick;
                    }

                    const element = React.cloneElement(item, props);
                    return (<li key={`action-${rand()}-${rand()}`}>{element}{resolution <= 425 && item.props.label ? (<span className={'action-label'}>{item.props.label}</span>) : ''}</li>);
                })

                content = ( <ul className={`list-item-${column.name}`}>{content}</ul>);
            }
        }

        if (!content) {
            content = (<small className={'no-table-cell-data'}>Keine Daten</small>);
        }

        if((highlightColumns instanceof Array && highlightColumns.indexOf(index) !== -1) || highlightColumns === index) {
            content = highlightString(content);
        }

        const dataCellOptions = column.dataCellOptions ? column.dataCellOptions : {};
        let copy = '';
        let checkbox = <Fragment />;
        let checkboxMobile = <Fragment />;
        let collapse = <Fragment />;
        let collapseMobile = <Fragment />;

        //Add Copy Cell Data Element
        if(column.copyable && row[column.name]) {
            copy = (<Clipboard className={'hover-visible'} data={row[column.name]} title={`${column.title} kopieren`}/>);
        }

        if(typeof content === 'string') {
            content = <ToolTip trigger={<span dangerouslySetInnerHTML={{__html: content}} />}>{content}</ToolTip>;
        }

        if(index === 0 && !!rowCheckAble && isCheckAble(row)) {
            if(resolution <= 425) {
                checkboxMobile = <td key={rand()} className={'mobile-checkbox'}>
                    <Checkbox className={'cell-checkbox'} id={id} checked={checked} observer={observer} onChange={event => { setChecked(event.target.checked) }} label={'auswählen'} value={row._id} name={'selection'}/>
                </td>
            } else {
                checkbox = (<Checkbox className={'cell-checkbox'} id={id} observer={observer} label={''} value={row._id} name={'selection'} />)
            }
        }

        if(index === 0 && !!(rowCollapseAble || onCollapseContent) && isCollapseAble(row)) {
            if(resolution <= 425) {
                collapseMobile = (<td key={rand()} className={'mobile-collapse'} onClick={() => { updateCollapse(row._id, expanded); setExpanded(!expanded)}}><Icon icon={'arrow_right'} /><span>{collapseLabel}</span><Icon icon={'arrow_right'} /></td>)
            } else {
                collapse = (<Icon icon={'arrow_right'} tooltip={{content: 'Zusätzliche Daten anzeigen', place:'right'}} onClick={() => { updateCollapse(row._id, expanded); setExpanded(!expanded) }} className={['collapse-item cell-collapse']}/>);
            }
        }

        let cellClasses = [];
        if(column.classes instanceof Array) {
            cellClasses = column.classes.map(item => {
                if(typeof item === 'function') {
                    return item(row);
                }

                return item;
            })
        }

        let classNames = [addAlign(column)].concat(cellClasses);

        if(dataCellOptions.className) {
            classNames = classNames.concat([dataCellOptions.className])
        }

        if(resolution <= 425) {
            let size = 12;

            if(column.mobile && column.mobile.size) {
                size = column.mobile.size > 12 ? 12 : column.mobile.size;
                size = size < 1 ? 1 : size
            }

            classNames.push(`mobile-td-${size}`);
        }

        return (<Fragment key={rand()}>{checkboxMobile}{collapseMobile}<td key={rand()}{...dataCellOptions} className={classNames.join(' ')}>{addLabel(column)}{checkbox}{collapse}{content}{copy}</td></Fragment>);
    };

    const addAlign = (column) => {
        if(resolution <= 425) {
            if(column.mobile && column.mobile.align) {
                return `cell-align-${column.mobile.align}`
            }
        }

        return `cell-align-${column.align ? column.align : 'left'}`
    }

    const addLabel = (column) => {
        if(resolution <= 425) {
            return (<span className={'table-data-headline'}>{column.title}</span>);
        }

        return '';
    }

    const isCheckAble = (item) => {
        if(typeof rowCheckAble === 'function' && item) {
            return rowCheckAble(item);
        }

        return !!rowCheckAble;
    };

    const isCollapseAble = (item) => {
        if(typeof rowCollapseAble === 'function' && item) {
            return rowCollapseAble(item);
        } else if(onCollapseContent) {
            return !!onCollapseContent;
        }

        return !!rowCollapseAble;
    };

    const getRowState = (row) => {
        if(row.disabled) {
            return 'disabled';
        }

        return 'active';
    };

    const doCollapse = (item) => {
        const childItems = onCollapseContent(item, resolution);
        if(childItems.props.rows) {
            return childItems;
        }

        const classes = ['data-collapse', offset ? 'first-cell-offset' : ''];

        if(resolution <= 425) {
            classes.push('mobile-collapse-expand');
        }

        return <tr key={`row-${row._id}-collapse`} className={addClass(classes)}>
            <td colSpan={columns.length} key={rand()}>
                {resolution <= 425 && <Icon className={'collapse-close'} icon={'close'} onClick={() => { updateCollapse(row._id, expanded); setExpanded(!expanded) }} /> }
                <div className={addClass(['data-collapse-container', isCheckAble(item) ? 'check-able-collapse' : ''])}>
                    {childItems}
                </div>
            </td>
        </tr>
    };

    if(entity.__removed) {
        return <Fragment />;
    }

    return (
        <Fragment key={`row-${rand()}-${rand()}`}>
            <tr key={rand()} className={addClass([`${getRowState(entity)}-row`,highlight() ? 'data-highlight' : '', expanded ? 'expand' : '', offset ? 'first-cell-offset' : '', isLastGroupRow ? 'last-group-row' : '', checked ? 'row-selected' : ''])}>
                {subTableOffset && <td colSpan={subTableOffset} className={'offset-cell'} />}
                {columns && columns.filter(item => item.display === undefined || !!item.display).map((item, index) => renderCell(item, entity, index) )}
            </tr>
            {expanded && onCollapseContent && (doCollapse(entity))}
        </Fragment>
    );
}

TableRow.defaultProps = {
    offset: false,
    highlightColumns: [0],
    collapsed: false,
    resolution: 2024
}

export default TableRow;
import React, {useState, useContext} from 'react';
import Card from '@components/Card';
import Col from '@components/Col';
import Content from '@components/Content';
import Row from '@components/Row';
import Dialog from "@components/Dialog";
import Organisation from "../../parts/forms/dialog/doctor/office/Organisation";
//import Summary from "../../parts/forms/dialog/doctor/Summary";
import AuthContext from "@context/AuthContext";
import doctorOfficeServiceFactory from "@factories/doctorOfficeServicefactory";
import routeServiceFactory from "@factories/routeServiceFactory";
import {useHistory} from 'react-router';
import Icon from "@components/Icon";
import Modal from "@components/Modal";
import Link from "@components/Link";
import Alert from "@components/Alert";
import Checkbox from "@components/Checkbox";
import {toast} from "react-toastify";
import doctorServiceFactory from "@factories/doctorServiceFactory";
import Button from "@components/Button";

const DoctorOfficeForm = () => {

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const history = useHistory();

    /**
     * Services
     */
    const routeService = routeServiceFactory();
    const doctorOfficeService = doctorOfficeServiceFactory({auth: auth});
    const doctorService = doctorServiceFactory({auth});

    /**
     * STATES
     */
    const [state, setState] = useState({});
    const [openDoctorModal, setOpenDoctorModal] = useState(false);
    const [openEmptyDoctorsModal, setOpenEmptyDoctorsModal] = useState(false);



    /**
     * Functions
     */
    const change = (data) => {
        setState({...state, ...data});
    };

    const submit = async () => {
        if(!state.organisation.doctors || state.organisation.doctors.length === 0) {
            setOpenEmptyDoctorsModal(true);
        } else {
            await sendRequest();
        }

        return false;
    };

    const sendRequest = async () => {
        const response = await doctorOfficeService.create({...state.organisation, preferred: state.organisation.contact_type, doctors: null});

        if(state.organisation.doctors) {
            for(let item of state.organisation.doctors) {
                await doctorService.update(item._id, {office: response._id, lastname: item.lastname, fax: item.fax, email: item.email}, null);
            }
        }


        if((state.organisation.doctors && state.organisation.doctors.length > 0) || document.cookie.match('hide-question-create-doctor=1;?')) {
            toast.success('Arztpraxis wurde erstellt.');
            history.push(routeService.doctorOfficeList());
            setState({});
        } else {
            closeEmptyDoctorModal();
            setOpenDoctorModal(true);
        }
    }

    const closeEmptyDoctorModal = () => {
        setOpenEmptyDoctorsModal(false)
    }

    return (
        <Content>
            <form noValidate>
                <Row sm={12}>
                    <Col sm={12}>
                        <Card center={true} title={'Neue Arztpraxis'}>
                            <Dialog onChange={change} onSubmit={submit}>
                                <Organisation title={'Daten'} name={'organisation'} icon={<Icon icon={'medical_home'}/>}/>*/}
                            </Dialog>
                        </Card>
                    </Col>
                </Row>
            </form>
            <Modal button={null} canClose={false} title={'Arzt zur Praxis hinzufügen'} open={openDoctorModal} size={[400, 360]}>
                <Alert type={'success'}>
                    Praxis <strong>{state.name}</strong> wurde erfolgreich angelegt.
                </Alert>

                    <p className={'text-muted text-center pb-2'}>
                        <strong>Möchten Sie für diese Praxis nun Ärzte anlegen?</strong>
                    </p>
                <p className={'text-muted text-center mb-4 pb-2'} style={{fontSize: 12}}>
                    Sie können dies auch zu einen späteren Zeitpunkt unter <strong>STAMMDATEN &gt; Ärzte</strong> machen.
                </p>

                <Row>
                    <Col lg={12} className={'text-center'}>
                        <Link size={'large'} type={'button-success'} to={routeService.doctorCreate()}>Arzt hinzufügen</Link>
                    </Col>
                </Row>
                <Row className={'mt-2'}>
                    <Col lg={12} className={'text-center'}>
                        <Link to={routeService.doctorOfficeList()}>nicht jetzt!</Link>
                    </Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col lg={12}>
                        <Checkbox label={'nicht mehr zeigen'} value={'1'} name={'hide-question-create-doctor'} onChange={(e) => {
                            if(e.target.checked) {
                                document.cookie = `hide-question-create-doctor=1;expires=Thu, 18 Dec 2113 12:00:00 UTC; path=/`
                            } else {
                                document.cookie = `hide-question-create-doctor=0;expires=Thu, 18 Dec 1970 12:00:00 UTC; path=/`
                            }
                        }}/>
                    </Col>
                </Row>
            </Modal>
            <Modal button={null} canClose={false} title={'keine Ärzte in der Praxis'} open={openEmptyDoctorsModal} size={[550, 270]}>
                <Alert type={'warning'}>
                    Der Praxis sind keine Ärzte zugewiesen. es wird empfohlen Arztpraxen zu verwenden, wenn mindestens 2 Ärzte in der Praxis arbeiten.
                    Ansonsten ist es ausreichend das der Arzt alleinstehen ohne Arztpraxis erzeugt wird.
                </Alert>

                <Row>
                    <Col lg={6}>
                        <Button color={'danger'} onClick={closeEmptyDoctorModal}>Abbrechen</Button>
                    </Col>
                    <Col lg={6} className={'text-end'}>
                        <Button color={'success'} onClick={sendRequest}>Trotzdem erstellen</Button>
                    </Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col lg={12}>
                        <Checkbox label={'nicht mehr zeigen'} value={'1'} name={'hide-question-empty-doctor'} onChange={(e) => {
                            if(e.target.checked) {
                                document.cookie = `hide-question-empty-doctor=1;expires=Thu, 18 Dec 2113 12:00:00 UTC; path=/`
                            } else {
                                document.cookie = `hide-question-empty-doctor=0;expires=Thu, 18 Dec 1970 12:00:00 UTC; path=/`
                            }
                        }}/>
                    </Col>
                </Row>
            </Modal>
        </Content>
    );
};

export default DoctorOfficeForm;
import React, {Fragment, useContext, useEffect, useState} from "react";
import Row from "@components/Row";
import Col from "@components/Col";
import eventServiceFactory from "@factories/eventServiceFactory";
import PropTypes from "prop-types";
import AuthContext from "@context/AuthContext";
import Headline from "@components/Headline";
import Message from "@components/communication/Message";
import {rand} from "@utils/utils";
import moment from "moment";
import CommunicationForm from "@components/communication/CommunicationForm";
import Alert from "@components/Alert"; //comment in if you add Proptypes to your Component


const Communication = ({types, id, formLocation, ...args}) => {

    /**
     * Context
     */
    const {auth} = useContext(AuthContext);

    /**
     * Services
     */
    const service = eventServiceFactory({auth: auth});

    /**
     * States
     */
    const [collection, setCollection] = useState([]);

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();

        return () => {
            setCollection([]);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const fetch = async () => {
        const response = await service.get(id, {type: types});

        let group = {};

        for(let item of response.items) {
            const date = moment(item.createdAt).format('DD.MM.YYYY');
            if(!group[date]) {
                group[date] = {label: date, items: []};
            }

            group[date].items.push(item);
        }

        setCollection(Object.values(group));
    }

    const setType = (item) => {
        if(item.createdFrom && item.createdFrom._id === auth.user) {
            return 'self'
        } else {
            return item.type;
        }
    }

    return <Fragment>
        {formLocation === 'top' && (<CommunicationForm doRefresh={fetch} id={id}/>)}
        <div className={'container'}>
        <Row sm={12} className={'mt-3'}>
            <Col sm={12}>
                <Headline size={'h4'}>Nachrichten</Headline>
            </Col>
        </Row>
        </div>
        <Row className={'chat-wrapper'}>
            <Col sm={12} className={'chat-content'}>
                <div className={'container chat-body'}>
                    <div className={'messages'}>
                        {collection.map((group) => (<Fragment key={rand()}>
                                <Row key={rand()} className={'message-item'}>
                                    <Col osm={5} sm={'2'} xs={5}>
                                        <Headline design={'none'} size={'h6'}>{group.label}</Headline>
                                    </Col>
                                </Row>
                                {group.items.map((item) => (
                                    <Row key={rand()} className={'message-item'}>
                                        <Col sm={12}><Message entry={item} files={item.files} creator={item.createdFrom ? item.createdFrom.username : 'Unbekannt'} type={setType(item)}>{item.data}</Message>
                                        </Col>
                                    </Row>
                                ))}
                        </Fragment>
                        ))}
                        {collection.length === 0 && (
                            <Row>
                                <Col sm={12}>
                                    <Alert type={'warning'}>Keine Nachrichten vorhanden.</Alert>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>
            </Col>
        </Row>
        {formLocation === 'bottom' && (<CommunicationForm doRefresh={fetch} id={id}/>)}
    </Fragment>
}

Communication.propTypes = {
    id: PropTypes.string.isRequired,
    formLocation: PropTypes.oneOf(['top', 'bottom'])
};

Communication.defaultProps = {
    types: [],
    formLocation: 'top'
};

export default Communication;
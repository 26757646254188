import React from 'react';
import { Link } from 'react-router-dom';

const SideBarLink = ({ children, to, icon, notice}) => {
    return (
        <li className='nav-item'>
            <Link to={to} className='nav-link'>
                {icon ?? icon}
                <span className='link-title'>{children}</span>
            </Link>
            {notice}
        </li>
    );
};

export default SideBarLink;

import React from "react";
import Icon from "@components/Icon";
import {copyToClipboard} from "@utils/clipboard";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const Clipboard = ({data, title, className, ...args}) => {


    /**
     * Functions
     */
    const copy = () => {
        copyToClipboard(data);
    }

    return <Icon className={['clipboard', className].join(' ').trim()} onClick={copy} tooltip={{content: title, place: 'top', arrow: false, classes: 'tooltip-clipboard'}} icon={'clipboard'} {...args} />
}

Clipboard.propTypes = {};

Clipboard.defaultProps = {};

export default Clipboard;
/* jshint esversion: 8 */
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Grid,
    InputAdornment,
    Link,
    TextField,
    Typography,
} from '@material-ui/core';
import axios from 'axios';
import $ from 'jquery';
import React, { useContext, useEffect, useState } from 'react';
import { MdLockOpen, MdPermIdentity, MdSend, MdVisibility } from 'react-icons/md';
import blimusLogo from '../assets/img/blimus.jpg';
import erixaLogo from '../assets/img/erixa.png';
import mediChargelogo from '../assets/img/mediChargeLogo.png';
import Footer from '../components/Footer';
import AuthContext from '../context/auth';
import '../assets/scss/login.scss';
import handleErrors from '../utils/handleErrors';

const Login = ({ history }) => {
    const demo = window.location.host === 'localhost:3000' || window.location.host === 'demo.medicharge.de';
    const demoData = [
        { user: 'Apothekerin', password: 'apo' },
        { user: 'Pharmazeutin', password: 'apo' },
        { user: 'Heimleiterin', password: 'heim' },
        { user: 'Pflegerin', password: 'heim' },
        { user: 'Ärztin', password: 'arzt' },
        { user: 'Schwester', password: 'arzt' },
        { user: 'Fahrdienstleiterin', password: 'fahrer' },
        { user: 'Fahrerin', password: 'fahrer' },
    ];

    const { setAuth } = useContext(AuthContext);
    const [state, setState] = useState({
        username: '',
        password: '',
    });
    const [value, setValue] = useState({
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValue({ ...value, showPassword: !value.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const submit = async (e) => {
        e.preventDefault();

        try {
            let response = await axios.post('/api/auth', state);
            localStorage.setItem('auth', JSON.stringify(response.data));
            setAuth(response.data);

            if (response.data.once_password === true) {
                history.push('/profil#scrollable-force-tab-1');
            } else {
                history.push('/');
            }
        } catch (err) {
            handleErrors(err, setAuth, history);
        }

        return false;
    };

    const change = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const [isApomondo, setIsApomondo] = useState(false);
    const fetchApomondo = async () => {
        try {
            const res = await axios.get('/api/apomondo');
            setIsApomondo(res.data.apomondo);
        } catch (err) {
            setIsApomondo(false);
        }
    };

    useEffect(() => {
        fetchApomondo();
    }, []);

    const insertLoginData = (e) => {
        let index = e.target.dataset.index;
        setState({
            ...state,
            username: demoData[index].user,
            password: demoData[index].password,
        });
        $('#username').val(demoData[index].user);
        $('#password').val(demoData[index].password);
    };

    return (
        <div className='login-wrap'>
            <Grid container style={{ flexFlow: 'column wrap' }} alignItems='center'>
                <Grid item lg={4} style={{ marginTop: '5%' }}>
                    <form onSubmit={(e) => submit(e)}>
                        <Card style={{ padding: '10px', marginTop: '24px' }}>
                            <CardMedia
                                image={mediChargelogo}
                                title='MediCharge Logo'
                                style={{ minHeight: '100px', backgroundSize: 'contain' }}
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id='username'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start' style={{ margin: '0 6px' }}>
                                                        <MdPermIdentity />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label='Benutzername'
                                            name='username'
                                            onChange={(e) => change(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id='password'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        position='start'
                                                        style={{ margin: '0 6px' }}
                                                    >
                                                        {value.showPassword ? <MdVisibility /> : <MdLockOpen />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label='Passwort'
                                            name='password'
                                            onChange={(e) => change(e)}
                                            type={value.showPassword ? 'text' : 'password'}
                                        />
                                    </Grid>
                                    {demo && (
                                        <Grid
                                            container
                                            spacing={0}
                                            direction='row'
                                            justify='center'
                                            alignItems='center'
                                            style={{ marginTop: '40px' }}
                                        >
                                            <Grid item md={8} xs={12} direction='row'>
                                                <Grid container spacing={0} style={{ marginLeft: '50px' }}>
                                                    <Grid item xs={6}>
                                                        <strong>Benutzer</strong>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <strong>Passwort</strong>
                                                    </Grid>
                                                    {demoData.map((item, index) => (
                                                        <>
                                                            <Grid item xs={6}>
                                                                <Link
                                                                    onClick={insertLoginData}
                                                                    data-index={index}
                                                                    className='username'
                                                                >
                                                                    {item.user}
                                                                </Link>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Link
                                                                    onClick={insertLoginData}
                                                                    data-index={index}
                                                                    className='password'
                                                                >
                                                                    {item.password}
                                                                </Link>
                                                            </Grid>
                                                        </>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Grid container justify='flex-end' spacing={2}>
                                    <Grid item>
                                        <Button
                                            color='primary'
                                            endIcon={<MdSend />}
                                            type='submit'
                                            id={'login-submit'}
                                            variant='contained'
                                        >
                                            Anmelden
                                        </Button>
                                    </Grid>
                                    {isApomondo === true && (
                                        <Grid item xs={12} style={{ marginTop: 24 + 'px' }}>
                                            <Grid container justifyContent='center'>
                                                <Button
                                                    onClick={() => {
                                                        window.location.href = `https://backend.apomondo.online/#/auth/login?response_type=code&client_id=medicharge&state=sso&redirect_uri=${encodeURIComponent(
                                                            window.location.href.replace('/login', '')
                                                        )}%2Foauth&scope=openid%20&nonce=123gd12gigd1187t12edg781gd1197d12db1278g1`;
                                                        return null;
                                                    }}
                                                    variant='outlined'
                                                    color='primary'
                                                >
                                                    Anmelden mit <span style={{ color: 'red' }}>APO</span>MONDO
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardActions>
                        </Card>
                    </form>
                </Grid>
                <Grid item lg={4} />
            </Grid>

            {!demo && (
                <Grid
                    container
                    spacing={2}
                    style={{ marginTop: '24px', flexFlow: 'column wrap', width: '100%' }}
                    alignItems='center'
                >
                    <Grid item xs={12} sm={8} lg={6} xl={4} style={{ width: '100%' }}>
                        <Link target='_blank' href='https://blimus.de' aria-label='Blimus' title='Blimus'>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar aria-label='recipe' variant='square' src={blimusLogo} />}
                                    title='Blimus'
                                    subheader='Pharmazeutische Datenmanagement & Service GmbH'
                                />
                                <CardContent>
                                    <Typography variant='body2' color='textSecondary' component='p'>
                                        Sparen Sie Zeit und vermeiden Sie Fehler bei der Versorgung der Patienten mit
                                        Medikamenten. Lassen Sie sich durch Ihre Apotheke mit Blistern versorgen.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={8} lg={6} xl={4} style={{ width: '100%' }}>
                        <Link target='_blank' href='https://erixa.de' aria-label='eRiXa' title='eRiXa'>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar aria-label='recipe' variant='square' src={erixaLogo} />}
                                    title='eRiXa'
                                    subheader='PSO Prima Smart Office GmbH'
                                />
                                <CardContent>
                                    <Typography variant='body2' color='textSecondary' component='p'>
                                        Tauschen Sie Rezepte mit den Ärzten oder Ihrer Apotheke rechtssicher digital
                                        aus. Kein Papier mehr. Kein Kurier mehr.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
            )}

            <Grid container>
                <Grid item xs={12}>
                    <Footer />
                </Grid>
            </Grid>
        </div>
    );
};

export default Login;

import factory from "@factories/factory";

const meetingServiceFactory = (config) => {
    return {
        ...factory,
        ...config,
        v3: true,
        path: 'meetings',
    }
}

export default meetingServiceFactory;
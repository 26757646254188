import {getRandomSingleItem, makeMockxiosId} from "../maxiosUtils";

const db = (homeGroup, doctor) => {
    return {
        '/api/users': [
            {
                _id: makeMockxiosId(),
                username: 'system',
                password: 'test',
                once_password: false,
                roles: ['ROLE_ADMIN'],
                doctor: null,
                homeGroup: null,
                salutation: '',
                title: '',
                firstname: '',
                lastname: '',
            },
            {
                _id: makeMockxiosId(),
                username: 'peter.krausen',
                password: 'test',
                once_password: false,
                roles: ['ROLE_PHARMA'],
                doctor: null,
                homeGroup: null,
                salutation: 'mr',
                title: '',
                firstname: 'Peter',
                lastname: 'Krause',
            },
            {
                _id: makeMockxiosId(),
                username: 'test_home',
                password: 'test',
                once_password: false,
                roles: ['ROLE_HOME'],
                doctor: null,
                homeGroup: getRandomSingleItem(homeGroup),
                salutation: 'mrs',
                title: '',
                firstname: 'Marion',
                lastname: 'Klassen',
            },
            {
                _id: makeMockxiosId(),
                username: 'test_doctor',
                password: 'test',
                once_password: false,
                roles: ['ROLE_DOCTOR'],
                doctor: getRandomSingleItem(doctor),
                homeGroup: null,
                salutation: null,
                title: '',
                firstname: null,
                lastname: null,
            },
            {
                _id: makeMockxiosId(),
                username: 'sabine.himmel',
                password: 'test',
                once_password: false,
                roles: ['ROLE_HOME'],
                doctor: null,
                homeGroup: getRandomSingleItem(homeGroup),
                salutation: 'mrs',
                title: '',
                firstname: 'Sabine',
                lastname: 'Himmel',
            }
        ]
    }
}

export default db;
import React from 'react';
import { FiSearch } from 'react-icons/fi';
import ProfileMenu from './ProfileMenu';
import ShortNotifications from "@components/ShortNotifications";
import Masquerade from "./Masquerade";

const Navbar = () => {
    return (
        <nav className='navbar'>
            <div className='navbar-content'>
                <form className='search-form' method='GET'>
                    <div className='input-group'>
                        <div className='input-group-text'>
                            <FiSearch />
                        </div>
                        <input
                            type='text'
                            className='form-control'
                            id='navbarForm'
                            name='search'
                            placeholder='Suchen...'
                        />
                    </div>
                </form>
                <ul className='navbar-nav'>
                    <li className={'nav-item dropdown'}><Masquerade/></li>
                    <li className={'nav-item dropdown'}><ShortNotifications /></li>
                    <ProfileMenu />
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;

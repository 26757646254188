import React, {useEffect, useRef, useState} from "react";
import Row from "./Row";
import Col from "./Col";
import Button from "@components/Button";
import PropTypes from "prop-types";

const Dialog = ({submitLabel, initialStep, children, onChange, onSubmit, data}) => {
    /**
     * States
     */
    const [step, setStep] = useState(0);                                //current step Index
    const [max, setMax] = useState(0);                                  //max step index
    const [current, setCurrent] = useState(null);                       //current dialog view
    const [state, setState] = useState({});                             //dialog data
    const [progressWidth, setProgressWidth] = useState(12/children.length);
    const [display, setDisplay] = useState({next: true, submit: true}); //display controls
    const [valid, setValid] = useState(true);                           //Next step or submit only available if data valid
    const [views, setViews] = useState([]);
    const [childElements, setChildElements] = useState([]);

    /**
     * References
     */
    const stateRef = useRef();
    stateRef.current = {data: state, set: setState};
    const currentRef = useRef();
    currentRef.current = {data: current, set: setCurrent};

    /**
     * Functions
     */
    const next = async (data, args) => {

        if(data) {
            if(typeof data === 'function') {
                data = await data(args);
            }

            const name = childElements[step + 1].props.name;

            if(onChange) {
                onChange({[name]: {...data}});
            }

            stateRef.current.set({...stateRef.current.data, [name]: {...data}});
        }

        setStep(step + 1);
        setMax(step + 1);
    }

    const jumpToStep = (stepIndex) => {
        if(stepIndex >= 0) {
            setStep(stepIndex);
        }
    }

    const updateValid = (valid) => {
        setValid(valid);
    }

    const change = (e) => {
        const name = currentRef.current.data.props.name;

        const data = e.target ? {[e.target.name]: e.target.value} : e;
        const subData = stateRef.current.data[name] ? {...stateRef.current.data[name]} : {};
        const update = {...stateRef.current.data, [name]: {...subData, ...data}};

        stateRef.current.set(update);

        if(e && onChange) {
            onChange(update);
        }
    }

    const jump = (index) => {
        if(index <= max) {
            if(index < step || (index === max && valid)) {
                setStep(index);
            }
        }
    }

    /**
     * Hooks
     */
    useEffect(() => {
        setState(data ? data : {})
    }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setStep(initialStep)
    }, [initialStep]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setChildElements(validateChildren(children));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    useEffect(() => {
        setViews(childElements);
        setProgressWidth(100/childElements.length);
        switchView(step);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childElements]);

    useEffect(() => {
        switchView(step);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, views]);

    const validateChildren = (childs) => {
        if(!(childs instanceof Array)) {
            childs = [childs];
        }
        return childs.filter((item) => typeof item === 'object');
    }

    const switchView = (index) => {
        if(views[index]) {
            const element = views[index];
            currentRef.current.set(React.cloneElement(element, {key: `dialog-step-view-${index}`, onNext: next, onChange: change, setValid: updateValid, form: state}));
            setDisplay({
                ...display,
                next: 'hide' in element.props && 'next' in element.props.hide ? !!element.props.hide.next : true,
                submit: 'hide' in element.props && 'submit' in element.props.hide ? !!element.props.hide.submit : true
            });
        }
    }

    const addSubmitButtons = () => {
        const buttons = [];
        buttons.push(<Button
            key={`submit-btn-default`}
            type={'submit'}
            color={'success'}
            disabled={!valid}
            className='mt-4 mobile-no-mt float-end submit-btn'
            order={10}
            onClick={async () => {
                if(onSubmit) { await onSubmit(state, jumpToStep); }
                return false;
            }}
        >
            {submitLabel}
        </Button>);

        if(current.props.submit) {
            for(let elementIndex in current.props.submit) {
                const element = current.props.submit[elementIndex];
                buttons.push(React.cloneElement(element, {key: `submit-btn-${elementIndex}`, order: element.props.order !== undefined ? element.props.order : 10, disabled:!valid, className:'mt-4 float-end submit-btn', onClick: async () => {
                        if(element.props.onClick) {
                            await element.props.onClick(state, jumpToStep)
                        } else if(onSubmit) {
                            await onSubmit(state, jumpToStep);
                        }

                        return false;
                    }}));
            }
        }

        return buttons.sort((btn1, btn2) => { return btn1.props.order - btn2.props.order });
    }

    return (
        <div className={'dialog-context'}>
            {childElements && childElements.length > 1 && (
                <Row className={'step-progress'}>
                    {childElements.map((item, index) => (<Col
                        key={`dialog-progress-step-${index}`}
                        style={{width: `${progressWidth}%`}}
                        className={`step ${index <= max ? ('prev-active') : ''} ${index === step ? ('active') : ''}`}
                        onClick={() => jump(index)}
                        md={1}>
                        <p onClick={() => jump(index)}>
                            {item.props.icon}
                        </p>
                        <strong>{item.props.title}</strong>
                    </Col>))}
                </Row>
            )}

            <div className={'dialog-container'}>
                {currentRef.current.data && (currentRef.current.data)}
            </div>

            {step < (childElements.length - 1) && currentRef.current.data && display.next &&
            (<Row>
                <Col sm={12}>
                    <Button
                        disabled={!valid}
                        color={'primary'}
                        className='mt-4 mobile-no-mt float-end'
                        onClick={() => {
                            next();
                            return false;
                        }}
                    >
                        Weiter
                    </Button>
                </Col>
            </Row>)}

            {step === (childElements.length - 1) && currentRef.current.data && display.submit &&
            (<Row>
                {state[currentRef.current.data.props.name] && state[currentRef.current.data.props.name].id && (
                    <Col sm={12}>
                        <Button
                            type={'submit'}
                            color={'success'}
                            disabled={!valid}
                            className='mt-4 mobile-no-mt float-end submit-btn'
                            onClick={() => {
                                if(onSubmit) { onSubmit(state); }
                                return false;
                            }}
                        >
                            Änderungen Übernehmen
                        </Button>
                    </Col>
                )}
                {state[currentRef.current.data.props.name] && !state[currentRef.current.data.props.name].id && (
                <Col sm={12}>
                    {addSubmitButtons()}
                </Col>)}
            </Row>)}
        </div>
    );
};

Dialog.propTypes = {
    initialStep: PropTypes.number,
    submitLabel: PropTypes.string
};

Dialog.defaultProps = {
    initialStep: 0,
    submitLabel: 'Erstellen'

};

export default Dialog;
import React, {Fragment, useContext, useEffect, useState} from "react";
import Icon from "@components/Icon";
import Headline from "@components/Headline";
import Row from "@components/Row";
import Col from "@components/Col";
import AuthContext from "@context/AuthContext";
import taskServiceFactory from "@factories/taskServiceFactory";
import {rand} from "@utils/utils";
import moment from "moment";
import routeServiceFactory from "@factories/routeServiceFactory";
import Link from "@components/Link";
import eventServiceFactory from "@factories/eventServiceFactory";
import Alert from "@components/Alert";
import invoiceServiceFactory from "@factories/invoiceServiceFactory";
import documentServiceFactory from "@factories/documentServiceFactory";
import Checkbox from "@components/Checkbox";
import SocketContext from "../../context/socket";
import sound from '../assets/sounds/notificationsound.mp3';
import {toast} from "react-toastify";
import {hasPermission} from "@utils/utils";
import Button from "@components/Button";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const ShortNotifications = ({...args}) => {
    /**
     * Context
     */
    const {auth} = useContext(AuthContext);
    const {socket} = useContext(SocketContext);

    /**
     * States
     */
    const [collection, setCollection] = useState([]);
    const [filter, setFilter] = useState({tasks: true, events: true, invoices: true, documents: true, unseen: 1});
    const [pushNotificationPermission, setPushNotificationPermission] = useState(false);
    const [notifications, setNotifications] = useState({});
    const [soundOff, setSoundOff] = useState(false);
    const [soundDisabled, setSoundDisabled] = useState(false);


    /**
     * Vars
     */
    const audio = new Audio(sound);
    let audioVolume = document.cookie.match('medicharge_volume=([0-9]{1,3});?');

    if(audioVolume && audioVolume[1]) {
        audioVolume = parseInt(audioVolume[1]);
    } else {
        audioVolume = parseInt(50);
    }

    /**
     * Services
     */
    const routeService = routeServiceFactory();
    const taskService = taskServiceFactory({auth: auth});
    const eventService = eventServiceFactory({auth: auth});
    const invoiceService = invoiceServiceFactory({auth: auth});
    const documentService = documentServiceFactory({auth: auth});

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
    }, [filter]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(typeof Notification !== 'undefined') {
            Notification.requestPermission().then(function (permission) {
                setPushNotificationPermission(permission === 'granted');
            });
        }

        let enabled = document.cookie.match('medicharge_volume_enabled=([0-9]{1});?');

        if(enabled && enabled[1]) {
            setSoundDisabled(parseInt(enabled[1]) === 1);
        } else {
            setSoundDisabled(true);
        }

        setSoundOff(audioVolume === 0);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(pushNotificationPermission) {
            if (socket !== null) {
                socket.on(`notify_refresh_${auth.user}`, async function () {
                    fetch();
                });

                socket.on(`notify_${auth.user}`, async function (message) {
                    let entry = null;

                    const collection = [];

                    switch(message.type) {
                        case 'task':
                            entry = await taskService.get(message.id);
                            collection.push({
                                id: message.id,
                                title: 'Neuer Auftrag',
                                message: `Patient: ${getPatientName(entry.patient)}<div>Aktion: ${entry.medicine}</div>`,
                                time: `${moment(entry.modifiedAt).format('hh:mm')} Uhr`,
                                browser: 'Es wurde ein neuer Auftrag für Sie Eingestellt'
                            });
                            break;

                        case 'task_event':
                            entry = await taskService.get(message.id);
                            if(message.message === '[{SYSTEM_FILE_UPLOAD}]') {
                                collection.push({
                                    id: message.id,
                                    title: 'Neues Dokument',
                                    message: `<strong>Von</strong>: ${message.username}<br /><strong>Patient</strong>: ${getPatientName(entry.patient)}<br /><br /><strong>Dokument</strong>:<br />${message.files.map((file) => { return `</div><Icon icon="document" />${file}<br />` }).join('')}<br /><br /><strong>Aktion</strong>: ${entry.medicine}`,
                                    time: `${moment(message.modifiedAt).format('hh:mm')} Uhr`,
                                    browser: 'Neues Dokument in einer Aufgaben'
                                });
                            } else {
                                collection.push({
                                    id: message.id,
                                    title: 'Neue Nachricht',
                                    message: `<strong>Von</strong>: ${message.username}<br /><strong>Patient</strong>: ${getPatientName(entry.patient)}<br /><br /><strong>Nachricht</strong>:<br /> ${message.message}<br /><br /><strong>Aktion</strong>: ${entry.medicine}`,
                                    time: `${moment(message.modifiedAt).format('hh:mm')} Uhr`,
                                    browser: 'Neues Dokument in einer Aufgaben'
                                });
                            }

                            break;
                        case 'recipe_request':
                            let tasks = {};

                            for(let ii = 0; ii < message.tasks.length; ii++) {
                                const task = message.tasks[ii];
                                if(!tasks[task.patient._id]) {
                                    tasks[task.patient._id] = [];
                                }

                                tasks[task.patient._id].push(task);
                            }

                            tasks = Object.values(tasks);
                            for(let ii = 0; ii < tasks.length; ii++) {
                                const patientTasks = tasks[ii];
                                let table = '';

                                for(let jj = 0; jj < patientTasks.length; jj++) {
                                    const patientTask = patientTasks[jj];
                                    table += `<td>${patientTask.amount ? patientTask.amount : 1}x ${patientTask.medicine}</td><td>${patientTask.dose}</td>`
                                }

                                collection.push({
                                    id: message.id,
                                    title: 'Neue Rezept Anforderung',
                                    message: `<strong>Von</strong>: ${message.sender}<br />
                                    <strong>Patient</strong>: ${patientTasks[0].patient.firstname} ${patientTasks[0].patient.firstname}<br />
                                    <strong>Geburtstag</strong>: ${moment(patientTasks[0].patient.dateOfBirth).format('DD.MM.YYYY')}<br />
                                    <strong>Betreff</strong>: ${message.subject}<br />
                                    <strong>Nachricht</strong>: ${message.message}<br />
                                    <table class="notify-medicine-table">
                                        <tr>
                                            <th>Medikament</th>
                                            <th>Dosierung</td>
                                        </tr>               
                                        ${table}
                                    </table>
`,
                                    time: `${moment().format('hh:mm')} Uhr`,
                                    browser: 'Neue Rezept Anforderung'
                                });
                            }
                            break;
                        default:
                    }

                    for(let ii = 0; ii < collection.length; ii++) {
                        const message = collection[ii];

                        if(typeof Notification !== 'undefined') {
                            new Notification(message.title, { body: `${message.browser}` });
                            setNotifications({...notifications, [message.id]: {
                                    title: message.title,
                                    message: message.message
                                }});
                        }

                        toast(() => {
                            return <Fragment>
                                <h4 className={'push-notification-header'}>{message.title}<span className="push-notification-time" dangerouslySetInnerHTML={{__html: message.time}} /></h4>
                                <div className={'push-notification-body'}><div dangerouslySetInnerHTML={{__html: message.message}}
                                /></div>
                            </Fragment>
                        }, {
                            data: {
                                title: 'Hello World Again',
                                text: 'We are here again with another article'
                            },
                            className: 'push-notification',
                            position: "top-right",
                            autoClose: false,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                    const volume = document.getElementById('input-sound-volume');
                    audio.volume = volume.value/100;

                    if(!soundDisabled) {
                        audio.play();
                    }
                });
            }

            return () => {
                if (socket !== null) {
                    socket.off(`notify_${auth.user}`);
                    socket.off(`notify_refresh_${auth.user}`);
                }
            };
        }
    }, [pushNotificationPermission]);// eslint-disable-line react-hooks/exhaustive-deps

    /**y
     * Functions
     */
    const click = (e) => {
        setFilter({...filter, [e.target.name]: e.target.checked});
    }

    const getPatientName = (patient) => {
        if(patient && patient.firstname && patient.lastname) {
            return `${patient.firstname} ${patient.lastname}`
        }

        return 'Unbekannt';
    }

    const saveVolume = (volume) => {
        volume = volume.target ? volume.target.value : volume;
        document.cookie = `medicharge_volume=${volume}; expires=Thu, 18 Dec 2113 12:00:00 UTC; path=/`;

        setSoundOff(parseInt(volume) === 0);
        playSound();

    }

    const changeEnableSound = () => {
        setSoundDisabled(!soundDisabled);
        document.cookie = `medicharge_volume_enabled=${!soundDisabled ? 1 : 0}; expires=Thu, 18 Dec 2113 12:00:00 UTC; path=/`;
    }

    const playSound = () => {
        const volume = document.getElementById('input-sound-volume');
        audio.volume = volume.value/100;
        audio.play();
    }

    const renderEventMessage = (item) => {
        const content = [];

        content.push(`<strong>Von</strong>: ${item.createdFrom.username}<br /><br />`);
        if(item.data) {
            content.push(`<strong>Nachricht</strong>: ${item.data}<br /><br />`)
        }


        content.push(`<strong>Medikament</strong>: ${item.task.medicine}<br />`);
        content.push(`<strong>Dosierung</strong>: ${item.task.dose}`);

        return content.join('');
    }

    const fetch = async () => {
        let tasks = [];
        let events = [];
        let invoices = [];
        let documents = [];

        if(hasPermission(auth, 'task_list_view') && filter.tasks) {
            tasks = (await taskService.get(null, {notSeen: filter.unseen ? 1 : 0, status: 'new'})).items.map((item) => {
                return {
                    type: 'task',
                    seen: item.seen.includes(auth.user),
                    _id: item._id,
                    label: 'Neue Aufgabe',
                    date: moment(item.modifiedAt).format('DD.MM.YYYY'),
                    message: `Patient: ${getPatientName(item.patient)}<br />Medizin: ${item.medicine}`,
                    url: `${routeService.taskList()}?display[task]=${item._id}`
                }
            });
        }

        if(hasPermission(auth, 'task_list_view') && filter.events) {
            events = (await eventService.list({notSeen: filter.unseen ? 1 : 0, types: ['recipe_request', 'message']})).items.map((item) => {
                if(item.type === 'recipe_request') {
                    item.label = 'Neue Rezeptanforderung';
                    const data = JSON.parse(item.data);
                    item.data = `<div>${data.subject}</div><div>${data.message}</div>`;
                }

                return {
                    type: 'event',
                    seen: item.seen.includes(auth.user),
                    _id: item._id,
                    label: item.label ? item.label : 'Neues Ereignis',
                    date: moment(item.createdAt).format('DD.MM.YYYY'),
                    message: renderEventMessage(item),
                    url: `${routeService.taskList()}?display[task]=${item.task._id ? item.task._id : item.task}&display[event]=${item._id}`
                }
            });
        }

        if(hasPermission(auth, 'invoice_list_view') && filter.invoices) {
            invoices = (await invoiceService.get(null, {notSeen: filter.unseen ? 1 : 0})).items.filter((item) => item.status === 'open');

            invoices = invoices.map((item) => {
                return {
                    type: 'invoice',
                    seen: item.seen.includes(auth.user),
                    _id: item._id,
                    label: 'Neue Rechnung',
                    date: moment(item.createdAt).format('DD.MM.YYYY'),
                    message: '',
                    url: `${routeService.profile()}#scrollable-force-tab-2`
                }
            });
        }

        if(hasPermission(auth, 'document_list_view') && filter.documents) {
            documents = (await documentService.get(null, {notSeen: filter.unseen})).items.map((item) => {
                return {
                    type: 'document',
                    seen: item.seen.includes(auth.user),
                    _id: item._id,
                    label: 'Neues Dokument',
                    date: '',
                    message: '',
                    url: `${routeService.profile()}#scrollable-force-tab-4`
                }
            });
        }

        setCollection([...tasks, ...events, ...invoices, ...documents]);
    }

    const seen = async (id, type) => {
        switch(type) {
            case 'task':
                taskService.seen(id);
                break;
            case 'event':
                eventService.seen(id);
                break;
            default:
        }
        fetch();
    }


    const seenAll = async () => {
        if(filter.tasks) {
            await taskService.seen(null, true);
        }

        if(filter.events) {
            await eventService.seen();
        }

        fetch();
    }

    return <div className={'short-notification-container'}>
        <Icon icon={'message'} />
        {collection.length > 0 && (<div className={'bubble-count'}>{collection.length}</div>)}
        <div className={'short-notification-box dropdown-menu p-0'}>
            <div className='container'>
                <Row className={'mb-2'}>
                    <Col sm={12}><Headline size={'h4'}>Benachrichtigunen</Headline></Col>
                </Row>
                <Row className={'sound-position'}>
                    <Col sm={12}>
                        <Icon icon={soundOff || soundDisabled ? 'sound_off' : 'sound'} onClick={changeEnableSound} className={'notification-icon'}/>
                        <input type={'range'} id={'input-sound-volume'} disabled={soundDisabled} onChange={saveVolume} onClick={saveVolume} className={'notification-sound'} defaultValue={audioVolume} min={0} max={100}/>
                    </Col>
                </Row>
                <Row className={'filter-row mb-2'}>
                    <Col sm={12}>
                        <Checkbox onChange={click} className={'filter-check-box'} label={'Alle Benachrichtigungen anzeigen (letzten 50)'} value={'1'} name={'unseen'} checked={filter.unseen}/>
                    </Col>
                </Row>
                <Row className={'filter-row mb-2'}>
                    {hasPermission(auth, 'task_list_view') && (
                        <Col sm={5}><Checkbox onChange={click} className={'filter-check-box'} label={'Aufgaben'} value={'1'} name={'tasks'} checked={filter.tasks}/></Col>
                    )}
                    {hasPermission(auth, 'task_list_view') && (
                        <Col sm={5}><Checkbox onChange={click} className={'filter-check-box'} label={'Events'} value={'1'} name={'events'} checked={filter.events}/></Col>
                    )}
                    {hasPermission(auth, 'invoice_list_view') && (
                        <Col sm={5}><Checkbox onChange={click} className={'filter-check-box'} label={'Rechnungen'} value={'1'} name={'invoices'} checked={filter.invoices}/></Col>
                    )}
                    {hasPermission(auth, 'document_list_view') && (
                        <Col sm={5}><Checkbox onChange={click} className={'filter-check-box'} label={'Dokumente'} value={'1'} name={'documents'} checked={filter.documents}/></Col>
                    )}
                </Row>
                <Row className={'mb-3'}>
                    <Col sm={12}>
                        <Button fullwidth size={'small'} onClick={seenAll}>Alles auf Gelesen setzen</Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className='notification-list'>
                            {collection.map((item) => (
                                <Row key={rand()} className={'notification-row'}>
                                    <Col sm={12}>
                                        {!item.seen && (<Icon icon={'delete'} className={'remove-notification'} onClick={() => seen([item._id], item.type)}/>)}
                                        <Link to={item.url} className={'notification'}>
                                            <span className={'title'}>{item.label}</span><span className={'date'}>{item.date}</span>
                                            <div className={'info'} dangerouslySetInnerHTML={{__html: item.message}} />
                                        </Link>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </Col>
                </Row>
                {collection.length === 0 && (
                    <Row>
                        <Col sm={12}>
                            <Alert type={'warning'}>Es sind keine Neuen Benachrichtigungen verfügbar.</Alert>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    </div>
}

ShortNotifications.propTypes = {};

ShortNotifications.defaultProps = {};

export default ShortNotifications;
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import {addClass, rand} from "@utils/utils"; //comment in if you add Proptypes to your Component


const ToolTip = ({trigger, arrow, children, place, type, effect, className, ...args}) => {

    const id = `tooltip-${rand()}-${rand()}`

    return <Fragment>
        <div data-tip data-for={id} className={addClass(['tooltip-trigger', className])}>
            {trigger}
        </div>
        <ReactTooltip id={id} className={addClass([`effect-${effect}`, arrow ? '' : 'hide-arrow'])} place={place} type={type} effect={effect}>
            {typeof children === 'string' && <span dangerouslySetInnerHTML={{__html: children}} />}
            {typeof children !== 'string' && children}
        </ReactTooltip>
    </Fragment>
}

ToolTip.propTypes = {
    place: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
    type: PropTypes.oneOf(['dark', 'success', 'warning', 'error', 'info', 'light']),
    effect: PropTypes.oneOf(['float', 'solid']),
    arrow: PropTypes.bool
};

ToolTip.defaultProps = {
    place: 'top',
    type: 'light',
    effect: 'float',
    arrow: false,
    className: '',
};

export default ToolTip;
import React  from 'react';
import ContentWrapper from '@components/ContentWrapper';
// import Footer from '@components/Footer';
// import PageWrapper from '@components/PageWrapper';
// import Navbar from '../parts/Navbar';
// import SideBar from '../parts/SideBar';

const DefaultLayout = ({ children }) => {
    return (<div className={'v3'}>
        <ContentWrapper>{children}</ContentWrapper>
    </div>);
    // return (
    //     <Fragment>
    //         <SideBar />
    //         <PageWrapper>
    //             <Navbar />
    //             <ContentWrapper>{children}</ContentWrapper>
    //             <Footer />
    //         </PageWrapper>
    //     </Fragment>
    // );
};

export default DefaultLayout;

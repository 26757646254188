/* jshint esversion: 8 */
import React, {Fragment, useContext, useState, useEffect} from "react";
import AuthContext from "@context/AuthContext";
import contractServiceFactory from "@factories/contractServiceFactory";
import Table from "@components/Table/Table";
import moment from "moment";
import ContractCollapse from "./ContractCollapse";
import ContractCollapseV3 from "./ContractCollapseV3";
import roleEnum from "@enum/roleEnum";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const ContractTab = ({...args}) => {

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [possibleOptions, setPossibleOptions] = useState({});

    /**
     * Services
     */
    const contractService = contractServiceFactory({auth});
    const tableService = {};
    const contractTableService = {
        get: async () => {
            const response = await contractService.get();

            let contracts = [];

            if(response.contracts) {
                for (const item of response.contracts) {
                    const assumptionOfCosts = item.assumptionOfCosts ? item.assumptionOfCosts : false;
                    const ownContract = item.ownContract ? item.ownContract : false;

                    let contract = item.list.pop();

                    if (contract) {
                        const mom = moment(contract.validFrom);
                        if (mom.isAfter()) {
                            if (item.list.length > 0) {
                                let tmpContract = item.list.pop();
                                contract.prevContract = item.list.pop();
                                tmpContract.nextContract = contract;
                                contract = tmpContract;
                            }
                        } else {
                            contract.prevContract = contract.contractRef;
                            delete contract.contractRef;
                        }

                        contract.ownContract = ownContract;
                        contract.assumptionOfCosts = assumptionOfCosts;
                        contract.optionItems = contract.items;
                        contract.cType = contract.items;
                        if (contract.home) {
                            contract.cType = roleEnum.ROLE_HOME;
                        } else if (contract.doctor) {
                            contract.cType = roleEnum.ROLE_DOCTOR;
                        } else {
                            contract.cType = roleEnum.ROLE_PHARMA;
                        }
                        delete contract.items;

                        contracts.push(contract);
                    }
                }
            }

            return {
                success: true,
                items: contracts,
                max: contracts.length
            }
        }
    }

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const fetch = async () => {
        const response = await contractService.get();
        if (response.success) {
            setPossibleOptions(response.possibleOptions);
        }
    }

    const collapse = (item) => {
        if(item.version && item.version === 3) {
            return <ContractCollapseV3 refresh={tableService.refresh} item={item} possibleOptions={possibleOptions[item.cType][item.runtime]}/>
        }

        return <ContractCollapse refresh={tableService.refresh} item={item} possibleOptions={possibleOptions[item.cType][item.runtime]}/>
    }

    /**
     * Columns
     */
    const columns = [
        {
            title: 'Unternehmen', render: (item) => {
                if (item.cType === roleEnum.ROLE_HOME) {
                    return item.home.name;
                } else if (item.cType === roleEnum.ROLE_DOCTOR) {
                    return `${item.doctor.title} ${item.doctor.firstname} ${item.doctor.lastname}`
                }

                return item.company;
            }
        },
        {
            title: 'Mit Login', render: (item) => {
                return item.withLogin ? 'Ja' : 'Nein'
            }
        },
        {
            title: 'Von', render: (item) => {
                const contract = item.prevContract ? item.prevContract : item;

                const mom = moment(contract.validFrom);
                return mom.format('DD.MM.YYYY');
            }
        },
        {
            title: 'Bis', render: (item) => {
                const contract = item.prevContract ? item.prevContract : item;

                const mom = moment(contract.validTo);
                return mom.format('DD.MM.YYYY');
            }
        },
        {
            title: 'Vertragsart', render: (item) => {
                const contract = item.nextContract ? item.nextContract : item;
                let nextContractType = '';

                if(contract !== item) {
                    nextContractType = `<span class="contract-period-change">(wechsel zu ${contract.product.runtime === "month" ? 'Monatsvertrag' : 'Jahresvertrag'})</span>`;
                }

                const runtime = item.product.runtime === "month" ? 'Monatsvertrag' : 'Jahresvertrag';
                if (contract.cType === roleEnum.ROLE_HOME) {
                    return `${runtime} Pflegeeinrichtung${nextContractType}`
                } else if (contract.cType === roleEnum.ROLE_DOCTOR) {
                    return `${runtime} Arzt/Ärztin${nextContractType}`
                }

                return `${runtime} Apotheke${nextContractType}`;
            }
        },
    ];

    return <Fragment>
        <Table
            self={tableService}
            columns={columns}
            service={contractTableService}
            onCollapseContent={collapse}
            searchable={false}
        />
    </Fragment>
}

ContractTab.propTypes = {};

ContractTab.defaultProps = {};

export default ContractTab;
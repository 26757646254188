import factory from "@factories/factory";

const medicineServiceFactory = (config) => {
    return {
        ...factory,
        ...config,
        path: 'medicine',
        messages: {
            update: 'Medizin wurde erfolgreich aktualisiert.'
        }
    }}

export default medicineServiceFactory;
import Col from "@components/Col";
import Row from "@components/Row";
import React, {Fragment, useContext, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Input from "@components/Input";
import Select from "@components/Select";
import {isValid, registerValidationFailureMessages, validate} from "@utils/forms";
import {has, isset} from "@utils/utils";
import doctorServiceFactory from "@factories/doctorServiceFactory";
import AuthContext from "@context/AuthContext";

const Organisation = ({readonly, onChange, setValid, form, name, edit, description}) => {
    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [errors, setErrors] = useState({});
    const [state, setState] = useState(form);
    const [validationFailureMessages, setValidationFailureMessages] = useState(null);
    const [doctors, setDoctors] = useState([]);

    const definedValidationFailureMessages = {
        name: ['Bitte vergeben Sie einen Namen.'],
        street: ['Bitte Straße angeben'],
        zip: ['Bitte Postleitzahl angeben'],
        city: ['Bitte Ort angeben'],
        phone: {messages: ['Bitte Telefonnummer angeben'], state: {field: 'contact_type', value: 'phone'}},
        fax: {messages: ['Bitte Faxnummer angeben'], state: {field: 'contact_type', value: 'fax'}},
        mail: {messages: ['Bitte E-Mail angeben'], state: {field: 'contact_type', value: 'mail'}}
    }

    /**
     * Services *
     */
    const doctorService = doctorServiceFactory({auth});

    /**
     * Hooks
     */
    useEffect(() => {
        let data = form[name] ? {...form[name]} : {};
        data = edit ? form : data;

        if(!data.contact_type) {
            data.contact_type = 'phone'
        }

        data.type = isset(form, 'product_selection.product.services') && has(form.product_selection.product.services, 'create-doctor-office') ? 'select' : 'create';
        data.edit =  edit //|| ((isset(form, 'product_office.product.services') && has(form.product_office.product.services, 'create-doctor-office')) || (isset(form, 'product_selection.product.services') && has(form.product_selection.product.services, 'create-doctor-office')));

        setState(data);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        fetchDoctors();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        registerValidationFailureMessages(definedValidationFailureMessages, state, setValidationFailureMessages);
        if(setValid) {
            setValid(isValid(validationFailureMessages, errors, state, setErrors));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    /**
     * Functions
     */
    const change = (e) => {
        validate(e, validationFailureMessages, errors, setErrors);

        setState({...state, [e.target.name]: e.target.value});

        if(onChange) {
            onChange({...state, [e.target.name]: e.target.value});
        }

        return false;
    }

    /**
     * Functions
     */
    const changeDoctors = (e) => {
        validate(e, validationFailureMessages, errors, setErrors);

        setState({...state, [e.target.name]: e.target.value});

        if(onChange) {
            onChange({...state, [e.target.name]: e.target.value});
        }

        return false;
    }

    const fetchDoctors = async () => {
        try {
            const response = await doctorService.get(null, {}, 0, -1);
            setDoctors(response.items.map((item) => {
                return {key: item._id, label: `${item.firstname} ${item.lastname}`, value: item}
            }));
        } catch(e) {
            console.log(e);
        }
    }

    return (<Fragment>
                    {state.type === 'create' && (<Fragment>
                        <p className='text-muted text-center mb-4 pb-2'>
                            {description || 'Geben Sie die Daten für ihre neue Arztpraxis ein.'}
                        </p>
                        <div className='container'>
                            <h5 className={'text-uppercase mt-5 mb-4'}>Stammdaten</h5>
                            <Row>
                                <Col sm={12}>
                                    <Input
                                        readonly={readonly}
                                        required
                                        errors={errors.name}
                                        valid={state.name && state.name.length > 0 && !errors.name}
                                        name={'name'}
                                        label={'Name'}
                                        placeholder='Name'
                                        value={state.name ? state.name : ''}
                                        description={''}
                                        onChange={change} />
                                </Col>
                            </Row>
                            <h5 className={'text-uppercase mt-5 mb-4'}>Addresse</h5>
                            <Row className={'mt-3'}>
                                <Col sm={12}>
                                    <Input
                                        readonly={readonly}
                                        required
                                        errors={errors.street}
                                        valid={state.street && state.street.length > 0 && !errors.street}
                                        name={'street'}
                                        type={'text'}
                                        label={'Straße'}
                                        placeholder='Straße'
                                        value={state.street ? state.street : ''}
                                        onChange={change} />
                                </Col>
                            </Row>
                            <Row className={'mt-3'}>
                                <Col sm={12} md={4}>
                                    <Input
                                        readonly={readonly}
                                        required
                                        errors={errors.zip}
                                        valid={state.zip && state.zip.length > 0 && !errors.zip}
                                        name={'zip'}
                                        type={'text'}
                                        label={'Postleitzahl'}
                                        placeholder='12345'
                                        value={state.zip ? state.zip : ''}
                                        onChange={change} />
                                </Col>
                                <Col sm={12} md={8}>
                                    <Input
                                        readonly={readonly}
                                        required
                                        errors={errors.city}
                                        valid={state.city && state.city.length > 0 && !errors.city}
                                        name={'city'}
                                        type={'text'}
                                        label={'Ort'}
                                        placeholder='Ort'
                                        value={state.city ? state.city : ''}
                                        onChange={change} />
                                </Col>
                            </Row>
                            <h5 className={'text-uppercase mt-5 mb-4'}>Kontakt</h5>
                            <Row className={'mt-3'}>
                                <Col sm={12}>
                                    <Select
                                        readonly={readonly}
                                        name={'contact_type'}
                                        label={'Primär Kontakt Art'}
                                        defaultValue={state.contact_type ? state.contact_type : ''}
                                        options={[{key: 'phone', label: 'Telefon'}, {key: 'mail', label: 'E-Mail'}, {key: 'fax', label: 'Fax'}]}
                                        onChange={change}
                                    >
                                    </Select>
                                </Col>
                            </Row>
                            <Row className={'mt-3'}>
                                <Col sm={4}>
                                    <Input
                                        readonly={readonly}
                                        required={!state.contact_type || state.contact_type === 'phone'}
                                        errors={errors.phone}
                                        valid={state.phone && state.phone.length > 0 && !errors.phone}
                                        name={'phone'}
                                        type={'tel'}
                                        label={'Telefon'}
                                        placeholder='Telefon'
                                        value={state.phone ? state.phone : ''}
                                        onChange={change} />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        readonly={readonly}
                                        required={state.contact_type === 'mail'}
                                        errors={errors.mail}
                                        valid={state.mail && state.mail.length > 0 && !errors.mail}
                                        name={'mail'}
                                        type={'email'}
                                        label={'E-Mail'}
                                        placeholder='E-Mail'
                                        value={state.mail ? state.mail : ''}
                                        onChange={change} />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        readonly={readonly}
                                        required={state.contact_type === 'fax'}
                                        errors={errors.fax}
                                        valid={state.fax && state.fax.length > 0 && !errors.fax}
                                        name={'fax'}
                                        type={'text'}
                                        label={'Fax'}
                                        placeholder='Fax'
                                        value={state.fax ? state.fax : ''}
                                        onChange={change} />
                                </Col>
                            </Row>
                            {!readonly && (<Fragment>
                            <h5 className={'text-uppercase mt-5 mb-4'}>Ärzte</h5>
                            <Row className={'mt-3'}>
                                <Select
                                    defaultValue={state.doctors ? state.doctors.map(item => item._id) : []}
                                    name={'doctors'} isMulti={true} options={doctors} onChange={changeDoctors}/>
                            </Row></Fragment>)}
                        </div>
                    </Fragment>)}
        </Fragment>
    );
}

Organisation.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    name: PropTypes.string.isRequired
};

Organisation.defaultProps = {
    readonly: false,
    edit: false
};

export default Organisation;
import React from 'react';
import Timer from 'react-countdown';
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component

/**
 * DOKU: https://npm.io/package/react-countdown
 */

const Countdown = ({ className, date, callback, ...args }) => {
	/**
	 * Functions
	 */
	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return callback;
		} else {
			// Render a countdown
			return (
				<span>
					<span className={'timer-value'}>{days}</span> Tage <span className={'timer-value'}>{hours}</span> Stunden <span className={'timer-value'}>{minutes}</span> Minuten <span className={'timer-value'}>{seconds}</span> Sekunden
				</span>
			);
		}
	};

	return (
		<div className={className}>
			<Timer date={date.unix() * 1000} renderer={renderer} />
		</div>
	);
};

Countdown.propTypes = {
};

Countdown.defaultProps = {
	className: ''
};

export default Countdown;

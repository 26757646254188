import React, {useContext} from 'react';
import Button from "./Button";
import Modal from "./Modal";
import Link from "./Link";
import AuthContext from "@context/AuthContext";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {translate} from "@utils/utils";

const ContractRequiredModal = ({ type, label, className, fullWidth, message, ...args }) => {
    /**
     * Context
     */
    const {auth} = useContext(AuthContext);
    const { t } = useTranslation();

    /**
     * Functions
     */
    const getToggle = () => {
        if(type === 'button') {
            return (<Button fullwidth={fullWidth} className={`${className} contract-required-btn`} color={'warning'}>{label}</Button>);
        } else {
            return <span className={`${className} contract-required-link`}>{label}</span>
        }
    }

    return (<Modal
        size={[768, 380]}
        button={getToggle()}
        title={'Vertrag benötigt!'}

    >
        {message && (<div className={'contract-required-text'}><div dangerouslySetInnerHTML={{__html: translate(t, message, auth)}}/></div>)}

        {auth.roles.includes('ROLE_MANAGER') && (
            <div className={'contract-required-link-container'}>
                <Link type={'button-success'} to={'/profil?tab=3'}>Vertrag übernehmen</Link>
            </div>
        )}
    </Modal>);
}

ContractRequiredModal.propTypes = {
    type: PropTypes.oneOf(['normal', 'button'])
};

ContractRequiredModal.defaultProps = {
    type: 'normal',
    fullWidth: false,
    messages: ''

};

export default ContractRequiredModal;
import React from 'react';
import defaultAvatar from '../../assets/img/default.png';
import { FiUser, FiLogOut } from 'react-icons/fi';
import NavbarDropdown from '@components/NavbarDropdown';
import User from '@components/User';
import ProfileMenuWrapper from '@components/ProfileMenuWrapper';
import ProfileMenuLink from '@components/ProfileMenuLink';
import routeServiceFactory from "@factories/routeServiceFactory";

const ProfileMenu = () => {

    /**
     * Services
     */
    const routeService = routeServiceFactory();

    return (
        <NavbarDropdown
            icon={<img className='wd-30 ht-30 rounded-circle' src={defaultAvatar} alt='profile' />}
            id='profile'
        >
            <User />
            <ProfileMenuWrapper>
                <ProfileMenuLink icon={<FiUser className='me-2 icon-md' />} to={routeService.profile()}>
                    Profil
                </ProfileMenuLink>
                <ProfileMenuLink icon={<FiLogOut className='me-2 icon-md' />} to={routeService.logout()}>
                    Abmelden
                </ProfileMenuLink>
            </ProfileMenuWrapper>
        </NavbarDropdown>
    );
};

export default ProfileMenu;

import roleEnum from "@enum/roleEnum";
import taskTypeEnum from "@enum/taskTypeEnum";

const getTaskTypeName = (value) => {
    switch(value) {
        case taskTypeEnum.ORDER:
            return 'Auftrag';
        case taskTypeEnum.DOCTOR_OFFICE_RECIPE:
            return 'Rezept in Arztpraxis';
        case taskTypeEnum.LIVING_AREA_RECIPE:
            return 'Rezept in Wohnbereich';
        case taskTypeEnum.SELF_PURCHASE:
            return 'Selbstkauf';
        default:
            return '';
    }
}

const getRoleName = (value) => {
    switch(value) {
        case roleEnum.ROLE_ADMIN:
            return 'Administrator';
        case roleEnum.ROLE_MANAGER:
            return 'Manager';
        case roleEnum.ROLE_PHARMA:
            return 'Apotheker';
        case roleEnum.ROLE_DOCTOR:
            return 'Arzt';
        case roleEnum.ROLE_HOME:
            return 'Heim';
        case roleEnum.ROLE_DRIVER:
            return 'Fahrer';
        case roleEnum.ROLE_ACCOUNTING:
            return 'Buchhalter';
        default:
            return '';
    }
}

export { getRoleName, getTaskTypeName };